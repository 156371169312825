import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../environment/environment.service';
import { TermOfUseConsentStatus } from '@mwe/models';
import { REQUEST_APPROVAL_CONDITIONS_OF_USE } from '@mwe/constants';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LogWienConditionsOfUseService {
  resourceUrl: string;
  private consentStatus: TermOfUseConsentStatus;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/termofuse/';
  }

  async getRouteForConsentStatus(): Promise<string> {
    await this.getTermOfUseConsentStatus();

    if (this.consentStatus.consent) {
      return null;
    }

    const route = REQUEST_APPROVAL_CONDITIONS_OF_USE;
    return `/${this.environmentService.getPortalUrlPrefix()}/${route}`;
  }

  async approve(): Promise<Object> {
    return lastValueFrom(this.http.post(this.resourceUrl + 'consent', { consent: true }));
  }

  async decline(): Promise<Object> {
    return lastValueFrom(this.http.post(this.resourceUrl + 'consent', { consent: false }));
  }

  private async getTermOfUseConsentStatus(): Promise<void> {
    // only skip backend request when user has approved migration. to recheck backend every time in case of no approval
    if (this.consentStatus?.consent) {
      return;
    }

    const response = await lastValueFrom(this.http.get<TermOfUseConsentStatus>(this.resourceUrl + 'status'));
    this.consentStatus = response;
  }
}
