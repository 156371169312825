import { Component, Input } from '@angular/core';
import { LinkListGroupItem } from '@mwe/models';

@Component({
  selector: 'mwe-link-list-group',
  templateUrl: './link-list-group.component.html',
})
export class LinkListGroupComponent {
  @Input() items: LinkListGroupItem[];
}
