import { inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TrackingService } from '@ikp/service/tracking/tracking-service';

@Injectable({
  providedIn: 'root',
})
export class TrackingGuard implements CanActivate {
  private trackingService = inject(TrackingService);

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.trackingService.trackVirtualPageView(state.url);
    return true;
  }
}
