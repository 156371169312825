import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AnmeldeDetails, Abonnent, HTTPResponse, HTTPError } from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  get resourceUrl(): string {
    return `${this.environmentService.getApiUrl()}api/newsletter/`;
  }

  async setNewsletterSubscription(anmeldeDetails: AnmeldeDetails): Promise<HTTPResponse<void>> {
    try {
      const res = await lastValueFrom(
        this.http.put<void>(`${this.resourceUrl}subscription`, anmeldeDetails, {
          observe: 'response',
        }),
      );
      return new HTTPResponse(res.status.toString(), 'IL', undefined);
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async getNewsletterSubscriptionState(): Promise<HTTPResponse<Abonnent>> {
    try {
      const params = new HttpParams().set('kundentyp', this.environmentService.getPortalCustomerType());
      const res = await lastValueFrom(this.http.get<Abonnent>(`${this.resourceUrl}exists`, { observe: 'response', params }));
      return new HTTPResponse(res.status.toString(), 'IL', res.body);
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }
}
