<mwe-tariff-card-template
  [hasDiscount]="hasDiscount"
  [state]="state"
  [category]="product.category"
  [title]="isWaerme ? (product.category | translateCategory | async) : (product | translateTariffClass | async)"
  [subTitle]="isWaerme ? '' : (product.category | translateCategory | async)"
  [headlineReference]="headlineReference"
>
  <ng-content></ng-content>
</mwe-tariff-card-template>
