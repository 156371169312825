import { computed, inject, Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { ApiService } from '../api/api.service';
import { toSignal } from '@angular/core/rxjs-interop';
@Injectable({
  providedIn: 'root',
})
export class IkpStaticContentService {
  private apiService = inject(ApiService);
  #temporaryInfoAlert = toSignal(this.apiService.getTemporaryAlertData());
  temporaryInfoAlert = computed(() => this.#temporaryInfoAlert());
  showTemporaryInfoAlert = computed(() => {
    return !!this.isTemporaryInfoAlertValid();
  });
  isTemporaryInfoAlertValid() {
    if (this.temporaryInfoAlert()) {
      const validFrom = dayjs(this.temporaryInfoAlert().validFrom);
      const validTo = dayjs(this.temporaryInfoAlert().validTo);
      if (validFrom.isBefore(dayjs()) && validTo.isAfter(dayjs())) {
        return true;
      }
    }
    return false;
  }
}
