<button
  *ngIf="infoText"
  class="btn btn-clean ml-1 {{ colorClass }}"
  (click)="iconClicked()"
  type="button"
  [attr.title]="'tariff.details.furtherInformations' | translate"
  [attr.aria-label]="'tariff.details.furtherInformations' | translate"
>
  <i [attr.data-test]="dataTest" class="fas fa-info-circle fa-w-16 pointer my-auto"></i>
</button>
