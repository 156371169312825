export interface IHTTPError {
  status: string;
  error: any;
}

export class HTTPError implements IHTTPError {
  system: 'BE' | 'IL' | 'WP';
  code: string;

  constructor(public status: string, public error: any) {
    this.status = status + ''; // enforce cast to string
    this.error = this.getCorrectErrorDepth(error);

    const errorMessage = Array.isArray(this.error) ? this.error[0]?.message : this.error?.message;

    if (errorMessage?.indexOf('System-IL') > -1) {
      // {code=2005, message={System-IL} ....
      this.system = 'IL';
    }
    this.code = this.error?.code;
  }

  getCorrectErrorDepth(givenErrorParam: any) {
    if (Array.isArray(givenErrorParam) && givenErrorParam.length > 0) {
      givenErrorParam = givenErrorParam[0];
    }

    return givenErrorParam?.error?.error || givenErrorParam?.error || givenErrorParam;
  }

  is4xxError(): boolean {
    return this.status.charAt(0) === '4';
  }

  isILError(): boolean {
    return this.system === 'IL';
  }

  isBackendError(): boolean {
    return this.system === 'BE';
  }

  isWPError(): boolean {
    return this.system === 'WP';
  }
}
