import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerDataChangeDTO, HTTPError, ICustomerDataChangeResponse, parseCustomerDataChangeItemDTO } from '@mwe/models';
import { EnvironmentService } from '../../environment/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataChangeService {
  private resourceUrl: string;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/customer/';
  }

  async confirm(dataDTO: CustomerDataChangeDTO): Promise<ICustomerDataChangeResponse> {
    try {
      return await lastValueFrom(this.http.post<ICustomerDataChangeResponse>(this.getResourceUrl('change/confirm'), dataDTO));
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async retry(submitId: number, dataDTO: CustomerDataChangeDTO): Promise<ICustomerDataChangeResponse> {
    try {
      return await lastValueFrom(
        this.http.put<ICustomerDataChangeResponse>(this.getResourceUrl('change/retry') + '?submitId=' + submitId, dataDTO),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  confirmStatus(submitId: number): Promise<ICustomerDataChangeResponse> {
    return lastValueFrom(this.http.get<ICustomerDataChangeResponse>(this.getResourceUrl('change/confirmStatus') + '?submitId=' + submitId));
  }

  getOrderStatusDetails(orderId: string): Promise<CustomerDataChangeDTO> {
    return lastValueFrom(this.http.get(this.resourceUrl + 'orderStatusDetails/' + orderId)).then(res => {
      return parseCustomerDataChangeItemDTO(res);
    });
  }

  private getResourceUrl(path: string): string {
    return this.resourceUrl + path;
  }
}
