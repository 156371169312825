import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiComponentsModule } from '@mwe/ui/components';
import { UiCommonsModule } from '@mwe/ui/commons';
import { EnvironmentService, ProfileService } from '@mwe/services';
import { TranslateService } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IkpEnvironmentService } from './service/environment/ikp-environment.service';
import { FooterComponent } from './layout/footer/footer.component';
import { CookieComponent } from './components/cookie/cookie.component';
import { MazeComponent } from './components/maze/maze.component';

import { NgIf } from '@angular/common';
import { HeaderComponent } from '@shared/layout/header/header.component';
import { Meta } from '@angular/platform-browser';
import { version } from './environments/version';
import { KeycloakRefreshService } from '@shared/service/keycloak-refresh.service';
import { KeycloakEventLoggerService } from '@shared/service/keycloakEventLogger.service';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    UiComponentsModule,
    UiCommonsModule,
    FontAwesomeModule,
    HeaderComponent,
    FooterComponent,
    CookieComponent,
    MazeComponent,
    NgIf,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [ProfileService, EnvironmentService, Meta],
})
export class AppComponent {
  isCookieConsentEnabled: boolean;
  isMazeAnalyticsEnabled: boolean;
  private envService = inject(IkpEnvironmentService);
  private metaService = inject(Meta);
  private keycloakRefreshService: KeycloakRefreshService;
  private keycloakEventLoggerService: KeycloakEventLoggerService;
  public translate = inject(TranslateService);

  constructor() {
    this.translate.setDefaultLang('de');
    this.translate.addLangs(['de', 'en']);
    this.translate.use('de');
    this.metaService.addTag(
      {
        name: 'version',
        content: version,
      },
      true,
    );

    this.isCookieConsentEnabled = this.envService.getPiwikTagPropertyId() !== '';
    this.isMazeAnalyticsEnabled = this.envService.getMazePropertyId() !== '';
    this.keycloakRefreshService = inject(KeycloakRefreshService);
    if (!this.envService.isProduction()) {
      this.keycloakEventLoggerService = inject(KeycloakEventLoggerService);
    }
  }
}
