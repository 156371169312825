import { Component, Input } from '@angular/core';
import { IFormInput } from '@mwe/models';
import { UntypedFormGroup } from '@angular/forms';
import { getFormErrorText, isFormErrorVisible } from '@mwe/utils';

@Component({
  selector: 'mwe-form-input-error',
  templateUrl: './form-input-error.component.html',
})
export class FormInputErrorComponent {
  @Input() inputElem: IFormInput;
  @Input() parentForm: UntypedFormGroup;
  @Input() errorId: string;

  isErrorVisible(): boolean {
    const control = this.parentForm.get(this.inputElem.name);
    return isFormErrorVisible(this.inputElem, control);
  }

  getErrorText(): string {
    const control = this.parentForm.get(this.inputElem.name);
    return getFormErrorText(this.inputElem, control);
  }
}
