<div class="d-flex justify-content-between pb-4-md pb-2 pointer" (click)="collapse.toggle()">
    <span class="mb-0 open-item-text light-gray">
      {{ groupTitle | translate }}
      <i class="fa fa-chevron-circle-down orange" [ngClass]="{ 'rotate-180': !isCollapsed }"></i>
    </span>
</div>
<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
  <div class="d-flex justify-content-between border-top pt-2 pb-2"
       [ngClass]="{'mt-3': first}"
       *ngFor="let item of items; let first = first;">
    <span class="mb-0 open-item-text light-gray">{{ item }}</span>
  </div>
</div>
