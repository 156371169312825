import { Pipe, PipeTransform } from '@angular/core';
import { IPersonalData } from '@mwe/models';

@Pipe({
  name: 'personalPhone'
})
export class PersonalPhonePipe implements PipeTransform {
  transform(value: IPersonalData): string {
    if (!value.telefonCountryCode) {
      return value.telefonNumber;
    }

    return `${value.telefonCountryCode} ${value.telefonNumber}`;
  }

}
