import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'mwe-sliding-card',
  templateUrl: './sliding-card.component.html',
})
export class SlidingCardComponent {
  @Output() onCardSelect = new EventEmitter<void>();
  @Input() text: string;
  @Input() id: string;
  @Input() type: 'default' | 'featured' = 'default';
  cardIsFocused = false;

  constructor() {}

  onClick(event): void {
    event.preventDefault();
    this.onCardSelect.emit(event);
  }
}
