import { Injectable } from '@angular/core';
import { FeatureToggledOutInfo, FeatureToggleType, FrontpageAlertData } from '@mwe/models';
import { LocalStorageService, SessionStorageService, SyncStorage } from 'ngx-webstorage';
import { LoggingService } from './../logging/logging.service';
import { MemoryCacheItem, MemoryCacheService } from './memory-cache.service';
import { accountSessionIdKey } from '../account/state/account-state.service';
import { ProductStateService } from '../products/product-state.service';
import { CookieService } from 'ngx-cookie-service';

export const appStorageKeys = {
  redirectUrl: 'redirectURL',
  requestedRoute: 'requested_route',
  emailInvitation: 'emailInvitation',
};

const redirectURL = appStorageKeys.redirectUrl;
const orderTitles = 'order_titles';
const emailInvitation = 'emailInvitation';
const maintenanceWhitelist = 'maintenance_whitelist';
const featureTogglesOverwrite = 'feature_toggles_overwrite';
const currentFeatureToggledOutInfo = 'current_feature_toggled_out_info';
const requestedRoute = appStorageKeys.requestedRoute; //set by Keycloak

@Injectable({ providedIn: 'root' })
export class AppStorageService {
  private _authServerFailure: FrontpageAlertData;

  constructor(
    private loggingService: LoggingService,
    private productStateService: ProductStateService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private memoryCacheService: MemoryCacheService,
    private cookieService: CookieService,
  ) {}

  clear(): void {
    this.clearSessionStorage();
    this.productStateService.clear();
    this.memoryCacheService.clear(MemoryCacheItem.ProductDetails);
    this.memoryCacheService.clear(MemoryCacheItem.PartnerDetails);
    this.removeRequestedRoute();
  }

  clearSessionStorage() {
    const sessionId = this.sessionStorageService.retrieve(accountSessionIdKey);
    this.sessionStorageService.clear();
    this.sessionStorageService.store(accountSessionIdKey, sessionId);
  }

  clearDataAfterProcess(): void {
    this.productStateService.clearRecentOrders();
    this.memoryCacheService.clear(MemoryCacheItem.ProductDetails);
    this.memoryCacheService.clear(MemoryCacheItem.PartnerDetails);
    this.removeRequestedRoute();
  }

  clearDataAndGroupsAfterProcess(): void {
    this.productStateService.clearRecentOrdersAndGroups();
  }

  setEntryUrl(link: string): void {
    this.set(redirectURL, link);
  }

  getEntryUrl(): string {
    return this.get(redirectURL) ? this.get(redirectURL) : '/';
  }

  // TODO Typisieren
  setOrderTitles(response: any): void {
    this.set(orderTitles, response);
  }

  getOrderTitles(): any {
    return this.get(orderTitles);
  }

  setEmailInvitation(value: boolean): void {
    this.set(emailInvitation, value, true);
  }

  isEmailInvitation(): boolean {
    return this.get(emailInvitation, true) === true;
  }

  removeEmailInvitation(): void {
    this.removeItem(emailInvitation, true);
  }

  setMaintenanceWhitelist(whitelist: string[]): void {
    this.set(maintenanceWhitelist, whitelist);
  }

  getMaintenanceWhitelist(): string[] {
    return this.get(maintenanceWhitelist);
  }

  getFeatureTogglesOverwrite(): FeatureToggleType {
    return this.getFromStorage(featureTogglesOverwrite) ?? {};
  }

  getCurrentFeatureToggledOutInfo(): FeatureToggledOutInfo {
    return this.get(currentFeatureToggledOutInfo);
  }

  setCurrentFeatureToggledOutInfo(value: FeatureToggledOutInfo) {
    if (!value) {
      this.removeItem(currentFeatureToggledOutInfo);
      return;
    }

    this.set(currentFeatureToggledOutInfo, value);
  }

  removeRequestedRoute(): void {
    this.cookieService.delete(requestedRoute);
  }

  private set(key: string, value: any, useLocalStorage?: boolean): void {
    const storage = this.getStorageService(useLocalStorage);

    try {
      storage.store(key, typeof value === 'string' ? value : JSON.stringify(value));
    } catch (e) {
      this.loggingService.logError(e);
    }
  }

  private get(key: string, useLocalStorage?: boolean): any {
    const storage = this.getStorageService(useLocalStorage);

    try {
      return JSON.parse(storage.retrieve(key));
    } catch (err) {
      return storage.retrieve(key);
    }
  }

  private getFromStorage(key: string, useLocalStorage?: boolean): any {
    const storage = this.getStorage(useLocalStorage);

    try {
      return JSON.parse(storage.getItem(key));
    } catch (err) {
      return storage.getItem(key);
    }
  }

  private removeItem(key: string, useLocalStorage?: boolean): void {
    const storage = this.getStorageService(useLocalStorage);
    storage.clear(key);
  }

  private getStorageService(useLocalStorage?: boolean): SyncStorage {
    return useLocalStorage ? this.localStorageService : this.sessionStorageService;
  }

  private getStorage(useLocalStorage?: boolean): Storage {
    return useLocalStorage ? localStorage : sessionStorage;
  }

  getAuthServerFailure(): FrontpageAlertData {
    const data = { ...this._authServerFailure };
    this._authServerFailure = undefined;
    return data;
  }

  setAuthServerFailure(value: FrontpageAlertData) {
    this._authServerFailure = value;
  }
}
