import { Injectable } from '@angular/core';
import { IProduktSelektion, PreisIndikationen, Sparte, TariffOptionSap, TariffSelectionCardData } from '@mwe/models';
import { LoggingService, TariffSelectionLogic } from '@mwe/services';
import { getTariffOptionsIdList, isArrayWithMinOneItem } from '@mwe/utils';
import { tariffSelectionStoreUtils } from './tariff-selection-store.utils';
import { TariffSelectionStoreService } from './tariff-selection-store.service';
import { ServiceStateEnum } from '@mwe/constants';
import { VoucherFormComponent } from '../../voucher/voucher-form/voucher-form.component';
import { concatMap, from, mergeAll, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TariffSelectionCardLogic {
  // helper method, after every tariff-option-change we have to validate the voucher...
  // todo refactor VoucherFormComponent so we can reuse logic/service for voucher-validation instead of VoucherFormComponent
  doSomeVoucherFormMagic: (selection: IProduktSelektion) => VoucherFormComponent;

  constructor(
    private logic: TariffSelectionLogic,
    private storeService: TariffSelectionStoreService,
    private loggingService: LoggingService,
  ) {}

  reset(): void {
    this.doSomeVoucherFormMagic = null;
  }

  async checkInitialVoucherCode(): Promise<void> {
    const selection: IProduktSelektion = this.getProductSelectionFromSelectedTariffs();
    await this.getVoucherCode(selection, true);
    await this.handleVoucherCodeUpdate();
  }

  async handleVoucherCodeUpdate(): Promise<void> {
    this.updateAllStates(ServiceStateEnum.LOADING);

    from(this.storeService.selectedTariffs())
      .pipe(
        map(tariff => {
          return from(this.getNewPriceIndicationForUpdatedTariffOptionAfterVoucherUpdate(tariff, tariff.userSelection));
        }),
        mergeAll(),
        concatMap((data: { indication: PreisIndikationen; tariff: TariffSelectionCardData; state: ServiceStateEnum }) => {
          this.storeService.updateSelectedTariffs({
            ...data.tariff,
            priceIndication: data.indication,
            state: ServiceStateEnum.SUCCESS,
          });
          return of();
        }),
      )
      .subscribe();
  }

  async handleTariffOptionChange(tariffData: TariffSelectionCardData, newSelectedOption: TariffOptionSap): Promise<void> {
    this.updateState(tariffData, ServiceStateEnum.LOADING);

    const everySelectionItemNotUpdated = tariffData.userSelection.filter(u => u.art !== newSelectedOption.art);
    const userSelection = [...everySelectionItemNotUpdated, newSelectedOption];

    try {
      const priceIndication = await this.getNewPriceIndicationForUpdatedTariffOption(tariffData, userSelection, true);
      this.storeService.updateSelectedTariffs({
        ...tariffData,
        userSelection,
        priceIndication,
        state: ServiceStateEnum.SUCCESS,
      });
    } catch (error) {
      this.loggingService.logError(error, 'TariffSelectionCardLogic/handleTariffOptionChange');
      this.storeService.updateSelectedTariffs({
        ...tariffData,
        userSelection,
        state: ServiceStateEnum.ERROR,
      });
    }
  }

  async getNewPriceIndicationForUpdatedTariffOption(
    selectedTariff: TariffSelectionCardData,
    userSelection: TariffOptionSap[],
    withVoucherCodeValidation: boolean,
  ): Promise<PreisIndikationen> {
    // tariff-change can have tariffs without options, so you don't need an update-request for other prices
    if (!isArrayWithMinOneItem(selectedTariff.priceIndication.tarif.tarifOptionen)) {
      const copyPriceIndication = { ...selectedTariff.priceIndication } as PreisIndikationen;
      if (selectedTariff.anlageId) {
        copyPriceIndication.anlageId = selectedTariff.anlageId;
      }
      return copyPriceIndication;
    }

    const updatedTariff = {
      ...selectedTariff,
      userSelection,
    };

    // create temp selection for voucher code validation
    // first step: create selection with values before update
    const selection: IProduktSelektion = this.getProductSelectionFromSelectedTariffs();
    const updatedProduct = tariffSelectionStoreUtils.createNewProduct(this.storeService.allProducts(), updatedTariff);
    // second step: update specific selection with new tariff-options
    if (selectedTariff.anlageId) {
      // tariff change
      const unaffectedAnlagen = selection.anlagen.filter(a => a.anlageId !== selectedTariff.anlageId);
      selection.anlagen = [...unaffectedAnlagen, updatedProduct];
    } else {
      // new client
      selection[selectedTariff.category.toLowerCase()] = updatedProduct;
    }

    const voucherCode = await this.getVoucherCode(selection, withVoucherCodeValidation);
    const response = await this.logic.getUpdatedPriceIndications(
      this.storeService.zipCode(),
      selectedTariff.category,
      selectedTariff.tariffKey,
      getTariffOptionsIdList(userSelection),
      voucherCode,
    );

    // tariff-change
    if (selectedTariff.anlageId) {
      response.anlageId = selectedTariff.anlageId;
    }

    return response;
  }

  async getVoucherCode(selection: IProduktSelektion, withVoucherCodeValidation: boolean): Promise<string> {
    const voucherFormRef = this.doSomeVoucherFormMagic?.(selection);
    if (voucherFormRef?.code && withVoucherCodeValidation) {
      await voucherFormRef.checkCode();
    }

    // wl voucher initialized todo refactor VoucherFormComponent to have a better way
    if (voucherFormRef?.showWLFields && !voucherFormRef.hasValidWLFormData()) {
      return undefined;
    }

    return voucherFormRef?.hasInvalidCode() ? undefined : voucherFormRef?.code;
  }

  getProductSelectionFromSelectedTariffs(): IProduktSelektion {
    if (!isArrayWithMinOneItem(this.storeService.selectedTariffs())) {
      return {};
    }

    // tariff change
    if (this.storeService.selectedTariffs()[0].anlageId) {
      return {
        anlagen: this.storeService.selectedTariffs().map(tariff => {
          return tariffSelectionStoreUtils.createNewProduct(this.storeService.allProducts(), tariff);
        }),
      };
    }

    // new client
    return {
      strom: this.storeService.getProduktAuswahlFor(Sparte.Strom),
      gas: this.storeService.getProduktAuswahlFor(Sparte.Gas),
    };
  }

  updateAllStates(state: ServiceStateEnum): void {
    const newTariffs = this.storeService.selectedTariffs().map(tariff => {
      return {
        ...tariff,
        state,
      };
    });

    this.storeService.selectedTariffs.set(newTariffs);
  }

  private updateState(tariffData: TariffSelectionCardData, state: ServiceStateEnum): void {
    this.storeService.updateSelectedTariffs({ ...tariffData, state });
  }

  // helper function for rxjs-construct
  private async getNewPriceIndicationForUpdatedTariffOptionAfterVoucherUpdate(
    tariff: TariffSelectionCardData,
    userSelection: TariffOptionSap[],
  ): Promise<{ indication: PreisIndikationen; tariff: TariffSelectionCardData; state: ServiceStateEnum }> {
    try {
      const indication = await this.getNewPriceIndicationForUpdatedTariffOption(tariff, userSelection, false);
      return { indication, tariff, state: ServiceStateEnum.SUCCESS };
    } catch (error) {
      this.loggingService.logError(error, 'TariffSelectionCardLogic/handleVoucherCodeUpdate');
      return { indication: tariff.priceIndication, tariff, state: ServiceStateEnum.ERROR };
    }
  }
}
