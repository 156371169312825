import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@mwe/services';

@Directive({
  selector: '[mweEnvTranslate]',
})
export class EnvTranslateDirective implements OnInit {
  @Input() mweEnvTranslate: string;

  constructor(
    private translateService: TranslateService,
    private elementRef: ElementRef,
    private environmentService: EnvironmentService,
  ) {}

  ngOnInit(): void {
    const label = `${this.mweEnvTranslate}.${this.environmentService.getPortalCustomerType()}`;
    const translation = this.translateService.instant(label);
    this.elementRef.nativeElement.innerText = translation;
  }
}
