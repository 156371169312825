<mwe-form [formModel]="countryFormModel"></mwe-form>

<mwe-address-input
  *ngIf="!useManualAddressForm"
  [ignoreAvailableZipCodes]="true"
  [selectedAddress]="initialOlavEntry"
  noResultsButtonText="shared.international-address-input.noResultsButtonText"
  (allSearchParametersEntered)="receiveSelectionFinished($event)"
  (noResultsButtonClicked)="changeToManualAddressForm()"
></mwe-address-input>
<mwe-manual-address-input
  *ngIf="useManualAddressForm"
  [initialData]="initialDataForManualForm"
  (formChanged)="receiveSelectionFinished($event)"
></mwe-manual-address-input>
