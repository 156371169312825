import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { containsFernwaerme, getMonthLabel } from '@mwe/utils';
import { Sparte } from '@mwe/models';
import dayjs from 'dayjs';

@Pipe({ name: 'partialPaymentLabel' })
export class PartialPaymentLabelPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(label: string, date: string, categories: Sparte[]): string {
    return containsFernwaerme(categories) ? this.getWaermeDescription(label, date) : this.getDescription(label, date);
  }

  private getDescription(label: string, date: string): string {
    const month = this.translateService.instant(getMonthLabel(date));

    return this.translateService.instant(label, { month });
  }

  private getWaermeDescription(label: string, date: string): string {
    const currentMonth = this.translateService.instant(getMonthLabel(date));
    const previousMonth = this.translateService.instant(getMonthLabel(dayjs(date).subtract(1, 'month')));

    return this.translateService.instant(label, {
      month: `${previousMonth} / ${currentMonth}`,
    });
  }
}
