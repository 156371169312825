<ng-container *ngIf="productList?.length > 0">
  <div class="mt-4">
    <p *ngIf="listHeaderKey" class="text--big mb-4" [translate]="listHeaderKey"></p>
    <div class="row gy-4">
      <ng-container *ngFor="let product of productList">
        <div class="col-12 col-lg-6">
          <mwe-wizard-product-card-container
            [product]="product"
            [isActiveCard]="isActiveCard"
            [notActiveText]="notActiveText"
            [isAvailableAtNewAddress]="isAvailableAtNewAddress"
            [showTariff]="showTariff"
          ></mwe-wizard-product-card-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
