import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import {
  ActionType,
  ContractStatusEnum,
  HTTPError,
  ILSystem,
  IProduct,
  IVerknuepfungenResponse,
  parseProductBillinfo,
  parseProductBillinfoOverview,
  ProductBillinfo,
  ProductBillinfoOverview,
  RecentOrderDTO,
  Sparte,
  VERKNUEPFUNGSSTATUS_MANUELL,
  VERKNUEPFUNGSSTATUS_VORSCHLAG_ABGELEHNT,
} from '@mwe/models';
import { containsSparte, isContractStatus, sortProductsByCategory } from '@mwe/utils';
import { TranslateService } from '@ngx-translate/core';
import { AccountLogic } from '../account/account.logic';
import { LoggingService } from '../logging/logging.service';
import { ProductAddStateService } from './product-add/product-add-state.service';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({ providedIn: 'root' })
export class ProductService {
  resourceUrl: string;

  constructor(
    private http: HttpClient,
    private productAddStateService: ProductAddStateService,
    private accountLogic: AccountLogic,
    private loggingService: LoggingService,
    private environmentService: EnvironmentService,
    private translateService: TranslateService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/products/';
  }

  async findPrivateProducts(kn: string, name: string, birthday: string): Promise<IVerknuepfungenResponse> {
    const requestParams = {
      kundenNummer: kn,
      nachname: name,
      geburtsDatum: birthday,
    };

    return lastValueFrom(this.http.post<IVerknuepfungenResponse>(this.resourceUrl + 'private/association', requestParams));
  }

  async findBusinessStromGasProducts(customerId: string, systemId: ILSystem, accountId: string): Promise<IVerknuepfungenResponse> {
    const requestParams = {
      kundenNummer: customerId,
      systemId,
      verrechnungskontoId: accountId,
    };

    return lastValueFrom(this.http.post<IVerknuepfungenResponse>(this.resourceUrl + 'business/association', requestParams));
  }

  async findBusinessWaermeProducts(customerId: string, systemId: ILSystem, organisationsName: string): Promise<IVerknuepfungenResponse> {
    const requestParams = {
      kundenNummer: customerId,
      systemId,
      organisationsName,
    };

    return lastValueFrom(this.http.post<IVerknuepfungenResponse>(this.resourceUrl + 'business/association', requestParams));
  }

  async getAssociationsUpdates(email: string): Promise<IVerknuepfungenResponse> {
    const requestParams = {
      einladungenPruefen: true,
      kundenTyp: this.environmentService.getPortalCustomerType(),
      eMail: email,
    };

    return lastValueFrom(this.http.post<IVerknuepfungenResponse>(this.resourceUrl + 'refresh', requestParams));
  }

  loadAssociations(): Promise<IVerknuepfungenResponse> {
    // we need this for DSGVO shenanigans - product.logic.ts - getProducts
    const customerType = this.environmentService.getPortalCustomerType();

    return lastValueFrom(this.http.get<IVerknuepfungenResponse>(`${this.resourceUrl}associations?kundenTyp=${customerType}`));
  }

  async addProducts(gpn: string, systemId: string, verknuepfungsStatus = VERKNUEPFUNGSSTATUS_MANUELL, informBackend = true): Promise<void> {
    const _params = new HttpParams()
      .set('geschaeftsPartnerId', gpn)
      .set('verknuepfungsStatus', verknuepfungsStatus)
      .set('userEmail', this.accountLogic.getAccountEmail())
      .set('systemId', systemId)
      .set('kundenTyp', this.environmentService.getPortalCustomerType())
      .set('informBackend', `${informBackend}`);

    if (this.accountLogic.getAccountEmail() !== null) {
      try {
        const addProductRequest = { addressGroups: [] };
        this.productAddStateService.addressGroups.forEach(ag => {
          sortProductsByCategory(ag.products);
          ag.products.forEach(p => {
            p.translatedCategory = this.translateService.instant(p.getCategoryKey());
          });
          addProductRequest.addressGroups.push(ag);
        });

        const response = await lastValueFrom(
          this.http.put<any>(this.resourceUrl + 'associations', addProductRequest, { params: _params, observe: 'response' }),
        );
        if (response.status === 207) {
          throw new HTTPError('500', response.body);
        }
      } catch (err) {
        throw new HTTPError(err.status, err.error);
      }
    } else {
      throw new HTTPError('404', 'Missing Email Address');
    }
  }

  async deleteTemporaryAssociations(gpn: string, systemId: string): Promise<void> {
    const email = this.accountLogic.getAccountEmail();
    const _params = new HttpParams().set('geschaeftsPartnerId', gpn).set('userEmail', email).set('systemId', systemId);

    try {
      await lastValueFrom(this.http.delete<any>(this.resourceUrl + 'associations', { params: _params }));
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async getProductBillinfoDetails(accountNumber: string, category: string): Promise<ProductBillinfo> {
    const _params = new HttpParams().set('accountNumber', accountNumber).set('category', category);

    const resp = await lastValueFrom(
      this.http.get(this.resourceUrl + 'billinfoDetails', {
        params: _params,
      }),
    );

    return parseProductBillinfo(resp);
  }

  async getProductBillinfoSum(accountNumber: string, category: string): Promise<ProductBillinfoOverview> {
    const _params = new HttpParams().set('accountNumber', accountNumber).set('category', category);

    const resp = await lastValueFrom(
      this.http.get(this.resourceUrl + 'billinfoSum', {
        params: _params,
      }),
    );

    return parseProductBillinfoOverview(resp);
  }

  mapCategoryForProductDetailsRequest(category: string): string {
    return ['Nachtstrom', 'nachtstrom', 'Stromrueck', 'stromrueck'].includes(category) ? 'Strom' : category;
  }

  isWaermepumpe(product: IProduct): boolean {
    return product.tariffClasses.indexOf('Wärmepumpe') > -1;
  }

  filterAssociations(response: IVerknuepfungenResponse): IVerknuepfungenResponse {
    response = this.filterProductsWithoutMandatoryProps(response);
    response = this.filterProductsWithValidContractStatus(response);

    return response;
  }

  filterProductsWithoutMandatoryProps(response: IVerknuepfungenResponse): IVerknuepfungenResponse {
    return {
      ...response,
      verknuepfungen: response.verknuepfungen?.filter(verknuepfung => {
        verknuepfung.produkte = verknuepfung?.produkte?.filter(produkt => {
          const hasAdressDetails = !!produkt?.additionalData?.adressDetails;
          if (!hasAdressDetails) {
            this.loggingService.logAction({
              type: ActionType.ASSOCIATIONS,
              message: `removed product because of missing adressDetails: ${JSON.stringify(produkt)}`,
            });
          }
          return hasAdressDetails;
        });
        return verknuepfung?.produkte?.length > 0;
      }),
    };
  }

  filterProductsWithValidContractStatus(response: IVerknuepfungenResponse): IVerknuepfungenResponse {
    return {
      ...response,
      verknuepfungen: response.verknuepfungen?.filter(verknuepfung => {
        verknuepfung.produkte = verknuepfung?.produkte?.filter(verknuepftesProdukt => {
          return (
            isContractStatus(verknuepftesProdukt.vertragsStatus, ContractStatusEnum.Aktiv) ||
            (isContractStatus(verknuepftesProdukt.vertragsStatus, ContractStatusEnum.Geplant) &&
              containsSparte(verknuepftesProdukt.sparte, [Sparte.Emobility]))
          );
        });
        return verknuepfung?.produkte?.length > 0;
      }),
    };
  }

  filterAssociationsWithValidVerknuepfungsStatus(response: IVerknuepfungenResponse): IVerknuepfungenResponse {
    return {
      ...response,
      verknuepfungen: response?.verknuepfungen?.filter(
        verknuepfung => !!verknuepfung.verknuepfungsStatus && verknuepfung.verknuepfungsStatus !== VERKNUEPFUNGSSTATUS_VORSCHLAG_ABGELEHNT,
      ),
    };
  }

  filterAssociationsWithoutDeclinedVerknuepfungsStatus(response: IVerknuepfungenResponse): IVerknuepfungenResponse {
    return {
      ...response,
      verknuepfungen: response.verknuepfungen?.filter(
        verknuepfung => verknuepfung.verknuepfungsStatus !== VERKNUEPFUNGSSTATUS_VORSCHLAG_ABGELEHNT,
      ),
    };
  }

  getRecentOrders(): Observable<RecentOrderDTO[]> {
    return this.http.get<RecentOrderDTO[]>(this.resourceUrl + 'recentOrderSummary');
  }
}
