import { Component, OnInit } from '@angular/core';
import { LogWienConditionsOfUseService, NavigationStateService } from '@mwe/services';
import { Router } from '@angular/router';

@Component({
  selector: 'mwe-gateway-auth',
  templateUrl: './gateway-auth.component.html',
})
export class GatewayAuthComponent implements OnInit {
  constructor(
    private router: Router,
    private logwienConditionsOfUseService: LogWienConditionsOfUseService,
    private navigationStateService: NavigationStateService
  ) {}

  async ngOnInit() {
    // check user migration approval
    const route = await this.logwienConditionsOfUseService.getRouteForConsentStatus();
    if (route) {
      await this.router.navigateByUrl(route);
    } else {
      // check deep linking
      this.navigationStateService.navigateInitialUrl();
    }
  }
}
