import { Component, Input, OnInit } from '@angular/core';
import { ClearingAccount, IAddressProducts } from '@mwe/models';
import { isArrayWithMinOneItem } from '@mwe/utils';
import { getCategoriesForClearingAccount, getClearingAccountDuePaymentSum } from '@mwe/utils';
import { FindPaymentSolutionLogic } from '@mwe/services';

@Component({
  selector: 'mwe-products-group-unpaid-invoice-notification',
  templateUrl: './products-group-unpaid-invoice-notification.component.html',
})
export class ProductsGroupUnpaidInvoiceNotificationComponent implements OnInit {
  @Input() unpaidInvoices: ClearingAccount[];
  @Input() addressProducts: IAddressProducts;
  @Input() isNavigateBtnVisible = true;
  amount: number;

  constructor(private findPaymentSolutionLogic: FindPaymentSolutionLogic) {}

  ngOnInit(): void {
    this.amount = getClearingAccountDuePaymentSum(this.unpaidInvoices);
  }

  showUnpaidInvoices(): void {
    const clearingAccountsWithUnpaidInvoices = this.unpaidInvoices.filter(ui => isArrayWithMinOneItem(ui?.unpaidInvoices?.offeneZahlungen));
    if (clearingAccountsWithUnpaidInvoices.length === 1) {
      this.findPaymentSolutionLogic.navigateToUnpaidInvoices(
        getCategoriesForClearingAccount(clearingAccountsWithUnpaidInvoices[0], this.addressProducts.products),
        clearingAccountsWithUnpaidInvoices[0],
        this.addressProducts.address
      );
      return;
    }

    this.findPaymentSolutionLogic.navigateToSelection(this.unpaidInvoices, this.addressProducts);
  }
}
