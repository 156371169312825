<div class="row">
  <div class="col-12 pb-4">
    <div class="card product-card h-auto" style="padding: 32px;">
      <div class="row">
        <h5 translate="products.unpaidInvoices.addressInfo"></h5>
      </div>
      <div class="row" style="margin-top: 8px;">
        <h1 class="red-text-color" data-test="unpaid-invoice-notification-amount"
        >{{ this.amount | euroCurrency }}</h1>
      </div>
      <div class="row mt-3" *ngIf="isNavigateBtnVisible">
        <mwe-button (clicked)="showUnpaidInvoices()" labelKey="products.unpaidInvoices.showBtn"
                    buttonId="unpaid-invoice-notification-show-unpaid-invoices-btn"></mwe-button>
      </div>
    </div>
  </div>
</div>
