<div class="fullscreen-process">
  <div aria-live="assertive" class="sr-only">
    <ng-container *ngIf="isSubmitting"> Eingabe wird verarbeitet.</ng-container>
  </div>
  <mwe-wizard-header
    (cancelEvent)="cancelOnHeader()"
    [isClosingAllowed]="!isSubmitting && !wizardModel.isNotCancelable"
    [titleKey]="wizardModel.titleKey"
  ></mwe-wizard-header>
  <div *ngIf="wizardModel.wizardSteps.length > 1 && !wizardModel.hideProgressBar && progressBarVisibilityOverwrite" class="progress thin">
    <div [style.width]="wizardStepWidth + '%'" aria-valuemax="100" aria-valuemin="0" class="progress-bar" role="presentation">
      <div aria-live="assertive" class="sr-only">
        {{ 'Schritt ' + currentWizardStepIdx + ' von ' + wizardModel.wizardSteps.length }}
      </div>
    </div>
  </div>
  <main
    class="process-content pt-5 pt-lg-6 pb-6 pb-lg-9 {{ customCssClass }} container {{
      wizardModel.useMoreWidth ? 'limited' : 'is-medium'
    }}"
    role="main"
  >
    <img *ngIf="imageUrl && !imageVisibilityOverwrite" [src]="imageUrl" class="mb-6 w-100 rounded-2" />
    <mwe-alert #wizardAlert *ngIf="showAlert" [messageKey]="alertMessageKey" [titleKey]="alertTitleKey" [type]="alertType"></mwe-alert>
    <mwe-progress-bar
      *ngIf="progressBarLabels && progressBarVisibilityOverwrite"
      [labels]="progressBarLabels"
      [selectedIndex]="getCurrentProgressBarIndex()"
      class="d-block mb-5 mb-lg-6"
    ></mwe-progress-bar>
    <div *ngIf="isWizardTitleVisible || isWizardSubTitleVisible" class="mb-4 mb-lg-5">
      <h1
        *ngIf="isWizardTitleVisible"
        [ngClass]="currentWizardStep?.subTitleKey ? 'mb-1' : ''"
        [translate]="currentWizardStep.titleKey"
        class="h2"
        data-test="wizard-title"
      ></h1>
      <p *ngIf="isWizardSubTitleVisible" [innerHTML]="subTitle | translate | safeHtml" data-test="wizard-subtitle"></p>
    </div>
    <ng-content></ng-content>
    <div *ngIf="!buttonsHidden" class="process-footer mt-5 mt-lg-6">
      <div class="buttons py-0" role="navigation">
        <mwe-button
          (clicked)="submit()"
          *ngIf="
            nextButtonVisibleOverwrite &&
            currentWizardStepHideNextButton !== true &&
            (currentWizardStepNextLabel$ | async) as currentWizardStepNextLabel
          "
          [buttonId]="'mwe-wizard-submit'"
          [color]="currentWizardStep?.nextButtonColor || 'orange'"
          [disabled]="isSubmitting || buttonsDisabled || (currentWizardStepNextDisabled$ | async)"
          [labelKey]="currentWizardStepNextLabelOverwrite || currentWizardStepNextLabel"
          [showHoverEffect]="currentWizardStepIsPrimaryHoverButton"
          [showSpinner]="isSubmitting && currentWizardStep?.showPrimarySpinnerWhenLoading !== false"
          class="btn-container d-block primary"
        ></mwe-button>
        <div class="break"></div>
        <mwe-button
          (clicked)="nextWithSkip()"
          *ngIf="currentWizardStep?.skipNextStepButton"
          [buttonId]="'mwe-wizard-submit-with-skip'"
          [labelKey]="currentWizardStep.skipNextStepButton.label"
          class="btn-container d-block tertiary"
          color="blue"
        ></mwe-button>
        <ng-container *ngIf="prevButtonVisibleOverwrite">
          <mwe-button
            (clicked)="cancel()"
            *ngIf="isPrevButtonVisible"
            [buttonId]="'mwe-wizard-cancel'"
            [color]="currentWizardStep?.prevButtonColor || 'orange'"
            [disabled]="isSubmitting || buttonsDisabled"
            [labelKey]="currentWizardStepPrevLabel"
            category="secondary"
            class="btn-container d-block back"
          ></mwe-button>
          <mwe-button
            (clicked)="cancelWithSkip()"
            *ngIf="currentWizardStep?.skipPrevStepButton"
            [buttonId]="'mwe-wizard-cancel-with-skip'"
            [disabled]="isSubmitting || buttonsDisabled"
            [labelKey]="currentWizardStep.skipPrevStepButton.label"
            category="secondary"
            class="btn-container d-block back"
          ></mwe-button>
        </ng-container>
        <mwe-button
          (clicked)="openLink(currentWizardStep?.textButtonHref)"
          *ngIf="currentWizardStepHidePrevButton === true && currentWizardStep?.textButtonKey"
          [buttonId]="'mwe-wizard-text-button'"
          [disabled]="buttonsDisabled"
          [labelKey]="currentWizardStep?.textButtonKey"
          category="secondary"
          class="btn-container d-block back"
          color="orange"
        ></mwe-button>
      </div>
    </div>
  </main>
  <ng-content select="#wizard-additional-footer"></ng-content>
</div>
