import { IBusinessData, IFormInput, IPersonalData } from '@mwe/models';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { escapeRegExp } from '../common/string.utils';
import { getEmailValidatorWithRegExp } from '../common/mwe-util';

export function bothOrNothingRequired(formControlNameToCompare: string): ValidatorFn {
  return (c: AbstractControl): { [key: string]: any | null } => {
    const otherControl = c.root.get(formControlNameToCompare);

    if (!otherControl) {
      return null;
    }

    if (c.value) {
      otherControl.setErrors(otherControl.value ? null : { required: true });
      return null;
    } else {
      otherControl.setErrors(null);
      return otherControl.value ? { required: true } : null;
    }
  };
}

export function equalEmailValidator(formControlNameToCompare: string): ValidatorFn {
  return (c: AbstractControl): { [key: string]: any | null } => {
    const controlToCompare = c.root.get(formControlNameToCompare);
    const otherValue = controlToCompare?.value;
    const currentValue = c?.value;

    const regExp = new RegExp(`^${escapeRegExp(currentValue)}`, 'mi');
    if (!regExp.test(otherValue)) {
      return { equal: false };
    }

    if (otherValue !== currentValue) {
      return { partlyEqual: true };
    }

    return null;
  };
}

export const getEmailInputs = (personalData: IPersonalData | IBusinessData, isLoggedIn: boolean): IFormInput[] => {
  if (isLoggedIn) {
    return [];
  }

  return [
    {
      name: 'mwe-order-personal-data-email',
      initialValue: personalData?.email,
      validators: [Validators.required, Validators.email, getEmailValidatorWithRegExp()],
      labelKey: 'newClient.personalDetails.email.label',
      validationErrorLabelKey: 'newClient.personalDetails.email.error',
      helpLabelKey: 'newClient.personalDetails.email.helpLabel',
      validate: true,
    },
    {
      name: 'mwe-order-personal-data-email-repeat',
      initialValue: personalData?.emailRepeat,
      validators: [Validators.required, equalEmailValidator('mwe-order-personal-data-email')],
      labelKey: 'newClient.personalDetails.email-repeat.label',
      validationErrorLabelKey: 'newClient.personalDetails.email-repeat.error',
      validate: true,
      avoidCopyPaste: true,
    },
  ];
};
