import { Component, Input } from '@angular/core';

@Component({
  selector: 'mwe-list-group-dropdown',
  templateUrl: './list-group-dropdown.component.html',
})
export class ListGroupDropdownComponent {
  @Input() groupTitle: string;
  @Input() items: string[];
  isCollapsed = true;
}
