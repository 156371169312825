import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { NavbarConfig, NavigatingBackTo } from '@mwe/models';
import { Router } from '@angular/router';

const initialUrlKey = 'initialUrl';
const wizardCancelUrlKey = 'wizardCancelUrl';
const wizardSuccessUrlKey = 'wizardSuccessUrl';
const navigatingBackToKey = 'navigatingBackTo';

@Injectable({ providedIn: 'root' })
export class NavigationStateService {
  @SessionStorage(initialUrlKey)
  private _initialUrlKey: string;

  @SessionStorage(wizardCancelUrlKey)
  private _wizardCancelUrl: string;

  @SessionStorage(wizardSuccessUrlKey)
  private _wizardSuccessUrl: string;

  @SessionStorage(navigatingBackToKey)
  private _navigatingBackTo: NavigatingBackTo[];

  // no storage
  private _navbarConfig: NavbarConfig;

  constructor(
    private storage: SessionStorageService,
    private router: Router,
  ) {}

  clearNavigationUrls(): void {
    this.clearWizardUrls();
    this.storage.clear(navigatingBackToKey);
  }

  clearWizardUrls(): void {
    this.storage.clear(wizardCancelUrlKey);
    this.storage.clear(wizardSuccessUrlKey);
  }

  getAndClearInitialUrl(): string {
    const url = this._initialUrlKey;
    this.storage.clear(initialUrlKey);
    return url;
  }

  navigateInitialUrl(): void {
    const initialUrl = this.getAndClearInitialUrl();
    this.router.navigateByUrl(initialUrl ?? '/');
  }

  setInitialUrl(url: string): void {
    this._initialUrlKey = url;
  }

  get wizardCancelUrl(): string {
    return this._wizardCancelUrl ?? `/`;
  }

  set wizardCancelUrl(value: string) {
    this._wizardCancelUrl = value;
  }

  get wizardSuccessUrl(): string {
    return this._wizardSuccessUrl ?? `/`;
  }

  set wizardSuccessUrl(value: string) {
    this._wizardSuccessUrl = value;
  }

  get navbarConfig(): NavbarConfig {
    return this._navbarConfig;
  }

  set navbarConfig(value: NavbarConfig) {
    this._navbarConfig = value;
  }

  getNavigatingBackTo(): NavigatingBackTo[] {
    return this._navigatingBackTo ?? [];
  }

  getLatestNavigatingBackTo(): NavigatingBackTo {
    const list = this.getNavigatingBackTo();

    return list[list.length - 1];
  }

  addNavigatingBackTo(link: NavigatingBackTo): void {
    this._navigatingBackTo = [...this.getNavigatingBackTo(), link];
  }

  removeLatestNavigatingBackTo(): void {
    this.getNavigatingBackTo().splice(-1);
    this._navigatingBackTo = this.getNavigatingBackTo();
  }
}
