import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { CoverageArea } from '@mwe/models';

@Injectable({ providedIn: 'root' })
export class CsvDataService {
  constructor(private httpClient: HttpClient) {}

  getCoverageArea(url): Promise<CoverageArea[]> {
    return this.getLocalCSV(url);
  }

  async getLocalCSV(url): Promise<CoverageArea[]> {
    const data = await lastValueFrom(this.httpClient.get(url, { responseType: 'text' }));
    return this.parseCSV(data);
  }

  parseCSV(data): CoverageArea[] {
    const csvData = data;
    const coverageArea = [];
    const csvToRowArray = csvData.split('\n');
    for (let index = 1; index < csvToRowArray.length - 1; index++) {
      const row = csvToRowArray[index].split(';');
      coverageArea.push(new CoverageArea(parseInt(row[0], 10), row[1].trim()));
    }
    return coverageArea;
  }
}
