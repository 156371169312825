import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAddress, IAddressProducts, IAddressProductsSelectedEvent, IProduct, NavigatingBackTo, parseAddress, Product } from '@mwe/models';
import { AddressLogic, LoggingService } from '@mwe/services';
import { getProductCategories, isArrayWithMinOneItem, isArrayWithOneItem } from '@mwe/utils';
import { ServiceStateEnum } from '@mwe/constants';

@Component({
  selector: 'mwe-address-selection',
  templateUrl: './address-selection.component.html',
  styles: [],
})
export class AddressSelectionComponent implements OnInit {
  @Input() noAddressGroupsTitle: string;
  @Input() noAddressGroupsText: string;
  @Input() extendedLoadingSpinner = false;
  @Input() navigatingBackTo: NavigatingBackTo;
  @Input() productsFilterFunc: (p: IProduct[]) => boolean;

  @Output() productSearchBtnClicked = new EventEmitter<void>();
  @Output() addressSelected = new EventEmitter<IAddressProductsSelectedEvent>();
  @Output() hasNoAddressProducts = new EventEmitter<void>();

  state = ServiceStateEnum.INIT;
  addressOptions: IAddressProducts[] = [];

  getProductCategories = getProductCategories;

  constructor(
    private addressLogic: AddressLogic,
    private loggingService: LoggingService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initAddressOptions();

    if (this.skipStepForOnlyOneAddress()) {
      return;
    }
  }

  selectOption(address: IAddress, products: Product[]): void {
    this.addressSelected.emit({
      addressProduct: { address, products },
      selectionWasSkippedDueToOneAddress: false,
    });
  }

  hasNoAddressOption(): boolean {
    const totalAddressOptions = this.addressOptions?.length;
    return !totalAddressOptions;
  }

  private async initAddressOptions(): Promise<void> {
    this.state = ServiceStateEnum.LOADING;

    try {
      this.addressOptions = await this.addressLogic.loadAddressesWithGroupNames();

      if (this.productsFilterFunc) {
        this.addressOptions = this.addressOptions.filter(aO => this.productsFilterFunc(aO.products));
      }

      if (!isArrayWithMinOneItem(this.addressOptions)) {
        this.hasNoAddressProducts.emit();
      }

      this.state = ServiceStateEnum.SUCCESS;
    } catch (ex) {
      this.state = ServiceStateEnum.ERROR;
      this.loggingService.logError(ex, 'error on loading possible addresses');
    }
  }

  private skipStepForOnlyOneAddress(): boolean {
    if (!isArrayWithOneItem(this.addressOptions)) {
      return false;
    }

    const address = parseAddress(this.addressOptions[0].address);
    const products = this.addressOptions[0].products;

    this.addressSelected.emit({
      addressProduct: { address, products },
      selectionWasSkippedDueToOneAddress: true,
    });
    return true;
  }
}
