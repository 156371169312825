import { Component, Output, EventEmitter, OnInit, Input, AfterViewInit } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';
import { padLeadingZeros } from '@mwe/utils';

registerLocaleData(localeDe, 'de');

@Component({
  selector: 'mwe-datepicker',
  templateUrl: './datepicker.component.html',
  providers: [DatePipe],
})
export class MweDatepickerComponent implements OnInit, AfterViewInit {
  @Output() onSubmit = new EventEmitter<any>();
  @Output() onReset = new EventEmitter<any>();
  @Input() datepickerConfig: any;
  @Input() cancelMsgKey: string;
  @Input() submitMsgKey: string;

  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  currentDate: dayjs.Dayjs;
  currentYear: number;
  currentMonth: number;
  currentDay: number;
  startDate: { year: number; month: number; day?: number };

  constructor(
    private calendar: NgbCalendar,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    if (!this.cancelMsgKey) {
      this.cancelMsgKey = 'global.datepicker.cancel';
    }

    if (!this.submitMsgKey) {
      this.submitMsgKey = 'global.datepicker.submit';
    }

    if (this.datepickerConfig.currentValue) {
      this.currentDate = dayjs(this.datepickerConfig.currentValue, 'YYYY-MM-DD');
    } else {
      this.currentDate = dayjs();
    }

    this.minDate = this.datepickerConfig.minDate ? this.datepickerConfig.minDate : '';
    this.maxDate = this.datepickerConfig.maxDate ? this.datepickerConfig.maxDate : '';

    const minDateValue = dayjs(
      this.minDate.year + '-' + padLeadingZeros(this.minDate.month, 2) + '-' + padLeadingZeros(this.minDate.day, 2),
      'YYYY-MM-DD',
    );

    if (minDateValue.isAfter(this.currentDate)) {
      this.currentDate = minDateValue;
    }

    this.currentYear = this.currentDate.year();
    this.currentMonth = this.currentDate.month() + 1;
    this.currentDay = this.currentDate.day();
    this.startDate = { month: this.currentMonth, year: this.currentYear };
  }

  ngAfterViewInit(): void {
    // WEKUKO-1389 start occurs only in webpack:prod version - find better solution
    const selects = document.getElementsByClassName('custom-select');
    for (let i = 0; i < selects.length; i++) {
      const ariaLabel = selects[i].getAttribute('aria-label').toLowerCase();
      if (ariaLabel.indexOf('month') > -1) {
        (selects[i] as HTMLSelectElement).value = this.currentMonth + '';
        (selects[i] as HTMLSelectElement).title = this.translate.instant('global.datepicker.selectMonth');
      }
      if (ariaLabel.indexOf('year') > -1) {
        (selects[i] as HTMLSelectElement).title = this.translate.instant('global.datepicker.selectYear');
      }
    }
    // WEKUKO-1389 end
    // WEKUKO-XY previous month arrow shows focus rectangle to avoid we do this hack
    document.getElementById('mwe-datepicker-submit').focus();
  }

  closeDatepicker(): void {
    this.onReset.emit(false);
  }

  selectDate(): void {
    this.onSubmit.emit(this.currentDate);
  }

  specialFormat(date: string): string {
    return date?.replace(/[.]/, '');
  }
}
