<div class="d-flex expander-element flex-column" [ngClass]="{ 'p-0': removePadding }">
  <form [formGroup]="form">
    <div *ngFor="let item of model.items" class="expander-component">
      <div class="d-flex expander-item align-items-start">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            [ngClass]="{ 'is-invalid': isErrorVisible(item.name) }"
            type="checkbox"
            [attr.id]="item.name"
            [formControlName]="item.name"
            [attr.data-test]="'form-input-' + item.name"
            [attr.aria-describedby]="item.name + '-checkbox'"
            [required]="item.required"
            [attr.aria-invalid]="!form.valid && !form.pristine"
          />
        </div>
        <div class="flex-fill">
          <label [for]="item.name" [attr.id]="item.name + '-checkbox'" [attr.data-test]="'form-label-' + item.name">{{
            item.titleKey | translate
          }}</label>
          <p
            #collapsible
            [id]="'description-' + item.name"
            [ngClass]="item.open || item.showMore === false ? '' : 'clamp'"
            [innerHTML]="item.descriptionKey | translate: item.descriptionValues | safeHtml"
          ></p>
          <p class="mb-0" *ngIf="isLineClampNeeded(item) || item.showMore === true">
            <button
              class="btn btn-clean text-secondary"
              style="text-transform: none; letter-spacing: 0"
              mweClickPreventDefault
              (click)="toggleReadMore(item.name)"
              aria-hidden="true"
            >
              {{ (item.open ? model.showLessKey : model.showMoreKey) | translate }}
            </button>
          </p>
          <span
            *ngIf="isErrorVisible(item.name)"
            [translate]="item.errorKey"
            id="expander-form-checkbox-error"
            class="invalid-feedback d-block"
          ></span>
          <span *ngIf="item.hintKey && !form.controls[item.name].value" [translate]="item.hintKey" class="hint-error d-block"></span>
        </div>
      </div>
    </div>
  </form>
</div>
