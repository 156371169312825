import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { environment } from '@mwe/environment';
import { CookieConsent } from '@wienenergiegithub/modules-next';

const containerElementName = 'cookieConsent';
declare global {
  interface Window {
    WE_COOKIE_DOMAIN: string;
    WE_COOKIECONSENT_DATAURL: string;
  }
}

@Component({
  selector: 'mwe-cookie-consent',
  template: `<span #${containerElementName}></span>`,
  encapsulation: ViewEncapsulation.None,
  styles: [],
})
export class CookieComponent implements OnDestroy, AfterViewInit {
  @ViewChild(containerElementName, { static: false }) containerRef: ElementRef;

  ngOnDestroy(): void {
    CookieConsent.unMount(this.containerRef.nativeElement);
  }

  ngAfterViewInit(): void {
    this.render();
  }

  showConsent() {
    CookieConsent.mount({
      rootElement: this.containerRef.nativeElement,
      props: {
        hideOverlay: false,
        piwikTagPropertyId: '5427bfdc-8989-495e-bf6b-a195ebfbfa22',
      },
    });
  }

  private render(): void {
    const temp = window.location.host.split('.').reverse();
    const rootDomain = '.' + temp[1] + '.' + temp[0];
    const cookieDomain = window.location.host.includes('localhost') ? 'localhost' : rootDomain;
    window.WE_COOKIE_DOMAIN = cookieDomain;
    window.WE_COOKIECONSENT_DATAURL = `${environment.staticContent}/modules/cookie-consent/cookie-consent-data.json`;
    this.showConsent();
  }
}
