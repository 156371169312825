import { Injectable, Injector } from '@angular/core';
import { IDsgvoAdditionalData, IPopupModel, ISaveConsentModel, UserJournal } from '@mwe/models';
import dayjs from 'dayjs';
import { DEFAULT_DSGVO_POPUP_COMPONENT_TOKEN, FeatureToggleEnum, TriggeringProcessEnum } from '@mwe/constants';
import { convertUserJournalDateToDayJsObj } from '@mwe/utils';
import { DsgvoService, DsgvoStateService, LoggingService, ProfileService, UserJournalService, PopupService } from '@mwe/services';

@Injectable({
  providedIn: 'root',
})
export class DsgvoLogic {
  DSGVO_SUCCESS = 'dsgvoSuccess';
  DSGVO_ERROR = 'dsgvoFail';
  DSGVO_SUCCESS_365 = 'dsgvoSuccess365';

  constructor(
    private dsgvoService: DsgvoService,
    private dsgvoStateService: DsgvoStateService,
    private popupService: PopupService,
    private userJournalService: UserJournalService,
    private profileService: ProfileService,
    private loggingService: LoggingService,
    private injector: Injector,
  ) {}

  async checkIfDsgvoPopupShallOpen(): Promise<void> {
    try {
      const dsgvoEvents = await this.getAllDSGVOJournalEvents();
      if (
        !this.checkIfDSGVOShouldBeShown(dsgvoEvents) ||
        this.profileService.isFeatureToggleDisabled(FeatureToggleEnum.DSGVO_POPUP_ENABLED) ||
        this.dsgvoStateService.hasCheckedIfDsgvoPopupShouldBeOpened
      ) {
        return;
      }
      await this.openDsgvoPopUp();
    } catch (error) {
      this.loggingService.logError(error, `dsgvo`);
    }
  }

  async triggerDsgvoForAssociations(gpn: string, systemId: string): Promise<void> {
    const dsgvoData = {
      gruppen: [],
      gpList: [
        {
          geschaeftsPartnerId: gpn,
          sparte: systemId,
        },
      ],
      ausloesenderProzess: TriggeringProcessEnum.INITIAL,
    } as ISaveConsentModel;

    try {
      await this.dsgvoService.copyConsent(dsgvoData);
    } catch (reason) {
      if (reason.status == 404 && reason.error.code == 1001) {
        return this.checkIfDsgvoPopupShallOpen();
      }
    }
  }

  checkIfDSGVOPopupHasBeenShown(): boolean {
    return this.dsgvoStateService.wasDsgvoPopupOpened;
  }

  checkIfDSGVOShouldBeShown(dsgvoEvents: UserJournal[]): boolean {
    if (!dsgvoEvents || dsgvoEvents?.length === 0) {
      return true;
    }

    const today = dayjs();
    const latestDsgvoJournalEntry = dsgvoEvents?.reduce((a, b) =>
      convertUserJournalDateToDayJsObj(a.modifiedAt).isAfter(convertUserJournalDateToDayJsObj(b.modifiedAt)) ? a : b,
    );
    return this.isCapableToShowDSGVO(latestDsgvoJournalEntry, today);
  }

  isCapableToShowDSGVO(event: UserJournal, today: dayjs.Dayjs): boolean {
    const beginPeriod = convertUserJournalDateToDayJsObj(event.modifiedAt);
    const endPeriod = this.DSGVO_SUCCESS_365 === event.event ? beginPeriod.add(365, 'day') : beginPeriod.add(90, 'day');
    const additionalData: IDsgvoAdditionalData = JSON.parse(event.additionalData);
    return !today.isBefore(endPeriod) && additionalData?.dsgvoAccepted !== 'fully_accepted';
  }

  async getAllDSGVOJournalEvents(): Promise<UserJournal[]> {
    const result = await this.userJournalService.getEvents([this.DSGVO_SUCCESS, this.DSGVO_ERROR, this.DSGVO_SUCCESS_365]);
    this.dsgvoStateService.showOptionToSaveDecisionFor365Days = result?.length > 0;
    return result;
  }

  async updateDsgvoEvent(success: boolean, fieldStatus: string, saveDecisionFor365Days: boolean): Promise<void> {
    try {
      const messageEntry = success && saveDecisionFor365Days ? this.DSGVO_SUCCESS_365 : success ? this.DSGVO_SUCCESS : this.DSGVO_ERROR;
      await this.userJournalService.updateEvent(messageEntry, fieldStatus);
    } catch (e) {
      this.loggingService.logError(e, `could not save dsgvo event (success=${success})`);
    }
  }

  private async openDsgvoPopUp(): Promise<void> {
    if (this.dsgvoStateService.wasDsgvoPopupOpened) {
      return;
    }
    try {
      const response = await this.dsgvoService.loadDsvgoData();
      const model = this.getDsgvoPopupModel();
      model.componentData = response.body;
      this.dsgvoStateService.wasDsgvoPopupOpened = true;
      this.dsgvoStateService.hasCheckedIfDsgvoPopupShouldBeOpened = true;
      await this.popupService.tryOpen(model);
    } catch (error) {
      this.loggingService.logError(error, `could not load dsgvo-data`);
    }
  }

  private getDsgvoPopupModel(): IPopupModel {
    const component = this.injector.get(DEFAULT_DSGVO_POPUP_COMPONENT_TOKEN);
    return {
      id: 'dsgvo-logic-open-dsgvo',
      subscriptionId: 'dsgvo-policy-confirmation',
      useCustomReturnValue: true,
      titleKey: '',
      messageKey: '',
      showSubmitButton: false,
      submitButtonKey: 'products-add.product-search.submit',
      showCancelButton: false,
      component,
      dismissable: false,
      disableMobileView: true,
      modalSize: 'xl',
      formModel: {
        inputs: [],
      },
    };
  }
}
