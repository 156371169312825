import { Directive, HostListener, Input } from '@angular/core';
import { InfoText } from '@mwe/models';
import { InfoTextPopupComponent } from '../info-text-popup.component';
import { PopupService } from '@mwe/services';

@Directive({
  selector: '[mweInfoTextPopupFromLink]',
})
export class InfoTextPopupFromLinkDirective {
  @Input() mweInfoTextPopupFromLink: string;
  @Input() infoText: InfoText;

  @Input() modelSize: 'sm' | 'lg' | 'xl' | string = '';

  constructor(private popupService: PopupService) {}

  @HostListener('click', ['$event'])
  @HostListener('touchstart', ['$event.srcElement'])
  onClick(event: MouseEvent): void {
    const clickTarget = event.target as HTMLInputElement;
    const targetId = this.mweInfoTextPopupFromLink;
    if (targetId === clickTarget?.id) {
      event.preventDefault();
      this.popupService.open({
        id: 'info-text-icon-clicked',
        titleKey: '',
        messageKey: '',
        showSubmitButton: false,
        showCancelButton: false,
        component: InfoTextPopupComponent,
        componentData: this.infoText,
        dismissable: true,
        modalSize: this.modelSize,
        modalOpts: {
          ariaLabelledBy: 'mwe_info-text-popup-title',
        },
      });
    }
  }
}
