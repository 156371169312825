/**
 * Angular bootstrap Date adapter
 */
import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import dayjs, { Dayjs } from 'dayjs';
import { padLeadingZeros } from '../common/string.utils';

@Injectable()
export class NgbDateDayjsAdapter extends NgbDateAdapter<Dayjs> {
  fromModel(date: Dayjs): NgbDateStruct {
    return getNgbDateStructFromDayJs(date);
  }

  toModel(date: NgbDateStruct): Dayjs {
    return getDayJsFromNgbDateStruct(date);
  }
}

export function getNgbDateStructFromDayJs(date: Dayjs): NgbDateStruct {
  if (date != null && dayjs.isDayjs(date) && date.isValid()) {
    return { year: date.year(), month: date.month() + 1, day: date.date() };
  }
  return null;
}

export function getDayJsFromNgbDateStruct(date: NgbDateStruct): Dayjs {
  if (!date) {
    return null;
  }

  const month = padLeadingZeros(date.month, 2);
  const day = padLeadingZeros(date.day, 2);
  const dayStr = `${date.year}-${month}-${day}`;

  return dayjs(dayStr, 'YYYY-MM-DD');
}

export function convertDateToISOFormat(currentValue: string, datepickerConfig: any): string {
  if (currentValue && currentValue.length > 9) {
    const defaultDisplayFormat = 'YYYY-MM-DD';
    if (datepickerConfig.displayFormat && defaultDisplayFormat !== datepickerConfig.displayFormat) {
      return dayjs(currentValue, datepickerConfig.displayFormat).format('YYYY-MM-DD');
    } else {
      return currentValue;
    }
  }
  return currentValue;
}

export function convertDateToDisplayFormat(initialValue: string, datepickerConfig: any): string {
  if (initialValue && initialValue.length > 9) {
    const defaultDisplayFormat = 'YYYY-MM-DD';
    if (datepickerConfig.displayFormat && defaultDisplayFormat !== datepickerConfig.displayFormat) {
      return dayjs(initialValue).format(datepickerConfig.displayFormat);
    } else {
      return initialValue;
    }
  }
  return initialValue;
}
