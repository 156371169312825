<div class="mb-3">
  <mwe-form-input #email [parentForm]="parentForm" [inputElem]="emailInput" [isLoading]="isLoading"></mwe-form-input>
  <mwe-form-input-error [parentForm]="parentForm" [inputElem]="emailInput"></mwe-form-input-error>
</div>
<div *ngIf="emailConfirmationInput" [hidden]="emailConfirmationInput?.isHidden">
  <mwe-form-input
    #emailConfirmation
    [parentForm]="parentForm"
    [inputElem]="emailConfirmationInput"
    [isLoading]="isLoading"
  ></mwe-form-input>
  <mwe-form-input-error [parentForm]="parentForm" [inputElem]="emailConfirmationInput"></mwe-form-input-error>
</div>
