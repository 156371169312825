import { Component, Input } from '@angular/core';
import { TextNodeType } from '@mwe/models';

@Component({
  selector: 'mwe-basic-card-title',
  templateUrl: './basic-card-title.component.html',
})
export class BasicCardTitleComponent {
  @Input() iconClass: string;
  @Input() cardTitle: string;
  @Input() cardTitleParams;
  @Input() headingNodeType: TextNodeType = 'h5';
}
