<mwe-custom-popup (dismissClicked)="closePopup()">
  <div>
    <div class="process-content container is-medium">
      <h3 translate="association-suggestions.stepTitle" [translateParams]="{ total: totalSuggestions, current: currentSuggestionIdx }"></h3>
      <p translate="association-suggestions.stepMessage"></p>

      <ng-container *ngFor="let group of currentSuggestions">
        <mwe-products-group
          *ngIf="group"
          [address]="group.address"
          [products]="group.products"
          [showCompanyCorrelation]="true"
          state="list"
        ></mwe-products-group>
      </ng-container>
      <mwe-alert
        *ngIf="isError"
        type="danger"
        titleKey="error.service.default.title"
        messageKey="error.service.default.message"
      ></mwe-alert>
      <mwe-dsgvo-disclaimer></mwe-dsgvo-disclaimer>
      <div class="process-footer mt-7">
        <div class="row">
          <mwe-button-link
            class="col-12 col-md-6 vertical-center"
            [btnId]="'mwe-association-suggestions-cancel'"
            label="association-suggestions.buttonNOK"
            color="blue"
            (clicked)="ignoreSuggestion()"
          ></mwe-button-link>
          <mwe-button
            class="primary col-12 col-md-6 vertical-center"
            [buttonId]="'mwe-association-suggestions-submit'"
            [showHoverEffect]="true"
            labelKey="association-suggestions.buttonOK"
            [disabled]="isSubmittingAdd || isSubmittingIgnore"
            (clicked)="addSuggestion()"
            [showSpinner]="isSubmittingAdd"
          ></mwe-button>
        </div>
      </div>
    </div>
  </div>
</mwe-custom-popup>
