import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MNRContentResponse, MNRUserMessagesResponse } from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { lastValueFrom } from 'rxjs';
import { RetryOnFailure } from '@mwe/utils';

@Injectable({
  providedIn: 'root',
})
export class MnrMessagesControlService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  @RetryOnFailure({
    maxRetryCount: 1,
    delay: 100,
    retryOnError: true,
    validator: (returnValue: MNRUserMessagesResponse[]) => Array.isArray(returnValue),
  })
  async getMessageContent(messageId: number): Promise<MNRContentResponse> {
    return await lastValueFrom(this.http.get<MNRContentResponse>(`${this.getResourceUrl('content')}?messageId=${messageId}`));
  }

  async healthCheck(): Promise<string> {
    return await lastValueFrom(this.http.get(this.getResourceUrl('healthCheck'), { responseType: 'text' }));
  }

  private getResourceUrl(path: string): string {
    return `${this.environmentService.getMNRApiUrl()}api/messages/${path}`;
  }
}
