import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mwe-toggle',
  templateUrl: './toggle.component.html',
})
export class ToggleComponent {
  @Input() isToggled: boolean;
  @Input() label: string;
  @Input() translateParams;
  @Output() toggleChanged = new EventEmitter<boolean>();
}
