import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'categoryIconSrc' })
export class CategoryIconSrcPipe implements PipeTransform {

  transform(category: string, isDisabled: boolean = false): string {
    category = category.toLowerCase();

    if (isDisabled) {
      return `/assets/icons/products/icon-${category}-grau.png`;
    }

    return `/assets/icons/products/icon-${category}.png`;
  }
}
