import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mwe-button-link',
  templateUrl: './button-link.component.html',
})
export class ButtonLinkComponent implements OnInit {
  @Input() customCss = '';
  @Input() dataTest = '';
  @Input() label = '';
  @Input() ariaLabel = '';
  @Input() color: 'orange' | 'blue' = 'orange';
  @Input() url: string;
  @Input() btnId: string;
  @Input() isDisabled = null;
  @Input() btnType = 'button';
  @Input() route: string;
  @Input() iconCss: string;
  @Input() tabindex: string;
  @Input() ariaHidden: boolean;
  @Output() clicked = new EventEmitter<void>();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onClick(): void {
    if (this.route) {
      this.router.navigateByUrl(this.route);
    }

    if (this.url) {
      window.open(this.url, '_blank');
      return;
    }

    this.clicked.emit();
  }
}
