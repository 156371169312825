import { Component, OnInit, ViewChild } from '@angular/core';
import { IFormInput, IFormModel, IFormSelect, TariffOptionsFormInitialData, TarifOption } from '@mwe/models';
import { Validators } from '@angular/forms';
import { CategoryIconSrcPipe } from '@mwe/ui/pipes';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { parseTariff } from '@mwe/utils';
import { FormComponent } from './../../../form/form.component';
import { getTariffOptionLabel, getTariffOptionTranslationParams, getUniqueTariffOptionTypes } from '@mwe/utils';

@Component({
  selector: 'mwe-tariff-options-form',
  templateUrl: './tariff-options-form.component.html',
  providers: [CategoryIconSrcPipe],
})
export class TariffOptionsFormComponent implements OnInit {
  @ViewChild(FormComponent) formRef: FormComponent;
  // is being passed by popupService
  loadedData: TariffOptionsFormInitialData;
  formModel: IFormModel;
  tariffName: string;

  constructor(
    private activeModal: NgbActiveModal,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.createFormModel();
    this.tariffName = parseTariff(this.loadedData.tariffName);
  }

  submit(): void {
    const result: TarifOption[] = Object.values(this.formRef.form.value).map(value => {
      return this.loadedData.availableTariffOptions.find(o => o.option === value);
    });
    this.activeModal.close(result);
  }

  cancel(): void {
    this.activeModal.close();
  }

  private createFormModel(): void {
    // form is dynamically build from TarifOption[]. every unique art is a form-field (select/IFormInput)
    const uniqueGroupTypes = getUniqueTariffOptionTypes(this.loadedData.availableTariffOptions);

    this.formModel = {
      updateOn: 'blur',
      inputs: uniqueGroupTypes.map(type => this.getFormInputFrom(type)).filter(i => !!i),
    };
  }

  private getFormInputFrom(type: string): IFormInput {
    // get all items for type, every item is a selectable option
    const allSapOptions = this.loadedData.availableTariffOptions.filter(o => o.art === type);

    // get first item with default or first item of group
    let defaultOption = allSapOptions.find(o => o.default);
    defaultOption = defaultOption || allSapOptions[0];
    const selectedOption = this.loadedData.selectedTariffOptions.find(sto => sto.art === type);

    return {
      name: `${defaultOption.option}`,
      initialValue: selectedOption?.option || defaultOption.option,
      validators: [Validators.required],
      labelKey: defaultOption.art,
      validate: true,
      componentType: 'select',
      options: this.getOptions(allSapOptions),
    };
  }

  private getOptions(items: TarifOption[]): IFormSelect[] {
    return items.map(i => {
      return { value: i.option, text: this.getLabelFrom(i) };
    });
  }

  private getLabelFrom(option: TarifOption): string {
    const labelKey = getTariffOptionLabel(option);

    if (labelKey.indexOf('Anschlusspreis') > -1) {
      option.installationLabel = this.translateService.instant('tariff.optionsSelectionForm.anschlusspreis');
    }

    const params = getTariffOptionTranslationParams(option);

    return this.translateService.instant(labelKey, params);
  }
}
