<mwe-bullet-confirmation-view
  [mweLinkInLabelClick]="['mwe-invoice-data-change-retry', 'mwe-invoice-data-change-status-sepa', 'mwe-invoice-data-change-status-address']" (linkClicked)="handleLink($event)"
  [successMsgKey]="successMsgKey"
  [workInProgressMsgKey]="workInProgressMsgKey"
  [errorMsgKey]="infoMode ? defaultErrorMsgKey : errorMsgKey"
  [bulletStateMap]="bulletStateMap"
  [bulletLabelMap]="bulletLabelMap"
  [bulletTitleMap]="bulletTitleMap"
  [bulletIds]="bulletIds"
  [bulletTranslateMap]="bulletTranslateMap"
  [generalTranslateValues]="generalTranslateValues"
  [alertPlaceholders]="alertPlaceholders"
  [state]="state"
>
</mwe-bullet-confirmation-view>

<mwe-invoice-data-change-confirmation-check-push-pdf-sepa
  [confirmationWasSuccessfully]="confirmationWasSuccessfully"
  (pushPdfBtnClicked)="pushPdfBtnClicked.emit()"
></mwe-invoice-data-change-confirmation-check-push-pdf-sepa>
