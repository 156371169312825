import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sparte } from '@mwe/models';

@Component({
  selector: 'mwe-category-dropdown-filter',
  templateUrl: './category-dropdown-filter.component.html',
})
export class CategoryDropdownFilterComponent {
  @Input() categoryOptions: Sparte[] = [];
  @Input() selectedCategory: Sparte;
  @Output() onSelectedCategory = new EventEmitter<Sparte>();

  constructor() {}

  isFernwaermeTariffClass(sparte: Sparte): boolean {
    return ['HEIZUNG', 'WARMWASSER', 'HEIZUNG_WARMWASSER', 'KALT-/ABWASSER', 'KALT_ABWASSER'].includes(sparte);
  }

  selectCategory(category: Sparte): void {
    this.selectedCategory = category;
    this.onSelectedCategory.emit(this.selectedCategory);
  }
}
