import { Component, Input } from '@angular/core';
import { IProduct, TextNodeType } from '@mwe/models';

@Component({
  selector: 'mwe-product-card-simple',
  templateUrl: './product-card-simple.component.html',
})
export class ProductCardSimpleComponent {
  @Input() product: IProduct;
  @Input() isDisabled = false;
  @Input() textNodeType: TextNodeType = 'h6';
}
