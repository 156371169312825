import { Component } from '@angular/core';

@Component({
  selector: 'mwe-terms-content-card',
  templateUrl: './content-card.component.html',
})
export class ContentCardComponent {
  // [1, 2, 3, ..., 13]
  items = [...Array(13).keys()].map(i => i + 1);

  getScrollTarget(i: number): string {
    return `#${this.getItemId(i)}`;
  }

  getItemId(i: number): string {
    return `item${i}`;
  }

  getItemTitleKey(i: number): string {
    return `terms.item${i}.title`;
  }
  getItemTextKey(i: number): string {
    return `terms.item${i}.text`;
  }
}
