import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isArrayWithMinOneItem } from '@mwe/utils';
import { Product, translateTariffClasses } from '@mwe/models';
import { ProductCategoryEnum } from '@mwe/constants';
import { Observable } from 'rxjs';

@Pipe({ name: 'translateTariffClass' })
export class TranslateTariffClassPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  async transform(product: Product, tariffClassOverwrite?: string, defaultValue?: string): Promise<string> {
    const tariffClasses = tariffClassOverwrite ? [tariffClassOverwrite] : product.tariffClasses;
    const category = product.category;

    if (!isArrayWithMinOneItem(tariffClasses)) {
      return defaultValue || '';
    }

    if ([ProductCategoryEnum.FERNWAERME].includes(ProductCategoryEnum[category.toUpperCase()])) {
      const items = await translateTariffClasses(product.category, tariffClasses, this.translateService);
      return items.join(', ');
    }

    return tariffClasses[0] || defaultValue || '';
  }
}
