import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AddressSelectionEntry, availableZipCodes, IOlavEntry, ISolrDocList, ISolrResponse, SelectionState } from '@mwe/models';
import { BehaviorSubject, Observable, Subscription, lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isArrayWithMinOneItem, isNumber, replaceAll } from '@mwe/utils';
import { NewClientService } from '@mwe/services';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PillBadgeComponent } from '../badge/pill-badge/pill-badge.component';

@Component({
  selector: 'mwe-address-input',
  templateUrl: './address-input.component.html',
  styles: [],
})
export class AddressInputComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  private _searchUrl = '/api/solr/olav/search';
  private readonly searchSubject = new BehaviorSubject<string | undefined>('');

  suggestions: ISolrDocList[] = [];
  selectedOlav: IOlavEntry;
  input = new UntypedFormControl('');
  inputStr = '';
  selectionState: SelectionState = SelectionState.INITIAL;
  selectionStateAccepted = false;
  selectedOption: HTMLElement = null;
  inputLabel = '';
  inputPlaceholder = '';
  inputDescription = '';
  isAlreadyValidAddress = false;
  showErrorMsg = false;
  pendingRequest: Subscription;
  hasNoResults = false;
  isSearching = false;
  unknownAddress = false;
  useIdentSearch = false;
  _hideDropdown = false;
  _initFocus = false;
  selectionEntries: AddressSelectionEntry[] = [];

  @Output() allSearchParametersDeleted = new EventEmitter<boolean>();
  @Output() allSearchParametersEntered = new EventEmitter<boolean>();
  @Output() olavZipCodeMatchesFound = new EventEmitter<boolean>();
  @Output() selectedOlavEvent = new EventEmitter<IOlavEntry>();
  @Output() selectionFinishedEvent = new EventEmitter<boolean>();
  @Output() errorStateEvent = new EventEmitter<boolean>();
  @Output() noResultsButtonClicked = new EventEmitter<boolean>();
  @Input() selectedAddress: IOlavEntry;
  @Input() predefinedOlavKey: string;
  @Input() ignoreAvailableZipCodes = false;
  @Input() noResultsButtonText = '';
  @Input() processType: string;
  @ViewChild('inputElement') inputElement: ElementRef<HTMLElement>;
  @ViewChild('dropdown') dropdownElement: ElementRef<HTMLElement>;
  @ViewChildren('pills') selectionPills: QueryList<PillBadgeComponent>;

  get hasSelectionEntries(): boolean {
    return isArrayWithMinOneItem(this.selectionEntries);
  }

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private newClientService: NewClientService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.predefinedOlavKey) {
      const olavKeyAddress = await this.newClientService.getAddressFromOlavKey(this.predefinedOlavKey, this.processType);
      this.selectedAddress = olavKeyAddress?.numFound >= 1 ? this._checkAvailableZipCodes(olavKeyAddress.docs[0]) : undefined;
    }

    if (this.selectedAddress) {
      this.selectedOlav = this.selectedAddress;
      this.selectionStateAccepted = true;
      this.useIdentSearch = !!this.selectedAddress.identAdresse;
      this.createSelectionEntriesFromOlav();
    }
    if (this.selectedOlav !== undefined) {
      if (this.selectedOlav.tuerNr || this.selectionStateAccepted) {
        this.setSelectionState(SelectionState.COMPLETE);
      } else {
        this.setSelectionState(SelectionState.DOOR);
      }
    } else {
      this.setSelectionState(SelectionState.LOCATION);
      if (this.predefinedOlavKey) {
        this.unknownAddress = true;
        this.errorStateEvent.emit(this.unknownAddress);
      }
    }

    this.searchSubject
      .pipe(
        debounceTime(500),
        switchMap(async searchQuery => this.getSuggestions(searchQuery)),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedAddress'] !== undefined && changes['selectedAddressState'] !== undefined) {
      if (
        changes['selectedAddress'].currentValue !== undefined &&
        changes['selectedAddressState'].currentValue === SelectionState.COMPLETE
      ) {
        this.selectedOlav = changes['selectedAddress'].currentValue;
        this.setSelectionState(changes['selectedAddressState'].currentValue);
      }
    }
  }
  ngAfterViewInit(): void {
    this._initFocus && this.focusLastAddressPill();
  }
  private _checkAvailableZipCodes(selectedAddress: IOlavEntry): IOlavEntry {
    return this.isAnAvailableZipCode(selectedAddress.plz) ? selectedAddress : undefined;
  }

  private async setSelectionState(newState: SelectionState): Promise<void> {
    let label: string;
    let placeholder: string;
    let description: string;
    this.selectionState = newState;
    switch (newState) {
      case SelectionState.INITIAL:
        break;
      case SelectionState.LOCATION:
        label = await lastValueFrom(this.translateService.get('address-input.inputLabel.plz'));
        placeholder = await lastValueFrom(this.translateService.get('address-input.inputPlaceholder.plz'));
        description = await lastValueFrom(this.translateService.get('address-input.inputDescription.plz'));
        this.allSearchParametersDeleted.emit(true);
        if (this.selectionStateAccepted) {
          this.selectionStateAccepted = false;
          this.isAlreadyValidAddress = false;
        }
        break;
      case SelectionState.STREET:
        label = await lastValueFrom(this.translateService.get('address-input.inputLabel.street'));
        placeholder = await lastValueFrom(this.translateService.get('address-input.inputPlaceholder.street'));
        if (this.selectionStateAccepted) {
          this.selectionStateAccepted = false;
          this.isAlreadyValidAddress = false;
        }
        break;
      case SelectionState.BUILDING:
        label = await lastValueFrom(this.translateService.get('address-input.inputLabel.building'));
        placeholder = await lastValueFrom(this.translateService.get('address-input.inputPlaceholder.building'));
        if (this.selectionStateAccepted) {
          this.selectionStateAccepted = false;
          this.isAlreadyValidAddress = false;
        }
        break;
      case SelectionState.DOOR:
        label = await lastValueFrom(this.translateService.get('address-input.inputLabel.door'));
        placeholder = await lastValueFrom(this.translateService.get('address-input.inputPlaceholder.door'));
        this.selectedOlav.tuerNr = null;
        if (this.selectionStateAccepted && !this.isAlreadyValidAddress) {
          this.selectionStateAccepted = false;
        }
        this.allSearchParametersEntered.emit(false);
        break;
      case SelectionState.COMPLETE:
        this.allSearchParametersEntered.emit(true);
        this.allSearchParametersDeleted.emit(false);
        if (this.selectionPills) {
          this.focusLastAddressPill();
        } else {
          this._initFocus = true;
        }
        break;
    }
    if (label) {
      this.inputLabel = label;
      this.inputPlaceholder = placeholder;
    }
    this.inputDescription = description;
    this.selectedOlavEvent.emit(this.selectedOlav);
    this.selectionFinishedEvent.emit(this.selectionStateAccepted);
  }

  private createSelectionEntriesFromOlav(): void {
    this.selectionEntries = [];

    if (this.selectedOlav.plz && this.selectedOlav.gemeinde) {
      const text = `${this.selectedOlav.plz} ${this.selectedOlav.gemeinde}`;
      const state = SelectionState.LOCATION;
      this.selectionEntries.push({ text, state });
    }

    if (this.selectedOlav.strasse) {
      const text = `${this.selectedOlav.strasse}`;
      const state = SelectionState.STREET;
      this.selectionEntries.push({ text, state });
    }

    if (this.selectedOlav.gebadr1) {
      const text = `${this.selectedOlav.gebadr1}`;
      const state = SelectionState.BUILDING;
      this.selectionEntries.push({ text, state });
    }

    if (this.selectedOlav.tuerNr) {
      const text = `${this.selectedOlav.tuerNr}`;
      const state = SelectionState.DOOR;
      this.selectionEntries.push({ text, state });
    }
  }

  replaceSpecialChars(value: string): string {
    return value.replace(/[()]/g, '*').replace(/[ ]/g, '?');
  }

  private search(value: string): Observable<ISolrResponse> {
    const params = {};
    switch (this.selectionState) {
      case SelectionState.INITIAL:
        break;
      case SelectionState.LOCATION:
        params['zipCode'] = `${this.replaceSpecialChars(value)}`;
        break;
      case SelectionState.STREET:
        params['zipCode'] = this.selectedOlav.plz;
        params['location'] = this.replaceSpecialChars(this.selectedOlav.gemeinde);
        params['street'] = `${this.replaceSpecialChars(value)}*`;
        break;
      case SelectionState.BUILDING:
        params['zipCode'] = this.selectedOlav.plz;
        params['location'] = this.replaceSpecialChars(this.selectedOlav.gemeinde);
        params['street'] = this.replaceSpecialChars(this.selectedOlav.strasse);
        params['building'] = `${this.replaceSpecialChars(value)}*`;
        break;
      case SelectionState.DOOR:
        params['zipCode'] = this.selectedOlav.plz;
        params['location'] = this.replaceSpecialChars(this.selectedOlav.gemeinde);
        params['street'] = this.replaceSpecialChars(this.selectedOlav.strasse);
        params['building'] = this.replaceSpecialChars(this.selectedOlav.gebadr1);
        params['door'] = `${this.replaceSpecialChars(value)}*`;
        params['useIdentSearch'] = this.useIdentSearch ? 'true' : 'false';
        break;
      case SelectionState.COMPLETE:
        break;
    }

    return this.http.get<ISolrResponse>(this._searchUrl, {
      params,
    });
  }

  selectSuggestion(selectedDocList: ISolrDocList, $event: Event): void {
    this.showErrorMsg = false;
    $event.preventDefault();
    $event.stopPropagation();
    this.selectedOption = null;
    this.input.setValue('');
    this.inputStr = '';
    this.suggestions = [];
    this.selectedOlav = selectedDocList.docs[0];
    this.selectionEntries.push({
      text: this.getSelectionText(),
      state: this.selectionState,
    });

    this.useIdentSearch =
      (selectedDocList.numFound === 1 && selectedDocList.identNumFound > 0) ||
      !!this.selectedOlav.identAdresse ||
      this.selectedOlav.strasse !== this.selectedOlav.identStrasse;

    if (selectedDocList.numFound === 1 && selectedDocList.identNumFound <= 1) {
      this.selectionStateAccepted = true;
      this.setSelectionState(SelectionState.COMPLETE);
      return;
    } else {
      this.selectionStateAccepted = selectedDocList.docs.some(
        i =>
          i.gebadr1 === i.anladr1 &&
          i.gebadr1.localeCompare(selectedDocList.groupValue, 'de', { sensitivity: 'accent' }) === 0 &&
          i.hasOwnProperty('vstelle'),
      );
      if (!this.selectionStateAccepted) {
        // if there is no 'tuerNr' but numFound in response is > 1 (response is dubious --> hack to continue with address input)
        if (selectedDocList.numFound === 2 && this.selectionState === SelectionState.BUILDING) {
          if (!selectedDocList.docs.some(i => i.hasOwnProperty('tuerNr'))) {
            this.selectionStateAccepted = true;
            this.setSelectionState(SelectionState.COMPLETE);
          }
        }
        // ----- END of hack ----->
      } else {
        this.isAlreadyValidAddress = true;
      }
    }

    switch (this.selectionState) {
      case SelectionState.INITIAL:
        this.setSelectionState(SelectionState.LOCATION);
        break;
      case SelectionState.LOCATION:
        this.unknownAddress = false;
        this.showErrorMsg = false;
        this.setSelectionState(SelectionState.STREET);
        break;
      case SelectionState.STREET:
        this.setSelectionState(SelectionState.BUILDING);
        break;
      case SelectionState.BUILDING:
        this.setSelectionState(SelectionState.DOOR);
        break;
      case SelectionState.DOOR:
        this.setSelectionState(SelectionState.COMPLETE);
        break;
      case SelectionState.COMPLETE:
        break;
    }
    // document.querySelector('#mwe-address-input-field')['focus']();
  }

  onInput($event: KeyboardEvent): void {
    this.showErrorMsg = false;
    const currentValue = this._escapeSpecialChars(($event.target as HTMLInputElement).value.replace(/[ ()]/g, '*'));
    const minLength = this.selectionState === SelectionState.LOCATION || this.selectionState === SelectionState.STREET ? 4 : 0;

    if (this.inputStr !== currentValue) {
      this.inputStr = currentValue;

      if (currentValue?.length >= minLength) {
        this.searchSubject.next(currentValue?.trim());
      } else {
        this.suggestions = [];
      }
    }
  }

  limitChars($event: KeyboardEvent): boolean {
    if ($event.key === 'Enter') {
      $event.preventDefault();
    }
    if (this.selectionState === SelectionState.LOCATION && $event.key.length === 1) {
      // limit zip code to 4 digits
      if (this._isNumber($event)) {
        return !(
          ($event.target as HTMLInputElement).value !== undefined && ($event.target as HTMLInputElement).value.toString().length > 3
        );
      } else {
        $event.preventDefault();
      }
    }
    return undefined;
  }
  keyboardNavigation($event: KeyboardEvent): void {
    if (!this.suggestions.length) {
      return;
    }

    switch ($event.key) {
      case 'Enter': {
        if (!this.selectedOption || !this.suggestions.length) {
          break;
        }
        this.selectSuggestion(this.suggestions[this.selectedOption.getAttribute('data-suggestion-index')], $event);
        break;
      }
      case 'Escape': {
        this._hideDropdown = true;
        break;
      }
      case 'ArrowDown': {
        if (!this.selectedOption) {
          const firstOption = this.dropdownElement.nativeElement.querySelector('.dropdown-item') as HTMLElement;
          this.selectOption(firstOption);
          break;
        }
        const nextElement = this.selectedOption.nextSibling as HTMLElement;
        if (
          nextElement &&
          nextElement.nodeType === 1 &&
          this.selectedOption.classList.contains('dropdown-item') &&
          nextElement.classList.contains('dropdown-item')
        ) {
          this.selectOption(nextElement);
        }
        break;
      }
      case 'ArrowUp': {
        const prevElement = this.selectedOption.previousSibling as HTMLElement;
        if (
          prevElement &&
          prevElement.nodeType === 1 &&
          this.selectedOption.classList.contains('dropdown-item') &&
          prevElement.classList.contains('dropdown-item')
        ) {
          this.selectOption(prevElement);
        }
        break;
      }
    }
  }
  selectOption(element: HTMLElement): void {
    let siblings = n => [...n.parentElement.children].filter(c => c.nodeType == 1 && c != n);
    element.classList.add('dropdown-item-selected');
    siblings(element).forEach(e => e.classList.remove('dropdown-item-selected'));
    this.selectedOption = element;
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  _isNumber($event: KeyboardEvent): boolean {
    return isNumber($event.key);
  }

  _escapeSpecialChars(value: string): string {
    return value.replace(/[-/\\^$+?.|[\]{}]/g, '\\$&');
  }

  getSuggestions(inputValue: string): void {
    if (!inputValue) {
      if (this.pendingRequest) {
        this.pendingRequest.unsubscribe();
      }
      return;
    }

    if (this.pendingRequest) {
      this.pendingRequest.unsubscribe();
    }

    this.isSearching = true;
    this.pendingRequest = this.search(inputValue.replace(/[*]/g, '?')).subscribe(
      result => {
        this.isSearching = false;
        this.suggestions = this.parseSuggestions(result);
        this.selectedOption = null;
        this._hideDropdown = false;

        if (JSON.stringify(result).includes('"grouped":{"gemeinde"')) {
          if (result.grouped.gemeinde.matches != 0) {
            this.olavZipCodeMatchesFound.emit(true);
          } else {
            this.olavZipCodeMatchesFound.emit(false);
          }
        } else {
          this.olavZipCodeMatchesFound.emit(false);
        }
      },
      err => {
        this.isSearching = false;
        console.log(err);
      },
    );
  }

  isAnAvailableZipCode(plz: string): boolean {
    if (this.ignoreAvailableZipCodes) {
      return true;
    }

    return availableZipCodes.some(item => item === plz);
  }

  parseSuggestions(result: ISolrResponse): ISolrDocList[] {
    const newSuggestions: ISolrDocList[] = [];

    if (result && result.grouped) {
      const groups =
        result.grouped.gemeinde || result.grouped.strasse || result.grouped.gebadr1 || result.grouped.tuerNr || result.grouped.anladr1;
      this.hasNoResults = groups.matches === 0 ? true : false;
      if (groups.groups && groups.groups.length) {
        groups.groups.forEach(group => {
          if (group.doclist && group.doclist.docs && group.doclist.docs.length) {
            group.doclist.groupValue = group.groupValue;
            if (result.grouped.gemeinde) {
              if (this.isAnAvailableZipCode(group.doclist.docs[0].plz)) {
                newSuggestions.push(group.doclist);
              } else {
                this.hasNoResults = true;
              }
            } else {
              newSuggestions.push(group.doclist);
            }
          }
        });
      }
    }

    return this.sort(this.filterIncompleteOlavKeys(newSuggestions));
  }

  filterIncompleteOlavKeys(suggestions: ISolrDocList[]): ISolrDocList[] {
    const filteredSuggestions: ISolrDocList[] = [];
    suggestions.forEach(suggestion => {
      !this.isIncompleteOlavKey(suggestion.docs[0].olavKey, suggestion.identNumFound) &&
        filteredSuggestions.push(this.cleanDuplcatedAnlageKeys(suggestion));
    });

    let splittedSuggestions = [];
    if (this.selectionState === SelectionState.DOOR) {
      filteredSuggestions.forEach(suggestionItem => {
        splittedSuggestions.push(suggestionItem);
        if (suggestionItem.docs.length > 1) {
          const docsToSplit = suggestionItem.docs.slice(1, suggestionItem.docs.length);
          const uniqueTuerNr = [];
          this.updateTuerNr(suggestionItem.docs[0]);
          uniqueTuerNr.push(suggestionItem.docs[0].tuerNr);
          docsToSplit.forEach(item => {
            if (!uniqueTuerNr.includes(item.anladr1)) {
              uniqueTuerNr.push(item.anladr1);
              const clonedItem = { ...suggestionItem };
              this.updateTuerNr(item);
              clonedItem.docs = [item];
              splittedSuggestions.push(clonedItem);
            }
          });
        }
      });
    } else {
      splittedSuggestions = filteredSuggestions;
    }

    return splittedSuggestions;
  }

  private updateTuerNr(documentToUpdate: IOlavEntry) {
    if (documentToUpdate.tuerNr !== documentToUpdate.anladr1) {
      if (documentToUpdate.anladr1.trim().startsWith(documentToUpdate.gebadr1)) {
        const newTuerNr = documentToUpdate.anladr1.trim().replace(documentToUpdate.gebadr1, '').trim().replace('/', '');
        documentToUpdate.tuerNr = newTuerNr.replace('+', ' ');
      } else {
        documentToUpdate.tuerNr = documentToUpdate.anladr1.trim();
      }
    }
  }

  isIncompleteOlavKey(olavKey: string, identMatches: number): boolean {
    return [SelectionState.BUILDING, SelectionState.DOOR].includes(this.selectionState) && identMatches <= 1 && olavKey.includes('null');
  }

  unselect(event, targetState?: SelectionState): void {
    event.preventDefault();
    this.input.setValue('');
    this.suggestions = [];

    if (targetState) {
      const deletionIndex = this.selectionEntries.map(e => e.state).indexOf(targetState);
      if (deletionIndex === -1) {
        return;
      }
      this.selectionEntries.length = deletionIndex;
      this.setSelectionState(targetState);
      if (targetState === SelectionState.STREET || targetState === SelectionState.BUILDING || targetState === SelectionState.LOCATION) {
        this.isAlreadyValidAddress = false;
      }
    } else {
      switch (this.selectionState) {
        case SelectionState.STREET:
          this.setSelectionState(SelectionState.LOCATION);
          break;
        case SelectionState.BUILDING:
          this.setSelectionState(SelectionState.STREET);
          this.isAlreadyValidAddress = false;
          break;
        case SelectionState.DOOR:
          this.setSelectionState(SelectionState.BUILDING);
          this.isAlreadyValidAddress = false;
          break;
        case SelectionState.COMPLETE:
          this.setSelectionState(this.selectedOlav.tuerNr ? SelectionState.DOOR : SelectionState.BUILDING);
          break;
      }
    }
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    });
  }

  validate(): boolean {
    const isComplete = this.selectionState === SelectionState.COMPLETE || this.selectionStateAccepted;
    this.showErrorMsg = !isComplete;
    return isComplete;
  }

  getSelectedOlavWithLatestUserInputForManualAddressInput(): IOlavEntry {
    const customSelectedOlav = {} as IOlavEntry;

    switch (this.selectionState) {
      case SelectionState.LOCATION:
        customSelectedOlav.plz = this.inputStr;
        break;
      case SelectionState.STREET:
        customSelectedOlav.plz = this.selectedOlav?.plz;
        customSelectedOlav.gemeinde = this.selectedOlav?.gemeinde;
        customSelectedOlav.strasse = this.inputStr;
        break;
      case SelectionState.BUILDING:
        customSelectedOlav.plz = this.selectedOlav?.plz;
        customSelectedOlav.gemeinde = this.selectedOlav?.gemeinde;
        customSelectedOlav.strasse = this.selectedOlav?.strasse;
        customSelectedOlav.gebadr1 = replaceAll(this.inputStr, '\\/', '/');
        break;
      case SelectionState.DOOR:
        customSelectedOlav.plz = this.selectedOlav?.plz;
        customSelectedOlav.gemeinde = this.selectedOlav?.gemeinde;
        customSelectedOlav.strasse = this.selectedOlav?.strasse;
        customSelectedOlav.gebadr1 = this.selectedOlav?.gebadr1;
        customSelectedOlav.tuerNr = replaceAll(this.inputStr, '\\/', '/');
        break;
    }

    return customSelectedOlav;
  }

  sort(newSuggestions: ISolrDocList[]): ISolrDocList[] {
    let filteredSuggestions = newSuggestions;
    if ([SelectionState.BUILDING, SelectionState.DOOR].includes(this.selectionState)) {
      filteredSuggestions = newSuggestions.sort((a, b) => {
        if (a.groupValue.length === b.groupValue.length) {
          if (a.groupValue.indexOf(this.inputStr) === b.groupValue.indexOf(this.inputStr)) {
            return a.groupValue < b.groupValue ? -1 : 1;
          }
          return a.groupValue.indexOf(this.inputStr) - b.groupValue.indexOf(this.inputStr);
        } else {
          return a.groupValue.length - b.groupValue.length;
        }
      });
    }
    const endIdx = filteredSuggestions.length < 11 ? filteredSuggestions.length : 11;
    return filteredSuggestions.slice(0, endIdx);
  }

  cleanDuplcatedAnlageKeys(suggestion: ISolrDocList): ISolrDocList {
    const anlageKey = [];
    const filteredDocs = [];

    suggestion.docs.forEach(doc => {
      if (!anlageKey.includes(doc.anlageKey)) {
        anlageKey.push(doc.anlageKey);
        filteredDocs.push(doc);
      }
    });

    suggestion.docs = filteredDocs;
    return suggestion;
  }

  getSelectionText(specificOlav?: IOlavEntry, specificSelectionState?: SelectionState): string {
    const relevantState = specificSelectionState || this.selectionState;
    const relevantOlav = specificOlav || this.selectedOlav;
    switch (relevantState) {
      case SelectionState.LOCATION:
        return `${relevantOlav.plz} ${relevantOlav.gemeinde}`;
      case SelectionState.STREET:
        return `${relevantOlav.strasse}`;
      case SelectionState.BUILDING:
        return `${relevantOlav.gebadr1}`;
      case SelectionState.DOOR:
        return `${relevantOlav.tuerNr ? relevantOlav.tuerNr : relevantOlav.gebadr1}`;
      default:
        return 'Should not return that';
    }
  }
  focusLastAddressPill(): void {
    if (!this.selectionPills || !this.selectionPills.length) {
      return;
    }
    setTimeout(() => {
      this.selectionPills.last.buttonElement.nativeElement.focus();
    });
  }
  ngOnDestroy(): void {
    this.searchSubject.unsubscribe();
  }
}
