import { Component, Input, OnInit, signal } from '@angular/core';
import { StaticContentService } from '@mwe/services';
import { TariffSelectionStoreService } from '../services/tariff-selection-store.service';
import { TariffSelectionCardData, TextNodeType } from '@mwe/models';
import { TariffSelectionCardLogic } from '../services/tariff-selection-card.logic';
import { ServiceStateEnum } from '@mwe/constants';
import { HeadlineService } from '../../headline/headline.service';

@Component({
  selector: 'mwe-tariff-card-additional-options',
  templateUrl: './tariff-card-additional-options.component.html',
  providers: [HeadlineService],
})
export class TariffCardAdditionalOptionsComponent implements OnInit {
  // selectedTariff comes from signal() do not manipulate it!!!! use TariffSelectionStoreService!!!!!
  @Input({ required: true }) tariff: TariffSelectionCardData;
  @Input() isReadOnly = false;
  @Input() headlineReference: TextNodeType = 'h4';

  infoText = signal<string>('');
  title = signal<string>('');
  isSelected = signal<boolean>(false);

  constructor(
    private staticContentService: StaticContentService,
    private cardLogic: TariffSelectionCardLogic,
    private store: TariffSelectionStoreService,
    public headlineService: HeadlineService,
  ) {}

  get additionalOptionGroupName(): string {
    return this.store.additionalOptionGroupName();
  }

  async ngOnInit() {
    this.headlineService.setReferenceHeadline(this.headlineReference);
    const {
      tarifwelt: {
        optionen: { zusatzoptionen },
      },
    } = await this.staticContentService.getInfoTextList();
    this.infoText.set(zusatzoptionen?.[0]?.description ?? '');
    this.setTitle();
    this.getSelection();
  }

  async onCheckboxChange() {
    const newCheckValue = !this.isSelected(); // toggle select value
    // default is always "thanks but no", so default: true -> not checked and vice versa
    const newOption = this.tariff.tariffOptionsSap.find(o => o.art === this.additionalOptionGroupName && o.default !== newCheckValue);
    this.cardLogic.handleTariffOptionChange(this.tariff, newOption);
  }

  isCheckBoxDisabled() {
    return this.tariff?.state !== ServiceStateEnum.SUCCESS;
  }

  private setTitle() {
    let optionId: string;
    if (this.isReadOnly) {
      optionId = this.tariff.userSelection.find(t => t.art === this.additionalOptionGroupName)?.option;
    } else {
      optionId = this.tariff.tariffOptionsSap.find(t => t.art === this.additionalOptionGroupName && !t?.default)?.option;
    }

    const t2Option = this.tariff.priceIndication.tarif.tarifOptionen.find(t => t?.option === optionId);
    this.title.set(t2Option?.optionLabel ?? '');
  }

  private getSelection() {
    // get the preselected additional option
    const selectedUserOption = this.tariff.userSelection.find(o => o.art === this.additionalOptionGroupName);
    // default is always "thanks but no", so default: true -> not checked and vice versa
    this.isSelected.set(!selectedUserOption?.default);
  }
}
