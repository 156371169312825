<div
  class="card is-blank mx-0 flex-grow-1"
  [ngClass]="{ pointer: route }"
  (click)="cardClicked()"
  [attr.id]="cardId"
  [attr.data-test]="cardId"
>
  <div class="card-body h-100">
    @if (iconClass || iconImage) {
      <div class="card-image">
        @if (iconClass) {
          <i class="fas {{ iconClass }}"></i>
        } @else if (iconImage) {
          <img *ngIf="iconImage" src="assets/icons/{{ iconImage }}" />
        }
      </div>
    }
    <div class="card-detail">
      @if (titleLabel) {
        <h5 [translate]="titleLabel"></h5>
      }
      <div class="card-desc text--big mb-3">
        <ng-content></ng-content>
      </div>

      @if (isCtaButtonLink) {
        <div class="mt-3">
          <mwe-pre-load-data
            class="d-flex"
            [successTemplate]="buttonLink"
            loadingSpinnerType="normal-without-box"
            alertTitleKey=""
            [alertMessageKey]="ctaButtonStateErrorMessage"
            [state]="ctaButtonState"
          >
          </mwe-pre-load-data>
          <ng-template #buttonLink>
            <mwe-button-link
              [customCss]="'mt-0 ' + ctaButtonCss"
              [dataTest]="ctaButtonDataTest"
              [label]="ctaButtonLabel"
              (clicked)="ctaButtonClicked.emit()"
            ></mwe-button-link>
          </ng-template>
        </div>
      } @else {
        <mwe-button [buttonId]="ctaButtonDataTest" [labelKey]="ctaButtonLabel" (clicked)="ctaButtonClicked.emit()"></mwe-button>
      }
    </div>
    @if (route) {
      <i class="fas fa-chevron-right"></i>
    }
  </div>
</div>
