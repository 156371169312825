import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function trimmedMinLength(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const badValue = { equal: false };
    if (!!control.value) {
      return control.value.trim().length >= minLength ? null : badValue;
    }
    return badValue;
  };
}
