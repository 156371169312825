import { parseISODate } from '../model_utils';

interface IUserJournal {
  id?: any;
  userId: string;
  event: string;
  createdAt: Date;
  modifiedAt: Date;
  additionalData?: string;
  isFirstTimeVisited(): boolean;
}

export class UserJournal implements IUserJournal {
  constructor(
    public id: string,
    public userId: string,
    public event: string,
    public createdAt: any,
    public modifiedAt: any,
    public additionalData?: string
  ) {
    this.id = id;
    this.userId = userId;
    this.event = event;
    this.createdAt = parseISODate(createdAt);
    this.modifiedAt = parseISODate(modifiedAt);
    this.additionalData = additionalData;
  }

  isFirstTimeVisited(): boolean {
    return this.modifiedAt.getTime() === this.createdAt.getTime();
  }
}
