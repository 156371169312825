<div class="form-floating d-flex align-items-center position-relative">
  <input
    type="text"
    class="form-control datetimepicker-input"
    [ngClass]="{
      'is-invalid':
        !inputElem.datepickerConfig.readonly && parentForm.controls[inputElem.name].errors && !parentForm.controls[inputElem.name].pristine,
      'bg-transparent': inputElem.datepickerConfig.readonly
    }"
    [formControl]="parentForm.controls[inputElem.name]"
    [attr.id]="inputElem.name"
    [attr.data-test]="'form-input-' + inputElem.name"
    [attr.name]="inputElem.name"
    [attr.placeholder]="inputElem.placeholder | translate"
    [attr.avoidCopyPaste]="inputElem.avoidCopyPaste || false"
    [mweBlockPasteEvent]="inputElem.avoidCopyPaste"
    [mweInputKeyDirective]="inputElem.directiveType"
    [attr.disabled]="isLoading ? '' : null"
    [attr.aria-describedby]="ariaDescribedBy || null"
    [attr.aria-invalid]="!isValid"
    #d
  />
  <label
    [attr.for]="inputElem.name"
    class="control-label"
    [ngClass]="inputElem.labelCss"
    [translate]="inputElem.labelKey"
    *ngIf="inputElem.labelKey"
  ></label>
  <div class="form-floating-icon">
    <button
      class="btn btn-clean"
      (click)="openDatepicker(d, inputElem.datepickerConfig)"
      [attr.aria-label]="'global.datepicker.toggleButton' | translate"
      [attr.data-test]="'form-input-' + inputElem.name + '-datepicker'"
    >
      <i class="far fa-calendar-alt"></i>
    </button>
  </div>
</div>
