import { Injectable } from '@angular/core';
import { HeadingNodeType, TextNodeType } from '@mwe/models';

@Injectable()
export class HeadlineService {
  private referenceHeadline: TextNodeType;

  setReferenceHeadline(refH: TextNodeType) {
    this.referenceHeadline = refH;
  }

  getHeadline(increment?: number) {
    if (!this.referenceHeadline) {
      return 'p';
    }
    if (!increment) {
      return this.referenceHeadline;
    }
    return this.incrementHeadingNodeType(this.referenceHeadline, increment);
  }

  incrementHeadingNodeType(subject: TextNodeType, value: number): TextNodeType {
    let parsedHeadlineLevel;
    let targetHeadlineLevel;
    if (subject.length === 1) {
      return 'p';
    }
    if (subject.length === 2) {
      parsedHeadlineLevel = parseInt(subject.substr(1, 1), 10);
    }
    targetHeadlineLevel = parsedHeadlineLevel + value;
    if (targetHeadlineLevel >= 1 && targetHeadlineLevel <= 6) {
      return ('h' + targetHeadlineLevel) as TextNodeType;
    }
    return 'p';
  }
}
