<br />

<ng-template #dpft>
  <div class="row justify-content-end">
    <div class="col-auto">
      <button
        id="mwe-datepicker-close"
        type="button"
        class="btn btn-link btn-link-secondary text-uppercase"
        (click)="closeDatepicker()"
        [translate]="cancelMsgKey"
      >
        Abbrechen
      </button>
    </div>
    <div class="col-auto">
      <button
        id="mwe-datepicker-submit"
        type="button"
        class="btn btn-link btn-link-primary text-uppercase"
        (click)="selectDate()"
        [translate]="submitMsgKey"
      >
        Ok
      </button>
    </div>
  </div>
</ng-template>

<div class="datepicker" style="--bs-border-color: var(--bs-gray)">
  <div class="datepicker-header">
    <p class="title">Datum</p>
    <p class="year">{{ currentYear }}</p>
    <h2 class="fulldate">{{ specialFormat(currentDate?.toDate() | date: 'EEE, dd. MMMM' : undefined : 'de') }}</h2>
  </div>
  <ngb-datepicker [(ngModel)]="currentDate" [footerTemplate]="dpft" [minDate]="minDate" [maxDate]="maxDate" [startDate]="startDate">
  </ngb-datepicker>
</div>
