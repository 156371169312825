import { Params } from '@angular/router';

export function getSingleValueFromParams(params: Params, key: string): string {
  const value = params[key];

  if (Array.isArray(value)) {
    return value[0];
  }

  return value;
}

export function getArrayFromParams(params: Params, key: string): string[] {
  const tariffKeys = params[key];

  if (!tariffKeys) {
    return [];
  }

  if (!Array.isArray(tariffKeys)) {
    return [tariffKeys];
  }

  return tariffKeys;
}

export function mapTariffQueryParameters(queryParams: Params): Map<string, string>[] {
  const tariffs = getArrayFromParams(queryParams, 'tarif');

  return tariffs.map(element => {
    // remove useless chars, string starts with [ and ends with ]
    const kvpTokens = element.slice(1, -1).split(';');
    const kvpMap = new Map<string, string>();
    kvpTokens.forEach(kvpToken => {
      const kvp = kvpToken.split(':');
      kvpMap.set(kvp[0].toLowerCase(), kvp[1]);
    });
    return kvpMap;
  });
}
