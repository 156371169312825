import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IBulletConfirmationStatusChangeResponse, IWizardStepComponent } from '@mwe/models';
import { ServiceStateEnum } from '@mwe/constants';
import { anyTrue, everyTrue, getFormattedCategory, sortProductsEnumCategory, translateCategory } from '@mwe/utils';
import { ServiceStateEnumExporter } from '@mwe/ui/shared';
import { TranslateService } from '@ngx-translate/core';
import { AccountLogic, LoggingService } from '@mwe/services';
import { lastValueFrom } from 'rxjs';

export interface BulletConfirmationLabelOverwriteInfo {
  label: string;
  state: ServiceStateEnum;
}

@Component({ template: '' })
export abstract class AbstractBulletConfirmationComponent<T extends IBulletConfirmationStatusChangeResponse, M>
  extends ServiceStateEnumExporter
  implements OnInit, IWizardStepComponent
{
  @Output() readonly onHideButtons = new EventEmitter<boolean>();
  @Output() readonly statusSuccess = new EventEmitter<boolean>();
  submitId: number;
  checkStatusLoopCount = 0;
  checkStatusLoopTimeout = 500; // ms
  // we will wait 100 * 500ms before we break the loop
  // number of main loops: sepa, eRechnung, tariff+
  checkStatusLoopLimit = 100; // TODO: how long should we wait?
  bulletStateMap: Map<string, ServiceStateEnum> = new Map();
  bulletLabelMap: Map<string, string> = new Map();
  bulletTitleMap: Map<string, string> = new Map();
  bulletTranslateMap: Map<string, any> = new Map();
  bulletLabelOverwriteMap: Map<string, BulletConfirmationLabelOverwriteInfo> = new Map();
  bulletStateIconMap: Map<string, Map<ServiceStateEnum, string>> = new Map();
  bulletStateClassMap: Map<string, Map<ServiceStateEnum, string>> = new Map();
  bulletIds: string[];
  currentBulletId: string;
  state: ServiceStateEnum;
  useProcessStatusCanceledAfterStepError = false;
  defaultErrorMsgKey = '';
  retryErrorMsgKey: string;
  errorMsgKey = this.defaultErrorMsgKey;
  retryAllowed = true;
  errorDescription: Map<number, string> = new Map();
  errorCode: number;
  dataDTO: M;
  generalTranslateValues = {};
  alertPlaceholders: Map<string, string> = new Map();
  confirmationWasSuccessfully = false;
  protected failedBulletIds: string[];
  protected categoryTranslateMap: Map<string, string> = new Map();
  protected lastConfirmationStatus: T;

  constructor(
    protected accountLogic: AccountLogic,
    protected loggingService: LoggingService,
    protected translateService: TranslateService,
  ) {
    super();
  }

  abstract getConfirmStatus(): Promise<T>;

  abstract updateStateRetriedOnError(retried: boolean): void;

  abstract sendConfirm(): void;

  abstract initializeDefaultState(): Promise<void>;

  abstract getDataChangeDTO(): M;

  updateBulletLabelOverwriteMap(responseData?: string): void {}

  updateBulletTitleOverwriteMap(res?: any): void {}

  updateFailedBulletIds(response: IBulletConfirmationStatusChangeResponse): void {
    this.failedBulletIds = [];
  }

  async ngOnInit(): Promise<void> {
    this.onHideButtons.emit(true);
    this.alertPlaceholders.set('changeType', 'Bankverbindung');
    this.alertPlaceholders.forEach((value: string, key: string) => {
      this.generalTranslateValues[key] = value;
    });
    await this.initializeDefaultState();
    this.commitData();
  }

  commitData(): void {
    if (this.submitId) {
      // we are going to retry the process so hide buttons again
      this.checkConfirmationStatus();
    } else {
      this.confirm();
    }
  }

  submit(): Promise<void> {
    return Promise.resolve();
  }

  hasAllNeededData(): boolean {
    return true;
  }

  validate(): boolean {
    return anyTrue(this.state === ServiceStateEnum.SUCCESS, everyTrue(!!this.submitId, this.state === ServiceStateEnum.ERROR));
  }

  setInitialStateValues(submitId: number, retriedOnError: boolean): void {
    this.state = submitId ? ServiceStateEnum.INIT : ServiceStateEnum.LOADING;
    this.submitId = submitId;
    if (retriedOnError === true) {
      this.retryAllowed = false;
    }
  }

  resetLoopCount(): void {
    // we moved one hub further, so we assume everything is working well
    // and therefore we reset the loop count
    this.checkStatusLoopCount = 0;
  }

  setLabelState(label: string, state: string): string {
    return label.substring(0, label.lastIndexOf('.') + 1) + (state === 'loading' ? 'inProgress' : state);
  }

  isFailedBuildId(bulletId: string): boolean {
    return this.failedBulletIds?.includes(bulletId);
  }

  setState(state: ServiceStateEnum, ...bubbleIds: string[]): void {
    bubbleIds.forEach(item => {
      this.bulletStateMap.set(item, this.isFailedBuildId(item) ? ServiceStateEnum.FAILED : state);
      this.bulletLabelMap.set(item, this.getLabel(state, item));
    });

    if (state === 'error' && !!this.useProcessStatusCanceledAfterStepError) {
      this.bulletLabelMap.forEach((value: string, key: string, map: Map<string, string>) => {
        if (!bubbleIds.includes(key) && !value.includes('success')) {
          map.set(key, value.substring(0, value.lastIndexOf('.') + 1).concat('canceled'));
        }
      });
    }
  }

  setError(bubbleId: string): void {
    if (!this.submitId) {
      // MWE-6300
      this.retryAllowed = false;
    }
    this.state = ServiceStateEnum.ERROR;
    if (everyTrue(this.retryAllowed, !bubbleId)) {
      this.retryAllowed = false;
      this.errorMsgKey = this.retryErrorMsgKey;
    } else if (everyTrue(!this.retryAllowed, !bubbleId)) {
      this.retryAllowed = false;
      this.errorMsgKey = this.defaultErrorMsgKey;
    } else {
      this.errorMsgKey = this.retryAllowed ? this.retryErrorMsgKey : this.getErrorMsgKey();
      this.setState(ServiceStateEnum.ERROR, bubbleId);
    }
  }

  getErrorMsgKey(): string {
    if (this.errorDescription.has(this.errorCode)) {
      return this.errorDescription.get(this.errorCode);
    } else {
      return this.defaultErrorMsgKey;
    }
  }

  updateAndCheckIfLimitIsExceeded(): boolean {
    this.checkStatusLoopCount++;
    if (this.checkStatusLoopCount > this.checkStatusLoopLimit) {
      this.loggingService.logError(
        new Error('CHECKCONFIRMATION_STATUS_LOOP_LIMIT'),
        'Check status loop limit excited! loop count: ' + this.checkStatusLoopCount,
      );
      this.retryAllowed = false;
      this.setError(this.currentBulletId);
      return true;
    }
    return false;
  }

  confirm(): void {
    try {
      this.sendConfirm();
    } catch (err) {
      this.setError(null);
    }
  }

  async checkConfirmationStatus(): Promise<void> {
    this.onHideButtons.emit(true);
    if (this.updateAndCheckIfLimitIsExceeded()) {
      return;
    }
    try {
      await this.getConfirmStatus().then(res => {
        this.lastConfirmationStatus = res;
        this.updateFailedBulletIds(res);
        this.updateBulletLabelOverwriteMap(res.responseData);
        if (res.status.startsWith('processing')) {
          const processingParts = res.status.split('_');
          this.currentBulletId = processingParts[1] + '_' + processingParts[2];
          const sliceEndIx = this.bulletIds.indexOf(this.currentBulletId);
          if (processingParts[3] === 'error') {
            // all bubbles before should have state success
            this.retryAllowed = res.retryAllowed;
            this.errorCode = res.errorCode;
            this.updateStateRetriedOnError(true);
            this.setState(ServiceStateEnum.SUCCESS, ...this.bulletIds.slice(0, sliceEndIx));
            this.setError(this.currentBulletId);
            this.onHideButtons.emit(false);
            this.updateBulletTitleOverwriteMap(res);
            return;
          } else if (everyTrue(processingParts[3] === 'success', sliceEndIx > -1)) {
            this.resetLoopCount();
            this.setState(ServiceStateEnum.SUCCESS, ...this.bulletIds.slice(0, sliceEndIx + 1));
          } else if (everyTrue(processingParts[3] === 'failed', sliceEndIx > -1)) {
            this.setState(ServiceStateEnum.SUCCESS, ...this.bulletIds.slice(0, sliceEndIx));
            this.setState(ServiceStateEnum.FAILED, this.bulletIds[sliceEndIx]);
          } else if (everyTrue(processingParts[3] === 'init', sliceEndIx > -1)) {
            this.setState(ServiceStateEnum.SUCCESS, ...this.bulletIds.slice(0, sliceEndIx));
            this.setState(ServiceStateEnum.LOADING, this.bulletIds[sliceEndIx]);
          }
          this.updateBulletTitleOverwriteMap(res);
          setTimeout(() => this.checkConfirmationStatus(), this.checkStatusLoopTimeout);
        } else if (!res.status.startsWith('init')) {
          this.state = res.status.startsWith('success') ? ServiceStateEnum.SUCCESS : ServiceStateEnum.ERROR;
          if (this.state === ServiceStateEnum.SUCCESS) {
            this.setState(ServiceStateEnum.SUCCESS, ...this.bulletIds);
            this.onHideButtons.emit(false);
            this.statusSuccess.emit(true);
            this.confirmationWasSuccessfully = true;
          } else {
            this.statusSuccess.emit(false);
            this.confirmationWasSuccessfully = false;
          }
          this.updateBulletTitleOverwriteMap(res);
        } else {
          setTimeout(() => this.checkConfirmationStatus(), this.checkStatusLoopTimeout);
        }
      });
    } catch (err) {
      this.setError(null);
    }
  }

  protected async loadTranslations(changeTypeI18nKey?: string): Promise<void> {
    try {
      const translationPromises = ['fernwaerme', 'stromrueck', 'emobility', 'iptv', 'voip'].map(category => {
        return translateCategory(category, this.translateService).then(tv => {
          this.categoryTranslateMap.set(category, tv);
        });
      });
      if (changeTypeI18nKey) {
        translationPromises.push(
          lastValueFrom(this.translateService.get(changeTypeI18nKey)).then(tv => {
            this.generalTranslateValues['changeType'] = tv;
            this.alertPlaceholders.set('changeType', tv);
          }),
        );
      }
      await Promise.all(translationPromises);
    } catch (e) {}
  }

  protected getTranslatedCategories(categories: string[]): string {
    if (!Array.isArray(categories)) {
      return '';
    }

    sortProductsEnumCategory(categories);
    const translatedCategories = Array.from(new Set(categories)).map(category => {
      if (this.categoryTranslateMap.has(category.toLowerCase())) {
        return this.categoryTranslateMap.get(category.toLowerCase());
      }

      return getFormattedCategory(category);
    });

    return translatedCategories.join(', ');
  }

  protected populateMaps(
    categories: string[],
    bulletType: string,
    oIx: number,
    titleKey: string,
    labelKey: string,
    translateValues = {},
  ): void {
    translateValues['categories'] = this.getTranslatedCategories(categories);
    this.bulletStateMap.set(bulletType + oIx, ServiceStateEnum.INIT);
    this.bulletTitleMap.set(bulletType + oIx, titleKey);
    this.bulletLabelMap.set(bulletType + oIx, labelKey);
    this.bulletTranslateMap.set(bulletType + oIx, translateValues);
  }

  private getLabel(state: ServiceStateEnum, bubbleId: string): string {
    const overwriteItem = this.bulletLabelOverwriteMap.get(bubbleId);

    if (overwriteItem?.state === state) {
      return overwriteItem.label;
    }

    return this.setLabelState(this.bulletLabelMap.get(bubbleId), state);
  }
}
