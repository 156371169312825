<div class="position-relative" (mweClickOutside)="hideDropDown()">
  <mwe-address
    [ngClass]="{ pointer: addressOptions?.length > 1 }"
    [address]="address"
    [isAddressDetailsBodyVisible]="false"
    dropdownType="custom"
    (click)="toggleDropDown()"
  >
    @if (addressOptions?.length > 1) {
      <ng-container slot="icon-right">
        <i class="fas fa-angle-down fa-w-10 font-size-22 text-white" aria-hidden="true" [ngClass]="{ rotate: !isCollapsed }"></i>
      </ng-container>
    }
  </mwe-address>
  <div class="address-selection-dropdown-menu">
    <div class="dropdown-menu top-border-0 menu-full-width d-block">
      <div [(ngbCollapse)]="isCollapsed" data-test="address-selection-dropdown" class="list-group-flush pl-0 pr-0 address-menu">
        @for (option of addressOptions; track option) {
          @if (option?.address) {
            <a class="list-group-item" (click)="onAddressSelected(option)" [ngClass]="{ selected: isAddressSelected(option.address) }">
              @if (option.address.addressGroup?.groupName) {
                <span class="d-block">{{ option.address.addressGroup.groupName }}</span>
              }
              {{ option.address.getPostCodeAndStreet?.() }}
            </a>
          }
        }
      </div>
    </div>
  </div>
</div>
