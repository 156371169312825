import { Component, Input } from '@angular/core';
import { TariffOptionSap, TariffSelectionCardData } from '@mwe/models';
import { TariffSelectionCardLogic } from '../services/tariff-selection-card.logic';
import { TariffSelectionStoreService } from '../services/tariff-selection-store.service';

@Component({
  selector: 'mwe-tariff-selection-card-store',
  templateUrl: './tariff-selection-card-store.component.html',
})
export class TariffSelectionCardStoreComponent {
  @Input() hasActions: boolean;
  @Input() isReadonly: boolean;
  @Input() lockedGroupIds: string[] = [];
  // data comes from signal() do not manipulate it!!!! notify parent and parent should change it in TariffSelectionStoreService
  @Input() tariff: TariffSelectionCardData;

  get additionalOptionGroupName(): string {
    return this.store.additionalOptionGroupName();
  }

  constructor(
    private cardLogic: TariffSelectionCardLogic,
    private store: TariffSelectionStoreService,
  ) {}

  onTariffUpdated(data: TariffOptionSap) {
    this.cardLogic.handleTariffOptionChange(this.tariff, data);
  }

  onReloadAfterError() {
    // retry with old state
    this.cardLogic.handleTariffOptionChange(this.tariff, this.tariff.userSelection[0]);
  }
}
