import { Component, Input } from '@angular/core';

@Component({
  selector: 'mwe-green-check-list',
  templateUrl: './green-check-list.component.html',
})
export class GreenCheckListComponent {
  @Input() labels: string[];
  @Input() translateParams: any;
  @Input() displayMode: 'list' | 'grid' = 'list';
}
