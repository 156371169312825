import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTPError, IMoveOutConfirmationStatusResponse, MoveOutDataDTO, parseMoveOutDataItemDTO } from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MoveOutService {
  private resourceUrl: string;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/moveout/';
  }

  async confirm(addressCode: string, dataDTO: MoveOutDataDTO): Promise<IMoveOutConfirmationStatusResponse> {
    try {
      return await lastValueFrom(this.http.post<IMoveOutConfirmationStatusResponse>(this.getResourceUrl('confirm'), dataDTO));
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async retry(submitId: number, dataDTO: MoveOutDataDTO): Promise<IMoveOutConfirmationStatusResponse> {
    try {
      return await lastValueFrom(
        this.http.put<IMoveOutConfirmationStatusResponse>(this.getResourceUrl('retry') + '?submitId=' + submitId, dataDTO),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  confirmStatus(submitId: number): Promise<IMoveOutConfirmationStatusResponse> {
    return lastValueFrom(this.http.get<IMoveOutConfirmationStatusResponse>(this.getResourceUrl('confirmStatus') + '?submitId=' + submitId));
  }

  getStatusDetails(addressGroupId: string): Promise<MoveOutDataDTO> {
    return lastValueFrom(this.http.get(this.getResourceUrl('moveOutStatusDetails') + '?addressGroupId=' + addressGroupId)).then(res => {
      return parseMoveOutDataItemDTO(res);
    });
  }

  private getResourceUrl(path: string): string {
    return this.resourceUrl + path;
  }
}
