import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[mweDefaultImage]'
})
export class DefaultImageDirective {
  @Input() mweDefaultImage: string;
  isFirstError = true;

  constructor(private elementRef: ElementRef) {
  }


  @HostListener('error')
  onError(): void {
    if (!this.isFirstError) {
      // only handle first error to avoid error-handling-loop
      return;
    }

    this.isFirstError = false;
    this.elementRef.nativeElement.src = this.mweDefaultImage;
  }
}
