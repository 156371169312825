import { Component, OnInit } from '@angular/core';
import { PUSH_PDF_POPUP_CLOSED, PUSH_PDF_POPUP_PERIOD_AFTER, ServiceStateEnum } from '@mwe/constants';
import { IMultiProductCard, IPdfBillActivationConfiguration } from '@mwe/models';
import { StaticContentService, UserJournalService } from '@mwe/services';
import { createMultiProductCardsFromProducts } from '@mwe/utils';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceStateEnumExporter } from '@mwe/ui/shared';
import { InvoiceDataChangeStateService } from '@mwe/services';

@Component({
  selector: 'mwe-push-pdf',
  templateUrl: './push-pdf.component.html',
})
export class PushPdfComponent extends ServiceStateEnumExporter implements OnInit {
  multiProductCards: IMultiProductCard[] = [];
  state: ServiceStateEnum = ServiceStateEnum.INIT;
  invoiceEmail = 'none';
  staticContent: IPdfBillActivationConfiguration;

  constructor(
    private invoiceDataChangeStateService: InvoiceDataChangeStateService,
    private activeModal: NgbActiveModal,
    private userJournalService: UserJournalService,
    private staticContentService: StaticContentService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.staticContent = await this.staticContentService.loadPdfBillActivationConfiguration();
    this.initMultiProductCards();
    this.loadInvoiceEmail();
  }

  private initMultiProductCards(): void {
    this.multiProductCards = createMultiProductCardsFromProducts(this.invoiceDataChangeStateService.products);
    this.multiProductCards.forEach(mpc => (mpc.isChecked = true));
  }

  private loadInvoiceEmail(): void {
    this.invoiceEmail = this.invoiceDataChangeStateService.eRechnungsInfo?.rechnungEmail || 'missing email';
  }

  closePopup(): void {
    if (this.state !== ServiceStateEnum.SUCCESS) {
      this.userJournalService.updateEvent(PUSH_PDF_POPUP_CLOSED, PUSH_PDF_POPUP_PERIOD_AFTER);
    }
    this.invoiceDataChangeStateService.clear();
    this.activeModal.close('OK');
  }

  onChangeToSepaClicked(): void {
    // just close popup, everything else is handled by PushPdfInfoOnConfirmationComponent
    this.activeModal.close('OK');
  }

  confirmPushPdf(): void {
    this.state = ServiceStateEnum.LOADING;
  }

  onStatusSucess(success: boolean) {
    if (success) {
      this.state = ServiceStateEnum.SUCCESS;
    }
  }

  isStateLoading(): boolean {
    return this.state === ServiceStateEnum.LOADING;
  }

  isStateInit(): boolean {
    return this.state === ServiceStateEnum.INIT;
  }

  isStateSuccess(): boolean {
    return this.state === ServiceStateEnum.SUCCESS;
  }

  get campaignReasons(): string[] {
    return this.staticContent?.campaign
      ? Object.keys(this.staticContent.campaign)
          .filter(prop => /^reason_\d+/.test(prop))
          .map(prop => this.staticContent.campaign[prop])
      : [];
  }
}
