import { Injectable } from '@angular/core';
import { CATEGORY_SORT_SEQUENCE, isProductCategoryFernwaerme } from '@mwe/utils';
import { ProductCategoryEnum } from '@mwe/constants';
import { IConsumptionHistory, Product } from '@mwe/models';
import { ConsumptionDataService } from '../../consumption/consumption-data.service';

@Injectable({
  providedIn: 'root',
})
export class ProductConsumptionLogic {
  refProduct: Product;
  isFernwaermeProduct: boolean;

  constructor(private consumptionDataService: ConsumptionDataService) {}

  async loadProductsConsumption(products: Product[]): Promise<IConsumptionHistory> {
    const consumption = { verbrauchshistorie: [] };
    const accounts = [];

    for (const p of products) {
      this.isFernwaermeProduct = isProductCategoryFernwaerme(p.category);
      const account = { accountNumber: p.accountNumber, systemId: p.systemId };
      accounts.push(JSON.stringify(account));
    }

    const uniqueAccounts = new Set(accounts);

    for (const account of uniqueAccounts) {
      const parsedAccount = JSON.parse(account);
      try {
        const history = await this.consumptionDataService.getConsumptionHistory(parsedAccount.accountNumber, parsedAccount.systemId);

        if (history?.verbrauchshistorie?.length) {
          history.verbrauchshistorie.forEach(i => (i.accountNumber = parsedAccount.accountNumber));
          consumption.verbrauchshistorie = consumption.verbrauchshistorie.concat(history.verbrauchshistorie);
        }
      } catch (error) {
        consumption.verbrauchshistorie = consumption.verbrauchshistorie.concat(
          products
            .filter(product => product.systemId === parsedAccount.systemId)
            .map(product => ({ sparte: product.category, accountNumber: product.accountNumber, verbrauch: null }))
        );
      }
    }

    return consumption;
  }

  async loadMonthlyConsumption(products: Product[]): Promise<IConsumptionHistory> {
    const accounts = [];
    const monthlyConsumption = { verbrauchshistorie: [] };

    for (const p of products) {
      if (isProductCategoryFernwaerme(p.category)) {
        const account = { accountNumber: p.accountNumber, systemId: p.systemId };
        accounts.push(JSON.stringify(account));
      }
    }

    const uniqueAccounts = new Set(accounts);

    for (const account of uniqueAccounts) {
      const parsedAccount = JSON.parse(account);
      if (this.isFernwaermeProduct) {
        try {
          const historyMonthly = await this.consumptionDataService.getConsumptionHistoryMonthly(
            parsedAccount.accountNumber,
            parsedAccount.systemId
          );

          if (historyMonthly?.verbrauchshistorie?.length) {
            historyMonthly.verbrauchshistorie.forEach(i => (i.accountNumber = parsedAccount.accountNumber));
            monthlyConsumption.verbrauchshistorie = monthlyConsumption.verbrauchshistorie.concat(historyMonthly.verbrauchshistorie);
          }
        } catch (error) {
          monthlyConsumption.verbrauchshistorie = monthlyConsumption.verbrauchshistorie.concat(
            products
              .filter(product => product.systemId === parsedAccount.systemId)
              .map(product => ({ sparte: product.category, accountNumber: product.accountNumber, verbrauch: null }))
          );
        }
      }
    }
    return monthlyConsumption;
  }

  sortProducts(products: Product[]): Product[] {
    return products.sort((a, b) => (this.getSequence(a) > this.getSequence(b) ? 1 : -1));
  }

  getSequence(product: Product): number {
    return this.isSameProduct(product, this.refProduct)
      ? 0
      : (CATEGORY_SORT_SEQUENCE[ProductCategoryEnum[product.category.toUpperCase()]] || 1000) + this.getFwwSubSequence(product);
  }

  getFwwSubSequence(product: Product): number {
    if (isProductCategoryFernwaerme(product.category)) {
      if (product.tariffClasses?.length > 0) {
        switch (product.tariffClasses.toString().toUpperCase()) {
          case 'HEIZUNG':
            return 0.1;
          case 'HEIZUNG,WARMWASSER':
          case 'WARMWASSER,HEIZUNG':
            return 0.2;
          case 'WARMWASSER':
            return 0.3;
          case 'KALT_ABWASSER':
            return 0.4;
          default:
            return 0.9;
        }
      }
    }
    return 0;
  }

  isSameProduct(product1: Product, product2: Product): boolean {
    if (!product2) return false;
    if (
      product1.customerNumber === product2.customerNumber &&
      product1.accountNumber === product2.accountNumber &&
      product1.businessPartnerNumber === product2.businessPartnerNumber &&
      product1.category === product2.category
    ) {
      return true;
    }
    return false;
  }
}
