<div class="d-flex flex-column align-items-center justify-content-center" style="height: 30rem; max-height: 50vh">
  <dotlottie-player
    class="animation"
    autoplay
    loop
    playMode="normal"
    src="/assets/loading-animation/MorphSpinnerBig.lottie"
    style="width: 200px"
  >
  </dotlottie-player>
  <p *ngIf="loadingSpinnerText" [translate]="loadingSpinnerText"></p>
</div>
