import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { isProductCategory, isSepa, isZahlschein, RetryOnFailure } from '@mwe/utils';
import {
  getSparte,
  HTTPError,
  HTTPResponse,
  IAktionen,
  IOlavEntry,
  IOrderDataDTO,
  IOrderDetailsInfo,
  IProduktAuswahl,
  IProduktAuswahlDetails,
  IProduktAuswahlParams,
  IProduktSelektion,
  IProduktVerfuegbarkeit,
  ISolrOlavKeyAddress,
  IVerfuegbarkeiten,
  obfuscateIBAN,
  parseAddress,
  parseBusinessData,
  parseOlavKeyAddress,
  parseOrderDataDTO,
  parsePersonalData,
  parseProductsForSelection,
  parseTitelListe,
  PreisIndikationen,
  PreisIndikationenApiResponse,
  Product,
  Sparte,
  TarifOption,
  TitelListe,
} from '@mwe/models';
import { map } from 'rxjs/operators';
import { ProductCategoryEnum, productCategoryEnumToName, ProductPriceIndicationCategory } from '@mwe/constants';
import { TariffSelectionStateService } from '../tariff/selection/tariff-selection-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductsWhiteListLogic } from '../products/products-white-list.logic';
import { NewClientStateService } from '../new-client/new-client-state.service';
import { AccountLogic } from '../account/account.logic';
import { AppStorageService } from '../cache/app-storage.service';
import { EnvironmentService } from '../environment/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public resourceUrl: string;

  constructor(
    private http: HttpClient,
    private newClientStateService: NewClientStateService,
    private accountLogic: AccountLogic,
    private appStorageService: AppStorageService,
    private productsWhiteListLogic: ProductsWhiteListLogic,
    private environmentService: EnvironmentService,
    private tariffSelectionStateService: TariffSelectionStateService,
    private translateService: TranslateService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/newclient/';
  }

  getAvailability(selectedOlav: IOlavEntry): Promise<IProduktVerfuegbarkeit[]> {
    const url = this.getResourceUrl() + 'availability';
    const splitGebadr1 = selectedOlav.gebadr1.split('/');
    const params = new HttpParams()
      .set('plz', selectedOlav.plz)
      .set('ort', selectedOlav.gemeinde)
      .set('strasse', selectedOlav.strasse)
      .set('hausnummer', splitGebadr1[0])
      .set('stiege', splitGebadr1.length === 2 ? splitGebadr1[1] : selectedOlav.gebadr1)
      .set('tuernummer', selectedOlav.tuerNr)
      .set('olavGaa', selectedOlav.gebaeudeKey)
      .set('olavGba', selectedOlav.gebaeudeKeyIdent)
      .set('olavAaa', selectedOlav.anlageAdressKey)
      .set('olavAna', selectedOlav.anlageKey);

    return lastValueFrom(
      this.http.get<IVerfuegbarkeiten>(url, { params }).pipe(
        map(verfuegbarkeiten => {
          return verfuegbarkeiten.verfuegbarkeiten.filter(verfuegbarkeit => {
            return this.productsWhiteListLogic.isInWhiteList(verfuegbarkeit.sparte);
          });
        }),
      ),
    );
  }

  getInternetAvailability(gaa: string, tkConnId: string): Promise<IProduktVerfuegbarkeit[]> {
    const url = this.getResourceUrl() + 'availability';
    const params = new HttpParams().set('olavGaa', gaa).set('tkconnid', tkConnId).set('internetRequest', '');

    return lastValueFrom(
      this.http.get<IVerfuegbarkeiten>(url, { params }).pipe(
        map(verfuegbarkeiten => {
          return verfuegbarkeiten.verfuegbarkeiten.filter(verfuegbarkeit => {
            return this.productsWhiteListLogic.isInWhiteList(verfuegbarkeit.sparte);
          });
        }),
      ),
    );
  }

  async getProductsForSelection(params: IProduktAuswahlParams): Promise<HTTPResponse<IProduktAuswahl>> {
    const _params = new HttpParams()
      .set('sparte', params.sparte)
      .set('tarifKey', params.tarifKey)
      .set('tarifTyp', params.tarifTyp)
      .set('kanalCode', params.kanalCode)
      .set('kundenTyp', params.kundenTyp)
      .set('prozessCode', params.prozessCode);

    try {
      const res = await lastValueFrom(
        this.http.get(this.getResourceUrl() + 'getProductsForSelection', { observe: 'response', params: _params }),
      );
      return new HTTPResponse<IProduktAuswahl>(res.status + '', 'IL', parseProductsForSelection(res.body));
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async getTitles(): Promise<HTTPResponse<TitelListe>> {
    if (this.appStorageService.getOrderTitles() !== null) {
      return new HTTPResponse<TitelListe>(200 + '', 'ES', parseTitelListe(this.appStorageService.getOrderTitles()));
    } else {
      try {
        // TODO Typisieren
        const res = await lastValueFrom(this.http.get(this.getResourceUrl() + 'titles', { observe: 'response' }));
        this.appStorageService.setOrderTitles(res.body);
        return new HTTPResponse<TitelListe>(res.status + '', 'IL', parseTitelListe(res.body));
      } catch (err) {
        throw new HTTPError(err.status, err.error);
      }
    }
  }

  hasFernwaermeInNewAddressProducts(orderDetails: IOrderDetailsInfo[]): boolean {
    return this.getNewAddressProductCategories(orderDetails).some(elem =>
      isProductCategory(ProductCategoryEnum[elem], ProductCategoryEnum.FERNWAERME),
    );
  }

  getNewAddressProductCategories(orderDetails: IOrderDetailsInfo[]): ProductCategoryEnum[] {
    return orderDetails.filter(elem => elem.status === 'Anmeldung').map(elem => ProductCategoryEnum[elem.category.toUpperCase()]);
  }

  parseBezahlart(orderDetailData: IOrderDetailsInfo): void {
    if (isSepa(orderDetailData.produktDetails.vertragskonto)) {
      this.setBezahlartSepa(orderDetailData);
    } else if (isZahlschein(orderDetailData.produktDetails.vertragskonto)) {
      this.setBezahlartZahlschein(orderDetailData);
    } else {
      throw new Error('unknown bezahlart');
    }
  }

  setBezahlartZahlschein(orderDetailData: IOrderDetailsInfo): void {
    orderDetailData.bezahlartIcon = 'zahlschein';
    orderDetailData.bezahlartText = 'Zahlschein';
  }

  setBezahlartSepa(orderDetailData: IOrderDetailsInfo): void {
    if (orderDetailData.produktDetails.vertragskonto.iban) {
      orderDetailData.bezahlartIcon = 'sepa';
      orderDetailData.bezahlartText = obfuscateIBAN(orderDetailData.produktDetails.vertragskonto.iban);
    } else {
      // we expect that only FW products sometime comne without an IBAN definition
      this.setBezahlartZahlschein(orderDetailData);
    }
  }

  setLastOrderId(lastOrderId: string): void {
    this.newClientStateService.lastOrderId = lastOrderId;
  }

  setNewClientType(newClientType: 'kunde-werden' | 'superschnell-bestellen'): void {
    this.newClientStateService.newClientType = newClientType;
  }

  getResourceUrl(path?: string): string {
    let baseUrl = this.resourceUrl;
    if (this.accountLogic.isLoggedOut()) {
      baseUrl = this.resourceUrl.replace('api', 'api/public');
    }
    return baseUrl + (path ? path : '');
  }

  getOrderStatusDetails(): Promise<IOrderDataDTO> {
    return lastValueFrom(this.http.get(this.resourceUrl + 'orderStatusDetails/' + this.newClientStateService.lastOrderId)).then(res => {
      const tempArray = [];
      tempArray.push(res);
      const orderData = parseOrderDataDTO(tempArray)[0];
      this.newClientStateService.newClientAddressOlav = orderData.selectedOlav;
      this.newClientStateService.newClientAddress = parseAddress(orderData.newAddress);
      this.newClientStateService.registrationDate = orderData.registrationDate;
      this.newClientStateService.orderDetails = orderData.orderDetailsInfo;
      this.newClientStateService.idCard = orderData.idcard;
      this.newClientStateService.personalData = parsePersonalData(orderData.orderDetailsInfo[0]);
      this.newClientStateService.pdfLinks = orderData.pdfLinks;
      this.newClientStateService.contractDetails = orderData.contractDetails;
      this.newClientStateService.invoiceData = orderData.orderDetailsInfo[0].produktDetails.vertragskonto;
      this.newClientStateService.eRechnungsInfo = orderData.orderDetailsInfo[0].eRechnungInfo;
      this.newClientStateService.lastOrderDate = orderData.orderedAt;
      this.newClientStateService.voucherInfo = orderData.voucherInfo ?? this.populateVoucherInfo(orderData.orderDetailsInfo);
      this.tariffSelectionStateService.indications = orderData.priceIndications || [];
      this.newClientStateService.productSelection = this.createProductSelection(orderData.orderDetailsInfo);
      this.newClientStateService.personalBusinessData = parseBusinessData(JSON.parse(tempArray[0].changeData));
      return orderData;
    });
  }

  populateVoucherInfo(orderDetailsInfo: IOrderDetailsInfo[]): IAktionen {
    let voucher: IAktionen = null;
    for (let i = 0; i < orderDetailsInfo.length; i++) {
      if (orderDetailsInfo[i].aktionen?.length > 0) {
        voucher = orderDetailsInfo[i].aktionen[0];
        break;
      }
    }
    return voucher;
  }

  createProductSelection(orderDetailsInfoArr: IOrderDetailsInfo[]): IProduktSelektion {
    const productSelection: IProduktSelektion = {};
    orderDetailsInfoArr.forEach(orderDetail => {
      const tarifOptionen = orderDetail.tarifOptionen;
      productSelection[orderDetail.category.toLowerCase()] = { tarif: { tarifOptionen } } as IProduktAuswahlDetails;
    });
    return productSelection;
  }

  setFernwaermeTariffClasses(fernwaermeAvailability: IProduktVerfuegbarkeit, products: Product[]): void {
    if (fernwaermeAvailability && fernwaermeAvailability.verfuegbar === 'JA' && fernwaermeAvailability.details) {
      const availabilityDetailsFernwaerme = fernwaermeAvailability.details.map(detail => detail.lieferkomponente);
      products
        .filter(product => isProductCategory(product.category, ProductCategoryEnum.FERNWAERME))
        .forEach(product => (product.tariffClasses = availabilityDetailsFernwaerme));
    }
  }

  async getAddressFromOlavKey(olavKey: string, processType?: string): Promise<ISolrOlavKeyAddress> {
    const _params = new HttpParams().set('olavKey', olavKey).set('process', processType === 'superschnell-bestellen' ? 'OI' : '');
    const res = await lastValueFrom(
      this.http.get(this.environmentService.getApiUrl() + 'api/solr/olav/searchOlavKey', { observe: 'response', params: _params }),
    );
    return parseOlavKeyAddress(res.body);
  }

  /*
    you can load preisindikationen from IL via kundewerden, tarifwechseln and verfuegbarkeiten
    only IL/kundewerden is able to load this data for anonymous user, so we can use only this endpoint
   */
  @RetryOnFailure({
    maxRetryCount: 1,
    delay: 100,
    retryOnError: true,
    validator: (returnValue: PreisIndikationen[]) => Array.isArray(returnValue),
  })
  loadPriceIndications(
    plz: string,
    category: string,
    tariffKey?: string,
    options?: string,
    voucherCode?: string,
  ): Promise<PreisIndikationen[]> {
    // category Fernwaemre is not supported by the endpoint
    if (category === Sparte.Fernwaerme) {
      return undefined;
    }

    // special hack, T2 only knows tanke
    if (category === Sparte.Emobility) {
      category = ProductPriceIndicationCategory.TANKE;
    }

    const url = this.getResourceUrl() + 'preisindikationen';
    let params = new HttpParams().set('plz', plz).set('sparte', category?.toLowerCase());

    if (tariffKey) {
      params = params.set('tarifTypen', tariffKey);
    }

    if (options) {
      params = params.set('optionen', options);
    }

    if (voucherCode) {
      params = params.set('voucherCode', voucherCode);
    }

    return lastValueFrom(
      this.http.get<PreisIndikationenApiResponse>(url, { params }).pipe(
        map((response: PreisIndikationenApiResponse) => {
          // special hack, T2 only knows tanke
          if (response.preisindikationen) {
            response.preisindikationen.forEach(p => {
              if (p.sparte === ProductPriceIndicationCategory.TANKE) {
                p.sparte = Sparte.Emobility;
              }
            });
            return this.mapPriceIndicationsForInternetProducts(response.preisindikationen);
          }
          return response?.preisindikationen;
        }),
      ),
    );
  }

  mapPriceIndicationsForInternetProducts(priceIndications: PreisIndikationen[]): PreisIndikationen[] {
    const internetPriceIndikations = [];
    const separatedTraiffOptionKey = [];
    priceIndications.forEach(priceIndication => {
      if (priceIndication.sparte.toLowerCase() === ProductPriceIndicationCategory.SUPERSCHNELL.toLowerCase()) {
        priceIndication.sparte = getSparte(productCategoryEnumToName(ProductCategoryEnum.INTERNET));
        // loop over all options to create  price indications for IPTV and/or VOIP
        if (priceIndication.tarif.tarifOptionen) {
          // loop over all internet tariff options
          // the key is either an iptv ot voip tariff
          // therefore we create an seaparated price indication
          // for this tariff
          priceIndication.tarif.tarifOptionen.forEach(to => {
            if (!separatedTraiffOptionKey.includes(to.option)) {
              const seaparatedIndication = this.createIPTVorVOIPIndication(to);
              if (seaparatedIndication) {
                internetPriceIndikations.push(seaparatedIndication);
                separatedTraiffOptionKey.push(seaparatedIndication.tarif.ISUTarifKey);
              }
            }
          });
        }
      }
    });
    priceIndications.forEach(priceIndication => {
      // remove separated indications
      if (priceIndication.tarif.tarifOptionen) {
        priceIndication.tarif.tarifOptionen = priceIndication.tarif.tarifOptionen.filter(
          to => !separatedTraiffOptionKey.includes(to.option),
        );
      }
    });
    return priceIndications.concat(internetPriceIndikations);
  }

  createIPTVorVOIPIndication(to: TarifOption): PreisIndikationen {
    const ipTvTariff = ['WEBIPTVLIGHT', 'WEBIPTVPREMIUM'];
    const voipTariff = ['WEBTELEU500', 'WEBTEL\u00d6500'];
    let category;
    if (ipTvTariff.includes(to.option)) {
      category = productCategoryEnumToName(ProductCategoryEnum.IPTV);
    } else if (voipTariff.includes(to.option)) {
      category = productCategoryEnumToName(ProductCategoryEnum.VOIP);
    }
    if (category) {
      const apAttributes = to.preise.find(p => p.deltaBasis === 'arbeitspreis');
      const gpAttributes = to.preise.find(p => p.deltaBasis === 'grundpreis');
      const ipAttributes = to.preise.find(p => p.deltaBasis === 'anschlusspreis');
      return this.populateAddtionalProperties({
        sparte: getSparte(category),
        arbeitspreis: apAttributes?.delta,
        arbeitspreisUnit: apAttributes?.deltaUnit,
        grundpreis: gpAttributes?.delta,
        grundpreisUnit: gpAttributes?.deltaUnit,
        anschlusspreis: ipAttributes?.delta,
        anschlusspreisUnit: ipAttributes?.deltaUnit,
        tarif: {
          ISUTarifKey: to.option,
          tarifKey: to.cmsOptionId,
          tarifName: to.optionLabel,
          tarifOptionen: this.populateTariffOptions(to.option, to.tarifOptionen),
        },
        boni: [],
        preisbestandteile: null, // TODO provide mapping
      });
    }
    return null;
  }

  private populateAddtionalProperties(indication: PreisIndikationen): PreisIndikationen {
    return indication;
  }

  private populateTariffOptions(tariffKey: string, tariffOptions: TarifOption[]): TarifOption[] {
    const resultingTariffOptions = [];
    tariffOptions?.forEach(to => {
      const gpAttributes = to.preise.find(p => p.deltaBasis === 'grundpreis');
      const ipAttributes = to.preise.find(p => p.deltaBasis === 'anschlusspreis');
      to.deltaBasis = gpAttributes?.deltaBasis;
      to.deltaUnit = gpAttributes?.deltaUnit;
      if (ipAttributes && Number(ipAttributes.delta.replace(',', '.')) > 0) {
        to.installationLabel = this.translateService.instant('tariff.details.installationPriceIptv');
        to.installationPrice = ipAttributes.delta;
        to.installationBasis = ipAttributes.deltaBasis;
        to.installationUnit = ipAttributes.deltaUnit;
      }
      to.delta = gpAttributes?.delta;
      resultingTariffOptions.push({ ...to });
    });

    return resultingTariffOptions;
  }
}
