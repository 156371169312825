import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../environment/environment.service';
import { OSSRelocationData, RelocationOssResponse } from '@mwe/models';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OSSService {
  public resourceUrl: string;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/oss/';
  }

  async submitRelocation(changeAddressData: OSSRelocationData): Promise<RelocationOssResponse> {
    return lastValueFrom(this.http.post<RelocationOssResponse>(this.resourceUrl + 'addresschange', changeAddressData));
  }
}
