import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppStorageService } from '@mwe/services';

@Component({
  selector: 'mwe-redirect',
  template: ``,
})
export class RedirectComponent implements OnInit {
  constructor(private router: Router, private appStorage: AppStorageService) {}

  ngOnInit(): void {
    const rurl = this.appStorage.getEntryUrl() ? this.appStorage.getEntryUrl() : '/';
    this.router.navigate([rurl]);
  }
}
