import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ADD_PRODUCTS_ROUTE, ADD_PRODUCTS_SEARCH_ROUTE } from '@mwe/constants';
import {
  AlertType,
  GueltigkeitsDetails,
  HTTPError,
  IAktionen,
  IFormModel,
  InfoText,
  IProduktAuswahlDetails,
  IProduktSelektion,
  TarifOption,
  VoucherBodyModel,
  VoucherProzessType,
  ZusatzInformationen,
} from '@mwe/models';
import { IVoucherFormConfig, LoggingService, VoucherService, VoucherStateService } from '@mwe/services';
import {
  anyTrue,
  containsStromOrGasCategory,
  everyTrue,
  isArrayWithMinOneItem,
  isNumberIgnoringSpace,
  sortProductsEnumCategory,
} from '@mwe/utils';
import { TranslateService } from '@ngx-translate/core';
import { FormComponent } from '@mwe/ui/components';
import { WlVoucherFormComponent } from '../wl-voucher-form/wl-voucher-form.component';
import { HeadingNodeType } from '../../../../../../models/src/lib/typography/typography.model';

type VoucherCodeCheck = 'invalid' | 'valid' | 'error' | 'warning' | '';

@Component({
  selector: 'mwe-voucher-form',
  templateUrl: './voucher-form.component.html',
})
export class VoucherFormComponent implements OnInit, AfterViewInit {
  @ViewChild(WlVoucherFormComponent, { static: false }) wlFormRef: WlVoucherFormComponent;
  @ViewChild('codeForm', { static: false }) formRef: FormComponent;
  @Input() config: IVoucherFormConfig;
  @Input() predefinedCode: string;
  @Input() headingNodeType: HeadingNodeType = 'h2';
  @Output() readonly codeChanged = new EventEmitter<IAktionen>();
  @Output() readonly userHasChangedCode = new EventEmitter<void>();
  prozesstyp: VoucherProzessType = 'Kunde werden';
  codeCheckDisabled = true;
  state = 'check';
  code = '';
  codeControlName = 'mwe-voucher-code';
  maxNumberForValidityChecks: number;
  aktionsCodeArt: string;
  nennwert: string;
  codeCheck: VoucherCodeCheck;
  errorAlertStyle: AlertType = 'danger';
  cardNumber: string;
  errorTitleKey: string;
  errorMessageKey: string;
  showWLFields = false;
  aktionsArt: string;
  wlFormHasNoErrors: boolean;
  voucherStateService: VoucherStateService;
  errorTitle: Map<string, string> = new Map();
  errorDescription: Map<string, string> = new Map();
  errorType: Map<string, VoucherCodeCheck> = new Map();
  hasAlreadyStromGasProducts = false;
  tariffNotValidInfoText: InfoText;
  isTariffNotValidError: boolean;
  voucherFormModel: IFormModel;

  get hasFaq(): boolean {
    return isArrayWithMinOneItem(this.config.faq);
  }

  // msgPlaceholders has strange keys - category + 'Msg'
  // unfortunately we can not use these keys so I had to implement these hack...
  // can't remove 'Msg'', because it is already in database
  uiSuccessInfos: string[];
  private msgPlaceholders: Map<string, string> = new Map();

  constructor(
    private voucherService: VoucherService,
    private loggingService: LoggingService,
    private translateService: TranslateService,
    private router: Router,
  ) {}

  get loading(): boolean {
    return this.state === 'loading';
  }

  ngOnInit(): void {
    if (this.config.processCode) {
      this.prozesstyp = this.config.processCode;
    }
    this.voucherStateService = this.config.voucherStateService;
    this.setDefaultLabels();
    this.setErrorCodes();
    if (this.voucherStateService.getProductSelection()?.commonMapping?.find(cm => containsStromOrGasCategory(cm.categories))) {
      this.hasAlreadyStromGasProducts = true;
    }

    this.handleInitialValue();
    this.initializeVoucherFormModel();
  }

  onFormChange(formData: unknown): void {
    this.code = formData[this.codeControlName];
    this.codeCheckDisabled = typeof this.code === 'string' ? this.code.length <= 0 : true;
    this.showWLFields = false;
    if (this.formRef) {
      this.formRef.form.markAsUntouched();
    }
    this.setStateCheckBlank();
    this.codeChanged.emit(null);
  }

  initializeVoucherFormModel(): void {
    const initialValue = this.predefinedCode || this.code;
    this.voucherFormModel = {
      inputs: [
        {
          name: this.codeControlName,
          initialValue,
          validate: true,
          validators: this.config.isCodeMandatory ? [Validators.required] : [],
          validateSuccess: true,
          labelKey: this.config.codeLabelKey,
          validationErrorLabelKey: this.config.codeErrorKey,
          updateOn: 'change',
          dataType: 'text',
          loadingSpinnerOptions: {
            suppressValidityCheckOnSubmit: true,
            asyncLoadingFunc: async (value: string) => {
              if (this.state === 'delete') {
                this.reset();
                this.userHasChangedCode.emit();
                return;
              }
              this.code = value;
              await this.checkCode(true);

              // wl voucher has own logic
              if (!this.showWLFields) {
                this.userHasChangedCode.emit();
              }
            },
            submitLabelKey: () => {
              return this.state === 'delete' ? this.config.deleteCodeButtonKey : this.config.checkCodeButtonKey;
            },
            disabledWhileInvalid: true,
            disabledWhileEmpty: true,
            disabled: () => {
              return this.codeCheckDisabled;
            },
            enterInInputTriggersSpinnerButton: true,
          },
        },
      ],
    };
  }

  handleInitialValue() {
    this.code = this.config.initialValue?.aktionscode;
    if (this.code) {
      this.setStateDeleteCheckValid();
      const voucherMsg: Map<string, string> = new Map();
      for (let i = 0; i < this.config.initialValue.infoTextKey.length; i++) {
        const _msg = this.config.initialValue.infoText[i];
        this.msgPlaceholders.set(this.config.initialValue.infoTextKey[i], _msg);
        voucherMsg.set(this.config.initialValue.infoTextKey[i].replace('Msg', ''), _msg);
      }
      this.updateVoucherMsg(voucherMsg, false);
    } else {
      this.setStateCheckBlank();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.predefinedCode) {
      this.onInput(this.predefinedCode);
      this.formRef.fireValidation();
      await this.checkCode();
      await this.getVoucherDetails();
    }
  }

  wlFormValidityChanged(isValid: boolean): void {
    this.wlFormHasNoErrors = isValid;
  }

  async wlFormCheckPressed(isValid: boolean): Promise<void> {
    this.wlFormHasNoErrors = isValid;
    this.state = 'loading';
    await this.submit();
    this.state = 'delete';

    if (isValid) {
      this.userHasChangedCode.emit();
    }
  }

  private setDefaultLabels(): void {
    if (!this.config.titleKey) {
      this.config.titleKey = 'voucher.title';
    }
    if (!this.config.codeLabelKey) {
      this.config.codeLabelKey = 'voucher.code.label';
    }
    if (!this.config.codeErrorKey) {
      this.config.codeErrorKey = 'voucher.code.error';
    }
    if (!this.config.checkCodeButtonKey) {
      this.config.checkCodeButtonKey = 'voucher.checkCode';
    }
    if (!this.config.deleteCodeButtonKey) {
      this.config.deleteCodeButtonKey = 'voucher.deleteCode';
    }
    if (!this.config.invalidCodeMsgKey) {
      this.config.invalidCodeMsgKey = 'voucher.invalidCode';
    }
    if (!this.config.invalidCodeWLMsgKey) {
      this.config.invalidCodeWLMsgKey = 'voucher.invalidCodeWL';
    }
  }

  private setErrorCodes(): void {
    this.errorTitle.set('3008', 'voucher.validity.alreadyInUse.errorTitle');
    this.errorDescription.set('3008', this.getErrorLabelForProcess('voucher.validity.alreadyInUse.errorText'));
    this.errorTitle.set('3009', 'voucher.validity.noLongerValid.errorTitle');
    this.errorDescription.set('3009', this.getErrorLabelForProcess('voucher.validity.noLongerValid.errorText'));
    this.errorTitle.set('3010', 'voucher.validity.processCodeNotValid.errorTitle');
    this.errorDescription.set('3010', this.getErrorLabelForProcess('voucher.validity.processCodeNotValid.errorText'));
    this.errorTitle.set('3011', 'voucher.validity.tariffNotValid.errorTitle');
    this.errorDescription.set('3011', this.getErrorLabelForProcess('voucher.validity.tariffNotValid.errorText'));
    this.errorType.set('3011', this.isVoucherRedeemProcess() ? 'warning' : 'error');
    this.errorType.set('3012', this.isVoucherRedeemProcess() ? 'warning' : 'error');
    this.errorTitle.set('3017', 'voucher.validity.wlDataInvalid_3017.errorTitle');
    this.errorDescription.set('3017', 'voucher.validity.wlDataInvalid_3017.errorText');
    this.errorTitle.set('3018', 'voucher.validity.noLongerValid.errorTitle');
    this.errorDescription.set('3018', this.getErrorLabelForProcess('voucher.validity.noLongerValid.errorText'));
    this.errorTitle.set('3019', 'voucher.validity.wlDataInvalid_3019.errorTitle');
    this.errorDescription.set('3019', 'voucher.validity.wlDataInvalid_3019.errorText');
    this.errorTitle.set('3024', 'voucher.validity.wlCardAlreadyUsed.errorTitle');
    this.errorDescription.set('3024', 'voucher.validity.wlCardAlreadyUsed.errorText');

    if (!this.isVoucherRedeemProcess()) {
      this.errorTitle.set('3012', 'voucher.validity.tariffOptionsNotValid.errorTitle');
      this.errorDescription.set('3012', this.getErrorLabelForProcess('voucher.validity.tariffOptionsNotValid.errorText'));
    }

    this.errorTitle.set('3023', '');
    this.errorDescription.set('3023', this.config.invalidCodeMsgKey);
    this.errorTitle.set('3024', 'voucher.wlinien.validity.alreadyInUse.errorTitle');
    this.errorDescription.set('3024', 'voucher.wlinien.validity.alreadyInUse.errorText');

    this.tariffNotValidInfoText = {
      code: '',
      title: this.translateService.instant('voucher.validity.tariffNotValid.title'),
      description: this.translateService.instant('voucher.validity.tariffNotValid.description'),
      link: null,
    };
  }

  private getErrorLabelForProcess(label: string): string {
    const extended = this.isVoucherRedeemProcess() ? '' : 'Extended';
    return label + extended;
  }

  private isVoucherRedeemProcess(): boolean {
    return this.prozesstyp === 'Energiegutschein';
  }

  async isCodeValid(): Promise<boolean> {
    this.fireValidation();

    if (everyTrue(this.code?.length > 0, this.codeCheck !== 'valid')) {
      await this.checkCode();
      return false; // enforce to stay on page
    }
    const isCodeSet = this.code?.length > 0 ? this.codeCheck === 'valid' : !this.config.isCodeMandatory;
    if (everyTrue(this.config.isCodeMandatory, !isCodeSet)) {
      this.formRef.fireValidation();
    }
    return isCodeSet;
  }

  hasInvalidCode(): boolean {
    return this.codeCheck === 'invalid' || this.codeCheck === 'error' || this.codeCheck === 'warning';
  }

  handleOldVoucherFormat(): boolean {
    if (this.config.oldVoucherCodeWarningKey && isNumberIgnoringSpace(this.code)) {
      this.setStateOldVoucherCode();
      this.codeChanged.emit(null);
      return true;
    }
    return false;
  }

  async checkCode(checkOnlyForm?: boolean): Promise<void> {
    this.isTariffNotValidError = false;

    if (this.handleOldVoucherFormat()) {
      return;
    }

    if (!this.formRef.isValid) {
      return;
    }

    if (everyTrue(!checkOnlyForm, this.code?.length > 0)) {
      this.codeCheck = '';
      await this.updateSuccessMsg(true);
      if (this.hasInvalidCode()) {
        this.showWLFields = false;
        this.state = 'delete';
        return;
      }
    }

    const wlFormValidOrNotRequired = this.showWLFields ? this.wlFormHasNoErrors : true;

    if (this.code?.length > 0 && wlFormValidOrNotRequired) {
      await this.submit();
    }
  }

  async submit(): Promise<void> {
    this.state = 'loading';
    this.codeCheck = '';
    if (this.showWLFields) {
      await this.updateSuccessMsg(false);
    } else {
      await this.getVoucherDetails();
    }
  }

  private async getVoucherDetails(): Promise<void> {
    try {
      this.showWLFields = false;
      let sendPreCheck = false;
      if (!this.voucherStateService.hasAllProductsASelectedTariff()) {
        this.codeChanged.emit({
          aktionscode: this.code,
          aktionsCodeArt: this.aktionsCodeArt,
          prozesstyp: this.prozesstyp,
          infoText: null,
          infoTextKey: null,
          errorTitle: '',
          errorMsg: 'tariffChange.productsSelection.emptySelectionError',
        });
        this.setStateDeleteCheckBlank();
        return;
      }

      await this.voucherService
        .voucherDetails(this.code)
        .then(resp => {
          this.aktionsArt = null;
          if (!resp.aktion.aktiv) {
            this.setStateDeleteCheckInvalid();
          } else if (resp.aktion.aktionsArt === '1' || resp.aktion.aktionsArt === '2') {
            this.aktionsArt = resp.aktion.aktionsArt;
            sendPreCheck = true;
            // reset emitted code because we have to check validity for WL voucher
            this.codeChanged.emit(null);
          }

          if (this.showWLFields && this.config.wlFieldsDataUseCommit) {
            this.config.wlFieldsDataUseCommit.labelKey = 'voucher.wlinien.dataUseCommit.label_dob';
          }

          this.aktionsCodeArt = resp.aktionsCodeArt;
          this.nennwert = resp.nennwert;
          this.maxNumberForValidityChecks = resp.maxVerwendbar;
        })
        .catch(e => {
          this.setErrorState(e);
          this.loggingService.logError(e);
        });

      if (everyTrue(!sendPreCheck, this.codeCheck === '')) {
        await this.updateSuccessMsg(false);
      } else if (sendPreCheck) {
        await this.updateSuccessMsg(true);
        if (!this.hasInvalidCode()) {
          this.showWLFields = true;
          this.setStateDeleteCheckBlank();
        }
      }
    } catch (e) {
      this.setErrorState();
      this.loggingService.logError(e);
    }
  }

  reset(): void {
    this.resetForm();
    this.setStateCheckBlank();
  }

  updateFormValue(value: string): void {
    this.formRef.form.get(this.codeControlName).setValue(value);
  }

  async onInput(newValue: string): Promise<void> {
    this.showWLFields = false;
    this.formRef.form.markAsUntouched();
    this.code = newValue;
    this.setStateCheckBlank();
    this.codeChanged.emit(null);
    this.codeCheckDisabled = typeof this.code === 'string' ? this.code.length <= 0 : true;
  }

  onInputCardNumber(newValue: string): void {
    this.cardNumber = newValue;
  }

  async updateSuccessMsg(noAdditionalFields: boolean): Promise<void> {
    this.msgPlaceholders = new Map();
    const selectedProducts = this.voucherStateService.getProductSelection();
    const now = new Date().toISOString();
    let allInvalid = true;
    const voucherMsg: Map<string, string> = new Map();
    const validityCallError: boolean[] = [];
    let errorResponse;
    const validSelectionIds = [];
    const promises = [];
    if (selectedProducts) {
      try {
        const firstPid =
          this.voucherStateService.getProductSelectionIds().length > 0 ? this.voucherStateService.getProductSelectionIds()[0] : null;

        ({ allInvalid, errorResponse } = await this.firstValidityCheck(
          firstPid,
          selectedProducts,
          now,
          noAdditionalFields,
          allInvalid,
          voucherMsg,
          validityCallError,
          validSelectionIds,
          errorResponse,
        ));
        const httpError = errorResponse ? new HTTPError(errorResponse.status + '', errorResponse.error) : null;
        if (anyTrue(!allInvalid, httpError?.code === '3011', httpError?.code === '3012')) {
          this.voucherStateService.getProductSelectionIds().forEach(pid => {
            if (pid !== firstPid) {
              ({ allInvalid, errorResponse } = this.checkValidity(
                pid,
                selectedProducts,
                now,
                noAdditionalFields,
                allInvalid,
                voucherMsg,
                validityCallError,
                validSelectionIds,
                errorResponse,
                promises,
              ));
            }
          });
        }
      } catch (error) {
        this.loggingService.logError(error, 'error at updating success-message');
        errorResponse = error;
        this.setErrorState();
        return;
      }
    }

    const otherValidityCheckResponses = await Promise.allSettled([...promises]).catch(() => {
      // ignore we handle already each rejection
    });

    allInvalid = validSelectionIds.length === 0;
    this.updateVoucherMsg(voucherMsg, true);
    if (everyTrue(validityCallError.length > 0, ...validityCallError)) {
      const error = this.checkAllErrorsForWlPrioritisation(errorResponse, otherValidityCheckResponses);
      this.setErrorState(error);
      return;
    }
    if (noAdditionalFields) {
      if (allInvalid) {
        this.setStateNoProductsFound();
        this.codeChanged.emit(null);
      }
      return;
    }

    this.emitCode(allInvalid, validSelectionIds);
  }

  // if wl form is visible then show wl errors
  private checkAllErrorsForWlPrioritisation(firstError: HttpErrorResponse, otherValidityCheckResponses): HttpErrorResponse {
    if (!this.showWLFields || !isArrayWithMinOneItem(otherValidityCheckResponses)) {
      return firstError;
    }

    const errors = otherValidityCheckResponses
      .map(e => {
        return e?.reason;
      })
      .filter(e => !!e) as HttpErrorResponse[];

    errors.push(firstError);
    const wlError = errors.find(e => e.error.error.code === '3017');

    return wlError || firstError;
  }

  handleWarningLinkClicked(): void {
    this.router.navigateByUrl(`/${ADD_PRODUCTS_ROUTE}/${ADD_PRODUCTS_SEARCH_ROUTE}`);
  }

  fireValidation(): void {
    this.formRef?.fireValidation();
    this.wlFormRef?.fireValidation();
  }

  hasValidWLFormData(): boolean {
    return this.wlFormRef?.isFormValid && this.wlFormRef?.isApprovalFormValid && this.codeCheck === 'valid';
  }

  private async firstValidityCheck(
    firstPid: string,
    selectedProducts: IProduktSelektion,
    now: string,
    noAdditionalFields: boolean,
    allInvalid: boolean,
    voucherMsg: Map<string, string>,
    validityCallError: boolean[],
    validSelectionIds: any[],
    errorResponse: any,
  ): Promise<any> {
    if (firstPid) {
      const productDetails = this.voucherStateService.getProduktAuswahlDetails(firstPid, selectedProducts);
      if (productDetails) {
        const body = this.voucherService.createVoucherValidityBody(now, this.createVoucherBodyModel(productDetails, noAdditionalFields));
        await this.voucherService
          .voucherValidity(this.code, JSON.stringify(body))
          .then(res => {
            allInvalid = this.handleValidityResponse(res, allInvalid, voucherMsg, productDetails.sparte, noAdditionalFields);
            validityCallError.push(false);
            if (this.isVoucherValid(res)) {
              validSelectionIds.push(firstPid);
            }
          })
          .catch(error => {
            validityCallError.push(true);
            errorResponse = error;
          });
      }
    }
    return { allInvalid, errorResponse };
  }

  private emitCode(allInvalid: boolean, validSelectionIds: any[]): void {
    if (allInvalid) {
      this.setStateNoProductsFound();
      this.codeChanged.emit(null);
    } else {
      this.setStateDeleteCheckValid();
      const _infoText = [];
      const _infoTextKey = [];
      this.msgPlaceholders.forEach((value: string, key: string) => {
        if (key !== 'voucherMsg') {
          _infoText.push(value);
          _infoTextKey.push(key);
        }
      });
      this.codeChanged.emit({
        aktionscode: this.code,
        aktionsCodeArt: this.aktionsCodeArt,
        prozesstyp: this.prozesstyp,
        nennwert: this.nennwert,
        zusatzInformationen: this.createWLAdditionalValues(false),
        infoText: _infoText,
        infoTextKey: _infoTextKey,
        commonMappingData: this.voucherStateService.isFilterMappingData()
          ? this.voucherStateService.getFilteredMappingData(validSelectionIds)
          : null,
      });
    }
  }

  private createWLAdditionalValues(noAdditionalFields: boolean): ZusatzInformationen[] {
    const additionalFields: ZusatzInformationen[] = [];
    if (this.wlFormRef) {
      return this.wlFormRef.createWLAdditionalValues(noAdditionalFields);
    }
    return additionalFields;
  }

  private checkValidity(
    pid: string,
    selectedProducts: IProduktSelektion,
    now: string,
    noAdditionalFields: boolean,
    allInvalid: boolean,
    voucherMsg: Map<string, string>,
    validityCallError: boolean[],
    validSelectionIds: any[],
    errorResponse: any,
    promises: any[],
  ): any {
    const productDetails = this.voucherStateService.getProduktAuswahlDetails(pid, selectedProducts);
    if (productDetails) {
      const body = this.voucherService.createVoucherValidityBody(now, this.createVoucherBodyModel(productDetails, noAdditionalFields));
      let validityPromise;
      ({ validityPromise, allInvalid, errorResponse } = this.createValidityPromise(
        body,
        allInvalid,
        voucherMsg,
        productDetails,
        noAdditionalFields,
        validityCallError,
        validSelectionIds,
        pid,
        errorResponse,
      ));

      promises.push(validityPromise);
    }
    return { allInvalid, errorResponse };
  }

  private createValidityPromise(
    body: any,
    allInvalid: boolean,
    voucherMsg: Map<string, string>,
    productDetails: IProduktAuswahlDetails,
    noAdditionalFields: boolean,
    validityCallError: boolean[],
    validSelectionIds: any[],
    pid: string,
    errorResponse: any,
  ): any {
    const validityPromise = this.voucherService.voucherValidity(this.code, JSON.stringify(body));
    validityPromise
      .then(res => {
        allInvalid = this.handleValidityResponse(res, allInvalid, voucherMsg, productDetails.sparte, noAdditionalFields);
        validityCallError.push(false);
        if (this.isVoucherValid(res)) {
          validSelectionIds.push(pid);
        }
      })
      .catch(error => {
        validityCallError.push(true);
        errorResponse = error;
      });
    return { validityPromise, allInvalid, errorResponse };
  }

  private handleValidityResponse(
    res: GueltigkeitsDetails,
    allInvalid: boolean,
    voucherMsg: Map<string, string>,
    category: string,
    noAdditionalFields: boolean,
  ): boolean {
    if (this.isVoucherValid(res)) {
      allInvalid = false;
      if (anyTrue(!this.showWLFields, !noAdditionalFields)) {
        const _msg = res.infoText;
        voucherMsg.set(category, _msg);
        this.msgPlaceholders.set(this.createPlaceHolderKey(category), _msg);
      }
    }
    return allInvalid;
  }

  private createPlaceHolderKey(category: string): string {
    return category + 'Msg';
  }

  private updateVoucherMsg(voucherMsg: Map<string, string>, checkIfReuseable: boolean): void {
    let keys: string[] = [];
    voucherMsg.forEach((v, k) => keys.push(k));
    sortProductsEnumCategory(keys);
    if (checkIfReuseable && keys.length > this.maxNumberForValidityChecks) {
      const removeKeys = keys.slice(this.maxNumberForValidityChecks, keys.length);
      removeKeys.forEach(r => this.msgPlaceholders.delete(this.createPlaceHolderKey(r)));
      keys = keys.slice(0, this.maxNumberForValidityChecks);
    }

    this.getUITextFromMsgPlaceholders();
  }

  private getUITextFromMsgPlaceholders(): void {
    // hack because of 'Msg' in key... can't remove it, because it is already in database
    this.uiSuccessInfos = [];

    if (this.isVoucherRedeemProcess()) {
      // at voucher-redeem -> only first info from AKV is needed
      const firstEntry = this.msgPlaceholders.values().next().value;
      this.uiSuccessInfos.push(firstEntry);
      return;
    }

    this.msgPlaceholders.forEach((text: string, key: string) => {
      const category = key.replace('Msg', '').toLowerCase();
      const categoryTranslated = this.translateService.instant(`products.category.${category}`);
      const info = `<b>${categoryTranslated}:</b> ${text}`;
      this.uiSuccessInfos.push(info);
    });
  }

  private isVoucherValid(res: GueltigkeitsDetails): boolean {
    return res.gueltig === true;
  }

  private createVoucherBodyModel(productDetails: IProduktAuswahlDetails, noAdditionalFields: boolean): VoucherBodyModel {
    const _tariffKey = productDetails.tarif ? productDetails.tarif.ISUTarifKey : productDetails.tarifKey;
    const gewaehlteTarifOptionen: TarifOption[] = this.getChoosenTarifOptions(productDetails);
    return {
      tariffKey: _tariffKey,
      processCode: this.prozesstyp,
      additionalFields: this.createWLAdditionalValues(noAdditionalFields),
      gewaehlteTarifOptionen,
    };
  }

  private getChoosenTarifOptions(productDetails: IProduktAuswahlDetails): TarifOption[] {
    let gewaehlteTarifOptionen: TarifOption[];
    if (everyTrue(!!productDetails?.tarif?.tarifOptionen)) {
      gewaehlteTarifOptionen = [];
      productDetails.tarif.tarifOptionen.forEach(to => {
        gewaehlteTarifOptionen.push(to);
      });
    }
    return gewaehlteTarifOptionen;
  }

  private setErrorState(errorResponse?: HttpErrorResponse, alertStyle: AlertType = 'danger'): void {
    this.errorTitleKey = 'error.service.default.title';
    this.errorMessageKey = 'error.service.default.message';
    this.codeCheck = 'error';
    let errorCode = '';
    if (errorResponse) {
      errorCode = this.setNewErrorMessageKeys(errorResponse);
    }

    this.errorAlertStyle = alertStyle;
    if (errorCode !== '3017') {
      this.setFormError(this.codeControlName, { codeNotValid: true });
    }
    this.state = 'delete';
    this.codeChanged.emit(null);
  }

  private setNewErrorMessageKeys(errorResponse: HttpErrorResponse): string {
    const httpError = new HTTPError(errorResponse.status + '', errorResponse.error);

    if (this.errorTitle.has(httpError.code)) {
      this.errorTitleKey = this.errorTitle.get(httpError.code);
      this.errorMessageKey = this.errorDescription.get(httpError.code);
    }

    if (this.errorType.has(httpError.code)) {
      this.codeCheck = this.errorType.get(httpError.code);
    }

    if (['3008', '3009', '3010', '3011', '3012', '3018'].includes(httpError.code)) {
      this.showWLFields = false;
    }
    return httpError.code;
  }

  private setStateNoProductsFound(): void {
    this.state = 'delete';
    if (this.hasAlreadyStromGasProducts === true) {
      this.codeCheck = 'invalid';
      this.setFormError(this.codeControlName, { codeNotValid: true });
    } else {
      this.codeCheck = 'warning';
      this.errorMessageKey = this.getErrorLabelForProcess('voucher.validity.tariffNotValid.errorText');
      this.isTariffNotValidError = true;
    }
  }

  private setStateDeleteCheckInvalid(): void {
    this.state = 'delete';
    this.codeCheck = 'invalid';
    this.setFormError(this.codeControlName, { codeNotValid: true });
  }

  private setStateDeleteCheckValid(): void {
    this.state = 'delete';
    this.codeCheck = 'valid';
    this.clearFormErrors();
  }

  private setStateDeleteCheckBlank(): void {
    this.state = 'delete';
    this.codeCheck = '';
    this.clearFormErrors();
  }

  private setStateCheckBlank(): void {
    this.state = 'check';
    this.codeCheck = '';
  }

  private setStateOldVoucherCode(): void {
    this.state = 'delete';
    this.codeCheck = 'warning';
    this.setFormError(this.codeControlName, { codeTooOld: true });
    this.errorMessageKey = this.config.oldVoucherCodeWarningKey;
  }

  private resetForm(): void {
    this.formRef.form.reset();
    this.code = null;
    this.codeCheckDisabled = true;
    this.showWLFields = false;
    this.clearFormErrors();
    this.codeChanged.emit(null);
  }

  private clearFormErrors(): void {
    if (this.formRef && this.formRef.form?.controls && this.formRef?.form.controls[this.codeControlName]) {
      this.formRef.form.controls[this.codeControlName].setErrors(null);
    }
  }

  private setFormError(controlName: string, error: {}) {
    if (this.formRef && this.formRef.form?.controls && this.formRef?.form.controls[this.codeControlName]) {
      this.formRef.form.controls[this.codeControlName].setErrors(error);
    }
  }
}
