import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConsumptionHistory } from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsumptionDataService {
  private resourceUrl: string;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/consumption/';
  }

  getConsumptionHistoryMonthly(accountNumber: string, systemId: string): Promise<IConsumptionHistory> {
    return lastValueFrom(this.http.get<IConsumptionHistory>(this.resourceUrl + accountNumber + '/history/monthly?systemId=' + systemId));
  }

  getConsumptionHistory(accountNumber: string, systemId: string): Promise<IConsumptionHistory> {
    return lastValueFrom(
      this.http.get<IConsumptionHistory>(this.resourceUrl + accountNumber + '/consumption/history?systemId=' + systemId),
    );
  }
}
