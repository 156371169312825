export enum UserStatus {
  UNKNOWN = 'UNKNOWN',
  INVITED = 'INVITED',
  VERIFICATION_PENDING = 'VERIFICATION_PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REGISTRATION_ERROR = 'REGISTRATION_ERROR',
}

export class UserStatusInfo {
  numberOfVerificationAttempts: number;
  registeredAt: string;
  status: UserStatus;
}
