import { Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { IFormInput } from '@mwe/models';

@Component({
  selector: 'mwe-radio-input',
  templateUrl: './radio-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioInputComponent,
      multi: true,
    },
  ],
})
export class RadioInputComponent implements ControlValueAccessor {
  @Input() parentForm: UntypedFormGroup;
  @Input() inputElem: IFormInput;
  @Input() isLoading = false;

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  getUniqueId(i: number): string {
    return `radio-button-option-${this.inputElem.name}-${i}`;
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }
}
