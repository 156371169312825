import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ServiceStateEnum } from '@mwe/constants';
import { TextNodeType } from '@mwe/models';
import { HeadlineService } from '../../headline/headline.service';

interface TariffPriceCardTheme {
  cardClass: string;
}

interface TariffPriceCardThemes {
  loading: TariffPriceCardTheme;
  strom: TariffPriceCardTheme;
  gas: TariffPriceCardTheme;
  discount: TariffPriceCardTheme;
  fernwaerme: TariffPriceCardTheme;
}

const cardThemes: TariffPriceCardThemes = {
  loading: {
    cardClass: 'bg-gray',
  },
  strom: {
    cardClass: 'bg-success',
  },
  gas: {
    cardClass: 'bg-secondary-light',
  },
  discount: {
    cardClass: 'bg-warning',
  },
  fernwaerme: {
    cardClass: 'bg-primary',
  },
};

@Component({
  selector: 'mwe-tariff-card-template',
  templateUrl: './tariff-card-template.component.html',
  providers: [HeadlineService],
})
export class TariffCardTemplateComponent implements OnInit, OnChanges {
  @Input() category: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() hasDiscount: boolean;
  @Input() isDiscountKnoedlVisible = false;
  @Input() hasActions: boolean;
  @Input() noBoxShadow: boolean;
  @Input() state: ServiceStateEnum;
  @Input() headlineReference: TextNodeType = 'p';
  iconName: string;
  theme: TariffPriceCardTheme;

  constructor(public headlineService: HeadlineService) {}

  ngOnInit() {
    this.headlineService.setReferenceHeadline(this.headlineReference);
    this.iconName = `productDetails/${this.category.toLowerCase()}.svg`;
  }

  ngOnChanges() {
    this.setBackgroundCss();
  }

  private setBackgroundCss(): void {
    // !this.hasError && isArrayWithMinOneItem(filterActiveContractDiscounts(this.discounts))
    if (this.state === ServiceStateEnum.LOADING) {
      this.theme = cardThemes.loading;
      return;
    }
    if (this.hasDiscount) {
      this.theme = cardThemes.discount;
      return;
    }

    const theme = cardThemes[this.category?.toLowerCase()];
    this.theme = theme;
  }
}
