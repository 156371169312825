<div (click)="onClick.emit()" [ngClass]="{ pointer: isClickable }" class="mb-3 mb-md-4" data-test="selection-card-container">
  <div class="card-basic">
    <div class="card-basic-body position-relative p-4">
      <div class="row gx-3">
        <div class="col-auto">
          <i [ngClass]="iconClass" class="text-secondary fs-2rem"></i>
        </div>
        <div [ngClass]="!header ? 'd-flex align-items-center' : ''" class="col">
          @if (header) {
            <h6 class="mb-1" data-test="mwe-selection-card-header">{{ header }}</h6>
          }
          <p class="mb-0" data-test="mwe-selection-card-content">{{ content }}</p>
        </div>
        <div class="col-auto d-flex align-items-center">
          <i class="fa-solid fa-chevron-right text-secondary"></i>
        </div>
      </div>
    </div>
  </div>
</div>
