import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as NavigationConstants from '@mwe/constants';
import { INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE } from '@mwe/constants';
import { IAddressProducts, ILSystem, InvoiceListFilter, Rechnung, Sparte } from '@mwe/models';
import { getUniqueValues, isArrayWithMinOneItem } from '@mwe/utils';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PartialPaymentStateService } from '../../partial-payment/partial-payment-state.service';
import { InteractiveInvoicesStateService } from './interactive-invoices-state.service';

@Injectable({ providedIn: 'root' })
export class InteractiveInvoicesLogic {
  private currentInvoiceListFilter = new BehaviorSubject<InvoiceListFilter>({ category: undefined });

  constructor(
    private router: Router,
    private stateService: InteractiveInvoicesStateService,
    private partialPaymentStateService: PartialPaymentStateService,
  ) {}

  showInteractiveInvoices(addressProducts: IAddressProducts, categoryToFilter?: string): void {
    this.router.navigateByUrl(`/${NavigationConstants.INTERACTIVE_INVOICE_ROUTE}`);
    this.stateService.addressProducts = addressProducts;
    this.setInvoiceListFilter({ category: categoryToFilter as Sparte });
  }

  showInvoicePartialPaymentOverview(addressProducts: IAddressProducts, accountNumber: string, systemId: ILSystem): void {
    this.partialPaymentStateService.addressProducts = addressProducts;
    this.partialPaymentStateService.accountId = accountNumber;
    this.partialPaymentStateService.systemId = systemId;

    this.router.navigateByUrl(`/${INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE}`);
  }

  observeInvoices(onlyNew: boolean): Observable<Rechnung[]> {
    return combineLatest([of(this.stateService.invoiceList), this.currentInvoiceListFilter.asObservable()]).pipe(
      map(([allInvoices, filter]) => {
        let invoices = isArrayWithMinOneItem(allInvoices) ? allInvoices : [];
        invoices = invoices.filter(i => (onlyNew ? !i.gelesen : !!i.gelesen));

        if (filter.category) {
          invoices = invoices.filter(i => i.sparte.some(s => s.toLowerCase() === filter.category.toLowerCase()));
        }

        return invoices;
      })
    );
  }

  getAllCategoriesFromInvoiceList(): Sparte[] {
    if (!isArrayWithMinOneItem(this.stateService.invoiceList)) {
      return [];
    }

    const allCategories = this.stateService.invoiceList.map(i => {
      return i.sparte;
    });

    return getUniqueValues(allCategories.flat());
  }

  setInvoiceListFilter(filter: InvoiceListFilter): void {
    this.currentInvoiceListFilter.next(filter);
  }

  resetInvoiceListFilter(): void {
    this.currentInvoiceListFilter.next({ category: undefined });
  }

  getCurrentInvoiceListFilter(): InvoiceListFilter {
    return this.currentInvoiceListFilter.value;
  }
}
