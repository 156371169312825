import { Component, Input, OnInit } from '@angular/core';
import { Address, IERechnungsInfo } from '@mwe/models';

@Component({
  selector: 'mwe-summary-invoice',
  templateUrl: './summary-invoice.component.html',
})
export class SummaryInvoiceComponent implements OnInit {
  @Input() cardTitle: string;
  @Input() paymentIcon: string;
  @Input() paymentText: string;
  @Input() eRechnungInfo: IERechnungsInfo;
  @Input() invoiceAddress: Address;
  @Input() invoiceInfoAlertMessage: string;

  formattedInvoiceAddress: string;

  ngOnInit(): void {
    this.formattedInvoiceAddress = this.invoiceAddress?.getGroupFormatted(true);
  }
}
