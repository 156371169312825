@if (isReadonly) {
  <div class="text-end" [attr.data-test]="getTestId(selectedTariffGroupId)">{{ selectedTariffOption?.optionLabel }}</div>
} @else {
  <div ngbDropdown placement="bottom-right" #Dropdown="ngbDropdown">
    <button
      type="button"
      class="btn px-2 w-100 justify-content-between btn-sm btn-dropdown-{{ colorCss }}"
      [id]="getTestId(selectedTariffGroupId)"
      [attr.data-test]="getTestId(selectedTariffGroupId)"
      ngbDropdownToggle
    >
      <span class=""> <i class="mr-1 fa-solid fa-{{ selectedTariffOption?.iconLabel }}"></i>{{ selectedTariffOption?.optionLabel }} </span>
      <i [ngClass]="{ 'fa-chevron-up': Dropdown._open, 'fa-chevron-down': !Dropdown._open }" class="ml-2 fas" aria-hidden="true"></i>
    </button>

    <div ngbDropdownMenu class="dropdown-menu-tariff border border-2 border-{{ colorCss }} dropdown-menu-{{ colorCss }}">
      <button
        *ngFor="let item of options"
        [ngClass]="{ active: isOptionActive(item) }"
        (click)="onOptionSelected(item)"
        [attr.data-test]="getTestId(item.option)"
        type="button"
        ngbDropdownItem
        class="dropdown-item-{{ colorCss }}"
      >
        <i *ngIf="item.iconLabel" class="mr-1 fa-solid fa-{{ item.iconLabel }}"></i>
        {{ item.optionLabel }} ({{ item.delta }} {{ item.deltaUnit }})
      </button>
    </div>
  </div>
}
