import { SessionStorageService } from 'ngx-webstorage';

export abstract class ConfirmationStatusStateService {
  protected constructor(
    protected storage: SessionStorageService,
    protected lastOrderIdKey: string,
    protected retriedOnErrorKey: string,
    protected submitIdKey: string,
  ) {}

  abstract clearAll(): void;

  clear(): void {
    this.storage.clear(this.lastOrderIdKey);
    this.storage.clear(this.retriedOnErrorKey);
    this.storage.clear(this.submitIdKey);
  }

  get submitId(): number {
    return this.storage.retrieve(this.submitIdKey) || undefined;
  }

  set submitId(submitId: number) {
    if (submitId) {
      this.storage.store(this.submitIdKey, submitId);
    } else {
      this.storage.clear(this.submitIdKey);
    }
  }

  get lastOrderId(): string {
    return this.storage.retrieve(this.lastOrderIdKey) || undefined;
  }

  set lastOrderId(lastOrderId: string) {
    if (lastOrderId) {
      this.storage.store(this.lastOrderIdKey, lastOrderId);
    } else {
      this.storage.clear(this.lastOrderIdKey);
    }
  }

  get retriedOnError(): boolean {
    return this.storage.retrieve(this.retriedOnErrorKey) || undefined;
  }

  set retriedOnError(retriedOnError: boolean) {
    if (retriedOnError) {
      this.storage.store(this.retriedOnErrorKey, retriedOnError);
    } else {
      this.storage.clear(this.retriedOnErrorKey);
    }
  }
}
