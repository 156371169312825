import { CommonMappingData, IProduktAuswahlDetails, IProduktSelektion } from '@mwe/models';
import { SessionStorageService } from 'ngx-webstorage';
import { ConfirmationStatusStateService } from '../confirmation/confirmation-status-state.service';

export abstract class VoucherStateService extends ConfirmationStatusStateService {
  protected constructor(
    protected override storage: SessionStorageService,
    protected override lastOrderIdKey: string,
    protected override retriedOnErrorKey: string,
    protected override submitIdKey: string,
  ) {
    super(storage, lastOrderIdKey, retriedOnErrorKey, submitIdKey);
  }

  isFilterMappingData(): boolean {
    return false;
  }

  hasAllProductsASelectedTariff(): boolean {
    return true;
  }

  getFilteredMappingData(selectedProduktIds: string[]): CommonMappingData[] {
    const filteredMapping: CommonMappingData[] = [];
    this.getProductSelection().commonMapping.forEach(cm => {
      if (cm.anlageIds.find(anlageId => selectedProduktIds?.includes(anlageId))) {
        if (!cm.validVoucherAnlageId) {
          cm.validVoucherAnlageId = cm.anlageIds.find(aId => selectedProduktIds.includes(aId));
        }
        filteredMapping.push(cm);
      }
    });
    return filteredMapping.length > 0 ? filteredMapping : null;
  }

  getProduktAuswahlDetails(productSelectionId: string, products: IProduktSelektion): IProduktAuswahlDetails {
    if (products?.anlagen) {
      return products.anlagen.find(p => p.anlageId === productSelectionId);
    } else if (products?.commonMapping) {
      const commonMapping = products.commonMapping.find(p => p.anlageIds.includes(productSelectionId));
      const productIdx = commonMapping.anlageIds.indexOf(productSelectionId);
      const tariffKey = commonMapping.tariffKeys[productIdx];
      const category = commonMapping.categories[productIdx];
      return { sparte: category, tarifKey: tariffKey } as IProduktAuswahlDetails;
    } else if (products[productSelectionId]) {
      return products[productSelectionId];
    }
    return null;
  }

  abstract getProductSelection(): IProduktSelektion;
  abstract getProductSelectionIds(): string[];
}
