import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IConfirmationStatus,
  Address,
  IBankDetails,
  IBusinessData,
  IERechnungsInfo,
  IOlavEntry,
  IPersonalData,
  IProduktSelektion,
  IVertragskonto,
  parseAddress,
} from '@mwe/models';
import { ServiceStateEnum } from '@mwe/constants';
import { ConfirmationStatusStateService } from '../../confirmation/confirmation-status-state.service';

const productSelectionKey = 'chargingCardOrder_productSelection';
const personalDataKey = 'chargingCardOrder_personalData';
const businessDataKey = 'chargingCardOrder_businessData';
const carModelTypeKey = 'chargingCardOrder_carModelType';
const numberOfCardsKey = 'chargingCardOrder_numberOfCards';
const invoiceDataKey = 'chargingCardOrder_invoiceData';
const invoiceAddressKey = 'chargingCardOrder_invoiceAddress';
const invoiceAddressOlavKey = 'chargingCardOrder_invoiceAddressOlav';
const lastOrderIdKey = 'chargingCardOrder_lastOrderId';
const retriedOnErrorKey = 'chargingCardOrder_retriedOnError';
const submitIdKey = 'chargingCardOrder_submitId';
const bankDetailsKey = 'chargingCardOrder_bankDetails';
const eRechnungsInfoKey = 'chargingCardOrder_eRechnungsInfo';
const orderDateKey = 'chargingCardOrder_orderDateKey';
const confirmationStatusKey = 'chargingCardOrder_confirmationStatus';
const autoRegistrationSuccessfulKey = 'chargingCardOrder_autoRegistrationSuccessful';
const orderSuccessfullKey = 'chargingCardOrder_orderSuccessfull';
const submitSuccessStateKey = 'chargingCardOrder_submitSuccessState';
const grantedMarketingPermissionKey = 'chargingCardOrder_grantedMarketingPermission';

@Injectable({
  providedIn: 'root',
})
export class ChargingCardOrderStateService extends ConfirmationStatusStateService {
  @SessionStorage(productSelectionKey)
  private _productSelection: IProduktSelektion;

  @SessionStorage(personalDataKey)
  private _personalData: IPersonalData;

  @SessionStorage(businessDataKey)
  private _businessData: IBusinessData;

  @SessionStorage(carModelTypeKey)
  private _carModelType: string;

  @SessionStorage(numberOfCardsKey)
  private _numberOfCards: string;

  @SessionStorage(invoiceDataKey)
  private _invoiceData: IVertragskonto;

  @SessionStorage(invoiceAddressKey)
  private _invoiceAddress: Address;

  @SessionStorage(invoiceAddressOlavKey)
  private _invoiceAddressOlav: IOlavEntry;

  @SessionStorage(bankDetailsKey)
  private _bankDetails: IBankDetails;

  @SessionStorage(eRechnungsInfoKey)
  private _eRechnungsInfo: IERechnungsInfo;

  @SessionStorage(orderDateKey)
  private _orderDate: string;

  @SessionStorage(confirmationStatusKey)
  private _confirmationStatus: IConfirmationStatus;

  @SessionStorage(autoRegistrationSuccessfulKey)
  private _autoRegistrationSuccessful: boolean;

  @SessionStorage(orderSuccessfullKey)
  private _orderSuccessfull: boolean;

  @SessionStorage(submitSuccessStateKey)
  private _submitSuccessState: ServiceStateEnum;
  private submitSuccessStateSubject = new BehaviorSubject(ServiceStateEnum.INIT);

  @SessionStorage(grantedMarketingPermissionKey)
  private _grantedMarketingPermission: boolean;

  constructor(protected override storage: SessionStorageService) {
    super(storage, lastOrderIdKey, retriedOnErrorKey, submitIdKey);
  }

  clearAll() {
    this.clear();
  }

  override clear(): void {
    this.storage.clear(productSelectionKey);
    this.storage.clear(personalDataKey);
    this.storage.clear(businessDataKey);
    this.storage.clear(carModelTypeKey);
    this.storage.clear(numberOfCardsKey);
    this.storage.clear(invoiceDataKey);
    this.storage.clear(invoiceAddressKey);
    this.storage.clear(invoiceAddressOlavKey);
    this.storage.clear(bankDetailsKey);
    this.storage.clear(eRechnungsInfoKey);
    this.storage.clear(orderDateKey);
    this.storage.clear(confirmationStatusKey);
    this.storage.clear(orderSuccessfullKey);
    this.storage.clear(submitSuccessStateKey);
    this.storage.clear(autoRegistrationSuccessfulKey);
    this.storage.clear(grantedMarketingPermissionKey);
    super.clear();
  }

  get productSelection(): IProduktSelektion {
    return this._productSelection;
  }

  set productSelection(value: IProduktSelektion) {
    if (!value) {
      this.storage.clear(productSelectionKey);
      return;
    }

    this._productSelection = value;
  }

  get invoiceAddress(): Address {
    return (this._invoiceAddress && parseAddress(this._invoiceAddress)) || undefined;
  }

  set invoiceAddress(_newClientAddress: Address) {
    if (_newClientAddress) {
      this._invoiceAddress = _newClientAddress;
    } else {
      this.storage.clear(invoiceAddressKey);
    }
  }

  get invoiceAddressOlav(): IOlavEntry {
    return this._invoiceAddressOlav || undefined;
  }

  set invoiceAddressOlav(newClientAddressOlav: IOlavEntry) {
    if (newClientAddressOlav) {
      this._invoiceAddressOlav = newClientAddressOlav;
    } else {
      this.storage.clear(invoiceAddressOlavKey);
    }
  }

  get invoiceData(): IVertragskonto {
    return this._invoiceData || undefined;
  }

  set invoiceData(invoiceData: IVertragskonto) {
    if (invoiceData) {
      this._invoiceData = invoiceData;
    } else {
      this.storage.clear(invoiceDataKey);
    }
  }

  get personalData(): IPersonalData {
    return this._personalData || undefined;
  }

  set personalData(personalData: IPersonalData) {
    if (personalData) {
      this._personalData = personalData;
    } else {
      this.storage.clear(personalDataKey);
    }
  }

  get businessData(): IBusinessData {
    return this._businessData || undefined;
  }

  set businessData(businessData: IBusinessData) {
    if (businessData) {
      this._businessData = businessData;
    } else {
      this.storage.clear(businessDataKey);
    }
  }

  get carModelType(): string {
    return this._carModelType || undefined;
  }

  set carModelType(carModelType: string) {
    if (carModelType) {
      this._carModelType = carModelType;
    } else {
      this.storage.clear(carModelTypeKey);
    }
  }

  get numberOfCards(): string {
    return this._numberOfCards || undefined;
  }

  set numberOfCards(numberOfCards: string) {
    if (numberOfCards) {
      this._numberOfCards = numberOfCards;
    } else {
      this.storage.clear(numberOfCardsKey);
    }
  }

  get bankDetails(): IBankDetails {
    return this._bankDetails || undefined;
  }

  set bankDetails(bankDetails: IBankDetails) {
    if (bankDetails) {
      this._bankDetails = bankDetails;
    } else {
      this.storage.clear(bankDetailsKey);
    }
  }

  get eRechnungsInfo(): IERechnungsInfo {
    return this._eRechnungsInfo || undefined;
  }

  set eRechnungsInfo(eRechnungsInfo: IERechnungsInfo) {
    if (eRechnungsInfo) {
      this._eRechnungsInfo = eRechnungsInfo;
    } else {
      this.storage.clear(eRechnungsInfoKey);
    }
  }

  get orderDate(): string {
    return this._orderDate || undefined;
  }

  set orderDate(orderDate: string) {
    if (orderDate) {
      this._orderDate = orderDate;
    } else {
      this.storage.clear(orderDateKey);
    }
  }

  get confirmationStatus(): IConfirmationStatus {
    return this._confirmationStatus || undefined;
  }

  set confirmationStatus(confirmationStatus: IConfirmationStatus) {
    this._confirmationStatus = confirmationStatus;
  }

  get orderSuccessfull(): boolean {
    return this._orderSuccessfull;
  }

  set orderSuccessfull(orderSuccessfull: boolean) {
    this._orderSuccessfull = orderSuccessfull;
  }

  get submitSuccessState(): ServiceStateEnum {
    return this._submitSuccessState || undefined;
  }

  set submitSuccessState(submitSuccessState: ServiceStateEnum) {
    if (submitSuccessState) {
      this._submitSuccessState = submitSuccessState;
      this.submitSuccessStateSubject.next(submitSuccessState);
    } else {
      this.storage.clear(submitSuccessStateKey);
    }
  }

  get autoRegistrationSuccessful(): boolean {
    return this._autoRegistrationSuccessful;
  }

  set autoRegistrationSuccessful(isAutoRegistrationSuccessful: boolean) {
    this._autoRegistrationSuccessful = isAutoRegistrationSuccessful;
  }

  observeSubmitSuccessState(): Observable<ServiceStateEnum> {
    return this.submitSuccessStateSubject.asObservable();
  }

  get grantedMarketingPermission(): boolean {
    return this._grantedMarketingPermission;
  }

  set grantedMarketingPermission(grantedMarketingPermission: boolean) {
    this._grantedMarketingPermission = grantedMarketingPermission;
  }
}
