import { AbstractControl, ControlContainer, ControlValueAccessor, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, Host, HostBinding, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { IFormInput, IFormSelect } from '@mwe/models';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { escapeRegExp } from '@mwe/utils';
import { FormComponent } from '../../form.component';

@Component({
  selector: 'mwe-typeahead-input',
  templateUrl: './typeahead-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TypeaheadInputComponent,
      multi: true,
    },
  ],
})
export class TypeaheadInputComponent implements ControlValueAccessor, OnInit {
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  @Input() formControlName: string;
  @Input() formInputOptions: IFormInput;
  @Input() isLoading = false;

  @HostBinding('class.we-typeahead') componentClass = true;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  get typeaheadItems(): { value: string; text: string }[] {
    return this.formInputOptions.options;
  }

  get isValid(): any {
    return this.formControlDirective?.control?.valid;
  }

  formatter = (item: IFormSelect) => item.text;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        const regExp = new RegExp(this.getRegex(term), 'mi');
        return term === '' ? [] : this.typeaheadItems.filter(typeaheadItem => regExp.test(typeaheadItem.text)).slice(0, 15);
      }),
    );
  };

  constructor(
    private controlContainer: ControlContainer,
    @Optional() @Host() private formComponent: FormComponent,
  ) {}

  ngOnInit(): void {
    const initialValue = this.typeaheadItems.find(option => option.value === this.formInputOptions.initialValue);
    this.control.setValue(initialValue);
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  onSelected($event): void {
    this.control.setValue($event?.item?.value);
  }

  onBlur(): void {
    // if user selects value from suggestion-popup, control.value is an object
    // if user writes value in input field, control.value is a string and we have to check it
    //    if user-input matches an item from suggestion-popup, set corresponding object as new value
    //    else: delete user-input
    if (typeof this.control.value === 'string') {
      const newValue = this.typeaheadItems.find(value => value.text === this.control.value);
      this.control.setValue(newValue);
    }
  }

  getRegex(term: string): string {
    if (this.formInputOptions.typeaheadOptions?.regex) {
      return this.formInputOptions.typeaheadOptions?.regex.replace('{0}', escapeRegExp(term));
    }

    return escapeRegExp(term);
  }

  get ariaDescribedBy(): string | undefined {
    if (this.formComponent) {
      // Error State hat Vorrang für Screenreader
      if (this.formComponent.isInputErrorVisible(this.formInputOptions.componentType)) {
        return `form-input-error--${this.formInputOptions.name}`;
      } else if (this.formComponent.isInputHelpInfoVisible(this.formInputOptions)) {
        return `form-input-info--${this.formInputOptions.name}`;
      }
    }
    return undefined;
  }
}
