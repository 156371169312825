import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export interface ISliderResponse {
  id: string;
  checked: boolean;
}

@Component({
  selector: 'mwe-product-card-slider',
  templateUrl: './product-card-slider.component.html',
  styles: [],
})
export class ProductCardSliderComponent implements OnInit {
  @Input() id: string;
  @Input() initialValue: boolean;
  @Input() isOrdered: boolean;
  @Input() toggleOnMsgKey: string;
  @Input() toggleOffMsgKey: string;
  @Input() showCompanyReference = false;

  @Output() sliderState = new EventEmitter<ISliderResponse>();

  toggleLabel: string;

  constructor() {}

  ngOnInit(): void {
    if (this.initialValue) {
      this.toggleLabel = this.toggleOnMsgKey;
      this.isOrdered = true;
    } else {
      this.toggleLabel = this.toggleOffMsgKey;
    }
  }

  onProductSelect(isChecked: boolean): void {
    this.toggleLabel = isChecked ? this.toggleOnMsgKey : this.toggleOffMsgKey;
    this.isOrdered = isChecked;
    this.sliderState.emit({ id: this.id, checked: this.isOrdered });
  }
}
