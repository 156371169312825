<mwe-basic-card>
  <mwe-collapse titleMessageKey="terms.listOfContents.title">
    <ng-container *ngFor="let i of items">
      <h6><a [mweScrollToTargetOnClick]="getScrollTarget(i)" class="blue pointer" [translate]="getItemTitleKey(i)"></a></h6>
    </ng-container>
  </mwe-collapse>

  <mwe-collapse titleMessageKey="terms.conditionsOfUse.title" initialState="expanded">
    <div class="overflow-auto scrollbar-thin" style="max-height: 30rem">
      <ng-container *ngFor="let i of items">
        <h6 [attr.id]="getItemId(i)" translate="terms.item{{ i }}.title"></h6>
        <p [innerHTML]="getItemTextKey(i) | translate | safeHtml"></p>
      </ng-container>
    </div>
  </mwe-collapse>
</mwe-basic-card>
