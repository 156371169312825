import { IAddress, IAddressProducts } from 'libs/models/src/lib/address/address.model';
import { AddressGroup } from 'libs/models/src/lib/address/address-group.model';

export function checkAddressProductsForAddressGroupUpdates(addressProducts: IAddressProducts[], updates: AddressGroup[]): void {
  const addresses = addressProducts.map(addressProduct => addressProduct.address);

  checkAddressesForGroupUpdates(addresses, updates);
}

export function checkAddressForGroupUpdates(address: IAddress, updates: AddressGroup[]): void {
  checkAddressesForGroupUpdates([address], updates);
}

export function checkAddressesForGroupUpdates(addresses: IAddress[], updates: AddressGroup[]): void {
  updates.forEach((update: AddressGroup) => {
    const addressProduct = addresses.find(elem => elem.addressGroup.addressCode === update.addressCode);

    if (addressProduct) {
      addressProduct.addressGroup = update;
      addressProduct.addressGroup.markAsMatched();
    }
  });
}
