import { Component, Input } from '@angular/core';
import { Product } from '@mwe/models';

@Component({
  selector: 'mwe-wizard-product-card-list',
  templateUrl: './wizard-product-card-list.component.html',
  styles: [],
})
export class WizardProductCardListComponent {
  @Input() listHeaderKey: string;
  @Input() notActiveText: string;
  @Input() productList: Product[];
  @Input() isActiveCard: boolean;
  @Input() isAvailableAtNewAddress: boolean;
  @Input() showTariff = true;

  constructor() {}
}
