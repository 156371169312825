import { Component, computed, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProduktAuswahlDetails, TariffOptionSap, TariffSelectionCardData } from '@mwe/models';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TariffSelectionCardUiComponent } from '../tariff-selection-card/tariff-selection-card-ui.component';
import { TariffSelectionStoreService } from '../services/tariff-selection-store.service';
import { TariffSelectionCardLogic } from '../services/tariff-selection-card.logic';
import { ServiceStateEnum } from '@mwe/constants';
import { LoggingService } from '@mwe/services';
import { isArrayWithMinOneItem } from '@mwe/utils';

@Component({
  selector: 'mwe-tariffs-selection-button',
  templateUrl: './tariffs-selection-button.component.html',
})
export class TariffsSelectionButtonComponent implements OnInit {
  @Input() category: string;
  @Input() anlageId: string; // only for tariff-change
  @Output() tariffSelected = new EventEmitter<TariffSelectionCardData>();

  swiperConfig: SwiperOptions;
  items = computed<TariffSelectionCardData[]>(() =>
    this.computeListItems(this.storeService.allProducts(), this.storeService.selectedTariffs()),
  );

  get additionalOptionGroupName(): string {
    return this.storeService.additionalOptionGroupName();
  }

  constructor(
    private modalService: NgbModal,
    private storeService: TariffSelectionStoreService,
    private cardLogic: TariffSelectionCardLogic,
    private loggingService: LoggingService,
  ) {}

  ngOnInit(): void {
    this.setSwiperOptions();
  }

  openTariffsSelectionModal(content) {
    this.modalService.open(content, { fullscreen: true, windowClass: 'bg-gray--200' });
  }

  closeModal() {
    this.modalService.dismissAll();
    // restore VoucherFormComponent, todo refactor it so we can reuse logic/service for voucher-validation instead of VoucherFormComponent
    this.cardLogic.checkInitialVoucherCode();
  }

  selectTariff(cardRef: TariffSelectionCardUiComponent): void {
    this.modalService.dismissAll();
    this.storeService.updateSelectedTariffs(cardRef.selectedTariff);
    // restore VoucherFormComponent, todo refactor it so we can reuse logic/service for voucher-validation instead of VoucherFormComponent
    this.cardLogic.checkInitialVoucherCode();
  }

  async onTariffUpdate(
    newSapOption: TariffOptionSap,
    tariffData: TariffSelectionCardData,
    ref: TariffSelectionCardUiComponent,
  ): Promise<void> {
    // ui update - here we have no store-connection, so we can directly manipulate :D
    ref.selectedTariff.state = ServiceStateEnum.LOADING;

    const everySelectionItemNotUpdated = tariffData.userSelection.filter(u => u.art !== newSapOption.art);
    const userSelection = [...everySelectionItemNotUpdated, newSapOption];

    try {
      const priceIndication = await this.cardLogic.getNewPriceIndicationForUpdatedTariffOption(tariffData, userSelection, true);
      ref.tariff = {
        ...tariffData,
        userSelection,
        priceIndication,
        state: ServiceStateEnum.SUCCESS,
      };
    } catch (error) {
      this.loggingService.logError(error, 'TariffSelectionButtonComponent/onTariffUpdate');
      ref.tariff = {
        ...tariffData,
        userSelection,
        state: ServiceStateEnum.ERROR,
      };
    }
  }

  onReloadAfterError(item: TariffSelectionCardData, ref: TariffSelectionCardUiComponent) {
    // retry with old state
    this.onTariffUpdate(item.userSelection[0], item, ref);
  }

  // params comes from signal() do not manipulate it!!!!
  private computeListItems(allProducts: IProduktAuswahlDetails[], selectionList: TariffSelectionCardData[]) {
    const selectedTariff = selectionList.find(l => {
      return this.anlageId ? l.anlageId === this.anlageId : l.category.toLowerCase() === this.category.toLowerCase();
    });

    const otherProducts = allProducts
      .filter(p => {
        return (
          p.sparte.toLowerCase() === selectedTariff.category.toLowerCase() &&
          p.tarif.ISUTarifKey !== selectedTariff.tariffKey &&
          p.anlageId === this.anlageId
        ); // there is no anlageId in new-client so this is always true
      })
      .map(product => {
        const priceIndicationCopy = this.storeService
          .allPriceIndications()
          .find(priceIndication => product.tarif.ISUTarifKey === priceIndication.tarif.ISUTarifKey);

        if (!priceIndicationCopy) {
          return undefined;
        }

        const tariffOptionsSap = isArrayWithMinOneItem(product.tarif.tarifOptionen) ? [...product.tarif.tarifOptionen] : [];
        const userSelection = isArrayWithMinOneItem(product.tarif.tarifOptionen)
          ? [...product.tarif.tarifOptionen.filter(o => !!o.default)]
          : [];
        return {
          category: product.sparte,
          tariffKey: product.tarif.ISUTarifKey,
          tariffOptionsSap,
          userSelection,
          priceIndication: { ...priceIndicationCopy },
          anlageId: product.anlageId,
          state: ServiceStateEnum.SUCCESS,
        } as TariffSelectionCardData;
      })
      .filter(i => !!i);

    return [{ ...selectedTariff }, ...otherProducts];
  }

  private setSwiperOptions() {
    this.swiperConfig = {
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        992: {
          slidesPerView: 2.4,
          spaceBetween: 20,
        },
        850: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        750: {
          slidesPerView: 1.8,
          spaceBetween: 20,
        },
        500: {
          slidesPerView: 1.4,
          spaceBetween: 20,
        },
        400: {
          slidesPerView: 1.1,
          spaceBetween: 20,
        },
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
    };
  }
}
