import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IFormInput } from '@mwe/models';
import { AccountLogic } from '@mwe/services';
import { FormInputComponent } from '../form-input/form-input.component';

@Component({
  selector: 'mwe-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EmailConfirmationComponent,
      multi: true,
    },
  ],
})
export class EmailConfirmationComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() isLoading = false;
  @Input() allFormInputs: IFormInput[];

  @Output() setControlVisibility = new EventEmitter<{ controlName: string; isVisible: boolean }>();

  @ViewChild('email', { static: true })
  emailFormInputComponent: FormInputComponent;

  @ViewChild('emailConfirmation', { static: true })
  emailConfirmationFormInputComponent: FormInputComponent;

  emailInput: IFormInput;
  emailConfirmationInput: IFormInput;
  emailFormControlDirective: FormControlDirective;
  referenceEmail: string;

  get emailConfirmationFormControlDirective(): FormControlDirective {
    return this.emailConfirmationFormInputComponent?.formControlDirective;
  }

  private subscriptions = new Subscription();

  constructor(private accountLogic: AccountLogic) {}

  ngOnInit(): void {
    this.emailInput = this.allFormInputs.find(input => input.componentType === 'emailToConfirm');
    this.emailConfirmationInput = this.allFormInputs.find(input => input.componentType === 'emailConfirmationField');
    this.emailFormControlDirective = this.emailFormInputComponent.formControlDirective;
    this.referenceEmail =
      this.emailInput.emailConfirmationOptions?.referenceEmailIsInitialValue && typeof this.emailInput.initialValue === 'string'
        ? (this.emailInput.initialValue as string)
        : this.accountLogic.getAccountEmail();
  }

  ngAfterViewInit(): void {
    this.observeChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private observeChanges(): void {
    const valueChangesSub = this.emailFormControlDirective.valueChanges.subscribe(_ => {
      const pdfInvoiceMailIsDifferent = this.emailFormControlDirective.value !== this.referenceEmail; // this.accountLogic.getAccountEmail();
      const isVisible = pdfInvoiceMailIsDifferent && this.emailFormControlDirective.valid;

      this.setControlVisibility.emit({ controlName: this.emailConfirmationInput.name, isVisible });
    });
    this.subscriptions.add(valueChangesSub);
  }

  clearInput(): void {
    this.emailFormControlDirective.valueAccessor.writeValue('');
    this.emailConfirmationFormControlDirective.valueAccessor.writeValue('');
  }

  registerOnTouched(fn: any): void {
    this.emailFormControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.emailFormControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.emailFormControlDirective.valueAccessor.writeValue(obj);
    this.emailConfirmationFormControlDirective.valueAccessor.writeValue('');
  }

  setDisabledState(isDisabled: boolean): void {
    this.emailFormControlDirective.valueAccessor.setDisabledState(isDisabled);
    this.emailConfirmationFormControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  isConfirmationErrorVisible(): boolean {
    if (!this.emailConfirmationFormControlDirective.errors) {
      return false;
    }

    const isOtherError = this.emailConfirmationFormControlDirective.errors['partlyEqual'] === undefined;
    const isPartlyEqualError = !!this.emailConfirmationFormControlDirective.errors['partlyEqual'];
    const canShowPartlyEqualError = this.emailConfirmationInput.emailConfirmationOptions?.isPartlyEqualErrorVisible;

    return isOtherError || (isPartlyEqualError && canShowPartlyEqualError);
  }
}
