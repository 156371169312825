import { Component, Input, ViewChild } from '@angular/core';
import { FormControlDirective, UntypedFormGroup } from '@angular/forms';
import { IFormInput } from '@mwe/models';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { convertDateToDisplayFormat, convertDateToISOFormat, isFormErrorVisible, isMobile } from '@mwe/utils';
import { MweDatepickerComponent } from '../../../datepicker/datepicker.component';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';

@Component({
  selector: 'mwe-date-input',
  templateUrl: './date-input.component.html',
})
export class DateInputComponent {
  @Input() parentForm: UntypedFormGroup;
  @Input() inputElem: IFormInput;
  @Input() isLoading = false;
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  private datePickerSubscriptions: Subscription;

  constructor(private modalService: NgbModal) {}

  get isValid(): any {
    return this.formControlDirective?.control?.valid;
  }

  openDatepicker(dateInput: any, datepickerConfig: any): void {
    if (convertDateToISOFormat(dateInput.value, datepickerConfig) !== dateInput.value) {
      datepickerConfig.currentValue = convertDateToISOFormat(dateInput.value, datepickerConfig);
    }
    const modalOptions: NgbModalOptions = {
      backdrop: true,
      keyboard: false,
      centered: true,
    };

    if (isMobile()) {
      modalOptions.windowClass = `animated slideInUp faster`;
    }

    const popupRef = this.modalService.open(MweDatepickerComponent, modalOptions);
    const dpComponent = popupRef.componentInstance as MweDatepickerComponent;

    dpComponent.datepickerConfig = datepickerConfig;
    const onSubmitSubscription = dpComponent.onSubmit.subscribe(e => {
      this.closeDatepickerPopup(e, popupRef, dateInput, datepickerConfig);
    });
    const onResetSubscription = dpComponent.onReset.subscribe(() => {
      this.closeDatepickerPopup(null, popupRef, dateInput, null);
    });

    this.datePickerSubscriptions = new Subscription();
    this.datePickerSubscriptions.add(onSubmitSubscription);
    this.datePickerSubscriptions.add(onResetSubscription);
  }

  closeDatepickerPopup(date: dayjs.Dayjs, popupRef: NgbModalRef, dateInput: any, datepickerConfig: any): void {
    if (date) {
      const isoDate = date.format('YYYY-MM-DD');
      const displayDate = convertDateToDisplayFormat(isoDate, datepickerConfig);
      this.parentForm.controls[dateInput.name].patchValue(displayDate);
    }

    popupRef.close();
    this.datePickerSubscriptions?.unsubscribe();
  }

  get ariaDescribedBy(): string | undefined {
    const control = this.parentForm.get(this.inputElem.name);
    const hasError = isFormErrorVisible(this.inputElem, control);

    if (!!this.inputElem.helpLabelKey || hasError) {
      return `form-input-desc--${this.inputElem.name}`;
    }
    return undefined;
  }
}
