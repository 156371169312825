<mwe-address [address]="address"></mwe-address>

@if (isCollapsable) {
  <mwe-collapse
    [collapseId]="collapseId"
    titleMessageKey="summary.whatNext"
    [initialState]="initialState"
    [isCollapseHeaderVisible]="isCollapseHeaderVisible"
    [showBoxStyle]="false"
    [contentClass]="'pt-4'"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mwe-collapse>
} @else {
  <div class="mt-4">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
}

<ng-template #content>
  @if (stromOrGasProducts?.length || selectedTariffs?.length) {
    <div data-test="products-summary-strom-gas-container">
      <h4 [translate]="registerStromGasTitle"></h4>
      @if (registerStromGasText) {
        <p
          class="text--big"
          data-test="products-summary-strom-gas-register-text"
          [innerHTML]="registerStromGasText | translate: { registrationAt: registrationAt }"
        ></p>
      }
      <div class="row" data-test="products-summary-strom-gas-products">
        @if (selectedTariffs?.length) {
          <mwe-tariff-selection-container [selectedTariffs]="selectedTariffs" [isReadOnly]="true" class="d-block mb-4" />
        } @else {
          <div *ngFor="let product of stromOrGasProducts" class="col-12 col-lg-6 mb-4">
            <mwe-basic-card>
              <mwe-wizard-product-card
                [product]="product"
                [showTariffDetailsLink]="showTariffDetailsLink"
                [showTariffDetails]="showStromGasTarifDetails && showTariffDetails"
                [isAvailable]="true"
                [tariffClasses]="product.tariffClasses"
                [tarifKey]="product.tarifKey"
                [productSelection]="productSelection"
                [grundpreisLabelOverwrite]="grundpreisLabelOverwrite"
                [attr.data-test]="'products-summary-card-' + product.category?.toLowerCase()"
              ></mwe-wizard-product-card>
            </mwe-basic-card>
          </div>
        }
      </div>
      @if ((firstname && lastname) || businessData) {
        <div class="mb-4">
          <mwe-basic-card>
            <h6 [translate]="customerDataType !== 'Businesskunde' ? 'summary.personalData' : 'summary.businessData'"></h6>
            <div class="row w-100">
              <div class="col-12">
                <ul class="list-separated spacing-4" data-test="products-summary-customerData">
                  <li data-test="products-summary-customerData-name">
                    <p class="text--big mb-0">
                      <strong><span translate="summary.customerData"></span></strong><br />
                      <span *ngIf="!!customerDataType && !isKundeWerden">{{ customerDataType }} <br /></span>
                      <ng-container *ngIf="customerDataType === 'Businesskunde'">
                        <span *ngIf="businessData?.companyName">
                          <span translate="newClient.summary.companyName"></span>: {{ businessData.companyName }}
                        </span>
                        <span *ngIf="businessData?.uidNumber">
                          <br /><span translate="newClient.summary.uidNumber"></span>: {{ businessData.uidNumber }}
                        </span>
                        <span *ngIf="businessData?.corporateRegisterNumber">
                          <br /><span translate="newClient.summary.corporateRegisterNumber"></span>:
                          {{ businessData.corporateRegisterNumber }}
                        </span>
                      </ng-container>
                      <span data-test="products-summary-customer-name">
                        {{ salutation ? salutation + ' ' : '' }}{{ title ? title + ' ' : '' }}{{ firstname }} {{ lastname }}
                      </span>
                      <span
                        *ngIf="!customerDataType || (!!customerDataType && customerDataType === 'Privatkunde')"
                        data-test="products-summary-customer-birthdate"
                      >
                        <br />{{ birthDate }}</span
                      >

                      <br *ngIf="telefonNumber" />
                      <span *ngIf="telefonNumber" data-test="products-summary-customer-phonenumber">
                        {{ telefonNumber }}
                      </span>
                      <br />
                      <span data-test="products-summary-customer-email">
                        {{ email }}
                      </span>
                      <ng-content select="[slot='products-summary-customerData-name-additionalContent']"></ng-content>
                    </p>
                  </li>
                  <li *ngIf="idCard && idCard.art === 'Ausweis'" data-test="products-summary-customerData-idCard">
                    <p class="text--big mb-0">
                      <strong><span translate="summary.idData"></span></strong>
                      <br />
                      {{ idCard.type }}, {{ idCard.number }}, {{ idCard.issuer }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </mwe-basic-card>
        </div>
      }
      <div class="mb-4">
        <mwe-basic-card>
          <mwe-products-summary-invoice-old
            [isPaymentInfoVisible]="isPaymentInfoVisible"
            [personalBillingTitle]="personalBillingTitle"
            [eRechnungInfo]="eRechnungInfo"
            [paymentIcon]="paymentIcon"
            [paymentText]="paymentText"
            [formattedAddress]="formattedAddress"
            [contactDataEmailAddress]="contactDataEmailAddress"
            [additionalBulletPoints]="additionalBulletPointsStromGas"
            [areBankAccountInfoTextsVisible]="areBankAccountInfoTextsVisible"
            [productCategories]="productCategories"
            [invoiceInfoAlertMessage]="invoiceInfoAlertMessage"
            [infoTextCardLabel]="infoTextCardLabel"
          ></mwe-products-summary-invoice-old>
        </mwe-basic-card>
      </div>
      <ng-content select=".after-invoice"></ng-content>
      <div class="mb-4" *ngIf="showMeterReading || voucherInfo">
        <mwe-basic-card>
          <h6 translate="summary.additionalDetails"></h6>
          <div class="row w-100">
            <div class="col-12">
              <ul class="list-separated spacing-4" data-test="products-summary-additionalDetails">
                <li *ngIf="showMeterReading" data-test="products-summary-additionalDetails-showMeterReading">
                  <p class="text--big mb-0">
                    <strong><span translate="summary.meterReading.title"></span></strong><br />
                    <span translate="summary.meterReading.description"></span>
                  </p>
                </li>
                <li *ngIf="voucherInfo" data-test="products-summary-additionalDetails-voucher">
                  <p class="text--big mb-0">
                    <strong
                      ><span
                        translate="summary.voucherInfo.title"
                        [translateParams]="{ voucherCode: voucherInfo.aktionscode }"
                      ></span></strong
                    ><br />
                    <span *ngIf="voucherStrom?.length > 0" [innerHTML]="voucherStrom + '<br/>'"></span>
                    <span *ngIf="voucherGas?.length > 0" [innerHTML]="voucherGas + '<br/>'"></span>
                  </p>
                </li>
                <li *ngIf="mgmCode" data-test="products-summary-additionalDetails-mgmCode">
                  <p class="text--big mb-0">
                    <strong><span translate="newClient.mgm.summaryTitle"></span></strong><br />
                    <span> {{ mgmCode }}</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </mwe-basic-card>
      </div>
      <div class="w-100 mt-4 mb-4" *ngIf="registerStromGasInfoMessage">
        <mwe-alert type="info" [messageKey]="registerStromGasInfoMessage"></mwe-alert>
      </div>
    </div>
  }

  @if (fernwaermeProducts?.length) {
    <div data-test="products-summary-fernwaerme-container">
      @if (stromOrGasProducts?.length) {
        <hr />
      }
      <h6 translate="summary.productsFromWE"></h6>
      <p class="text--big" [translate]="registerFernwaermeSubTitle"></p>
      <div class="row">
        @for (product of fernwaermeProducts; track product) {
          <div class="col-12 col-lg-6 mb-4">
            @if (useNewWaermeCards) {
              <mwe-tariff-card-waerme [product]="product"></mwe-tariff-card-waerme>
            } @else {
              <mwe-basic-card>
                <mwe-wizard-product-card
                  [product]="product"
                  [showTariffDetails]="false"
                  [isAvailable]="true"
                  [tariffClasses]="product.tariffClasses?.length > 0 && product.tariffClasses[0] !== '' ? product.tariffClasses : []"
                  [showWaermeTariffClassesAsideOfIcon]="showWaermeTariffClassesAsideOfIcon"
                ></mwe-wizard-product-card>
              </mwe-basic-card>
            }
          </div>
        }
      </div>
      @if (showFernwaermeInvoiceData) {
        <div class="mb-4">
          <mwe-basic-card>
            <mwe-products-summary-invoice-old
              [isPaymentInfoVisible]="isPaymentInfoVisible"
              [personalBillingTitle]="personalBillingTitle"
              [eRechnungInfo]="eRechnungInfo"
              [paymentIcon]="paymentIcon"
              [paymentText]="paymentText"
              [formattedAddress]="formattedAddress"
              [contactDataEmailAddress]="contactDataEmailAddress"
              [additionalBulletPoints]="additionalBulletPointsFernwaerme"
              [areBankAccountInfoTextsVisible]="areBankAccountInfoTextsVisible"
              [productCategories]="productCategories"
            ></mwe-products-summary-invoice-old>
          </mwe-basic-card>
        </div>
      }
      <ng-content select="[sepa-mandat-fw]"></ng-content>
      <div class="w-100 mt-4 mb-7">
        <mwe-alert type="info" [messageKey]="registerFernwaermeText" [placeholderMap]="registerFernwaermeTextPlaceholder"></mwe-alert>
      </div>
    </div>
  }
</ng-template>
