import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountLogic } from '../account/account.logic';
import { NavigationStateService } from '../cache/navigation-state.service';
import { AuthService } from './auth.service';
import { GATEWAY_AUTH_ROUTE, GATEWAY_ROUTE, REQUEST_APPROVAL_CONDITIONS_OF_USE } from '@mwe/constants';
import { LogWienConditionsOfUseService } from '../account/log-wien-conditions-of-use.service';

export const authenticatedAccessService: CanActivateFn = async (_, state) => {
  const router = inject(Router);
  const accountLogic = inject(AccountLogic);
  const authService = inject(AuthService);
  const navigationStateService = inject(NavigationStateService);
  const logwienConditionsOfUseService = inject(LogWienConditionsOfUseService);

  // when user is logged out -> handle deeplink and redirect to login
  if (accountLogic.isLoggedOut()) {
    sessionStorage.clear();
    navigationStateService.setInitialUrl(state.url);
    authService.login();
    return false;
  }

  // in case no consent was given, redirect to consent-page
  const route = await logwienConditionsOfUseService.getRouteForConsentStatus();
  const isGatewayRoute = state.url.includes(`${GATEWAY_ROUTE}/${GATEWAY_AUTH_ROUTE}`);
  const isApprovalRoute = state.url.includes(REQUEST_APPROVAL_CONDITIONS_OF_USE);
  // prevent redirect-loop
  const consentBlacklist = isGatewayRoute || isApprovalRoute;
  if (route && !consentBlacklist) {
    return router.parseUrl(route);
  }

  // everything is fine
  return true;
};
