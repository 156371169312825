import { IAddress } from '../address/address.model';
import { TarifOption } from '../availabilities/availabilities.model';
import { CommonMappingData, IAktionen, IBulletConfirmationStatusChangeResponse, ZusatzInformationen } from '../integrationlayer/api.model';

export type VoucherProzessType = 'Energiegutschein' | 'Tarifwechsel' | 'Kunde werden';

export interface VoucherBodyModel {
  tariffKey: string;
  processCode: string;
  additionalFields: ZusatzInformationen[];
  gewaehlteTarifOptionen?: TarifOption[];
}

export interface EnergyVoucherDTO {
  mappingData: EnergyVoucherMapping[];
  voucher: IAktionen;
  addressCode: string;
  orderedAt?: string;
  id?: number;
  processStatus?: string;
}

export const parseEnergyVoucherDTO = (orderDetail): EnergyVoucherDTO => {
  if (!orderDetail) {
    return null;
  }

  const orderData = JSON.parse(orderDetail.changeData);
  return {
    mappingData: orderData.mappingData,
    addressCode: orderData.addressCode,
    voucher: orderData.voucher,
    orderedAt: orderDetail.createdAt,
    id: orderDetail.id,
    processStatus: orderDetail.processStatus,
  };
};

// tslint:disable-next-line: no-empty-interface
export interface EnergyVoucherMapping extends CommonMappingData {
  address: IAddress;
}

// tslint:disable-next-line:no-empty-interface
export type IEnergyVoucherStatusChangeResponse = IBulletConfirmationStatusChangeResponse;
