import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[mweBlockPasteEvent]',
})
export class BlockPasteEventDirective {
  @Input() mweBlockPasteEvent: boolean;

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    if (this.mweBlockPasteEvent) {
      event.preventDefault();
    }
  }
}
