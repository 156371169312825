import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CategoryIconSrcPipe } from './category/category-icon-src.pipe';
import { CorrelatedCompanyReferencePipe } from './category/correlated-company-reference.pipe';
import { TranslateCategoryPipe } from './category/translate-category.pipe';
import { TranslateTariffClassPipe } from './category/translate-tariff-class.pipe';
import { FormatDatePipe } from './date/format-date.pipe';
import { EuroCurrencyPipe } from './euro-currency.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { PartialPaymentLabelPipe } from './invoice/partial-payment-label.pipe';
import { FindLanguageFromKeyPipe } from './language/find-language-from-key.pipe';
import { TariffClassLineBreakPipe } from './linebreak.pipe';
import { SafeCMSContentPipe } from './safe-cmscontent.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { TariffOptionInfoTextCodePipe } from './tariff/tariff-option-info-text-code.pipe';
import { PersonalNameBirthdayPipe } from './person/personal-name-birthday.pipe';
import { PersonalPhonePipe } from './person/personal-phone.pipe';
import { AddStaticImagePathPipe } from './images/add-static-image-path.pipe';

const pipes = [
  SafeCMSContentPipe,
  EuroCurrencyPipe,
  TranslateCategoryPipe,
  SafeHtmlPipe,
  TariffClassLineBreakPipe,
  SafeUrlPipe,
  CategoryIconSrcPipe,
  FormatDatePipe,
  FormatNumberPipe,
  CorrelatedCompanyReferencePipe,
  TranslateTariffClassPipe,
  PartialPaymentLabelPipe,
  TariffOptionInfoTextCodePipe,
  FindLanguageFromKeyPipe,
  PersonalNameBirthdayPipe,
  PersonalPhonePipe,
  AddStaticImagePathPipe,
];
@NgModule({
  declarations: pipes,
  exports: pipes,
  providers: [CurrencyPipe, DecimalPipe, FormatNumberPipe, EuroCurrencyPipe, PartialPaymentLabelPipe],
})
export class UiPipesModule {}
