import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateInputComponent } from './date-input.component';

@Component({
  selector: 'mwe-datepicker-input',
  templateUrl: './datepicker-input.component.html',
})
export class DatepickerInputComponent extends DateInputComponent {
  constructor(modalService: NgbModal) {
    super(modalService);
  }
}
