import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PRODUCTS_ROUTE } from '@mwe/constants';
import { NavigationLogic } from '@mwe/services';
import { NavigationStateService } from '@mwe/services';

@Component({
  selector: 'mwe-button-back',
  templateUrl: './button-back.component.html',
})
export class ButtonBackComponent implements OnInit {
  @Input() route: string;
  @Input() onlyEmitClick = false;
  @Input() label = 'global.back';
  @Output() backBtnClicked = new EventEmitter<void>();

  private routeFromWizardCancelUrl = false;
  private routeFromNavigateBackList = false;

  constructor(private navigationLogic: NavigationLogic, private navigationStateService: NavigationStateService, private router: Router) {}

  ngOnInit(): void {
    this.setRoute();
  }

  onButtonClicked(): void {
    if (this.onlyEmitClick) {
      this.backBtnClicked.emit();
      return;
    }

    if (this.routeFromWizardCancelUrl) {
      this.navigationStateService.wizardCancelUrl = null;
    } else if (this.routeFromNavigateBackList) {
      this.navigationStateService.removeLatestNavigatingBackTo();
    }

    this.router.navigateByUrl(this.route);
  }

  private setRoute(): void {
    if (this.onlyEmitClick) {
      return;
    }

    this.checkIfNavigationItemCanBeRemovedBecauseOfBrowserBack();

    if (this.route) {
      return;
    }

    if (
      this.navigationStateService.wizardCancelUrl !== '/' &&
      this.navigationStateService.wizardCancelUrl !== this.router.url // check if there was a browser-back
    ) {
      this.routeFromWizardCancelUrl = true;
      this.route = this.navigationStateService.wizardCancelUrl;
      return;
    }

    if (!this.navigationStateService.getLatestNavigatingBackTo()) {
      this.route = '/';
      return;
    }

    this.routeFromNavigateBackList = true;
    this.checkIfNavigationItemCanBeRemovedBecauseOfBrowserBack();
    this.route = this.navigationLogic.getRouteFromLatestNavigatingBackItem();
    this.navigationLogic.checkSpecialRouteHandling();

    if (!this.route) {
      this.route = `/${PRODUCTS_ROUTE}`;
    }
  }

  private checkIfNavigationItemCanBeRemovedBecauseOfBrowserBack(): void {
    if (this.router.url === this.navigationLogic.getRouteFromLatestNavigatingBackItem()) {
      this.navigationStateService.removeLatestNavigatingBackTo();
    }
  }
}
