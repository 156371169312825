import dayjs from 'dayjs';
import {
  CommonMappingData,
  IBankDetails,
  IBulletConfirmationStatusChangeResponse,
  IDifferentInvoiceRecipient,
  IERechnungsInfo,
} from '../integrationlayer/api.model';
import { IOrderDetailsInfo, ORDER_DETAILS_VERSION } from '../order/order.model';

export enum DataChangeType {
  BankAccountChangeOnly = 'BANK_ACCOUNT_CHANGE_ONLY',
  PdfInvoiceChangeOnly = 'E_RECHNUNG_CHANGE_ONLY',
  InvoiceAddressChangeOnly = 'INVOICE_ADDRESS_CHANGE_ONLY',
  BankAccountAndPdfInvoice = 'BANK_ACCOUNT_AND_PDF_INVOICE',
  NewClientKW = 'NC_KW',
  NewClientSS = 'NC_SS',
  SecureOffer = 'TC_O',
}

export interface InvoiceDataChangeDTO {
  mappingData: CommonMappingData[];
  addressCode: string;
  sepaMandat?: IBankDetails;
  eRechnungsInfo?: IERechnungsInfo;
  rechnungsAdresse?: RechnungsAdresse;
  differentInvoiceRecipient?: IDifferentInvoiceRecipient;
  orderedAt?: string;
  id?: number;
  processStatus?: string;
  mandatsReferenzMapping?: MandatsReferenzMappingItem[];
  unsupportedCategories?: string[][];
}

export interface MandatsReferenzMappingItem {
  vertragskontoNummer: string;
  systemId: string;
  mandatsreferenz: string;
}

// tslint:disable-next-line:no-empty-interface
export type IInvoiceDataChangeResponse = IBulletConfirmationStatusChangeResponse;

export const parseInvoiceDataChangeDTO = (orderDetails: any): InvoiceDataChangeDTO[] => {
  const orderDTODetails: InvoiceDataChangeDTO[] = [];
  if (Array.isArray(orderDetails)) {
    orderDetails.forEach(item => {
      const orderDataDTO = parseInvoiceDataChangeItemDTO(item);
      orderDTODetails.push(orderDataDTO);
    });
  }
  return orderDTODetails.sort((a, b) => (dayjs(a.orderedAt).isBefore(dayjs(b.orderedAt)) ? 1 : -1));
};

export const parseInvoiceDataChangeItemDTO = (orderDetail): InvoiceDataChangeDTO => {
  if (!orderDetail) {
    return null;
  }

  const orderData = JSON.parse(orderDetail.changeData);
  return {
    mappingData: orderData.mappingData,
    addressCode: orderData.addressCode,
    sepaMandat: orderData.sepaMandat,
    eRechnungsInfo: orderData.eRechnungsInfo,
    rechnungsAdresse: orderData.rechnungsAdresse,
    differentInvoiceRecipient: orderData.differentInvoiceRecipient,
    orderedAt: orderDetail.createdAt,
    id: orderDetail.id,
    processStatus: orderDetail.processStatus,
    mandatsReferenzMapping: orderDetail.responseData ? JSON.parse(orderDetail.responseData).response : [],
    unsupportedCategories: orderData.unsupportedCategories ?? [],
  };
};

export const getDataTypeForInvoiceDataChangeDTO = (changeData: InvoiceDataChangeDTO): DataChangeType => {
  if (changeData.sepaMandat && changeData.eRechnungsInfo) {
    return DataChangeType.BankAccountAndPdfInvoice;
  } else if (changeData.sepaMandat) {
    return DataChangeType.BankAccountChangeOnly;
  } else if (changeData.eRechnungsInfo) {
    return DataChangeType.PdfInvoiceChangeOnly;
  } else if (changeData.rechnungsAdresse) {
    return DataChangeType.InvoiceAddressChangeOnly;
  }
  return null;
};

export const newInvoiceDataChangeDetailsInfo = (_category: string, _customerNumber: string, _accountNUmber: string): IOrderDetailsInfo => {
  return {
    apiVersion: ORDER_DETAILS_VERSION,
    produktDetails: null,
    invoiceAddress: null,
    eRechnungInfo: null,
    bezahlartIcon: null,
    bezahlartText: null,
    status: 'Bankverbindung',
    category: _category,
    customerNumber: _customerNumber,
    accountNumber: _accountNUmber,
    physischePerson: null,
  };
};

type Adressart = 'Rechnungsadresse' | 'Kontaktadresse' | 'Verbrauchsstellenadresse' | 'Anschlussobjektadresse';

const Adressart = {
  Rechnungsadresse: 'Rechnungsadresse' as Adressart,
  Kontaktadresse: 'Kontaktadresse' as Adressart,
  Verbrauchsstellenadresse: 'Verbrauchsstellenadresse' as Adressart,
  Anschlussobjektadresse: 'Anschlussobjektadresse' as Adressart,
};

export interface RechnungsAdresse {
  adressart?: Adressart;
  adresshinweis?: string;
  postfach?: string;
  hausnummer?: string;
  stiege?: string;
  strasse?: string;
  tuernummer?: string;
  adresszusatz?: string;
  raumnummer?: string;
  stock?: string;
  ortsteil?: string;
  land?: string;
  laendercode?: string;
  plz?: string;
  ort?: string;
  bundesland?: string;
  gis?: string;
  olavGaa?: string; // Gebäude Key
  olavGba?: string; // Gebäude Key Ident. (z.B. Eckhäuser mit 2 Adresse)
  olavAna?: string; // Anlage Key
  olavAaa?: string; // AnlageAdressKey
  istHauptwohnsitz?: boolean;
}
