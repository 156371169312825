<form
  #formComponent="ngForm"
  class="form-horizontal text-start"
  [ngClass]="{ 'form-error': form.invalid && !form.pristine }"
  [formGroup]="form"
  (ngSubmit)="submit(form.value)"
  *ngIf="formModel"
>
  <div class="mb-3" *ngFor="let inputElem of formModel.inputs" [attr.data-test]="inputElem.name + '-group'">
    <ng-container *ngIf="!inputElem.isHidden">
      <div *ngIf="inputElem.inputInfoHTML" [innerHTML]="inputElem.inputInfoHTML | safeCMSContent: 'html'"></div>
      <ng-template [ngIf]="inputElem.hasOwnProperty('initialValue')">
        <ng-container *ngIf="!inputElem.componentType || inputElem.componentType === 'input'">
          <mwe-form-input
            [parentForm]="form"
            [inputElem]="inputElem"
            [isLoading]="isLoading"
            [isInline]="isInline"
            (inputBlurred)="inputBlurred.emit()"
          ></mwe-form-input>
        </ng-container>
        <ng-container *ngIf="inputElem.componentType === 'checkbox'">
          <mwe-checkbox-input [isLoading]="isLoading" [inputElem]="inputElem" [parentForm]="form"></mwe-checkbox-input>
        </ng-container>
        <ng-container *ngIf="inputElem.componentType === 'radio'">
          <mwe-radio-input [isLoading]="isLoading" [inputElem]="inputElem" [parentForm]="form"></mwe-radio-input>
        </ng-container>
        <mwe-typeahead-input
          *ngIf="inputElem.componentType === 'typeahead'"
          [formControlName]="inputElem.name"
          [formInputOptions]="inputElem"
          [isLoading]="isLoading"
        ></mwe-typeahead-input>
        <ng-container *ngIf="inputElem.componentType === 'emailToConfirm'">
          <mwe-email-confirmation
            [isLoading]="isLoading"
            [allFormInputs]="formModel.inputs"
            [parentForm]="form"
            (setControlVisibility)="setControlVisibility($event.controlName, $event.isVisible)"
          ></mwe-email-confirmation>
        </ng-container>

        <ng-container *ngIf="inputElem.componentType === 'date'">
          <mwe-date-input [parentForm]="form" [inputElem]="inputElem" [isLoading]="isLoading"></mwe-date-input>
        </ng-container>

        <ng-container *ngIf="inputElem.componentType === 'birthdate'">
          <mwe-birthdate-input [parentForm]="form" [inputElem]="inputElem"></mwe-birthdate-input>
        </ng-container>

        <ng-container *ngIf="inputElem.componentType === 'datepicker'">
          <mwe-datepicker-input [parentForm]="form" [inputElem]="inputElem" [isLoading]="isLoading"></mwe-datepicker-input>
        </ng-container>

        <mwe-form-select
          *ngIf="inputElem.componentType === 'select'"
          [inputElem]="inputElem"
          [parentForm]="form"
          [isLoading]="isLoading"
          [selectedValue]="selectedValue"
          (selectChanged)="onSelectChange($event.option, $event.name)"
        ></mwe-form-select>

        <mwe-form-input-error
          *ngIf="!isInputEmailConfirmation(inputElem.componentType)"
          [parentForm]="form"
          [inputElem]="inputElem"
          [errorId]="inputElem.uuid"
        ></mwe-form-input-error>
      </ng-template>
      <ng-template [ngIf]="inputElem.hasOwnProperty('inputs')">
        <div class="row" [class]="inputElem.rowClass" [ngClass]="{ 'gx-3': !inputElem.rowClass }">
          <div *ngFor="let inputElemInner of inputElem.inputs" [class]="inputElemInner.inputClasses">
            <ng-container *ngIf="!inputElemInner.isHidden">
              <ng-container *ngIf="!inputElemInner.componentType || inputElemInner.componentType === 'input'">
                <mwe-form-input
                  [parentForm]="form"
                  [inputElem]="inputElemInner"
                  [isLoading]="isLoading"
                  [isInline]="isInline"
                  (inputBlurred)="inputBlurred.emit()"
                ></mwe-form-input>
              </ng-container>

              <ng-container *ngIf="inputElemInner.componentType === 'checkbox'">
                <mwe-checkbox-input [isLoading]="isLoading" [inputElem]="inputElemInner" [parentForm]="form"></mwe-checkbox-input>
              </ng-container>

              <ng-container *ngIf="inputElemInner.componentType === 'radio'">
                <mwe-radio-input [isLoading]="isLoading" [inputElem]="inputElemInner" [parentForm]="form"></mwe-radio-input>
              </ng-container>

              <mwe-typeahead-input
                *ngIf="inputElemInner.componentType === 'typeahead'"
                [formControlName]="inputElemInner.name"
                [formInputOptions]="inputElemInner"
                [isLoading]="isLoading"
              ></mwe-typeahead-input>

              <mwe-form-select
                *ngIf="inputElemInner.componentType === 'select'"
                [inputElem]="inputElemInner"
                [parentForm]="form"
                [isLoading]="isLoading"
                [selectedValue]="selectedValue"
                (selectChanged)="onSelectChange($event.option, $event.name)"
              ></mwe-form-select>

              <mwe-form-input-error [parentForm]="form" [inputElem]="inputElemInner"></mwe-form-input-error>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <div
    id="form-buttons"
    class="mt-5 submit text-end form-buttons"
    *ngIf="formModel.isResetButtonVisible || formModel.isSubmitButtonVisible"
  >
    <mwe-button
      buttonId="reset"
      [labelKey]="formModel.resetLabelKey"
      [disabled]="isLoading || form.pristine"
      (clicked)="reset()"
      *ngIf="formModel.isResetButtonVisible"
    ></mwe-button>
    <mwe-button
      buttonId="mwe-form-submit"
      [showHoverEffect]="true"
      [labelKey]="formModel.submitLabelKey"
      buttonType="submit"
      [showSpinner]="isLoading"
      [disabled]="isLoading || form.invalid"
      *ngIf="formModel.isSubmitButtonVisible"
    ></mwe-button>
  </div>
</form>
