import { TranslateService } from '@ngx-translate/core';
import { IProduktAuswahl } from '../new-client/new-client.model';
import { AvailabilityTarif, TarifOption } from '../availabilities/availabilities.model';

import { ProductCategoryEnum, productCategoryEnumToName } from '@mwe/constants';
import { IILError } from './il-response.model';
import { IBusinessData, IPersonalData } from '../order/order.model';
import { ICustomerAdressDetails, IGeschaeftsPartner, IPhysischePerson } from './customer.model';
import { Address, ISolrOlavKeyAddress } from '../address/address.model';
import { lastValueFrom } from 'rxjs';

export const ZAHLUNGSMETHODE_BANKEINZUG = 'Bankeinzug';
export const ZAHLUNGSMETHODE_ZAHLSCHEIN = 'Zahlschein';

export const RECHNUNGSVERSAND_POST = 'Post';
export const RECHNUNGSVERSAND_ERECHNUNG = 'eRechnung';

export const VERKNUEPFUNGSSTATUS_MANUELL = 'manuell_bestaetigt';
export const VERKNUEPFUNGSSTATUS_ADMIN = 'admin_bestaetigt';
export const VERKNUEPFUNGSSTATUS_VORSCHLAG_BESTAETIGT = 'vorschlag_bestaetigt';
export const VERKNUEPFUNGSSTATUS_VORSCHLAG_ABGELEHNT = 'vorschlag_abgelehnt';

export interface IVerknuepfungenResponse {
  verknuepfungen: IVerknuepfungen[];
  error?: IILError;
}

export interface IVerknuepfungen {
  userId: string;
  geschaeftsPartnerId: string;
  verknuepfungsStatus: string;
  systemId: string;
  produkte: IVerknuepfungenProdukte[];
  createdAt: Date;
  lastUpdatedAt: Date;
  lastUpdatedBy: string;
  kundenTyp: string;

  isValidLink?: boolean;
  hasActiveProducts?: boolean;
}

export interface IVerknuepfungenProdukte {
  sparte: string;
  geschaeftsPartnerId: string;
  verrechnungskontoId: string;
  vertragsNummer: string;
  vertragsStatus: string;
  additionalData: IVerknuepfungenAdditionalData;
  createdAt?: Date;
  lastUpdatedAt?: Date;
  lastUpdatedBy?: string;
}

export interface IVerknuepfungenAdditionalData {
  adressDetails: IVerknuepfungenAdditionalDataAdressDetails;
  produktDetails: IVerknuepfungenAdditionalDataProduktDetails[];
}

export interface IVerknuepfungenAdditionalDataAdressDetails {
  ort: string;
  strasse: string;
  tuernummer: string;
  hausnummer: string;
  land: string;
  stiege: string;
  plz: string;
  olavGaa: string;
  olavGba: string;
  olavAna: string;
  olavAaa: string;
}

export interface IVerknuepfungenAdditionalDataProduktDetails {
  anlageId: string;
  anlagentyp: Sparte;
  kartenNummer?: string;
  ISUTarifKey?: string;
  tarifName: string;
}

export interface IPartner {
  adresse?: IILAdresse;
  partnerId: string;
  partnerGruppe: string;
  nickname: string;
  registrierungStatus: string;
  rolle: string;
  partnerTyp: string;
  anrede: string;
  titel: string;
  titelKurz?: string;
  nachname: string;
  vorname: string;
  firma: string;
  email: string;
  telefon: string;
  telefonvorwahl: string;
}
export interface ICustomerDetails {
  anrede?: string;
  titel: string;
  titelKurz?: string;
  nachname: string;
  vorname: string;
  geburtsDatum: string;
  geburtsOrt: string;
  sterbeDatum: string;
  persNo: string;
}

interface IAnlage {
  anlageId: string;
  tariftypbezeichnung: string;
  tarifname: string;
  tarifbeschreibung: string;
  anlagentyp: string;
  zaehlpunkt: string;
}

export interface IVertragskonto {
  vertragskontoId: string;
  zahlungsmethode: string;
  kontonummer: string;
  blz: string;
  iban: string;
  bic?: string;
  anlagen?: IAnlage;
}
export interface IILAdresse {
  ort: string;
  plz: string;
  strasse: string;
  hausnummer: string;
  stiege?: string;
  tuernummer?: string;
  raum: string;
  adresshinweis: string;
  land: string;
  olavGaa?: string;
  olavAaa?: string;
}

export interface IRechnungsempfaenger {
  partnerId: string;
  partnerGruppe: string;
  nickname: string;
  registrierungStatus: string;
  rolle: string;
  partnerTyp: string;
  titel: string;
  nachname: string;
  vorname: string;
  firma: string;
  email: string;
  telefon: string;
  telefonvorwahl: string;
  adresse: IILAdresse;
  anrede?: string;
}

export interface IAnschlussobjekt {
  adresse: IILAdresse;
  verbrauchsstelle: string;
}

export interface IAblesung {
  ableseFirma: string;
  ableseDatum: string;
  ablesungVon: string;
  ablesungBis: string;
}

export interface IVerbrauch {
  rechnungsnummer: string;
  rechnungsnummerKurz: string;
  versorgungseinheit: string;
  periodeAb: string;
  periodeBis: string;
  verbauch: number;
  einheit: string;
}

export interface ITeilbetragsplan {
  periodeAb: string;
  periodBis: string;
  monatAb: string;
  monatBis: string;
  faelligkeitsdatum: string;
  gesamtbetrag: number;
  abgeschlossen: boolean;
}

export interface IRechnung {
  rechnungsnummer: string;
  partnerGruppe: string;
  belegdatum: string;
  rechnungsbetrag: number;
  faelligkeitsdatum: string;
  rechnungsgrund: string;
  buchungsdatum: string;
  druckdatum: string;
  storniert: boolean;
  rechnungsPdfGroesse: number;
}

export interface IKontostand {
  saldoForderung: boolean;
  teilbetragsplan: boolean;
}

export interface IProduktDetails {
  partner: IPartner;
  vertragskonto: IVertragskonto;
  anschlussobjekt: IAnschlussobjekt;
  rechnungsempfaenger: IRechnungsempfaenger;
  kontostand: IKontostand;
  ablesung: IAblesung;
  verbraeuche: IVerbrauch[];
  teilbetragsplaene: ITeilbetragsplan[];
  rechnungen: IRechnung[];
}

export interface IVerfuegbarkeiten {
  verfuegbarkeiten: IProduktVerfuegbarkeit[];
}

export interface IProduktVerfuegbarkeit {
  sparte: string;
  verfuegbar: string;
  status?: string;
  details?: IProduktDetailsVerfuegbarkeit[];
  multiProductCardCategory?: string;
}

export interface IProduktDetailsVerfuegbarkeit {
  anlagentyp?: string;
  lieferkomponente?: string;
  systeminformationen?: string;
  zaehlpunktnummer?: string;
  verbrauchsstelleId?: string;
  zaehlerNummer?: string;
  lastprofil?: string;
  nutzungsObjektId?: string;
  tkconnid?: string;
  vertragsBeginn?: string;
  vertragsEnde?: string;
  smartMeter?: string;
  adresse?: IILAdresse;
}

export interface IERechnungsInfo {
  isERechnungsEmpfaenger: boolean;
  rechnungEmail: string;
}

export interface ITarif {
  typ?: string;
  key?: string;
  shortcut?: string;
  name: string;
}

export interface IAktionen {
  aktionscode: string;
  aktionsCodeArt: string;
  prozesstyp: string;
  infoText: Array<string>;
  infoTextKey: Array<string>;
  nennwert?: string;
  zusatzInformationen?: Array<ZusatzInformationen>;
  commonMappingData?: CommonMappingData[];
  errorTitle?: string;
  errorMsg?: string;
}

// generated with swagger from coressponding IL WE_KUNDEWERDEN_API start
export interface IBankDetails {
  inhaber?: string;
  iban?: string;
  bankdaten?: {
    bankleitzahl: string;
    bankname: string;
    bic: string;
    land: string;
  };
}

export interface Tarife {
  tarifKeyFilter?: string;
  infotext?: string;
  beschreibung?: string;
}

export interface Prozesstypen {
  aktionProzessTypZuordnungsBeschreibung?: string;
  name?: string;
  beschreibung?: string;
}

export interface Aktion {
  aktionsArtBeschreibung?: string;
  zulaessigeTarife?: Array<Tarife>;
  infotext?: string;
  name?: string;
  gueltigAb?: Date;
  gueltigBis?: Date;
  aktionsArt?: string;
  beschreibung?: string;
  aktiv?: boolean;
  zulaessigeProzessTypen?: Array<Prozesstypen>;
}

export interface Aktionscode {
  aktionsCodeArtBeschreibung?: string;
  code?: string;
  manuellDeaktiviert?: string;
  aktionsCodeArt?: string;
  id?: string;
  maxVerwendbar?: number;
  aktion?: Aktion;
  referenz?: string;
  nennwert?: string;
}

export interface PruefDetails {
  datum?: Date;
  prozessCode?: string;
  zusatzInformationen?: Array<ZusatzInformationen>;
  tarifTyp?: string;
}

export interface ZusatzInformationen {
  wert?: string;
  schluessel?: string;
  feld?: string;
}

export interface GueltigleitsabfrageRequestBody {
  datum: Date;
  prozessCode: string;
  zusatzInformationen: ZusatzInformationen[];
  tarifTyp: string;
}

export interface GueltigkeitsDetails {
  gueltig: boolean;
  infoText?: string;
  nennwert?: string;
}

export interface TitelListeTitel {
  titleKey?: string;
  titleText?: string;
  titleDesc?: string;
}

export interface TitelListe {
  titelListe: Array<TitelListeTitel>;
}

export interface IBulletConfirmationStatusChangeResponse {
  submitId: number;
  status: string;
  retryAllowed: boolean;
  errorCode: number;
  responseData?: string;
}

export interface CommonMappingData {
  customerNumber: string;
  accountNumber: string;
  businessPartnerNumber?: string;
  categories?: string[];
  anlageIds?: string[];
  validVoucherAnlageId?: string;
  tariffKeys?: string[];
  isuTariffKeys?: string[];
  tariffClasses?: string[][];
  tarifOptionen?: TarifOption[];
  personalData?: IPersonalData;
  businessData?: IBusinessData;
  invoiceAddress?: ICustomerAdressDetails;
  changeERechnung?: boolean;
  changeSepaMandat?: boolean;
  changeRechnungsAdresse?: boolean;
  orderIdx?: number;
  orderIndexAlias?: string;
}
export interface IDifferentInvoiceRecipient {
  physischePerson: IPhysischePerson;
  invoiceAddress: ICustomerAdressDetails;
}

export interface IProduktAuswahlDetails {
  sparte: string;
  tarifBeschreibung?: string;
  tarifKey?: string;
  onlineTarif: boolean;
  kundenType: string;
  bedingungen?: IProduktBedingungen;
  anlageId?: string;
  details?: IProduktDetailsVerfuegbarkeit[];
  accountId?: string;
  customerNumber?: string;
  tarif?: AvailabilityTarif;
}

export interface IProduktBedingungen {
  sepaPflicht: boolean;
  eRechnungsPflicht: boolean;
}

export interface IProduktSelektion {
  strom?: IProduktAuswahlDetails;
  gas?: IProduktAuswahlDetails;
  emobility?: IProduktAuswahlDetails;
  internet?: IProduktAuswahlDetails;
  iptv?: IProduktAuswahlDetails;
  voip?: IProduktAuswahlDetails;
  anlagen?: IProduktAuswahlDetails[];
  commonMapping?: CommonMappingData[];
}

export type ILSystem = 'ISX100' | 'SGX100' | 'SGX200' | 'C2X200';

export const ILSystem = {
  ISX100: 'ISX100' as ILSystem,
  SGX100: 'SGX100' as ILSystem,
  SGX200: 'SGX200' as ILSystem,
  C2X200: 'C2X200' as ILSystem,
};

export type KontaktGrund = 'Hauptkontakt anlegen' | 'Aufgabe anlegen' | 'Beschwerde anlegen';
export const KontaktGrund = {
  HauptkontaktAnlegen: 'Hauptkontakt anlegen' as KontaktGrund,
  AufgabeAnlegen: 'Aufgabe anlegen' as KontaktGrund,
  BeschwerdeAnlegen: 'Beschwerde anlegen' as KontaktGrund,
};

export type Sparte =
  | 'Strom'
  | 'Gas'
  | 'StromGas'
  | 'Fernwaerme'
  | 'Internet'
  | 'Iptv'
  | 'Emobility'
  | 'Stromrueck'
  | 'Voip'
  | 'Netz'
  | 'Nachtstrom'
  | 'Mobiltelefonie'
  | 'HEIZUNG'
  | 'WARMWASSER'
  | 'HEIZUNG_WARMWASSER'
  | 'KALT-/ABWASSER';

export const Sparte = {
  Strom: 'Strom' as Sparte,
  Gas: 'Gas' as Sparte,
  StromGas: 'StromGas' as Sparte,
  Fernwaerme: 'Fernwaerme' as Sparte,
  Internet: 'Internet' as Sparte,
  Iptv: 'Iptv' as Sparte,
  Emobility: 'Emobility' as Sparte,
  Stromrueck: 'Stromrueck' as Sparte,
  Voip: 'Voip' as Sparte,
  Netz: 'Netz' as Sparte,
  Nachtstrom: 'Nachtstrom' as Sparte,
  Mobiltelefonie: 'Mobiltelefonie' as Sparte,
  HEIZUNG: 'HEIZUNG' as Sparte,
  WARMWASSER: 'WARMWASSER' as Sparte,
  'KALT-/ABWASSER': 'KALT-/ABWASSER' as Sparte,
};

export interface PredefinedTariffSelectionData {
  tariffKey: string;
  sapOptionIds?: string[];
}

export const getSparteForProductCategoryEnum = (category: ProductCategoryEnum): Sparte => {
  return getSparte(productCategoryEnumToName(category));
};

export const getSparte = (category: string): Sparte => {
  return Sparte[category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()];
};

export const parseILAddress = (addressIn?: IILAdresse): Address => {
  return new Address(
    null,
    addressIn.strasse,
    addressIn.hausnummer,
    addressIn.stiege ?? null,
    addressIn.raum || addressIn.tuernummer,
    addressIn.adresshinweis,
    addressIn.plz,
    addressIn.ort,
    addressIn.land,
  );
};

export const parsePartnerDetails = (json?: any): IGeschaeftsPartner => {
  return json.geschaeftspartner[0] as IGeschaeftsPartner;
};

export const parseBankDetails = (json?: any): IBankDetails => {
  return json as IBankDetails;
};

export const parseERechnungsInfo = (json?: any): IERechnungsInfo => {
  return json as IERechnungsInfo;
};

export const prettyPrintIBAN = (iban: string): string => {
  return iban.replace(/\s/g, '').replace(/([A-Za-z0-9*]{4})(?!$)/g, '$1 ');
};

export const obfuscateIBAN = (iban: string): string => {
  return prettyPrintIBAN(onlyObfuscateIBAN(iban));
};

export const prettyPrintMeterPointNumber = (meterPointNumber: string): string => {
  return meterPointNumber?.replace(/\s/g, '').replace(/([A-Z]{2})([0-9]{6})([0-9]{5})([A-Za-z0-9]+)/g, '$1 $2 $3 $4');
};

export const onlyObfuscateIBAN = (iban: string): string => {
  return iban.substring(0, 2) + iban.replace(/.(?=.{4})/g, '*').substring(2);
};

export const parseProductsForSelection = (json?: any): IProduktAuswahl => {
  return json as IProduktAuswahl;
};

export const parseAktionscode = (json?: any): Aktionscode => {
  return json as Aktionscode;
};

export const parsePruefDetails = (json?: any): PruefDetails => {
  return json as PruefDetails;
};

export const parseGueltigkeitsDetails = (json?: any): GueltigkeitsDetails => {
  return json as GueltigkeitsDetails;
};

export const parseTitelListe = (json?: any): TitelListe => {
  return json as TitelListe;
};

export const parseOlavKeyAddress = (json?: any): ISolrOlavKeyAddress => {
  return json.response as ISolrOlavKeyAddress;
};

export const translateTariffClasses = (
  category: string,
  tariffClasses: string[],
  translateService: TranslateService,
): Promise<string[]> => {
  if (!tariffClasses || tariffClasses.length === 0) {
    return Promise.resolve([]);
  }

  if (
    [
      ProductCategoryEnum.STROM,
      ProductCategoryEnum.NACHTSTROM,
      ProductCategoryEnum.GAS,
      ProductCategoryEnum.STROMRUECK,
      ProductCategoryEnum.EMOBILITY,
      ProductCategoryEnum.INTERNET,
      ProductCategoryEnum.IPTV,
      ProductCategoryEnum.VOIP,
    ].includes(ProductCategoryEnum[category.toUpperCase()])
  ) {
    return Promise.resolve(tariffClasses);
  } else if ([ProductCategoryEnum.FERNWAERME].includes(ProductCategoryEnum[category.toUpperCase()])) {
    // because of lieferkomponente 'HEIZUNG + WARMWASSER'
    if (tariffClasses.includes('HEIZUNG + WARMWASSER')) {
      tariffClasses.splice(tariffClasses.indexOf('HEIZUNG + WARMWASSER'), 1);
      tariffClasses.push('HEIZUNG');
      tariffClasses.push('WARMWASSER');
    }
    tariffClasses.sort((a, b) => sortFernwaermeTariffClasses(a, b));
    return Promise.all(
      tariffClasses.map(elem => {
        return lastValueFrom(translateService.get(`products.category.details.${elem.toLowerCase()}`));
      }),
    );
  }
  return Promise.resolve([]);
};

export const sortFernwaermeTariffClasses = (a: string, b: string): number => {
  const tariffClassOrder = ['HEIZUNG', 'WARMWASSER', 'HEIZUNG_WARMWASSER', 'KALT_ABWASSER', 'KALTWASSER/ABWASSER'];
  return tariffClassOrder.indexOf(a) - tariffClassOrder.indexOf(b);
};

export const getBankDetailsForVertrakskonto = (vertragskonto: IVertragskonto): IBankDetails => {
  return {
    iban: vertragskonto.iban,
    bankdaten: {
      bankleitzahl: vertragskonto.blz,
      bankname: null,
      bic: vertragskonto.bic,
      land: null,
    },
  };
};
