import { IBulletConfirmationStatusChangeResponse } from '../integrationlayer/api.model';
import { IGeschaeftspartnerBeziehung, IKontaktDetails } from '../integrationlayer/customer.model';
import { IBusinessData, IPersonalData } from '../order/order.model';

export interface ICustomerNumberInfo {
  firstName: string;
  lastName: string;
  productCategories: string[]; // translated
  rawProductCategories: string[];
  customerNumber: string;
  salutation?: string;
  birthDate?: string;
  systemId?: string;
  accountNumber?: string;
  businessPartnerNumber?: string;
  geschaeftsPartner?: IGeschaeftspartnerBeziehung;
}

export interface CustomerDataChangeDTO {
  customerData: ICustomerNumberInfo;
  kontaktdatenDetails: IKontaktDetails;
  personalData?: IPersonalData;
  businessData?: IBusinessData;
  orderedAt?: string;
}

// tslint:disable-next-line:no-empty-interface
export type ICustomerDataChangeResponse = IBulletConfirmationStatusChangeResponse;

export const parseCustomerDataChangeItemDTO = (orderDetail): CustomerDataChangeDTO => {
  if (!orderDetail) {
    return null;
  }

  const orderData = JSON.parse(orderDetail.changeData);
  return {
    customerData: orderData.customerData,
    kontaktdatenDetails: orderData.kontaktdatenDetails,
    personalData: orderData.personalData,
    businessData: orderData.businessData,
    orderedAt: orderDetail.createdAt,
  };
};
