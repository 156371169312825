import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mwe-chevron-nav',
  templateUrl: './chevron-nav.component.html',
})
export class ChevronNavComponent {
  @Input() navTitle: string;
  @Input() isBackwardButtonAvailable: boolean;
  @Input() isForwardButtonAvailable: boolean;
  @Output() backwardClicked = new EventEmitter<void>();
  @Output() forwardClicked = new EventEmitter<void>();

  constructor() {}
}
