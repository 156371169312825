import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Address } from '@mwe/models';
import { DetailsLinkTypeEnum } from '@mwe/constants';
import { DetailsLinkTypeEnumExporter, isAddressGroupToBeDisplayedDisabled, isArrayWithMinOneItem } from '@mwe/utils';

export interface AddressDropdownItem {
  id: string;
  label: string;
}

@Component({
  selector: 'mwe-address',
  templateUrl: './address.component.html',
})
export class AddressComponent extends DetailsLinkTypeEnumExporter implements OnInit, OnChanges {
  @Input() addressIdx: number;
  @Input() dropdownType: 'pen' | 'dropdown' | 'custom' | 'dots';
  @Input() address: Address;
  @Input() dropdownEditKey: string;
  @Input() allDropdownItems: AddressDropdownItem[]; // is split in featuredButtons and dropdownItems
  @Input() featuredBtnIds: string[];
  @Input() orderDateFormatted: string;
  @Input() recentOrderMsgKey: string;
  @Input() isArrowDownHidden = false;
  @Input() isAddressDetailsBodyVisible = false;
  @Input() productCategories: string[] = [];
  @Input() useRedesignedCard: boolean = false;
  @Output() onItemClick = new EventEmitter<string>();

  disabled: boolean;
  dropdownEditId: string;
  formattedAddress: string;
  tooltipClass: { [value: string]: boolean } = {};
  detailsLinkItemKey = '';

  featuredButtons: AddressDropdownItem[] = [];
  dropdownItems: AddressDropdownItem[] = [];
  private _single = false;

  constructor() {
    super();
  }

  get singleMarker(): boolean {
    return this._single;
  }

  @Input()
  set singleMarker(value: boolean) {
    this._single = value === true;
  }

  get hasTooltipBody() {
    return (this.address.addressGroup.detailsLinkType && this.isAddressDetailsBodyVisible) || this.orderDateFormatted?.length > 0;
  }

  ngOnInit(): void {
    this.initializeAddress();
    this.createFeatureButtonsAndDropDownItems();
    this.disabled = isAddressGroupToBeDisplayedDisabled(this.address.addressGroup.detailsLinkType, this.productCategories);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['address']) {
      this.initializeAddress();
    }
  }

  initializeAddress(): void {
    if (!this.address) {
      return;
    }

    this.dropdownEditId = 'mwe-address-' + this.addressIdx + '-dropdown';
    this.formattedAddress = this.address.getGroupFormatted();
    this.initializeTooltipCSS();
    if (this.address.addressGroup.detailsLinkType === DetailsLinkTypeEnum.RELOCATION) {
      this.detailsLinkItemKey = 'relocation.status';
    } else if (
      this.address.addressGroup.detailsLinkType === DetailsLinkTypeEnum.MOVEOUT ||
      this.address.addressGroup.detailsLinkType === DetailsLinkTypeEnum.MOVEOUT_IGNORE_ERROR
    ) {
      this.detailsLinkItemKey = 'moveout.status';
    }
  }

  initializeTooltipCSS(): void {
    const isDisabled = isAddressGroupToBeDisplayedDisabled(this.address.addressGroup.detailsLinkType, this.productCategories);
    this.tooltipClass = {
      single: this.singleMarker,
      disabled: isDisabled,
      'no-after': !!this.orderDateFormatted || this.isArrowDownHidden,
      'has-body': (!!this.address.addressGroup.detailsLinkType && this.isAddressDetailsBodyVisible) || this.orderDateFormatted?.length > 0,
      'background-gray': isDisabled,
    };
  }

  executeItemAction(id: string): void {
    this.onItemClick.emit(id);
  }

  private createFeatureButtonsAndDropDownItems(): void {
    if (!isArrayWithMinOneItem(this.featuredBtnIds)) {
      this.dropdownItems = this.allDropdownItems || [];
      return;
    }

    const hasId = (id: string) => !!this.featuredBtnIds.find(fId => fId === id);

    this.featuredButtons = this.allDropdownItems.filter(i => hasId(i.id));
    this.dropdownItems = this.allDropdownItems.filter(i => !hasId(i.id));
  }
}
