import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mwe-custom-popup',
  templateUrl: './custom-popup.component.html',
})
export class CustomPopupComponent {
  @Output() dismissClicked = new EventEmitter<void>();
  @Input() popupTitle = 'global.title';
  @Input() isDismissible? = true;
}
