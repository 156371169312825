import { environment } from '@mwe/environment';
import { ProductCategoryEnum } from '@mwe/constants';
import dayjs from 'dayjs';
import { AddressGroup } from './address/address-group.model';
import { IAddress, IAddressProducts } from './address/address.model';

// TODO utils in model lib net schoen
// temp fix to prevent circular dependencies

export const getPdfDocumentsUrl = (): string => {
  return 'http://'.concat(environment.documentUrl); // TODO environment NICHT direkt in einer Utils-Datei importieren sondern als args-Objekt übergeben, verursacht circular dependency
};

export const getPdfDocumentsSafeUrl = (): string => {
  return 'https://'.concat(environment.documentUrl); // TODO environment NICHT direkt in einer Utils-Datei importieren sondern als args-Objekt übergeben, verursacht circular dependency
};

export const getContractSummaryUrl = (tariffPart: string): string => {
  return 'https://'.concat(environment.documentUrl).concat('vertragszusammenfassung-superschnell-' + tariffPart + '/'); // TODO environment NICHT direkt in einer Utils-Datei importieren sondern als args-Objekt übergeben, verursacht circular dependency
};

export const everyTrue = (...values): boolean => {
  return values.every(b => b === true);
};

export const isEqualCategoryString = (categoryA: string, categoryB: string): boolean => {
  return categoryA && categoryB ? categoryA.toLowerCase() === categoryB.toLowerCase() : false;
};

export const parseISODate = (isoDate: string): Date => {
  return dayjs(isoDate).toDate();
};

export function checkAddressProductsForAddressGroupUpdates(addressProducts: IAddressProducts[], updates: AddressGroup[]): void {
  const addresses = addressProducts.map(addressProduct => addressProduct.address);

  checkAddressesForGroupUpdates(addresses, updates);
}

export function checkAddressForGroupUpdates(address: IAddress, updates: AddressGroup[]): void {
  checkAddressesForGroupUpdates([address], updates);
}

export function checkAddressesForGroupUpdates(addresses: IAddress[], updates: AddressGroup[]): void {
  updates.forEach((update: AddressGroup) => {
    const addressProduct = addresses.find(elem => elem.addressGroup.addressCode === update.addressCode);

    if (addressProduct) {
      addressProduct.addressGroup = update;
      addressProduct.addressGroup.markAsMatched();
    }
  });
}

export const isProductCategoryFernwaerme = (value: string): boolean => {
  return isProductCategory(value, ProductCategoryEnum.FERNWAERME);
};

export const isProductCategory = (value: string, categoryEnum: ProductCategoryEnum): boolean => {
  return !!value && value.toUpperCase() === ProductCategoryEnum[categoryEnum].toUpperCase();
};
