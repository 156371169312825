<mwe-basic-card-bodyless [noBoxShadow]="noBoxShadow" [height100]="height100">
  <div class="card-basic-body flex-grow-0 my-auto">
    <div *ngIf="card.categories?.length > 0" class="row gx-3 justify-content-center">
      <ng-container *ngFor="let category of card.categories">
        <div class="col-auto text-center">
          <mwe-product-icon [category]="category" size="lg" textNodeType="h3"></mwe-product-icon>
          <p *ngIf="showCompanyReference" class="text--legal text-gray-500 mb-0 mt-1">
            {{ card.categories[0] | correlatedCompanyReference }}
          </p>
        </div>
      </ng-container>
    </div>
    <div class="text-center">
      <p *ngIf="showSummarizedCompanyReference && !showCompanyReference && card.categories?.length > 0" class="text--legal mb-0 mt-1">
        {{ card.categories[0] | correlatedCompanyReference }}
      </p>
      <div class="mt-3" data-test="multi-product-card-account-customer-number">
        <p
          *ngIf="isAccountNumberVisible()"
          translate="shared.accountNumber_2"
          [translateParams]="{ accountNumber: card.accountNumber }"
        ></p>
        <p
          *ngIf="isCustomerNumberVisible()"
          translate="shared.customerNumber"
          [translateParams]="{ customerNumber: card.customerNumber }"
        ></p>
      </div>
    </div>
  </div>
</mwe-basic-card-bodyless>
