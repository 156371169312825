import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mwe-selection-card',
  templateUrl: './selection-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionCardComponent {
  @Input() content: string;
  @Input() header: string;
  @Input() iconClass: string = 'fa-solid fa-location-dot';
  @Input() isClickable = false;

  @Output() onClick = new EventEmitter<void>();
}
