import { Injectable } from '@angular/core';
import { FeatureToggleEnum } from '@mwe/constants';
import { Abschlagsplan, AdvancedPaymentDetails, ILSystem } from '@mwe/models';
import { containsFernwaerme, containsStromOrGasCategory, isArrayWithMinOneItem, sumReducer } from '@mwe/utils';
import { ProfileService } from '../profile/profile.service';
import { AdvancedPaymentPlanService } from './advanced-payment-plan.service';

@Injectable({ providedIn: 'root' })
export class AdvancedPaymentPlanLogic {
  constructor(
    private advancedPaymentPlanService: AdvancedPaymentPlanService,
    private profileService: ProfileService
  ) {}

  async getAdvancedPaymentPlan(systemId: ILSystem, accountId: string, categories: string[]): Promise<AdvancedPaymentDetails> {
    const data = await this.advancedPaymentPlanService.getAdvancedPaymentPlanDetails(systemId, accountId);

    // S/G has special item for total sum
    // but INKA does not provide this for fernwaerme... this adjustment should be done by IL, but ...
    if (
      containsFernwaerme(categories) &&
      isArrayWithMinOneItem(data?.abschlagsplaene) &&
      !data.abschlagsplaene[0].teilbetraege.find(part => part.nummer === '00')
    ) {
      const sum = data.abschlagsplaene[0].teilbetraege.map(t => t.betrag).reduce(sumReducer);
      data.abschlagsplaene[0].teilbetraege.push({nummer: '00', betrag: sum});
    }

    return data;
  }

  getReasonForRefusalCode(advancedPaymentPlan: Abschlagsplan): string {
    if (advancedPaymentPlan?.betragAenderungsAblehnungsgrund) {
      return advancedPaymentPlan.betragAenderungsAblehnungsgrund.ablehnungsCode;
    }
    return undefined;
  }

  showChangeRateButton(categories: string[]): boolean {
    return this.isPartialPaymentChangeForStromOrGasEnabled(categories) || this.isPartialPaymentChangeForForWaermeEnabled(categories);
  }

  isPartialPaymentChangeForStromOrGasEnabled(categories: string[]): boolean {
    return (
      containsStromOrGasCategory(categories) &&
      this.profileService.isFeatureToggleEnabled(FeatureToggleEnum.INVOICE_PARTIALPAYMENT_CHANGE_SG_ENABLED)
    );
  }

  isPartialPaymentChangeForForWaermeEnabled(categories: string[]): boolean {
    return (
      containsFernwaerme(categories) &&
      this.profileService.isFeatureToggleEnabled(FeatureToggleEnum.INVOICE_PARTIALPAYMENT_CHANGE_WAERME_ENABLED)
    );
  }
}
