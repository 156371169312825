import { Component, OnInit } from '@angular/core';
import { ChatBotService, EnvironmentService } from '@mwe/services';

@Component({
  selector: 'mwe-chatbot-popup',
  templateUrl: './chatbot.component.html',
  styleUrls: [],
})
export class ChatbotComponent implements OnInit {
  constructor(private chatbotService: ChatBotService, private environmentService: EnvironmentService) {
    this.isChatbotVisible = true;
  }

  isChatbotVisible: boolean;

  ngOnInit(): void {
    const widgetId = this.environmentService.getChatbotWidgetId();
    const dynamicScripts = ['https://cdn.chatvisor.com/cdn/js/IMP7HB3gx2.js'];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node: any = document.createElement('script');
      node.id = 'chatbot';
      node.innerHTML = `window.OnlimChatbotLite = { widgetId: "${widgetId}", baseUrl: "https://app.onlim.com", lang: "de"}; (function () { var ol = window.Onlim; if (typeof ol !== "function") { var o = function() { o.a(arguments); }; o.q = []; o.a = function(args) { o.q.push(args); }; window.Onlim = o; var d = document; var s = d.createElement("script"); s.type = "text/javascript"; s.async = true; s.src = "https://app.onlim.com/chat-app-lite/js/host.js"; s.id = "onlim-script"; var x = d.getElementsByTagName("script")[0]; x.parentNode.insertBefore(s, x); } })();`;
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      if (this.chatbotService.hasScriptAlreadyBeenLoaded === false) {
        document.getElementById('onlim-chat-widget-lite-anchor').appendChild(node);
        this.chatbotService.hasScriptAlreadyBeenLoaded = true;
      }
    }

    if ((window as any).OnlimLite) {
      (window as any).OnlimLite('boot');
    }
  }

  bootChatbot() {
    if ((window as any).OnlimLite) {
      (window as any).OnlimLite('boot');
    }
  }

  shutdownChatbot() {
    if ((window as any).OnlimLite) {
      (window as any).OnlimLite('shutdown');
    }
    this.chatbotService.eventAsSubject().next(false);
  }

  showChatbot() {
    this.isChatbotVisible = true;
    document.getElementById('mwe-chatbot').style.display = 'block';
  }

  hideChatbot() {
    this.isChatbotVisible = false;
    document.getElementById('mwe-chatbot').style.display = 'none';
  }
}
