<div class="text-start rounded-13 p-3 mb-3 {{ divColorCss }}">
  <div class="row gx-2">
    <div class="col-auto">
      <div
        class="d-flex align-items-center justify-content-center rounded-pill text-white text-center font-size-14 {{ iconColorCss }}"
        style="width: 1.5rem; height: 1.5rem"
      >
        <i class="fa fa-gift"></i>
      </div>
    </div>
    <div class="col">
      <p class="text-secondary fw-semibold mb-0" data-test="tariff-price-card-discount-item-title">
        {{ boxTitle }}
      </p>
      <p class="mb-0" data-test="tariff-price-card-discount-item-description">{{ info }}</p>
    </div>
  </div>
</div>
