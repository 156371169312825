<mwe-pre-load-data
  [loadingSpinnerType]="extendedLoadingSpinner ? 'extended' : 'normal'"
  [state]="state"
  [successTemplate]="success"
  alertMessageKey="error.service.default.message"
  alertTitleKey="error.service.default.title"
/>

<ng-template #success>
  @if (addressOptions?.length >= 0) {
    <ng-container>
      <div class="row">
        @for (option of addressOptions; track $index) {
          <div class="col-12">
            <mwe-address
              (click)="selectOption(option.address, option.products)"
              [addressIdx]="$index"
              [address]="option.address"
              [attr.data-test]="'mwe-address-' + $index"
              [isAddressDetailsBodyVisible]="false"
              [isArrowDownHidden]="true"
              [productCategories]="getProductCategories(option.products)"
              [useRedesignedCard]="true"
              class="pointer"
              dropdownType="nope"
            />
          </div>
        }
      </div>
    </ng-container>
  }

  @if (hasNoAddressOption()) {
    <mwe-no-associations-found-warning
      (productSearchBtnClicked)="productSearchBtnClicked.emit()"
      [navigatingBackTo]="navigatingBackTo"
      [noAddressGroupsText]="noAddressGroupsText"
      [noAddressGroupsTitle]="noAddressGroupsTitle"
    />
  }
</ng-template>
