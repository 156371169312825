import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IkpEnvironmentService } from '@ikp/service/environment/ikp-environment.service';

const mazeElementName = 'maze';
declare global {
  interface Window {
    initializeMaze: () => void;
  }
}

@Component({
  selector: 'app-ikp-maze',
  standalone: true,
  imports: [CommonModule],
  template: `<span #${mazeElementName}></span>`,
  encapsulation: ViewEncapsulation.None,
})
export class MazeComponent implements OnInit {
  @ViewChild(mazeElementName, { static: false }) containerRef: ElementRef;

  envService = inject(IkpEnvironmentService);

  ngOnInit(): void {
    const mazePropertyId = this.envService.getMazePropertyId();

    if (mazePropertyId) {
      localStorage.setItem('MAZEPROPERTYID', mazePropertyId);
      window.initializeMaze();
    }
  }
}
