import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mwe-dsgvo-checkbox',
  templateUrl: './dsgvo-checkbox.component.html',
  styles: [],
})
export class DsgvoCheckboxComponent {
  @Input() text: string;
  @Input() value: boolean;
  @Input() reihe: number;

  @Output() returnValueEmitter = new EventEmitter<any>();

  returnValues(): void {
    this.returnValueEmitter.emit({ reihe: this.reihe, value: this.value });
  }
}
