import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getFormattedCategory, isProductCategoryEmobility, isProductCategoryInternet, translateCategory } from '@mwe/utils';
import { Sparte } from '@mwe/models';
import { lastValueFrom } from 'rxjs';

@Pipe({ name: 'translateCategory' })
export class TranslateCategoryPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  async transform(category: string, useFwTariffClassTranslation?: boolean): Promise<string> {
    if (useFwTariffClassTranslation) {
      return lastValueFrom(this.translateService.get(`products.category.details.${category.toLowerCase()}`));
    }
    if (category === Sparte.StromGas) {
      return lastValueFrom(this.translateService.get(`products.category.${category.toLowerCase()}`));
    }
    const translation = await translateCategory(category, this.translateService);
    if (isProductCategoryInternet(category) || isProductCategoryEmobility(category)) {
      return translation;
    }
    return getFormattedCategory(translation);
  }
}
