<label *ngFor="let option of options" [attr.data-test]="option.id" class="box select d-flex align-items-center">
  <div class="custom-control custom-radio form-check">
    <input
      type="radio"
      [id]="option.id"
      class="form-check-input"
      [value]="option.value"
      [(ngModel)]="selectedOption"
      (change)="updateSelectedOption()"
    />
    <label class="form-check-label" [attr.for]="option.id"></label>
  </div>
  <div class="flex-fill">
    <h6 *ngIf="option.title" [translate]="option.title"></h6>
    <p *ngIf="option.text" class="mb-0" [translate]="option.text"></p>
    <ng-container
      *ngIf="choiceItemTemplateRef && option.data"
      [ngTemplateOutlet]="choiceItemTemplateRef"
      [ngTemplateOutletContext]="{ data: option.data }"
    ></ng-container>
  </div>
</label>
