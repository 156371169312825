import { Component, Input } from '@angular/core';

@Component({
  selector: 'mwe-availability-badge',
  templateUrl: './availability-badge.component.html',
})
export class AvailabilityBadgeComponent {
  badgeMessage: string;
  availabilityIconURL: string;

  @Input() activeBadge: boolean = true;
  @Input() inactiveBadge: boolean = false;
  @Input() unknownBadge: boolean = false;
  @Input() hoverMessage: string;
  @Input() activeLabel: string = 'Aktiv';
  @Input() unknownLabel: string = 'Versorgung möglich';
  @Input() inactiveLabel: string = 'Inaktiv';
  @Input() customCss: string;

  getAvailabilityIcon(): string {
    this.availabilityIconURL = this.unknownBadge
      ? 'info-icon.svg'
      : this.activeBadge
      ? 'checkmark-icon.svg'
      : this.inactiveBadge
      ? 'cross-icon.svg'
      : null;
    return this.availabilityIconURL;
  }

  getAvailabilityBadgeMessage(): string {
    this.badgeMessage = this.unknownBadge
      ? this.unknownLabel
      : this.activeBadge
      ? this.activeLabel
      : this.inactiveBadge
      ? this.inactiveLabel
      : null;
    return this.badgeMessage;
  }
}
