<mwe-basic-card
  iconClass="fa-solid fa-file-user"
  [cardTitle]="cardTitle"
  data-test="products-summary-personalBilling"
  [hasBackground]="true"
>
  <div data-test="products-summary-personalBilling-payment">
    <strong class="has-text-blue"><span translate="summary.paymentData"></span></strong>
    <div class="row mb-1 mt-1">
      <div class="col col-auto">
        <div class="payment-badge">
          <img class="img-fluid" src="/assets/icons/payment/{{ paymentIcon }}.svg" [alt]="paymentText" />
        </div>
      </div>
      <div class="col">
        <p class="text--big mb-0" [innerHTML]="paymentText"></p>
      </div>
    </div>
  </div>
  <hr />
  <div data-test="products-summary-personalBilling-eBilling">
    <p class="text--big mb-0">
      <strong class="has-text-blue d-inline-block mb-2"><span translate="summary.invoiceShipping"></span></strong><br />
      <strong><span translate="summary.eBilling"></span>: </strong>
      <span class="d-inline-block mb-2" data-test="products-summary-personalBilling-invoice-address"> {{ formattedInvoiceAddress }}</span
      ><br />
      <ng-container *ngIf="eRechnungInfo?.isERechnungsEmpfaenger">
        <strong>
          <span translate="summary.invoiceAddress.shipping.emailPrefix"></span>
        </strong>
        <span data-test="products-summary-personalBilling-email"> {{ eRechnungInfo?.rechnungEmail }}</span>
      </ng-container>
      <span
        *ngIf="!eRechnungInfo?.isERechnungsEmpfaenger"
        translate="summary.invoiceAddress.shipping.post"
        data-test="products-summary-personalBilling-post"
      ></span>
    </p>
  </div>
</mwe-basic-card>
<mwe-alert
  *ngIf="invoiceInfoAlertMessage"
  [messageKey]="invoiceInfoAlertMessage"
  class="d-block mt-5"
  data-test="products-summary-personalBilling-eBillingAlert"
></mwe-alert>
