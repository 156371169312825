import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentService } from '@mwe/services';
import { getRouteData } from '@mwe/utils';

@Component({
  selector: 'mwe-alternate-route',
  template: '',
  styles: '',
})
export class AlternateRouteComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private environmentService: EnvironmentService,
  ) {}

  ngOnInit(): void {
    this.navigateTo();
  }

  navigateTo(): void {
    const routeData = getRouteData(this.activatedRoute) || {};
    const redirectPrefix = routeData?.redirectPrefix ?? '';
    const fallbackUri = `/${redirectPrefix}`;
    this.router.navigateByUrl(fallbackUri);
  }
}
