import { Injectable } from '@angular/core';
import { IkpEnvironmentService } from '@ikp/service/environment/ikp-environment.service';
import { track } from '@mwe/utils';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private readonly ROOT_URL_NAME = 'frontpage';
  constructor(private environmentService: IkpEnvironmentService) {}

  trackVirtualPageView(url: string) {
    const contents = {
      title: this.getTitle(url),
      url: url,
    };
    const event = 'virtualPageView';
    if (this.environmentService.isPiwikProTrackingDebugEnabled()) {
      // eslint-disable-next-line no-console
      console.log('Tracking event: ', JSON.stringify({ ...contents, event }));
    }
    track(event, contents);
  }

  getTitle(url: string) {
    const parts = url.split('/');
    if (this.hasIdParameter(parts)) {
      return parts[parts.length - 2] + '-details';
    }
    let title = parts[parts.length - 1];
    title = title.replace('=', '-');
    if (title.indexOf('#') > -1) {
      title = this.handleHash(title);
    }
    title = title.replace('#', '');
    return title == '' ? this.ROOT_URL_NAME : title;
  }

  hasIdParameter(parts: string[]) {
    if (parts.length >= 3 && parts[parts.length - 1].length == 36) {
      return true;
    }
  }

  handleHash(title: string) {
    const hash = title.split('#')[1];
    if (hash.startsWith('ey')) {
      return title.split('#')[0];
    }
    return title.replace('#', '');
  }
}
