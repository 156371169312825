import { inject, Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class KeycloakRefreshService {
  private keycloakService = inject(KeycloakService);

  constructor() {
    this.subscribeToKeycloakEvents();
  }

  private subscribeToKeycloakEvents() {
    this.keycloakService.keycloakEvents$.subscribe({
      next: event => {
        if (event.type == KeycloakEventType.OnTokenExpired) {
          this.keycloakService.updateToken(20).then(refreshed => {
            if (!refreshed) {
              this.keycloakService.login();
            }
          });
        }
      },
    });
  }
}
