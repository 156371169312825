import { IAddress, IAddressProducts } from '../address/address.model';
import { CommonMappingData, IBulletConfirmationStatusChangeResponse, ILSystem, Sparte } from '../integrationlayer/api.model';
import { ICustomerBankKonto, IGeschaeftspartnerBeziehung, IPhysischePerson, IVertrag } from '../integrationlayer/customer.model';

export interface Rechnung {
  rechnungsnummer: string;
  geschaeftsPartnerId?: string;
  belegdatum?: string;
  rechnungsbetrag?: number;
  rechnungssaldo?: number;
  faelligkeitsdatum?: string;
  rechnungsgrund?: string;
  rechnungsperiode?: string;
  buchungsdatum?: string;
  druckdatum?: string;
  storniert?: boolean;
  rechnungsPdfGroesse?: number;
  verrechnungskontoId?: string;
  systemId?: ILSystem;
  sparte?: Sparte[];
  verrechnungszeitraumAb?: string;
  verrechnungszeitraumBis?: string;
  gelesen?: boolean;
}

export interface RechnungDetails {
  rechnungNummer?: string;
  rechnungserstellungDatum?: string;
  vertragskontoId?: string;
  abrechnungszeitraumVon?: string;
  abrechnungszeitraumBis?: string;
  faelligkeitDatum?: string;
  storniert?: boolean;
  preisbestandteil?: Array<Preisbestandteil>;
  rechnungUnterposition?: Array<RechnungUnterposition>;
  abrechnungBeleg?: Array<AbrechnungBeleg>;
  archivName?: string;
  eindeutigeKennungImQuellsystem?: string;
  produkt?: Array<Produkt>;
}

export interface Preisbestandteil {
  preisbestandteilWert?: number;
  bezeichnung?: string;
  prozentsatz?: number;
  /**
   * DRUCKART in SAP (z.B. GEAB für Erdgasabgabe)
   */
  code?: string;
  preisbestandteil?: Array<Preisbestandteil>;
}

export interface RechnungUnterposition {
  bezeichnung?: string;
  preisbestandteilWert?: number;
  faelligkeitdatum?: string;
}

export interface AbrechnungBeleg {
  produkt: Produkt;
  abrechnungBelegVerbrauch?: Array<AbrechnungBelegVerbrauch>;
  abrechnungszeitraumBis: string;
  abrechnungszeitraumVon: string;
  preisbestandteil?: Array<Preisbestandteil>;
  emissionInformation?: string;
  energiequelle?: Array<EnergiequellenAnteil>;
  netzTarif?: string;
  zaehlpunkt?: Zaehlpunkt;
}

export interface Zaehlpunkt {
  zaehlpunktnummer?: string;
  anlageId?: string;
}

export interface AbrechnungBelegVerbrauch {
  durchschnittsVerbrauch: string;
  verbrauchstage: string;
  verbrauchswert?: string;
  verbrauchszeitraum?: VerbrauchszeitraumEnum;
}

export enum VerbrauchszeitraumEnum {
  Vorjahr = 'Vorjahr',
  Aktuell = 'Aktuell',
  Verbrauchsprognose = 'Verbrauchsprognose',
}

export interface EnergiequellenAnteil {
  energiequelleBezeichnung?: string;
  energiequelleAnteil?: number;
}

export interface Produkt {
  tarif?: {
    tarifKey?: string;
    ISUTarifKey?: string;
    tarifName: string;
  };
  sparte?: string;
  name?: string;
  beschreibung?: string;
  abrechnungszyklus?: string;
}

export interface Datei {
  dateiname?: string;
  dateiendung?: string;
  dateiInhalt?: string;
}

export interface InvoiceDocument {
  rechnungsobjekt: Datei;
}

export interface InvoiceDetails {
  rechnung: RechnungDetails;
}

export interface VertragskontoSystemSparte {
  vertragskontoId?: string;
  verrechnungskontoId?: string;
  storniert?: boolean;
  systemId: ILSystem;
  sparte?: Sparte;
}

export interface InvoiceParts {
  teilbetraguebersicht?: Array<TeilbetragsUebersicht>;
}

export interface Abschlagsplan {
  abschlagsplannummer: string;
  status: string;
  beginnAbschlagsplanperiode: string;
  endeAbschlagsplanperiode: string;
  vertragsnummer: string;
  verrechnungskontoId: string;
  geschaeftsPartnerId: string;
  letzteTeilbetragsAenderung: string;
  naechsteErlaubteTeilbetragsAenderung: string;
  abschlagsZyklus: string;
  zyklusAenderbar: boolean;
  betragAenderbar: boolean;
  betragAenderungsAblehnungsgrund: Ablehnungsgrund;
  aenderungsbereich: Aenderungsbereich[];
  teilbetraege?: Teilbetrag[];
  betragAenderungsZaehler?: AenderungsZaehler[];
}

export interface AenderungsZaehler {
  anzahl: number;
  bezeichnung: string;
}

export interface VerrechnungskontoResponse {
  verrechnungskonto: Verrechnungskonto;
}

export interface VerrechnungsKontenResponse {
  verrechnungskonten: Verrechnungskonto[];
}

export interface Verrechnungskonto {
  verrechnungskontoId: string;
  systemId?: ILSystem;
  status: string;
  buchungsKreis?: string;
  eingangsZahlweg?: string;
  ausgangsZahlweg?: string;
  verrechnungskontoTyp?: VerrechnungskontoTyp;
  vertraege: IVertrag[];
  eingangskonto?: ICustomerBankKonto;
  ausgangskonto?: ICustomerBankKonto;
  istPdfRechnungsEmpfaenger?: boolean;
  pdfRechnungsEmpfaenger?: string;
  geschaeftspartnerBeziehungen: IGeschaeftspartnerBeziehung[];
}

export interface AdvancedPaymentDetails {
  abschlagsplaene: Abschlagsplan[];
}
export interface Aenderungsbereich {
  faelligkeitsdatum: string;
  min: number; // min % Anteil
  max: number; // max % Anteil
  minWert: number;
  maxWert: number;
  aenderungsListe: Betragsaenderung[];
}

export interface Betragsaenderung {
  wert: number;
}

export interface TeilbetragsAenderungResponse {
  teilbetragsAenderung: TeilbetragsAenderung;
}

export interface TeilbetragsAenderungRequestData {
  accountId: string;
  systemId: ILSystem;
  abschlagsplannummer: string;
  startDate: string;
  newAmount: number;
}

export interface TeilbetragsAenderung {
  aktuellesFaelligkeitsdatum?: string;
  aktuellerBetrag?: number;
  naechstesFaelligkeitsdatum: string;
  neuerTeilbetrag: number;
  nextNotChangeable?: boolean;
  oneAfterNextAmount?: number;
  oneAfterNextAmountDifferent?: boolean;
}

export interface Ablehnungsgrund {
  ablehnungsCode: string;
  ablehnungsText: string;
}

export interface TeilbetragsUebersicht {
  sparte?: Sparte[];
  betragGesamt?: number;
  naechsterTeilbetrag?: number;
  faelligkeitDatum?: string;
  teilbetraege?: Teilbetrag[];
}

export interface Teilbetrag {
  nummer?: string;
  faelligkeitsdatum?: string;
  betrag?: number;
  dauerAbschlagsBetrag?: number;
  offenerBetrag?: number;
  teilbetragsStatus?: TeilbetragStatus;
  waehrung?: string;
  betragAenderbar?: true;
  produktspezifischerTeilbetrag?: Array<TeilbetragItem>;
}

export type TeilbetragStatus = 'offen' | 'bezahlt';

export interface TeilbetragItem {
  teilbetrag: number;
  sparte: Sparte;
  stundungsDatum?: string;
}

export enum BezeichnungEnum {
  GesamtsummeRechnung = 'GesamtsummeRechnung',
  Umsatzsteuerbetrag = 'Umsatzsteuerbetrag',
  Umsatzsteuersatz = 'Umsatzsteuersatz',
  BezahlteTeilbetraege = 'bezahlteTeilbetraege',
  Abrechnungssumme = 'Abrechnungssumme',
  NeuerTeilbetrag = 'NeuerTeilbetrag',
  Rechnungsbetrag = 'Rechnungsbetrag',
  RechnungsbetragOhneUnterePositionen = 'RechnungsbetragOhneUnterePositionen',
  AlterTeilbetrag = 'alterTeilbetrag',
  OffenerBetrag = 'offenerBetrag',
  BereitsBezahlt = 'bereitsBezahlt',
  Detailrechnungsbetrag = 'Detailrechnungsbetrag',
  Energie = 'Energie',
  Netz = 'Netz',
  Abgabe = 'Abgabe',
}

export interface InvoiceListFilter {
  category: Sparte;
}

export interface InteractiveInvoiceDemoData {
  addressProducts: IAddressProducts;
  invoiceList: Rechnung[];
  invoiceDetails: RechnungDetails[];
}

// tslint:disable-next-line:no-empty-interface
export type IPartialAmountChangeStatusResponse = IBulletConfirmationStatusChangeResponse;

export interface IPartialAmountChangeStatusData {
  changeData: string;
  createdAt?: string;
}

export interface IPartialAmountChangeDataDTO {
  mappingData: CommonMappingData[];
  address: IAddress;
  systemId: ILSystem;
  newPartialAmount?: string; // calculated from IL
  newStartDate?: string;
  oldPartialAmount?: string;
  wantedPartialAmount?: string; // user input
  advancedPaymentPlanNumber?: string;
  physischePerson?: IPhysischePerson;
}

export interface Rechnungen {
  rechnungen: Rechnung[];
}

export enum VerrechnungskontoTyp {
  S1 = 'Private - Konten',
  S2 = 'SD-Dienstleistungen',
  S3 = 'Business - Konten',
  S4 = 'Sammelrechnungskonten',
  S5 = 'IDEX-ATI Aggregierte VKTO',
  S9 = 'CPD Konto',
  M1 = 'eMobility',
  G1 = 'GaGro Vertragskonto',
  _02 = 'IS-U Sammelrechnungskonto',
}

export interface SepaMandat {
  systemId: ILSystem;
  vertragskontoNummern: string[];
  geschaeftspartnerId: string;
  email?: string;
  bankkonto: {
    iban: string;
  };
}
