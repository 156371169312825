import {
  Address,
  CoverageArea,
  IAddress,
  IAddressProducts,
  IOlavEntry,
  IProduktVerfuegbarkeit,
  Product,
  RechnungsAdresse,
  Sparte,
} from '@mwe/models';
import { isArrayWithMinOneItem } from '../common/mwe-util';

export const parseAddressCode = (addressCode: string): Address => {
  const acTokens = addressCode.split('_');
  const addressNumbers = acTokens[2].split('/');
  return new Address(
    null,
    acTokens[1],
    addressNumbers[0],
    addressNumbers.length > 1 ? addressNumbers[1] : null,
    addressNumbers.length > 2 ? addressNumbers.slice(2).join('/') : null, // always take the last token  as door number
    null, // TODO: identify adressHinweis
    acTokens[0],
    acTokens[3],
    acTokens[4],
  );
};

export const getFromOlavKey = (olavEntry: IOlavEntry): Address => {
  return new Address(
    null,
    olavEntry?.strasse,
    olavEntry?.gebadr1,
    '',
    olavEntry?.tuerNr,
    '' /* TODO: id adresshinweis */,
    olavEntry?.plz,
    olavEntry?.gemeinde,
    olavEntry?.isuKZS,
  );
};

export const parseRechnungsAdresse = (rechnungsAdresse: RechnungsAdresse): Address => {
  return new Address(
    '',
    rechnungsAdresse?.strasse,
    rechnungsAdresse?.hausnummer,
    rechnungsAdresse?.stiege,
    rechnungsAdresse?.tuernummer || rechnungsAdresse?.raumnummer,
    rechnungsAdresse?.adresshinweis,
    rechnungsAdresse?.plz,
    rechnungsAdresse?.ort,
    rechnungsAdresse?.laendercode,
  );
};

export const getRechnungsAdresse = (address: Address, olavEntry: IOlavEntry): RechnungsAdresse => {
  if (!address) {
    return undefined;
  }

  return {
    adressart: 'Rechnungsadresse',
    strasse: address.street,
    hausnummer: address.streetNumber,
    stiege: address.block,
    tuernummer: address.doorNumber,
    plz: address.postcode,
    ort: address.city,
    laendercode: address.country,
    olavGaa: olavEntry?.gebaeudeKey,
    olavGba: olavEntry?.gebaeudeKeyIdent,
    olavAna: olavEntry?.anlageKey,
    olavAaa: olavEntry?.anlageAdressKey,
  };
};

export function getAllProductsFromAddressProducts(addressProducts: IAddressProducts[]): Product[] {
  if (!isArrayWithMinOneItem(addressProducts)) {
    return [];
  }

  return addressProducts.map(ap => ap.products).flat();
}

export function isAddressInMundlCity(address: IAddress): boolean {
  return address.postcode?.[0] === '1';
}

export function isOlavAddressSupportedByCoverageArea(olav: IOlavEntry, coverageArea: CoverageArea[]): boolean {
  if (!olav || !isArrayWithMinOneItem(coverageArea)) {
    return false;
  }

  return coverageArea
    .filter(coverageArea => Number(olav.plz) == coverageArea.bezirk)
    .some(coverageArea => olav.gemeinde == coverageArea.gemeinde);
}

// todo this should be solved by backend
export async function specialHackToCheckIfGasIsAvailable(
  olav: IOlavEntry,
  availabilites: IProduktVerfuegbarkeit[],
  loadGasCoverageFunc: () => Promise<CoverageArea[]>,
): Promise<void> {
  if (!olav || !isArrayWithMinOneItem(availabilites) || !loadGasCoverageFunc) {
    return;
  }

  const isVienna = isAddressInMundlCity(getFromOlavKey(olav));
  if (isVienna) {
    return;
  }
  const coverageAreaGas = await loadGasCoverageFunc();
  const isGasSupported = isOlavAddressSupportedByCoverageArea(olav, coverageAreaGas);

  if (isGasSupported) {
    return;
  }

  const gas = availabilites.find(a => a.sparte === Sparte.Gas);
  if (gas) {
    gas.verfuegbar = 'NEIN';
  }
}
