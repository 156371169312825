import {
  ClearingAccount,
  IAddressProducts,
  Product,
  Ratenplan,
  Sparte,
  UnpaidInvoice,
  UnpaidInvoices,
  UnpaidInvoicesSumType,
} from '@mwe/models';
import { isArrayWithMinOneItem, sumReducer } from '../../common/mwe-util';
import { getMinDate } from '../../dates/date.utils';

export function filterDuePayments(unpaidInvoices: UnpaidInvoice[]): UnpaidInvoice[] {
  if (!isArrayWithMinOneItem(unpaidInvoices)) {
    return [];
  }

  return unpaidInvoices.filter(ui => {
    return (ui.sollHabenKennzeichen === 'DEBIT' || ui.sollHabenKennzeichen === 'CREDIT') && ui.bereitsFaellig;
  });
}

export function hasDuePayments(unpaidInvoices: UnpaidInvoices): boolean {
  if (!isArrayWithMinOneItem(unpaidInvoices.summen)) {
    return false;
  }

  const sum = unpaidInvoices.summen.find(s => s.betragsArt === UnpaidInvoicesSumType.DuePayment);
  return sum.sollHabenKennzeichen === 'DEBIT' && sum.betrag > 0;
}

export function getDuePaymentsSum(unpaidInvoices: UnpaidInvoices): number {
  if (!isArrayWithMinOneItem(unpaidInvoices?.summen)) {
    return 0;
  }

  const sum = unpaidInvoices.summen.find(s => {
    return s.betragsArt === UnpaidInvoicesSumType.DuePayment && s.sollHabenKennzeichen === 'DEBIT';
  });
  return sum?.betrag ?? 0;
}

export function getClearingAccountDuePaymentSum(accounts: ClearingAccount[]): number {
  if (!isArrayWithMinOneItem(accounts)) {
    return 0;
  }

  return accounts
    .map(a => a.unpaidInvoices)
    .reduce((prev, current) => {
      return prev + getDuePaymentsSum(current);
    }, 0);
}

export function hasSameClearingAccount(p1: Product, p2: Product): boolean {
  return p1.accountNumber === p2.accountNumber && p1.businessPartnerNumber === p2.businessPartnerNumber && p1.systemId === p2.systemId;
}

export function isOneUnpaidInvoiceDuePayment(unpaidInvoices: UnpaidInvoices): boolean {
  return unpaidInvoices.offeneZahlungen.some(ui => ui.belegArt === 'RV');
}

export function getCategoriesForClearingAccount(a: ClearingAccount, products: Product[]): Sparte[] {
  return products
    .filter(p => p.accountNumber === a.accountId && p.businessPartnerNumber === a.businessPartnerId && p.systemId === a.systemId)
    .map(p => p.category) as Sparte[];
}

export function getNearestDueDate(unpaidInvoices: UnpaidInvoices): string {
  if (!isArrayWithMinOneItem(unpaidInvoices?.offeneZahlungen)) {
    return '';
  }

  const dates = unpaidInvoices.offeneZahlungen.map(ui => ui.faelligkeitsdatum);
  return getMinDate(dates);
}

export function totalPaymentPlanSum(paymentPlan: Ratenplan): number {
  if (!isArrayWithMinOneItem(paymentPlan?.positionen)) {
    return 0;
  }

  return paymentPlan.positionen.map(p => p.betrag).reduce(sumReducer);
}

export function getClearingAccountsFrom(addressProducts: IAddressProducts[]): ClearingAccount[] {
  const accounts = [] as ClearingAccount[];
  const alreadyMappedAccounts = [];

  addressProducts.forEach(ag => {
    ag.products.forEach(p => {
      if (!alreadyMappedAccounts.includes(p.accountNumber)) {
        alreadyMappedAccounts.push(p.accountNumber);
        accounts.push({
          businessPartnerId: p.businessPartnerNumber,
          accountId: p.accountNumber,
          systemId: p.systemId,
        } as ClearingAccount);
      }
    });
  });

  return accounts;
}

export function getIbanForBuchungskreisWaerme(isExtension: boolean, buchungsKreis: string, defaultIban: string): string {
  if (isExtension) {
    switch (buchungsKreis) {
      case '0001':
        return 'AT26 1200 0006 9620 4304';
      case '0002':
        return 'AT03 1200 0006 9628 2318';
      case '0016':
        return 'AT61 1200 0006 9620 4353';
    }
  } else {
    switch (buchungsKreis) {
      case '0001':
        return 'AT79 1200 0006 9620 4320';
      case '0002':
        return 'AT78 1200 0006 9628 2326';
      case '0016':
        return 'AT61 1200 0006 9620 4353';
    }
  }

  return defaultIban;
}
