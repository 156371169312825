import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mwe-maintenance-template',
  templateUrl: './maintenance-template.component.html',
})
export class MaintenanceTemplateComponent {
  @Input() headline: string;
  @Input() subheadline: string;
  @Input() text: string;
  @Input() isBtnVisible = true;
  @Input() buttonLabelkey: string;
  @Input() isHeaderLogoVisible = false;
  @Output() buttonClick = new EventEmitter<void>();
}
