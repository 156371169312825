import { computed, Injectable, signal } from '@angular/core';
import { IDefaultProdukt, IProduktAuswahlDetails, PreisIndikationen, TariffSelectionCardData } from '@mwe/models';
import { isArrayWithMinOneItem } from '@mwe/utils';
import { tariffSelectionStoreUtils } from './tariff-selection-store.utils';
import { ServiceStateEnum } from '@mwe/constants';

@Injectable({ providedIn: 'root' })
export class TariffSelectionStoreService {
  allProducts = signal<IProduktAuswahlDetails[]>([]);
  allPriceIndications = signal<PreisIndikationen[]>([]);
  selectedTariffs = signal<TariffSelectionCardData[]>([]);
  additionalOptionGroupName = signal<string>('');
  zipCode = signal<string>('');
  hasError = computed(() => this.selectedTariffs().some(t => t.state === ServiceStateEnum.ERROR));

  reset(): void {
    this.allProducts.set([]);
    this.allPriceIndications.set([]);
    this.selectedTariffs.set([]);
  }

  // new-client store user-selection in session-storage
  getProduktAuswahlFor(category: string): IProduktAuswahlDetails {
    const tariff = this.selectedTariffs().find(t => t.category.toLowerCase() === category.toLowerCase());
    return this.getUtils().createNewProduct(this.allProducts(), tariff);
  }

  /** Update the price indication (needed because else we always work with the default indications loaded on initialization without vouchers) */
  updatePriceIndication(priceIndication: PreisIndikationen) {
    if (!priceIndication) {
      return;
    }
    const unaffectedPriceIndications = this.allPriceIndications().filter(p => p.tarif.ISUTarifKey !== priceIndication.tarif.ISUTarifKey);
    const newList = [{ ...priceIndication }, ...unaffectedPriceIndications];
    this.allPriceIndications.set(newList);
  }

  updateSelectedTariffs(newTariff: TariffSelectionCardData): void {
    // compare after category, else tariff count will increase :D
    const unaffectedTariffs = this.selectedTariffs().filter(t => {
      // tariff change
      if (newTariff.anlageId) {
        return t.anlageId !== newTariff.anlageId;
      }
      // new client
      return t.tariffKey !== newTariff.tariffKey && t.category.toLowerCase() !== newTariff.category.toLowerCase();
    });
    this.updatePriceIndication(newTariff.priceIndication);
    const newList = [{ ...newTariff }, ...unaffectedTariffs];
    this.getUtils().sortTariffs(newList);
    this.selectedTariffs.set(newList);
  }

  initializeSelectedTariffs(
    userSelectedItems: IProduktAuswahlDetails[],
    userSelectedPriceIndications: PreisIndikationen[],
    defaultProducts?: IDefaultProdukt,
  ) {
    let newSelectedTariffs = [] as TariffSelectionCardData[];
    // user selection in session storage
    if (isArrayWithMinOneItem(userSelectedItems)) {
      newSelectedTariffs = [
        ...newSelectedTariffs,
        ...this.getUtils().restoreSelectedTariffsFromSessionStorage(
          this.allProducts(),
          this.allPriceIndications(),
          userSelectedItems,
          userSelectedPriceIndications,
        ),
      ];
    }
    if (defaultProducts) {
      newSelectedTariffs = [
        ...newSelectedTariffs,
        ...this.getUtils().getMissingItemsAsDefault(this.allProducts(), this.allPriceIndications(), userSelectedItems, defaultProducts),
      ];
    }
    this.getUtils().sortTariffs(newSelectedTariffs);
    this.selectedTariffs.set(newSelectedTariffs);
  }

  // used for unit tests, to mock utils
  private getUtils() {
    return tariffSelectionStoreUtils;
  }
}
