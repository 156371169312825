import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { openPdf } from '@mwe/utils';
import { Datei } from '@mwe/models';
import { AccountLogic } from '../account/account.logic';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({ providedIn: 'root' })
export class FileService {
  public resourceUrl: string;
  public prozessTypUrlMap = new Map();

  constructor(
    private http: HttpClient,
    private accountLogic: AccountLogic,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/file';

    this.prozessTypUrlMap.set('KW', this.environmentService.getApiUrl() + 'api/newclient/upload');
    this.prozessTypUrlMap.set('PKW', this.environmentService.getApiUrl() + 'api/public/newclient/upload');
  }

  upload(file: File, processType: string): Observable<HttpResponse<string>> {
    const filesMultipartFormParam = 'files';
    const formData: FormData = new FormData();
    formData.append(filesMultipartFormParam, file);
    const uuidParam = !!this.accountLogic.getUUID() && !!this.accountLogic.isLoggedOut ? '&uuId='.concat(this.accountLogic.getUUID()) : '';
    return this.http.post<string>(this.getResourceUrl(processType) + '?processType=' + processType + uuidParam, formData, {
      observe: 'response',
    });
  }

  openPopulatedDocument(documentUrl: string, dynamicDataJson: string = '', tabTitle: string): void {
    lastValueFrom(this.downloadPopulatedDocument(documentUrl, dynamicDataJson))
      .then(remoteContent => {
        openPdf(remoteContent, tabTitle, true);
      })
      .catch(error => {
        console.log(error);
      });
  }

  downloadPopulatedDocument(documentUrl: string, dynamicDataJson: string = ''): Observable<Datei> {
    return this.http.post<Datei>(this.resourceUrl + '/populate-document?url=' + documentUrl, dynamicDataJson);
  }

  getResourceUrl(processType: string): string {
    if (this.prozessTypUrlMap.has(processType)) {
      return this.prozessTypUrlMap.get(processType);
    }
    return this.resourceUrl;
  }
}
