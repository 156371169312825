import { AvailabilityState, IProduktDetailsVerfuegbarkeit, IProduktVerfuegbarkeit, Product, TextNodeType } from '@mwe/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { capitalizeString, everyTrue, isEqualCategoryString, isProductCategory } from '@mwe/utils';
import { ProductCategoryEnum } from '@mwe/constants';
import { EnvironmentService, RelocationService } from '@mwe/services';
import { v4 } from 'uuid';
import { HeadlineService } from '../../headline/headline.service';

@Component({
  selector: 'mwe-wizard-product-card-container',
  templateUrl: './wizard-product-card-container.component.html',
  styles: [],
})
export class WizardProductCardContainerComponent implements OnInit {
  @Input() product: Product;
  @Input() productAvailabilities: IProduktVerfuegbarkeit[];
  @Input() isActiveCard: boolean;
  @Input() notActiveText: string;
  @Input() isAvailableAtNewAddress: boolean;
  @Input() isInitialChecked: boolean;
  @Input() wasInitiallyOrPreviouslyChecked: boolean;
  @Input() showTariff = true;
  @Input() showTariffDetails = true;
  @Input() showCurrentProductTariff = false;
  @Input() toggleLabel = 'products.toggle';
  @Input() isDisabled = false;
  @Input() isOrdered = false;
  @Input() headingNodeType: TextNodeType = 'h2';
  @Output() readonly toggleState = new EventEmitter();

  showInformationText = false;
  showParagraph = false;

  availabilityDetails: IProduktDetailsVerfuegbarkeit[];
  tariffClasses: string[];
  informationTextProductCategory: string;
  inputID: string = 'input' + v4();
  showMoreLegalText = true;
  containerTestSelector: string;

  constructor(
    private relocationService: RelocationService,
    public environmentService: EnvironmentService,
  ) {}

  ngOnInit(): void {
    this.initializeComponentData();
  }

  private initializeComponentData(): void {
    this.isOrdered = this.wasInitiallyOrPreviouslyChecked;
    if (isProductCategory(this.product.category, ProductCategoryEnum.FERNWAERME)) {
      this.availabilityDetails = this.productAvailabilities?.find(element =>
        isProductCategory(element.sparte, ProductCategoryEnum.FERNWAERME),
      ).details;
    }

    this.tariffClasses = this.getTariffClasses();
    this.toggleState.emit(this.wasInitiallyOrPreviouslyChecked);
    this.informationTextProductCategory = capitalizeString(this.product.category);
    this.showInformation(this.product);
    this.containerTestSelector = `wizard-product-card-container-${this.product.category?.toLowerCase()}`;
  }

  onProductSelect(isChecked: boolean): void {
    this.isOrdered = isChecked;
    this.toggleState.emit(isChecked);
  }

  toggleParagraph(): void {
    this.showParagraph = !this.showParagraph;
  }

  private showInformation(product: Product): boolean {
    this.showInformationText = false;
    this.productAvailabilities?.forEach(element => {
      if (isEqualCategoryString(element.sparte, product.category)) {
        this.showInformationText =
          element.verfuegbar === AvailabilityState.UNKNOWN ||
          element.verfuegbar === AvailabilityState.TECHNICAL_ERROR ||
          element.verfuegbar === AvailabilityState.BUILDING;
      }
    });
    return this.showInformationText;
  }

  getTariffClasses(): string[] {
    const customerType = this.environmentService.getPortalCustomerType();
    if (everyTrue(!this.showCurrentProductTariff, this.isAvailableAtNewAddress)) {
      if (
        isProductCategory(this.product.category, ProductCategoryEnum.STROM) ||
        isProductCategory(this.product.category, ProductCategoryEnum.GAS)
      ) {
        return [this.relocationService.getConfigTariffs()[this.product.category.toLowerCase()][customerType].name];
      } else if (isProductCategory(this.product.category, ProductCategoryEnum.FERNWAERME) && this.availabilityDetails) {
        const fernwaermeTariffDetails: string[] = [];
        this.availabilityDetails.forEach(element => {
          fernwaermeTariffDetails.push(element.lieferkomponente);
        });
        return fernwaermeTariffDetails;
      }
      return [];
    } else {
      return this.product.tariffClasses;
    }
  }

  toggleReadMoreLegalText(): void {
    this.showMoreLegalText = !this.showMoreLegalText;
  }
}
