import { Pipe, PipeTransform } from '@angular/core';
import { IPersonalData } from '@mwe/models';
import { formatDate } from '@mwe/utils';

@Pipe({
  name: 'personalNameBirthday'
})
export class PersonalNameBirthdayPipe implements PipeTransform {
  transform(value: IPersonalData): string {
    return `${value.firstName} ${value.lastName}, ${formatDate(value.birthday)}`;
  }
}
