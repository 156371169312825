export type RetryOnFailureOptions<T = unknown> = {
  maxRetryCount?: number;
  delay?: number;
  retryOnError?: boolean;
  validator?: (returnValue: T) => boolean;
};

export const DEFAULT_RETRY_ON_FAILURE_OPTIONS: RetryOnFailureOptions = {
  maxRetryCount: 1,
  retryOnError: true,
};

export function RetryOnFailure<T>(options: RetryOnFailureOptions<T> = DEFAULT_RETRY_ON_FAILURE_OPTIONS) {
  const { maxRetryCount, delay, retryOnError, validator: returnValueValidator } = { ...DEFAULT_RETRY_ON_FAILURE_OPTIONS, ...options };
  return (target: unknown, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: unknown[]) {
      let retryCount = 0;
      let response: T;
      let error: unknown;
      let failedWithError: boolean;
      do {
        failedWithError = false;
        try {
          response = await originalMethod.apply(this, args);
          if (!(returnValueValidator && !returnValueValidator(response))) {
            break;
          }
        } catch (e) {
          if (!retryOnError) {
            throw e;
          }
          error = e;
          failedWithError = true;
        }
        retryCount++;
        if (delay) {
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      } while (retryCount <= maxRetryCount);
      if (failedWithError) {
        throw error;
      }
      return response;
    };
  };
}
