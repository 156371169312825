<div class="big-card d-block justify-content-between" [ngClass]="{ shadow: !noBox, 'p-0': noBox, 'd-md-flex': oneRow }">
  <div class="d-flex flex-column justify-content-center">
    <h6 class="card-h">{{ postCodeAndStreet }}</h6>
    <p class="mb-0">{{ description }}</p>
  </div>
  <div class="d-flex ml-3" data-test="card-with-product-list-categories">
    <div class="icon-text mr-2 mt-3 text-center" *ngFor="let category of categories">
      <mwe-product-icon [category]="category" size="lg"></mwe-product-icon>
    </div>
  </div>
</div>
