import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[mweLinkInLabelClick]',
})
export class LinkInLabelClickDirective {
  @Input() mweLinkInLabelClick: string | string[];
  @Input() linkClickPreventDefault = true;
  @Output() linkClicked = new EventEmitter<string>();

  @HostListener('click', ['$event'])
  @HostListener('touchstart', ['$event.srcElement'])
  onClick(event: MouseEvent): void {
    const targetIds = Array.isArray(this.mweLinkInLabelClick) ? this.mweLinkInLabelClick : [this.mweLinkInLabelClick];
    const target = event.target as HTMLInputElement;
    if (targetIds.find(id => id === target?.id)) {
      if (this.linkClickPreventDefault) {
        event.preventDefault();
      }

      this.linkClicked.emit(target.id);
    }
  }
}
