import { PreisIndikationen, ProduktAttribute } from '@mwe/models';

export const getTarifIndication = (indications: PreisIndikationen[], tarifKey: string): PreisIndikationen => {
  return indications?.find(indication => indication.tarif.ISUTarifKey === tarifKey);
};

export const getTarifBinding = (indication: PreisIndikationen): ProduktAttribute => {
  return getPricePart(indication, 'Vertragsbindung:');
};

export const getPricePart = (indication: PreisIndikationen, partName: string): ProduktAttribute => {
  return indication?.preisbestandteile?.produktAttribute?.find(attribute => attribute.name.trim() === partName);
};
