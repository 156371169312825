import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@mwe/utils';

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
  constructor() {}

  transform(date: string | Date): string {
    return formatDate(date);
  }
}
