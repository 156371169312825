import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountStateService, AppStorageService, AuthService, NavigationStateService } from '@mwe/services';

@Component({
  selector: 'mwe-collaboration-entry',
  template: ``,
})
export class CollaborationEntryComponent implements OnInit {
  constructor(
    private appStorage: AppStorageService,
    private authService: AuthService,
    private accountStateService: AccountStateService,
    private route: ActivatedRoute,
    private navigationStateService: NavigationStateService,
  ) {}

  ngOnInit(): void {
    this.accountStateService.clearBeforeCollaboration();
    this.appStorage.clear();
    this.navigationStateService.setInitialUrl(this.route.snapshot.queryParamMap.get('redirect'));
    this.authService.login();
  }
}
