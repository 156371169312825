import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { IFileModel } from '@mwe/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'mwe-media-preview',
  templateUrl: './media-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaPreviewComponent implements OnInit {
  // is being passed by popupService
  loadedData: IFileModel;
  safeUrl = signal<SafeUrl>(undefined);

  constructor(
    private activeModal: NgbActiveModal,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.safeUrl.set(this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.loadedData.base64}`));
  }

  closePopup(): void {
    this.activeModal.close('OK');
  }
}
