<mwe-custom-popup (dismissClicked)="closePopup()" popupTitle="pdf-bill.push.header.text">
  <div *ngIf="isStateInit()" class="p-lg-2">
    <h4 class="mb-5 text-start">{{ staticContent?.campaign.title }}</h4>

    <mwe-green-check-list class="d-block mb-4" [labels]="campaignReasons" [displayMode]="'grid'"></mwe-green-check-list>

    <h6 class="text-start mb-3">{{ staticContent?.campaign.agreement }}</h6>
    <mwe-multi-product-card-container
      *ngIf="multiProductCards.length > 0"
      [multiProductCards]="multiProductCards"
      [isDisabled]="false"
      [showCompanyReference]="false"
      [showSummarizedCompanyReference]="true"
      [isSliderVisible]="false"
    >
    </mwe-multi-product-card-container>

    <div class="col-12 pl-0 vertical-center mb-4">
      <div>{{ 'pdf-bill.push.sentToDescription' | translate : { email: invoiceEmail } }}</div>
      <div>{{ 'pdf-bill.push.recipientEditableDescription' | translate }}</div>
    </div>

    <div class="row mb-2 flex-column-reverse flex-sm-row justify-content-between align-items-center">
      <div class="text-center col-lg-auto mb-3">
        <div class="w-100 text-center mt-lg-0">
          <mwe-button-link
            [label]="staticContent?.campaign.btnCancel"
            btnId="mwe-decide-later-for-pdf"
            color="blue"
            (clicked)="closePopup()"
          ></mwe-button-link>
        </div>
      </div>

      <div class="text-center col-lg-auto order-lg-0 mb-3">
        <mwe-button
          class="btn-container d-block tertiary"
          buttonId="mwe-switch-pdf-invoice-btn"
          data-test="push-pdf-invoice-switch-btn"
          [labelKey]="staticContent?.campaign.btnSuccess"
          [showHoverEffect]="true"
          (clicked)="confirmPushPdf()"
        >
        </mwe-button>
      </div>
    </div>
  </div>
  <div *ngIf="isStateLoading() || isStateSuccess()">
    <mwe-invoice-data-change-confirmation
      [infoMode]="true"
      class="d-block mb-3"
      (statusSuccess)="onStatusSucess($event)"
      (pushPdfBtnClicked)="onChangeToSepaClicked()"
    ></mwe-invoice-data-change-confirmation>
    <div class="process-footer mt-7" *ngIf="isStateSuccess()">
      <div class="buttons">
        <mwe-button
          class="btn-container d-block primary"
          buttonId="mwe-back-to-product-overview"
          labelKey="pdf-bill.push.button.backToProducts"
          (clicked)="closePopup()"
          [showHoverEffect]="true"
        >
        </mwe-button>
      </div>
    </div>
  </div>
</mwe-custom-popup>
