import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ServerTimeService {
  public resourceUrl: string;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/';
  }

  async getServerTime(): Promise<{ servertime: string }> {
    return lastValueFrom(this.http.get<{ servertime: string }>(this.resourceUrl + 'servertime'));
  }
}
