import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceStateEnum } from '@mwe/constants';

@Component({
  selector: 'mwe-card-with-icon-and-button',
  templateUrl: './card-with-icon-and-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardWithIconAndButtonComponent {
  @Input() cardId: string;
  @Input() iconClass: string;
  @Input() iconImage: string;
  @Input() titleLabel: string;
  @Input() ctaButtonLabel: string;
  @Input() ctaButtonCss: string;
  @Input() ctaButtonDataTest = 'card-with-icon-and-button-button';
  @Input() ctaButtonState = ServiceStateEnum.SUCCESS;
  @Input() ctaButtonStateErrorMessage: string;
  @Input() isCtaButtonLink = true;
  @Input() route: string;
  @Input() isExternalRoute = false;
  @Output() ctaButtonClicked = new EventEmitter<void>();

  constructor(private router: Router) {}

  cardClicked(): void {
    if (!this.route) return;
    if (!this.isExternalRoute) {
      this.router.navigateByUrl(this.route);
      return;
    }

    window.open(this.route, '_blank');
  }
}
