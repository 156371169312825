import { Pipe, PipeTransform } from '@angular/core';
import { TarifOption } from '@mwe/models';

@Pipe({ name: 'tariffOptionInfoTextCode' })
export class TariffOptionInfoTextCodePipe implements PipeTransform {
  transform(value: TarifOption, category: string): string {
    const usePrefix = value.art === 'Zusammensetzung' || value.art === 'Preisobergrenze';
    const prefix = usePrefix ? '_' + category.toLowerCase() : '';
    const artLabel = value.art.toLowerCase();

    return `tarifwelt.optionen.${artLabel}.art-label${prefix}`;
  }
}
