import { Component, Input, OnInit } from '@angular/core';
import { IFormInput } from '@mwe/models';

@Component({
  selector: 'mwe-form-input-help-info',
  templateUrl: './form-input-help-info.component.html',
})
export class FormInputHelpInfoComponent implements OnInit {
  @Input() inputElem: IFormInput;

  constructor() {}

  ngOnInit(): void {}
}
