import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  /*
   * do not use this pipe for user generated content, this pipe is for labels only
   */
  transform(value): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
