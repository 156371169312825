import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Datei,
  HTTPError,
  IBankDetails,
  ILSystem,
  InvoiceDetails,
  InvoiceParts,
  parseBankDetails,
  Rechnung,
  VertragskontoSystemSparte,
} from '@mwe/models';
import { AccountLogic } from '../../account/account.logic';
import { EnvironmentService } from '../../environment/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoiceDataService {
  private readonly invoiceDataResourceUrl: string;

  constructor(
    private http: HttpClient,
    private accountLogic: AccountLogic,
    protected environmentService: EnvironmentService,
  ) {
    this.invoiceDataResourceUrl = this.environmentService.getApiUrl() + 'api/invoicedata/';
  }

  async checkBankDetails(iban: string, pid?: string): Promise<IBankDetails> {
    const _params = new HttpParams().set('pid', pid ? pid : '');
    try {
      const bankDetails: IBankDetails = { iban: iban };
      const url = this.getInvoiceDataResourceUrl() + 'checkBankDetails';
      const res = await lastValueFrom(this.http.post(url, bankDetails, { params: _params }));
      return parseBankDetails(res);
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async getInvoiceDocument(invoiceNumber: string, systemId: ILSystem, accountId: string): Promise<Datei> {
    const _params = new HttpParams().set('systemId', systemId).set('accountId', accountId);
    const url = this.getInvoiceDataResourceUrl() + 'overview/document/' + invoiceNumber;
    const res = await lastValueFrom(this.http.get(url, { params: _params, responseType: 'text' }));
    return { dateiInhalt: res };
  }

  getInvoiceDetails(invoiceNumber: string, systemId: ILSystem): Promise<InvoiceDetails> {
    const _params = new HttpParams().set('systemId', systemId);
    const url = this.getInvoiceDataResourceUrl() + 'overview/details/' + invoiceNumber;
    return lastValueFrom(this.http.get<InvoiceDetails>(url, { params: _params }));
  }

  getInvoiceParts(systemId: ILSystem, accountId: string): Promise<InvoiceParts> {
    const _params = new HttpParams().set('systemId', systemId);
    const url = this.getInvoiceDataResourceUrl() + 'overview/parts/' + accountId;

    return lastValueFrom(this.http.get<InvoiceParts>(url, { params: _params }));
  }

  async getInvoiceList(invoiceQuery: VertragskontoSystemSparte[]): Promise<Rechnung[]> {
    const url = this.getInvoiceDataResourceUrl() + 'overview/list';

    return await lastValueFrom(
      this.http.post<Rechnung[]>(url, {
        VerrechnungskontoSystemSparte: invoiceQuery,
      }),
    );
  }

  private getInvoiceDataResourceUrl(): string {
    if (this.accountLogic.isLoggedOut()) {
      return this.invoiceDataResourceUrl.replace('api', 'api/public');
    }
    return this.invoiceDataResourceUrl;
  }
}
