<div *ngFor="let gruppe of loadedData.gruppen; let idx = index">
  <h3 class="bigger pb-0 mb-0 mt-15" *ngIf="gruppe.darstellungsTyp === 'Überschrift' && showTitle">
    {{ gruppe.anzeigeText }}
  </h3>
  <p
    *ngIf="gruppe.darstellungsTyp.includes('Text')"
    [ngClass]="gruppe.darstellungsTyp.includes('klein') ? 'text--small fst-italic text-start mt-4 mb-0' : 'bigger text-start mt-3 mb-0'"
    [innerHTML]="gruppe.anzeigeText | translate | safeHtml"
  ></p>
  <mwe-dsgvo-slider
    *ngIf="gruppe.darstellungsTyp === 'Schieberegler'"
    [sliderId]="idx"
    [text]="gruppe.anzeigeText"
    [value]="gruppe.defaultZustand"
    [reihe]="gruppe.reihenfolge"
    [disabled]="isSubmitting"
    (returnValueEmitter)="editDsgvoData($event)"
  ></mwe-dsgvo-slider>
</div>

<mwe-alert *ngIf="showAlert" type="danger" [messageKey]="'global.dsgvo.obligatoryFieldsNotSet'"></mwe-alert>

<div *ngIf="showOptionToSaveDecisionFor365Days && isPopUp" class="custom-checkbox custom-control-lg text-start">
  <input type="checkbox" [(ngModel)]="saveDecisionFor365Days" class="custom-control-input" id="mwe-save-decision-365-checkbox" />
  <label class="custom-control-label" for="mwe-save-decision-365-checkbox">
    <p class="bigge text-start ml-3 mb-0" translate="global.dsgvo.saveDecisionFor365Days"></p>
  </label>
</div>

<hr />
<mwe-info-text-card text="{{ 'global.profile.dsgvoSettings.productsInfo' | translate: translateParams }}"></mwe-info-text-card>

<div class="row justify-content-center g-3 mt-2" *ngIf="isPopUp">
  <div class="col-lg-auto text-center">
    <mwe-button
      class="btn-container back"
      buttonId="mwe-dsgvo-all-decline-btn"
      data-test="deselect-all-sliders-btn"
      labelKey="profile.dsgvoSettings.allDecline"
      category="secondary"
      (clicked)="setEverythingToFalse()"
    >
    </mwe-button>
  </div>
  <div class="col-lg-auto text-center ml-lg-auto">
    <mwe-button
      class="btn-container primary"
      buttonId="mwe-dsgvo-all-accept-btn"
      data-test="select-all-sliders-btn"
      labelKey="profile.dsgvoSettings.allAccept"
      category="secondary"
      (clicked)="setEverythingToTrue()"
    >
    </mwe-button>
  </div>
  <div class="col-lg-auto text-center">
    <mwe-button
      class="btn-container tertiary"
      [disabled]="submitButtonDisabled"
      buttonId="mwe-dsgvo-save-btn"
      data-test="dsgvo-save-btn"
      labelKey="profile.dsgvoSettings.save"
      [showHoverEffect]="true"
      (clicked)="submit()"
    >
    </mwe-button>
  </div>
</div>
