<div
  id="flip"
  class="pill-btn"
  data-test="invoice-list-category-filter-container"
  *ngIf="categoryOptions.length > 1"
  ngbDropdown
  placement="bottom-right top-right"
>
  <button class="card tooltip-card no-shadow btn-primary-tertiary--blue" style="padding: 6px 16px" ngbDropdownToggle>
    <div class="d-inline-flex flex-row h-100 align-items-center">
      <div class="bd-highlight flex-fill text-start">
        <p>
          {{
            selectedCategory
              ? (selectedCategory | translateCategory: isFernwaermeTariffClass(selectedCategory) | async)
              : ('interactive-invoices.allCategoriesFilter' | translate)
          }}
        </p>
      </div>
      <div class="bd-highlight ml-1">
        <div class="toolip-right-icon d-flex align-items-center">
          <i class="ml-1 fas fa-angle-down font-size-22" style="color: white"></i>
        </div>
      </div>
    </div>
  </button>

  <div ngbDropdownMenu class="dropdown-menu top-border-0" id="panel">
    <div class="list-group-flush px-0 address-menu text-muted">
      <a
        *ngFor="let category of categoryOptions"
        [ngClass]="{ selected: category === selectedCategory }"
        [attr.data-test]="'invoice-list-category-filter-dropdown-item-' + (category?.toLowerCase() || 'all')"
        class="list-group-item"
        (click)="selectCategory(category)"
        ngbDropdownItem
      >
        <span *ngIf="isFernwaermeTariffClass(category)">
          {{ category | translateCategory: true | async }}
        </span>
        <span *ngIf="!isFernwaermeTariffClass(category)">
          {{ category ? (category | translateCategory | async) : ('interactive-invoices.allCategoriesFilter' | translate) }}
        </span>
      </a>
    </div>
  </div>
</div>
