import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { parseProducts, Product, Address, parseAddress } from '@mwe/models';
import { ProductsWhiteListLogic } from '../products-white-list.logic';

const PRODUCT_BILL_INFO_PRODUCTS_KEY = 'productBillInfo_products';
const PRODUCT_BILL_INFO_ADDRESS_KEY = 'productBillInfo_address';

@Injectable({
  providedIn: 'root',
})
export class ProductBillInfoStateService {
  @SessionStorage(PRODUCT_BILL_INFO_PRODUCTS_KEY)
  private _products: Product[];

  @SessionStorage(PRODUCT_BILL_INFO_ADDRESS_KEY)
  private _address: Address;

  constructor(private storage: SessionStorageService, private productsWhiteListLogic: ProductsWhiteListLogic) {}

  clearData(): void {
    this.storage.clear(PRODUCT_BILL_INFO_PRODUCTS_KEY);
    this.storage.clear(PRODUCT_BILL_INFO_ADDRESS_KEY);
  }

  get products(): Product[] {
    return parseProducts(this._products);
  }

  set products(products: Product[]) {
    this._products = this.productsWhiteListLogic.removeNotWhiteListedProducts(products);
  }

  get address(): Address {
    if (!this._address) {
      return undefined;
    }

    return parseAddress(this._address);
  }

  set address(address: Address) {
    this._address = address;
  }
}
