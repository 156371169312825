import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CryptographyService {
  SERVICE_API_URL = '/api/public/crypto';

  constructor(private http: HttpClient) {}

  decrpytProducts(processId: string, propertyName: string, encryptedData: string): Promise<{ match: unknown[] }> {
    return lastValueFrom(
      this.http.post<{ match: unknown[] }>(
        this.SERVICE_API_URL + '/decrypt?processId=' + processId + '&propertyName=' + propertyName,
        encryptedData,
      ),
    );
  }
}
