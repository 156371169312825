import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mwe-collapse',
  templateUrl: './collapse.component.html',
})
export class CollapseComponent implements OnInit {
  @Input() collapseId: string;
  @Input() titleMessageKey: string;
  @Input() subtitleMessageKey: string;
  @Input() iconClass: string;
  @Input() initialState: 'expanded' | 'collapsed' = 'collapsed';
  @Input() showBoxStyle = true;
  @Input() headerTranslateParams: any;
  @Input() outerClass: string = 'mb-3 mb-lg-4';
  @Input() headerTheme: 'primary' | 'secondary' | 'secondary-light' | 'tertiary' | 'success' | 'info' | 'warning' | 'danger' | 'light' | 'dark' | '' = '';
  @Input() headerMarginPadding: string = 'py-4';
  @Input() isCollapseHeaderVisible = true;
  @Input() contentClass = 'py-4';

  //TODO: delete deprecated variables later
  @Input() headerClass = 'mb-0';
  @Input() addMarginPadding = true;
  // shitty solution but sometimes .epander-item overwrites some simple styles in the content-child-components
  @Input() noExpanderItemClass = false;

  isCollapsed: boolean;
  headerBackgroundColor: string;
  headerTextClass: string;
  chevronButton: string = 'btn-primary';
  ngOnInit(): void {
    this.isCollapsed = this.initialState === 'collapsed';
    this.processHeaderTheme();
  }

  processHeaderTheme(): void {
    if (this.headerTheme === '') {
      return;
    }
    this.headerBackgroundColor = 'bg-' + this.headerTheme;
    this.headerTextClass = 'text-white';
    this.chevronButton = 'btn-ghost';
  }
}
