<div class="popup xlarge">
  <div class="modal-content bg-transparent">
    <div class="modal-body p-0">
      <div class="popup-inner no-icon text-start pt-4">
        <div class="row">
          <div class="col-md-7 col-12">
            <h3 class="bigger text-start" data-test="banner-popup-title">{{ popupData?.title }}</h3>
            <div [innerHTML]="popupData?.content" data-test="banner-popup-content"></div>
          </div>
          <div class="col-md-5 col-12" *ngIf="imageUrl">
            <img [src]="imageUrl" class="p-2 popup-content-img" [alt]="popupData?.image" />
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-sm-auto col-12 mt-1">
            <mwe-button
              [showHoverEffect]="true"
              [labelKey]="popupData?.acceptButton.text"
              (clicked)="submit()"
              buttonId="banner-popup-accept-button"
            ></mwe-button>
          </div>

          <div class="col-sm-auto col-12 mt-1">
            <mwe-button
              category="secondary"
              [showHoverEffect]="false"
              [labelKey]="popupData?.rejectButton"
              (clicked)="cancel()"
              buttonId="banner-popup-reject-button"
            ></mwe-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
