<ng-container [ngSwitch]="headingNodeType">
  <ng-container *ngSwitchCase="'h1'">
    <h1 class="mb-3 h5 no-hyphenate">
      <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ cardTitle | translate: cardTitleParams }}
    </h1></ng-container
  >
  <ng-container *ngSwitchCase="'h2'">
    <h2 class="mb-3 h5 no-hyphenate">
      <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ cardTitle | translate: cardTitleParams }}
    </h2></ng-container
  >
  <ng-container *ngSwitchCase="'h3'">
    <h3 class="mb-3 h5 no-hyphenate">
      <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ cardTitle | translate: cardTitleParams }}
    </h3></ng-container
  >
  <ng-container *ngSwitchCase="'h4'">
    <h4 class="mb-3 h5 no-hyphenate">
      <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ cardTitle | translate: cardTitleParams }}
    </h4></ng-container
  >
  <ng-container *ngSwitchCase="'h5'">
    <h5 class="mb-3 h5 no-hyphenate">
      <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ cardTitle | translate: cardTitleParams }}
    </h5></ng-container
  >
  <ng-container *ngSwitchCase="'h6'">
    <h6 class="mb-3 h5 no-hyphenate">
      <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ cardTitle | translate: cardTitleParams }}
    </h6></ng-container
  >
</ng-container>
