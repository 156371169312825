import { everyTrue, hasSameClearingAccount, isMoveOutOnlineNotPossible, isRelocationChangeOnlineNotPossible } from '@mwe/utils';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { PRODUCTS_DETAILS_ROUTE, PRODUCTS_ROUTE } from '@mwe/constants';
import { ClearingAccount, IProduct, Product, Sparte, UnpaidInvoices } from '@mwe/models';
import { FindPaymentSolutionLogic, ProductDetailsStateService, ProductsWhiteListLogic, RelocationService } from '@mwe/services';

@Component({
  selector: 'mwe-product-card',
  templateUrl: './products-card.component.html',
})
export class ProductsCardComponent implements OnInit {
  @Input() product: IProduct;
  @Input() groupProducts: Product[];
  @Input() state: 'details' | 'list';
  @Input() iconExtendedStyle: string;
  @Input() showCompanyCorrelation;
  @Input() checkIfFernwaermeIsActive = false;
  @Input() unpaidInvoices: UnpaidInvoices;

  categoryIcon: string;
  categoryLabel$: Observable<string>;
  isActive = true;
  onlineNotPossibleWarningLabel: string;
  buttonIsFocused = false;

  private isRelocationOnlineNotPossible = false;
  private isMoveOutOnlineNotPossible = false;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private productDetailsStateService: ProductDetailsStateService,
    private productsWhiteList: ProductsWhiteListLogic,
    private relocationService: RelocationService,
    private findPaymentSolutionLogic: FindPaymentSolutionLogic,
  ) {}

  get isDetailsState(): boolean {
    return this.state === 'details';
  }

  get isClickable(): boolean {
    return this.isActive && this.isDetailsState;
  }

  async ngOnInit(): Promise<void> {
    await this.setNoOnlineCategory();
    this.categoryIcon = this.product.category.toLowerCase();
    this.categoryLabel$ = this.translateService.get(this.product.getCategoryKey());
    this.setActiveState();

    if (!this.isActive && !this.iconExtendedStyle) {
      this.iconExtendedStyle = 'grau';
    }

    if (this.iconExtendedStyle && this.iconExtendedStyle.length > 0) {
      this.categoryIcon = this.categoryIcon + '-' + this.iconExtendedStyle;
    }
  }

  showProductDetails(event): void {
    event.preventDefault();
    this.productDetailsStateService.product = this.product;
    this.productDetailsStateService.groupProducts = this.groupProducts;
    this.router.navigateByUrl(`/${PRODUCTS_ROUTE}/${PRODUCTS_DETAILS_ROUTE}`);
  }

  showUnpaidInvoices(): void {
    const categories = this.groupProducts.filter(gp => hasSameClearingAccount(gp, this.product)).map(gp => gp.category);
    const clearingAccount: ClearingAccount = {
      accountId: this.product.accountNumber,
      systemId: this.product.systemId,
      businessPartnerId: this.product.businessPartnerNumber,
      unpaidInvoices: this.unpaidInvoices,
    };
    this.findPaymentSolutionLogic.navigateToUnpaidInvoices(categories as Sparte[], clearingAccount, this.product.address);
  }

  private setActiveState(): void {
    // some product groups can have unsupported products
    // display unsupported products as inactive products
    this.isActive = this.productsWhiteList.isInWhiteList(this.product.category);
  }

  async setNoOnlineCategory(): Promise<void> {
    const isRelocation = this.product.address.isInRelocation();
    if (everyTrue(isRelocation, isRelocationChangeOnlineNotPossible(this.product.category))) {
      try {
        const relocationData = await this.relocationService.getRelocationSummaryDetailsFor(this.product.address.addressGroup.id);
        this.isRelocationOnlineNotPossible = relocationData.noOnlineCategories.includes(this.product.category);
      } catch {
        // REVIEW !!! is this the correct default behaviour ??
        this.isRelocationOnlineNotPossible = true;
      }
    }

    this.isMoveOutOnlineNotPossible = this.product.address.isInMoveOut() && isMoveOutOnlineNotPossible(this.product.category);

    if (this.isMoveOutOnlineNotPossible || this.isRelocationOnlineNotPossible) {
      this.iconExtendedStyle = '';
      this.product.isAddressDetailsLinkOnlineNotPossible = true;
      this.onlineNotPossibleWarningLabel = this.isMoveOutOnlineNotPossible
        ? 'products.card.moveOutActive'
        : 'products.card.relocationActive';
    }
  }
}
