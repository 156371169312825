import {
  GOODIE_ROUTE,
  INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE,
  NEW_CLIENT_ROUTE,
  PROFILE_NEWSLETTER_ROUTE,
  PROFILE_ROUTE,
  TARIFF_CHANGE_ROUTE,
  TERMS_OF_USE_ROUTE,
} from '@mwe/constants';
import { EnvironmentService, NavigationLogic, ProfileService } from '@mwe/services';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mwe-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  currentYear: number;
  links = {
    b2c: {
      newClient: NEW_CLIENT_ROUTE,
      partialPaymentOverview: INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE,
      goodies: GOODIE_ROUTE,
      newsletter: `${PROFILE_ROUTE}/${PROFILE_NEWSLETTER_ROUTE}`,
      tariffChange: TARIFF_CHANGE_ROUTE,
    },
    b2b: {
      newClient: NEW_CLIENT_ROUTE,
      tariffChange: TARIFF_CHANGE_ROUTE,
      newsletter: `${PROFILE_ROUTE}/${PROFILE_NEWSLETTER_ROUTE}`,
      goodies: GOODIE_ROUTE,
    },
    termsOfUseRoute: `/${this.environmentService.getPortalUrlPrefix()}/${TERMS_OF_USE_ROUTE}`,
  };

  constructor(
    private profileService: ProfileService,
    private environmentService: EnvironmentService,
    private navigationLogic: NavigationLogic,
  ) {}

  ngOnInit(): void {
    this.setCurrentYear();
  }

  setCurrentYear(): void {
    const profileInfo = this.profileService.getProfileInfo();
    const serverTimestamp = profileInfo.serverTimestamp.split('-');
    this.currentYear = new Date(
      parseInt(serverTimestamp[0], 10),
      parseInt(serverTimestamp[1], 10) - 1,
      parseInt(serverTimestamp[2], 10),
    ).getFullYear();
  }

  navigateToB2C(route: string): void {
    this.navigationLogic.navigateToB2C(route);
  }

  navigateToB2B(route: string): void {
    this.navigationLogic.navigateToB2B(route);
  }
}
