import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Sparte } from '@mwe/models';

@Component({
  selector: 'mwe-product-radio-button-selection',
  templateUrl: './product-radio-button-selection.component.html',
})
export class ProductRadioButtonSelectionComponent {
  @Input() categories: Sparte[];
  @Input() canShowError = false;
  @Input() errorMessage: string;
  @Output() selected = new EventEmitter<Sparte>();
  currentCategory: Sparte;

  get isInvalid(): boolean {
    return this.canShowError && !this.currentCategory;
  }

  onSelected(newCategory: Sparte) {
    this.currentCategory = newCategory;
    this.selected.emit(newCategory);
  }
}
