<div class="step-navigation-wrapper" *ngIf="hasLabels()">
  <div
    class="step-navigation"
    [style.--step-navigation-steps-count]="labels.length"
    [style.--step-navigation-steps-complete]="selectedIndex"
    aria-hidden="true"
  >
    <div
      class="step-navigation-step"
      *ngFor="let label of labels; let i = index"
      [ngClass]="{ 'step-navigation-step-complete': i < selectedIndex, 'step-navigation-step-active': i === selectedIndex }"
    >
      <div class="step-navigation-step-counter"></div>
      <div class="step-navigation-step-label text-center text-nowrap" [translate]="label"></div>
    </div>
  </div>
  <div class="sr-only" aria-live="assertive">Schritt {{ selectedIndex + 1 }} von {{ labels.length }}</div>
</div>
