import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[mweSelectFirstInvalidInput]',
})
export class SelectFirstInvalidInputDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const invalidEl = this.el.nativeElement.querySelector('input.ng-invalid');

    if (!invalidEl) {
      return;
    }

    invalidEl.focus();
  }
}
