import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChargingCardOrderStateService } from './charging-card-order-state.service';
import {
  HTTPError,
  IChargingCardOrderDTO,
  IChargingCardOrderStatusResponse,
  parseChargingCardOrderDataDTOFromOrderStatusDetail,
} from '@mwe/models';
import { lastValueFrom } from 'rxjs';
import { AccountLogic } from '../../account/account.logic';
import { EnvironmentService } from '../../environment/environment.service';

@Injectable({ providedIn: 'root' })
export class ChargingCardOrderService {
  public resourceUrl: string;

  constructor(
    private http: HttpClient,
    private accountLogic: AccountLogic,
    private environmentService: EnvironmentService,
    private chargingCardOrderStateService: ChargingCardOrderStateService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/chargingcard/';
  }

  async confirm(addressCode: string, dataDTO: IChargingCardOrderDTO): Promise<IChargingCardOrderStatusResponse> {
    try {
      return await lastValueFrom(
        this.http.post<IChargingCardOrderStatusResponse>(this.getResourceUrl('confirm') + '?addressCode=' + addressCode, dataDTO),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async retry(submitId: number, dataDTO: IChargingCardOrderDTO): Promise<IChargingCardOrderStatusResponse> {
    try {
      return await lastValueFrom(
        this.http.put<IChargingCardOrderStatusResponse>(this.getResourceUrl('retry') + '?submitId=' + submitId, dataDTO),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  confirmStatus(submitId: number): Promise<IChargingCardOrderStatusResponse> {
    const url = this.getResourceUrl('confirmStatus') + '?submitId=' + submitId;
    return lastValueFrom(this.http.get<IChargingCardOrderStatusResponse>(url));
  }

  async getOrderStatusDetails(): Promise<IChargingCardOrderDTO> {
    const response = await lastValueFrom(
      this.http.get(this.resourceUrl + 'orderStatusDetails/' + this.chargingCardOrderStateService.lastOrderId),
    );

    const result = parseChargingCardOrderDataDTOFromOrderStatusDetail(response);
    const mappingData = result.mappingData[0];
    const priceIndication = result.priceIndications[0];
    this.chargingCardOrderStateService.invoiceAddress = result.newAddress;
    this.chargingCardOrderStateService.orderDate = result.registrationDate;
    this.chargingCardOrderStateService.personalData = result.personalData;
    this.chargingCardOrderStateService.bankDetails = result.sepaMandat;
    this.chargingCardOrderStateService.eRechnungsInfo = result.eRechnungsInfo;
    this.chargingCardOrderStateService.productSelection = {
      emobility: {
        sparte: mappingData.categories[0],
        onlineTarif: true,
        kundenType: null,
        tarif: {
          ...priceIndication?.tarif,
          tarifKey: priceIndication?.tarif?.ISUTarifKey,
        },
      },
    };
    this.chargingCardOrderStateService.carModelType = result.carModelType;
    this.chargingCardOrderStateService.businessData = result.businessData;
    this.chargingCardOrderStateService.numberOfCards = result.numberOfCards;

    return result;
  }

  private getResourceUrl(path?: string): string {
    let baseUrl = this.resourceUrl;
    if (this.accountLogic.isLoggedOut()) {
      baseUrl = this.resourceUrl.replace('api', 'api/public');
    }
    return baseUrl + (path ? path : '');
  }
}
