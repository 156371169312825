import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mwe-special-alert',
  templateUrl: './special-alert.component.html',
})
export class SpecialAlertComponent {
  @Input() titleKey;
  @Input() messageKey;
  @Input() reloadButtonKey;
  @Input() showReloadLoadingSpinner = false;
  @Input() noShadow: boolean;
  @Output() reloadEventEmitter = new EventEmitter<any>();

  showTitle(): boolean {
    return this.titleKey && this.titleKey.length > 0;
  }
}
