import { Component, Input, OnInit } from '@angular/core';
import { containsProductCategory, isProductCategoryFernwaerme } from '@mwe/utils';
import { ProductCategoryEnum } from '@mwe/constants';

@Component({
  selector: 'mwe-new-bank-account-info-texts',
  templateUrl: './new-bank-account-info-texts.component.html',
})
export class NewBankAccountInfoTextsComponent implements OnInit {
  isStromGasInfoVisible = false;
  isFernwaermeInfoVisible = false;

  @Input() set productCategories(productCategories: string[]) {
    this.checkInfoVisibility(productCategories);
  }

  constructor() {}

  ngOnInit(): void {}

  private checkInfoVisibility(productCategories): void {
    this.isStromGasInfoVisible = productCategories.some(category => {
      return containsProductCategory(category, [ProductCategoryEnum.STROM, ProductCategoryEnum.NACHTSTROM, ProductCategoryEnum.GAS]);
    });

    this.isFernwaermeInfoVisible = productCategories.some(category => {
      return isProductCategoryFernwaerme(category);
    });
  }
}
