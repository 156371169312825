import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureToggleEnum, MAINTENANCE_ROUTE } from '@mwe/constants';
import { ProfileService } from '@mwe/services';

@Injectable({ providedIn: 'root' })
export class IdleCheckService {
  isUserIdle = false;
  wasWindowInactiveForLongerThanIdleTime = false;

  // 60000 ms = 1 minute
  private readonly TIME_WHEN_USER_IS_CONSIDERED_AS_IDLE = 60000 * 15;

  constructor(private profileService: ProfileService, private router: Router) {}

  moveToMaintenance() {
    this.router.navigate([`/${MAINTENANCE_ROUTE}`]);
  }

  setIsUserIdle(value: boolean) {
    this.isUserIdle = value;
  }

  getIsUserIdle(): boolean {
    return this.isUserIdle;
  }

  setWasWindowInactiveForLongerThanIdleTime(value: boolean) {
    this.wasWindowInactiveForLongerThanIdleTime = value;
  }

  getWasWindowInactiveForLongerThanIdleTime(): boolean {
    return this.wasWindowInactiveForLongerThanIdleTime;
  }

  getTimeWhenUserIsConsideredAsIdle(): number {
    return this.TIME_WHEN_USER_IS_CONSIDERED_AS_IDLE;
  }

  shouldReloadToggles(): Promise<void> {
    if (!this.profileService.shouldRefreshProfileInfo()) {
      return Promise.resolve();
    }

    return this.reloadToggles();
  }

  isMaintenanceEnabled(): boolean {
    return this.profileService.isFeatureToggleEnabled(FeatureToggleEnum.MAINTENANCE_ENABLED);
  }

  private async reloadToggles(): Promise<void> {
    await this.profileService.loadProfileInfo();

    // now active again after trying to see if toggles need to be reloaded
    this.setWasWindowInactiveForLongerThanIdleTime(false);
  }
}
