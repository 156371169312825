import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'euroCurrency' })
export class EuroCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: string | number): string | null {
    return this.currencyPipe.transform(value, 'EUR', 'symbol', '1.2-2', 'de-AT');
  }
}
