import { Component, Input } from '@angular/core';
import { TableDescription } from '@mwe/models';

@Component({
  selector: 'mwe-table-striped',
  templateUrl: './table-striped.component.html',
})
export class TableStripedComponent {
  @Input() description: TableDescription;
  @Input() cellClasses: string[] = [];
}
