import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { from, mergeMap, throwError } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const keycloak = inject(KeycloakService);

  if (req.url.startsWith(environment.apiUrl) || req.url.startsWith(environment.keycloakUrl)) {
    if (keycloak.isTokenExpired()) {
      keycloak
        .updateToken()
        .then(isRefreshed => {
          if (!isRefreshed) {
            keycloak.login();
          }
        })
        .catch(() => {
          return throwError(() => new HttpErrorResponse({ status: 401 }));
        });
    }
    return from(keycloak.getToken()).pipe(
      mergeMap(token => {
        const authReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        });
        return next(authReq);
      }),
    );
  } else {
    return next(req);
  }
};
