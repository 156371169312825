<div class="popup-inner no-icon p-4 p-md-5">
  <main>
    <h1 class="h5" id="mwe_tariff-options-form-headline" translate="tariff.details.chooseOptions"></h1>
    <div class="border-bottom border-gray my-4 pb-4">
      <div class="row g-4 align-items-center">
        <div class="col-auto" aria-hidden="true">
          <mwe-product-icon [category]="loadedData.category" size="lg"></mwe-product-icon>
        </div>
        <div class="col">
          <h2 class="h6 text-start">{{ tariffName }}</h2>
          <div class="mb-0 text--legal text-start text-gray">{{ loadedData.category | correlatedCompanyReference }}</div>
        </div>
      </div>
    </div>

    <mwe-form [formModel]="formModel"></mwe-form>
  </main>

  <div role="navigation" class="d-flex flex-column align-items-center">
    <mwe-button-link
      dataTest="mwe-tariff-options-form-submit"
      customCss="mb-2"
      label="tariff.optionsSelectionForm.submit"
      (clicked)="submit()"
    ></mwe-button-link>
    <mwe-button-link
      dataTest="mwe-tariff-options-form-cancel"
      color="blue"
      label="tariff.optionsSelectionForm.cancel"
      (clicked)="cancel()"
    ></mwe-button-link>
  </div>
</div>
