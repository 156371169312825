import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PriceIndicationCampaignTextList, TariffOptionSap, TariffSelectionCardData, TarifOption } from '@mwe/models';
import { ServiceStateEnum } from '@mwe/constants';
import { getUniqueTariffOptionTypes, isArrayWithMinOneItem, isArrayWithOneItem } from '@mwe/utils';

interface OptionGroups {
  id: string;
  label: string;
  options: TarifOption[];
  selectedTariffOptionId: string;
  infoTextCode: string;
  orderIndex?: number;
  isReadOnly: boolean;
}

@Component({
  selector: 'mwe-tariff-selection-card-ui',
  templateUrl: './tariff-selection-card-ui.component.html',
})
export class TariffSelectionCardUiComponent {
  // TariffSelectionCardComponent is used in multiple places, keep it as simple component. parent-component should communicate with store

  @Input() hasActions: boolean;
  @Input() isReadonly: boolean = false;
  @Input() additionalOptionGroupName: string;
  @Input() lockedGroupIds: string[] = [];
  @Output() tariffUpdated = new EventEmitter<TariffOptionSap>();
  @Output() reloadAfterError = new EventEmitter<void>();
  selectedTariff: TariffSelectionCardData;
  groups: OptionGroups[];
  activeCampaignTexts: PriceIndicationCampaignTextList[];
  advantages: string[];

  get state(): ServiceStateEnum {
    return this.selectedTariff.state;
  }

  // data comes from signal() do not manipulate it!!!! notify parent and parent should change it in TariffSelectionStoreService
  @Input() set tariff(data: TariffSelectionCardData) {
    this.selectedTariff = { ...data };
    this.createOptionsGroups();
    this.createCampaignData();
  }

  getTestId(type: string): string {
    return `tariff-selection-card-${type}`;
  }

  onOptionSelected(item: OptionGroups, optionId: string): void {
    // update userSelection with new selected sap option
    const newSapOption = this.selectedTariff.tariffOptionsSap.find(o => o.option === optionId);
    item.selectedTariffOptionId = optionId;

    this.tariffUpdated.emit(newSapOption);
  }

  private createOptionsGroups(): void {
    // use options from SAP to create groups/default-values, but for UI use options from T2
    // every option with same art is in same dropdown, but ASP is in own card...
    const sapOptionsWithoutAllesSicher = this.selectedTariff.tariffOptionsSap?.filter(o => o.art !== this.additionalOptionGroupName);
    const uniqueGroupTypes = getUniqueTariffOptionTypes(sapOptionsWithoutAllesSicher);

    // every uniqueArt is a dropdown
    this.groups = uniqueGroupTypes
      ?.map(uniqueArt => {
        // unfortunately SAP and T2 have different values for "art"... so we have to use option-ids
        const sapOptions = sapOptionsWithoutAllesSicher?.filter(to => to.art === uniqueArt);
        const options = this.selectedTariff.priceIndication.tarif.tarifOptionen?.filter(to => {
          return sapOptions?.some(so => so.option === to.option);
        });

        if (!isArrayWithMinOneItem(options)) {
          return null;
        }

        // check user selected option
        const userSelectedTariffOption = this.selectedTariff?.userSelection?.find(u => {
          return sapOptions?.some(o => o.option === u?.option);
        });

        const sapOption = sapOptions[0];
        const usePrefix = sapOption.art === 'Zusammensetzung' || sapOption.art === 'Preisobergrenze';
        const prefix = usePrefix ? '_' + this.selectedTariff.category.toLowerCase() : '';
        const artLabel = sapOption.art.toLowerCase();
        const infoTextCode = `tarifwelt.optionen.${artLabel}.art-label${prefix}`;
        const orderIndex = this.selectedTariff.priceIndication.tarif.tarifOptionen.findIndex(i => i.option === options[0].option);
        const isReadOnly = this.isReadonly || isArrayWithOneItem(options) || this.lockedGroupIds?.some(id => id === sapOption.art);

        return {
          id: uniqueArt,
          label: options[0].artLabel,
          options,
          selectedTariffOptionId: userSelectedTariffOption?.option,
          infoTextCode,
          orderIndex,
          isReadOnly,
        };
      })
      ?.filter(g => !!g);

    this.groups.sort((g1, g2) => (g1.orderIndex > g2.orderIndex ? 1 : -1));
  }

  private createCampaignData() {
    if (!this.selectedTariff.priceIndication) {
      return;
    }

    const allItems = this.selectedTariff.priceIndication.aktiveAktionen?.map(a => a.aktionstexte);
    this.activeCampaignTexts = allItems?.flat()?.filter(i => !!i);

    this.advantages = this.selectedTariff.priceIndication.vorteile?.filter(i => !!i);
  }
}
