import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BANK_ACCOUNT_CHANGE_ROUTE,
  CUSTOMER_DATA_CHANGE_ROUTE,
  CUSTOMER_DATA_CHANGE_SELECTION_ROUTE,
  GOODIE_ROUTE,
  INTERACTIVE_INVOICE_DETAILS_ROUTE,
  INTERACTIVE_INVOICE_ROUTE,
  INVOICE_ADDRESS_CHANGE_ROUTE,
  INVOICE_CHANGE_ADDRESS_SELECTION_ROUTE,
  INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE,
  LOCATION_TOKEN,
  MOVE_OUT_ADDRESS_SELECTION_ROUTE,
  MOVE_OUT_ROUTE,
  PDF_INVOICE_CHANGE_ROUTE,
  PRODUCTS_BILLINFO_ROUTE,
  PRODUCTS_CONSUMPTION,
  PRODUCTS_DETAILS_ROUTE,
  PRODUCTS_ROUTE,
  PROFILE_NEWSLETTER_ROUTE,
  PROFILE_ROUTE,
  RELOCATION_CURRENT_ADDRESS_ROUTE,
  RELOCATION_ROUTE,
  SECURE_OFFER_ROUTE,
  TARIFF_CHANGE_ADDRESS_SELECTION_ROUTE,
  TARIFF_CHANGE_ROUTE,
  UNPAID_INVOICES_PAYMENT_OPTIONS_ROUTE,
  UNPAID_INVOICES_ROUTE,
  UNPAID_INVOICES_SELECTION_ROUTE,
  VOUCHER_REDEEM_FORM_ROUTE,
  VOUCHER_REDEEM_ROUTE,
} from '@mwe/constants';
import { NavigationStateService } from '../cache/navigation-state.service';
import { FindPaymentSolutionStateService } from '../invoices/find-payment-solution/find-payment-solution-state.service';
import { InteractiveInvoicesStateService } from '../invoices/interactive-invoices/interactive-invoices-state.service';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({ providedIn: 'root' })
export class NavigationLogic {
  constructor(
    private navigationStateService: NavigationStateService,
    private interactiveInvoicesStateService: InteractiveInvoicesStateService,
    private findPaymentSolutionStateService: FindPaymentSolutionStateService,
    private router: Router,
    private environmentService: EnvironmentService,
    @Inject(LOCATION_TOKEN) private location: Location,
  ) {}

  closeProcess(): Promise<boolean> {
    return this.router.navigateByUrl(this.navigationStateService.wizardCancelUrl);
  }

  getNavigateBackRouteAndRemoveFromState(): string {
    const backRoute = this.getRouteFromLatestNavigatingBackItem();
    this.checkSpecialRouteHandling();
    this.navigationStateService.removeLatestNavigatingBackTo();
    return backRoute;
  }

  getRouteFromLatestNavigatingBackItem(): string {
    switch (this.navigationStateService.getLatestNavigatingBackTo()) {
      case 'InvoiceDetails':
        return `/${INTERACTIVE_INVOICE_ROUTE}/${INTERACTIVE_INVOICE_DETAILS_ROUTE}`;
      case 'ProductDetails':
        return `/${PRODUCTS_ROUTE}/${PRODUCTS_DETAILS_ROUTE}`;
      case 'TariffChange':
        return `/${TARIFF_CHANGE_ROUTE}/${TARIFF_CHANGE_ADDRESS_SELECTION_ROUTE}`;
      case 'Relocation':
        return `/${RELOCATION_ROUTE}/${RELOCATION_CURRENT_ADDRESS_ROUTE}`;
      case 'InvoiceOverview':
        return `/${INTERACTIVE_INVOICE_ROUTE}`;
      case 'PdfInvoiceChange':
        return `/${PDF_INVOICE_CHANGE_ROUTE}/${INVOICE_CHANGE_ADDRESS_SELECTION_ROUTE}`;
      case 'BankAccountChange':
        return `/${BANK_ACCOUNT_CHANGE_ROUTE}/${INVOICE_CHANGE_ADDRESS_SELECTION_ROUTE}`;
      case 'InvoiceAddressChange':
        return `/${INVOICE_ADDRESS_CHANGE_ROUTE}/${INVOICE_CHANGE_ADDRESS_SELECTION_ROUTE}`;
      case 'CustomerDataChange':
        return `/${CUSTOMER_DATA_CHANGE_ROUTE}/${CUSTOMER_DATA_CHANGE_SELECTION_ROUTE}`;
      case 'MoveOut':
        return `/${MOVE_OUT_ROUTE}/${MOVE_OUT_ADDRESS_SELECTION_ROUTE}`;
      case 'VoucherRedeem':
        return `/${VOUCHER_REDEEM_ROUTE}/${VOUCHER_REDEEM_FORM_ROUTE}`;
      case 'Newsletter':
        return `/${PROFILE_ROUTE}/${PROFILE_NEWSLETTER_ROUTE}`;
      case 'Consumption':
        return `/${PRODUCTS_ROUTE}/${PRODUCTS_CONSUMPTION}`;
      case 'Goodies':
        return `/${GOODIE_ROUTE}`;
      case 'UnpaidInvoicesSelection':
        return `/${UNPAID_INVOICES_ROUTE}/${UNPAID_INVOICES_SELECTION_ROUTE}`;
      case 'UnpaidInvoicesOptions':
        return `/${UNPAID_INVOICES_ROUTE}/${UNPAID_INVOICES_PAYMENT_OPTIONS_ROUTE}`;
      case 'PartialPayment':
        return `/${INVOICE_PARTIALPAYMENT_OVERVIEW_ROUTE}`;
      case 'Billinfo':
        return `/${PRODUCTS_ROUTE}/${PRODUCTS_BILLINFO_ROUTE}`;
      case 'SecureOffer':
        return `/${SECURE_OFFER_ROUTE}`;
      default:
        return '';
    }
  }

  checkSpecialRouteHandling(): void {
    switch (this.navigationStateService.getLatestNavigatingBackTo()) {
      case 'InvoiceDetails':
        this.interactiveInvoicesStateService.keepSessionStorage = true;
        break;
      case 'UnpaidInvoicesOptions':
        this.findPaymentSolutionStateService.keepStorageOnce = true;
        break;
    }
  }

  getProcessLabelKeyFromLatestNavigatingBackItem(): string {
    switch (this.navigationStateService.getLatestNavigatingBackTo()) {
      case 'TariffChange':
        return 'tariffChange.title';
      case 'Relocation':
        return 'relocation.title';
      case 'InvoiceOverview':
        return 'interactive-invoices.entry-slider-card';
      case 'PdfInvoiceChange':
        return 'pdfInvoiceChange.entry';
      case 'BankAccountChange':
        return 'bankAccountChange.entry';
      case 'InvoiceAddressChange':
        return 'invoiceAddress.change.entry';
      case 'CustomerDataChange':
        return 'customerData.change.entry';
      case 'MoveOut':
        return 'move-out.entry';
      case 'VoucherRedeem':
        return 'voucher.redeem.entry';
      case 'Newsletter':
        return 'profile.newsletter.title';
      case 'Consumption':
        return 'consumption.menuItem';
      case 'Goodies':
        return 'goodie.title';
      case 'PartialPayment':
        return 'invoice-partialpayment-overview.entry';
      case 'Billinfo':
        return 'billinfo.group.header';
      case 'SecureOffer':
        return 'secure-offer.wizard.entry';
      default:
        return '';
    }
  }

  switchPortal(): void {
    sessionStorage.clear();
    this.location.href = this.getSwitchPortalUrl();
  }

  getSwitchPortalUrl(): string {
    return this.navigationStateService.navbarConfig.profile.portalSwitchRoute;
  }

  navigateToB2C(route: string): void {
    if (this.environmentService.getPortalCustomerType() === 'privat') {
      this.router.navigateByUrl(route);
      return;
    }

    this.location.href = `${this.environmentService.getPrivatPortalUri()}/${route}`;
  }

  navigateToB2B(route: string): void {
    if (this.environmentService.getPortalCustomerType() === 'business') {
      this.router.navigateByUrl(route);
      return;
    }

    this.location.href = `${this.environmentService.getBusinessPortalUri()}/${route}`;
  }
}
