import { Pipe, PipeTransform } from '@angular/core';
import { StaticContentService } from '@mwe/services';

@Pipe({ name: 'addStaticImagePath' })
export class AddStaticImagePathPipe implements PipeTransform {
  constructor(private staticContentService: StaticContentService) {}

  transform(fileName: string): string {
    return `${this.staticContentService.getImagePath()}${fileName}`;
  }
}
