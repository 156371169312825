<div [id]="cardId"  class="box p-3 w-100 text-center mt-7" *ngIf="info?.isVisible">
  <h4><i [ngClass]="info.iconClass"></i><br/>{{ info.title }}</h4>
  <p [innerHTML]="info.text"></p>
  <mwe-button
    class="d-block mb-3"
    color="blue"
    [buttonId]="buttonId"
    [labelKey]="info.btn"
    (clicked)="startProcess()"
  ></mwe-button>
</div>
