import { Component, Input, OnInit } from '@angular/core';
import { getPdfDocumentsSafeUrl, TariffOptionSap, TariffSelectionCardData, TextNodeType } from '@mwe/models';
import { TariffSelectionStoreService } from '../services/tariff-selection-store.service';
import { isArrayWithMinOneItem } from '@mwe/utils';
import { HeadlineService } from '../../headline/headline.service';

@Component({
  selector: 'mwe-tariff-selection-container',
  templateUrl: './tariff-selection-container.component.html',
  providers: [HeadlineService],
})
export class TariffSelectionContainerComponent implements OnInit {
  @Input() isReadOnly: boolean = false;
  @Input() hideAdditionalOptions = false;
  @Input() headlineReference: TextNodeType = 'h3';
  // selectedTariffs comes from signal() do not manipulate it!!!! use TariffSelectionStoreService!!!!!
  @Input() selectedTariffs: TariffSelectionCardData[];

  constructor(
    private store: TariffSelectionStoreService,
    public headlineService: HeadlineService,
  ) {}

  ngOnInit() {
    this.headlineService.setReferenceHeadline(this.headlineReference);
  }

  getTariffPdfUrl(tariffKey: string): string {
    return getPdfDocumentsSafeUrl() + tariffKey;
  }

  checkIfAdditionalCardIsVisible(item?: TariffSelectionCardData): boolean {
    if (this.hideAdditionalOptions) {
      return false;
    }

    if (this.isReadOnly) {
      // user can only store one option of group. not default -> it is selected
      const options = this.getAdditionalOptions(item.userSelection);
      return options?.length && !options?.[0]?.default;
    }

    return this.hasAddidtionalOptions(item.tariffOptionsSap);
  }

  private hasAddidtionalOptions(options: TariffOptionSap[]) {
    return isArrayWithMinOneItem(this.getAdditionalOptions(options));
  }

  private getAdditionalOptions(options: TariffOptionSap[]): TariffOptionSap[] {
    return options?.filter(t => t.art === this.store.additionalOptionGroupName());
  }
}
