import {
  EingangsZahlweg,
  IBusinessData,
  ICustomerBankKonto,
  IDifferentInvoiceRecipient,
  IGeschaeftspartnerBeziehung,
  IPartner,
  IPersonalData,
  IRechnungsempfaenger,
  IVertragskonto,
  Verrechnungskonto,
} from '@mwe/models';
import {
  getAccountCustomerId,
  getAccountOwner,
  getInvoiceAddress,
  getInvoiceCustomerId,
  getInvoiceEmail,
  getInvoiceRecipient,
} from './mwe-util';

export const getPersonalDataFromClearingAccount = (clearingAccount: Verrechnungskonto, fallbackEmail?: string): IPersonalData => {
  const partnerDetail = getAccountOwner(clearingAccount.geschaeftspartnerBeziehungen);
  return getPersonalDataFromPartnerDetail(partnerDetail, fallbackEmail);
};

export const getPersonalDataFromPartnerDetail = (partnerDetail: IGeschaeftspartnerBeziehung, fallbackEmail?: string): IPersonalData => {
  // a B2B-user has {physischePerson: {vorname: ''}} - thanky you SAP/IL...
  if (!partnerDetail?.physischePerson?.vorname) {
    return null;
  }

  const person = partnerDetail.physischePerson;

  return {
    salutation: person.anrede,
    title: { text: person.titelVorgestellt || person.titelNachgestellt, value: '' },
    firstName: person.vorname,
    lastName: person.nachname,
    birthday: person.geburtsdatum,
    telefonNumber: partnerDetail?.telefon?.nummer,
    telefonCountryCode: partnerDetail?.telefon?.land,
    email: partnerDetail?.email?.eMail || fallbackEmail,
  };
};

export const getBusinessDataFromPartnerDetail = (partnerDetail: IGeschaeftspartnerBeziehung, fallbackEmail?: string): IBusinessData => {
  // a B2C-user has {organisation: {organisationsname: ''}} - thanky you SAP/IL...
  if (!partnerDetail?.organisation?.organisationsname) {
    return null;
  }

  const business = partnerDetail.organisation;

  return {
    type: 'BUSINESS',
    companyName: business.organisationsname,
    corporateRegisterNumber: business.firmenbuchnummer,
    uidNumber: business.umsatzsteuerId,
    telefonNumber: partnerDetail?.telefon?.nummer,
    telefonCountryCode: partnerDetail?.telefon?.land,
    email: partnerDetail?.email?.eMail || fallbackEmail,
  };
};

export const getFirstInvoiceMailFromClearingAccount = (details: Verrechnungskonto[]): string => {
  const emails = details.map(v => getInvoiceEmail(v));
  return emails.find(e => !!e);
};

export const getFirstSepaPaymentFromClearingAccount = (details: Verrechnungskonto[]): ICustomerBankKonto => {
  const vk = details.find(v => hasClearingAccountSepaMandant(v));
  if (vk) {
    // if sparte is stromrueck then the data is given by ausgangskonto
    return vk.eingangskonto ? vk.eingangskonto : vk.ausgangskonto;
  }
  return null;
};

export const hasClearingAccountDifferentInvoiceRecipient = (konto: Verrechnungskonto): boolean => {
  return getAccountCustomerId(konto.geschaeftspartnerBeziehungen) !== getInvoiceCustomerId(konto.geschaeftspartnerBeziehungen);
};

export const getDifferentInvoiceRecipientFromClearingAccount = (details: Verrechnungskonto[]): IDifferentInvoiceRecipient => {
  const detail = details.find(d => hasClearingAccountDifferentInvoiceRecipient(d));
  if (!detail) {
    return null;
  }
  const invoiceRecipient = getInvoiceRecipient(detail.geschaeftspartnerBeziehungen);
  return {
    physischePerson: invoiceRecipient.physischePerson,
    invoiceAddress: getInvoiceAddress(detail.geschaeftspartnerBeziehungen),
  };
};

export const isClearingAccountPaidByZahlschein = (vertragskonto?: Verrechnungskonto): boolean => {
  return vertragskonto?.eingangsZahlweg === EingangsZahlweg.Zahlschein;
};

export const hasClearingAccountSepaMandant = (vertragskonto?: Verrechnungskonto): boolean => {
  return vertragskonto && vertragskonto.eingangsZahlweg === EingangsZahlweg.Sepa;
};

export const parseIPartnerFromClearingAccount = (clearingAccount: Verrechnungskonto): IPartner => {
  const accountCustomer = getAccountOwner(clearingAccount.geschaeftspartnerBeziehungen);

  return {
    partnerId: accountCustomer?.geschaeftsPartnerId,
    partnerGruppe: null,
    nickname: null,
    registrierungStatus: null,
    rolle: null,
    partnerTyp: null,
    anrede: accountCustomer?.physischePerson.anrede,
    titel: accountCustomer?.physischePerson.titel,
    nachname: accountCustomer?.physischePerson.nachname,
    vorname: accountCustomer?.physischePerson.vorname,
    firma: null,
    email: accountCustomer?.email?.eMail,
    telefon: accountCustomer?.telefon?.nummer,
    telefonvorwahl: accountCustomer?.telefon?.land,
  };
};

export const parseIRechnungsempfaengerFromClearingAccount = (clearingAccount: Verrechnungskonto): IRechnungsempfaenger => {
  const accountCustomer = getAccountOwner(clearingAccount.geschaeftspartnerBeziehungen);
  const invoiceAddress = getInvoiceAddress(clearingAccount.geschaeftspartnerBeziehungen);
  return {
    partnerId: accountCustomer?.geschaeftsPartnerId,
    partnerGruppe: null,
    nickname: null,
    registrierungStatus: null,
    rolle: null,
    partnerTyp: null,
    anrede: accountCustomer?.physischePerson.anrede,
    titel: accountCustomer?.physischePerson.titel,
    nachname: accountCustomer?.physischePerson.nachname,
    vorname: accountCustomer?.physischePerson.vorname,
    firma: null,
    email: accountCustomer?.email?.eMail,
    telefon: accountCustomer?.telefon?.nummer,
    telefonvorwahl: accountCustomer?.telefon?.land,
    adresse: {
      ort: invoiceAddress.ort,
      plz: invoiceAddress.plz,
      strasse: invoiceAddress.strasse,
      hausnummer: invoiceAddress.hausnummer,
      land: invoiceAddress.land,
      raum: invoiceAddress.raumnummer,
      adresshinweis: invoiceAddress.adresshinweis,
    },
  };
};

export const parseIVertragskontoFromClearingAccount = (customerVertragskonto: Verrechnungskonto): IVertragskonto => {
  return {
    vertragskontoId: customerVertragskonto.verrechnungskontoId,
    zahlungsmethode: hasClearingAccountSepaMandant(customerVertragskonto) ? 'Bankeinzug' : 'Zahlschein',
    kontonummer: null,
    blz: null,
    iban: customerVertragskonto.eingangskonto?.iban,
    bic: customerVertragskonto.eingangskonto?.swift,
  };
};

export const areAllInvoicesSendByPost = (details: Verrechnungskonto[]): boolean => {
  return details.every(detail => !getInvoiceEmail(detail));
};

export const areAllInvoicesPaidByPaymentForm = (details: Verrechnungskonto[]): boolean => {
  return details.every(detail => isClearingAccountPaidByZahlschein(detail));
};
