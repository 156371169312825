import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBaseComponent } from './app/app-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { UiComponentsModule } from '@mwe/ui/components';
import { RouterModule } from '@angular/router';
import { GatewayAuthComponent } from './gateway-auth/gateway-auth.component';

const components = [AppBaseComponent, GatewayAuthComponent];

@NgModule({
  imports: [CommonModule, TranslateModule, UiComponentsModule, RouterModule],
  declarations: components,
  exports: components,
})
export class UiCommonsModule {}
