import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { getFormattedCategory, translateCategory } from '@mwe/utils';
import { LANGUAGES } from './language.constants';

@Injectable({ providedIn: 'root' })
export class LanguageHelperService {
  renderer: Renderer2 = null;
  private _language: BehaviorSubject<string>;

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private router: Router,
    rootRenderer: RendererFactory2
  ) {
    this._language = new BehaviorSubject<string>(this.translateService.currentLang);
    this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null);
    this.init();
  }

  getAll(): Promise<any> {
    return Promise.resolve(LANGUAGES);
  }

  get language(): Observable<string> {
    return this._language.asObservable();
  }

  /**
   * Update the window title using params in the following
   * order:
   * 1. titleKey parameter
   * 2. $state.$current.data.pageTitle (current state page title)
   * 3. 'global.title'
   */
  updateTitle(titleKey?: string, customTitle = ''): void {
    if (!titleKey) {
      return;
    }

    this.updateTitleFromKeys([titleKey], customTitle);
  }

  async updateTitleAndAddTranslatedCategory(titleKeys: string[], category: string): Promise<void> {
    const translatedCategory = await translateCategory(category, this.translateService);
    const formatedCategory = getFormattedCategory(translatedCategory);

    this.updateTitleFromKeys(titleKeys, '', formatedCategory);
  }

  updateTitleFromKeys(titleKeys?: string[], customTitle = '', additionalText = ''): void {
    if (!titleKeys) {
      return;
    }

    this.translateService.get(titleKeys).subscribe(translationObject => {
      const titles = Object.values(translationObject);

      if (customTitle) {
        titles.push(customTitle);
      }

      let newTitle = titles.join(': ');

      if (additionalText) {
        newTitle = `${newTitle} ${additionalText}`;
      }

      this.titleService.setTitle(newTitle);
    });
  }

  private init(): void {
    this.translateService.onLangChange?.subscribe((event: LangChangeEvent) => {
      this._language.next(this.translateService.currentLang);
      this.renderer.setAttribute(document.querySelector('html'), 'lang', this.translateService.currentLang);
      this.updateTitle();
    });
  }
}
