export const cloneDeepJSON = (source: unknown): unknown => {
  if (source) {
    return JSON.parse(JSON.stringify(source));
  }
  return source;
};

export const isEqual = (a, b): boolean => {
  function getType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
  }

  function areArraysEqual() {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!isEqual(a[i], b[i])) return false;
    }
    return true;
  }

  function areObjectsEqual() {
    if (Object.keys(a).length !== Object.keys(b).length) return false;
    for (const key in a) {
      if (Object.prototype.hasOwnProperty.call(a, key)) {
        if (!isEqual(a[key], b[key])) return false;
      }
    }
    return true;
  }

  function areFunctionsEqual() {
    return a.toString() === b.toString();
  }

  function arePrimativesEqual() {
    return a === b;
  }

  const type = getType(a);
  if (type !== getType(a)) return false;

  if (type === 'array') return areArraysEqual();
  if (type === 'object') return areObjectsEqual();
  if (type === 'function') return areFunctionsEqual();
  return arePrimativesEqual();
};

export const uniqBy = (arr, predicate) => {
  if (!Array.isArray(arr)) {
    return [];
  }

  const cb = typeof predicate === 'function' ? predicate : o => o[predicate];

  const pickedObjects = arr
    .filter(item => item)
    .reduce((map, item) => {
      const key = cb(item);

      if (!key) {
        return map;
      }

      return map.has(key) ? map : map.set(key, item);
    }, new Map())
    .values();

  return [...pickedObjects];
};

export type GenericStringRecordType<T extends Record<string, string | boolean>> = {
  [K in keyof T as K]: `${T[K]}`;
};

/**
 * Converts type <T> into Record<string, string>
 * @param object
 */
export function toStringRecord<T extends Record<string, string | boolean>>(object: T) {
  return Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: `${value}`,
    }),
    {} as GenericStringRecordType<T>
  );
}
