<div>
  <div class="sr-only" aria-live="assertive">
    @if (!hasError() && uploadSuccessful()) {
      {{ uploadedFiles()[uploadedFiles().length - 1]?.extension }}-Datei erfolgreich hochgeladen
    }
  </div>
  @if (config.titleKey) {
    <h6 class="mb-4">
      <i class="far fa-file-alt is-blue mr-2" aria-hidden="true"></i>
      {{ config.titleKey | translate }}
    </h6>
  }
  <div
    [infoText]="infoPopup()"
    [innerHTML]="config.descriptionKey | translate | safeHtml"
    class="text--big is-black mb-4"
    modelSize="lg"
    mweInfoTextPopupFromLink="documentInfoPopupOpener"
  ></div>

  <mwe-info-text-card text="upload.information" />
  <hr />
  <div [ngClass]="{ 'mb-2 rounded border border-2 border-danger px-2 py-3': hasError(), 'mb-3 mb-md-4': uploadedFiles().length }">
    <mwe-upload
      (emitUploadResult)="setUploadedFiles($event)"
      (fileUploaded)="setUploadSuccessful()"
      [config]="uploadConfiguration()"
      [deletePopupCancelKey]="deletePopupConfiguration().cancelKey"
      [deletePopupMsgKey]="deletePopupConfiguration().textKey"
      [deletePopupSubmitKey]="deletePopupConfiguration().submitKey"
      [deletePopupTitleKey]="deletePopupConfiguration().titleKey"
      [errorType]="'list'"
      [filesFromStorage]="internalUploadResult().uploadedFiles"
      [hasMarginBottom]="false"
      [processType]="config.processType"
    />
  </div>
  @for (uploadedFile of uploadedFiles(); track $index) {
    <div class="row gx-3 mt-3 flex-nowrap">
      <div class="col-auto" style="width: 2.875rem">
        @if (uploadedFile?.showPreview) {
          <i aria-hidden="true" class="d-block fa-solid fa-file-image fs-3 is-blue"></i>
        }
        @if (!uploadedFile?.showPreview) {
          @if (!uploadedFile.showError) {
            <i aria-hidden="true" class="d-block fa-solid fa-file-pdf fs-3 is-blue"></i>
          }

          @if (uploadedFile.showError) {
            <i aria-hidden="true" class="d-block fa-solid fa-exclamation-triangle fs-3 is-red"></i>
          }
        }
      </div>
      <div class="col overflow-hidden">
        <div class="text-truncate fw-semibold" [ngClass]="{ 'text-danger': uploadedFile.showError }">
          {{ uploadedFile.name }}
        </div>
        <div>{{ uploadedFile.formattedSize }} | {{ uploadedFile.extension }}</div>
      </div>
      <div class="col-auto">
        <div class="row gx-2 justify-content-end">
          @if (!hasError()) {
            <div class="col-auto">
              <button
                (click)="previewFile(uploadedFile)"
                class="btn btn-bubble btn-bubble-s bg-gray--400"
                type="button"
                title="upload.showPreview"
              >
                <i aria-hidden="true" class="text-secondary fas fa-eye"></i>
              </button>
            </div>
          }
          <div class="col-auto">
            <button
              (click)="removeFile(uploadedFile)"
              class="btn btn-bubble btn-bubble-s bg-gray--400"
              type="button"
              [attr.title]="'upload.deleteItemLabel' | translate"
            >
              <i [ngClass]="{ 'text-danger': uploadedFile.showError }" aria-hidden="true" class="text-secondary fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  }
  @if (hasError()) {
    <div class="font-size-11 text-danger mt-1">
      <i aria-hidden="true" class="fa fa-exclamation-circle mr-1"></i>
      <span>
        @if (showValidationError()) {
          {{ config.validationErrorKey | translate }}
        } @else if (showUploadError()) {
          {{ config.uploadErrorKey | translate }}
        }
      </span>
    </div>
  }
</div>
