import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClearingAccountLogic, InvoiceDataChangeLogic } from '@mwe/services';
import { Address, DataChangeType, Product, PushPdfInfo } from '@mwe/models';

@Component({
  selector: 'mwe-push-pdf-info-on-confirmation',
  templateUrl: './push-pdf-info-on-confirmation.component.html',
})
export class PushPdfInfoOnConfirmationComponent {
  @Input() info: PushPdfInfo;
  @Input() targetMode: DataChangeType;
  @Input() products: Product[];
  @Input() address: Address;
  @Output() startProcessBtnClicked = new EventEmitter<void>();

  get buttonId(): string {
    return this.cardType + 'btn';
  }

  get cardId(): string {
    return this.cardType + 'card';
  }

  private get cardType(): string {
    return this.targetMode === DataChangeType.BankAccountChangeOnly ? 'mwe-switch-to-sepa-' : 'mwe-switch-to-pdf-';
  }

  constructor(
    private logic: InvoiceDataChangeLogic,
    private clearingAccountLogic: ClearingAccountLogic
  ) {}

  async startProcess(): Promise<void> {
    this.startProcessBtnClicked.emit();
    this.clearingAccountLogic.clearProductDetails();

    if (this.targetMode === DataChangeType.BankAccountChangeOnly) {
      this.logic.startBankAccountChangeWithoutAddressSelection(this.products, this.address);
    } else if (this.targetMode === DataChangeType.PdfInvoiceChangeOnly) {
      this.logic.startPdfInvoiceChangeWithoutAddressSelection(this.products, this.address);
    }
  }
}
