import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AccountService } from '../account/account.service';
import { EnvironmentService } from '../environment/environment.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileLogic {
  constructor(
    private authService: AuthService,
    private environmentService: EnvironmentService,
    private accountService: AccountService,
    private oauthService: OAuthService
  ) {}

  openLogwienProfile(): void {
    window.open(this.environmentService.getLogwienProfileUri(), 'logwien_SelfService');
  }
}
