<mwe-custom-popup (dismissClicked)="closePopup(true)" [popupTitle]="staticContent?.campaign.header">
  <div *ngIf="isStateInit()" class="p-lg-2">
    <div class="row flex-column-reverse flex-lg-row">
      <div class="col-lg-7 col-12">
        <h4 class="mb-5 text-start">{{ staticContent?.campaign.title }}</h4>

        <p>{{ staticContent?.campaign.subtitle }}</p>

        <mwe-green-check-list
          class="d-block mb-4"
          [labels]="[staticContent?.campaign.reason_1, staticContent?.campaign.reason_2, staticContent?.campaign.reason_3]"
        ></mwe-green-check-list>
      </div>
      <div class="col-lg-5 col-12 d-flex justify-content-center">
        <img class="mr-1 mb-3" src="{{ staticContent?.campaign.imgSrc }}" alt="" width="300" />
      </div>
    </div>
    <div class="row mb-2 flex-lg-row align-items-center">
      <div class="text-center col-lg-auto order-lg-0 mb-3">
        <mwe-button
          class="btn-container d-block tertiary"
          buttonId="mwe-switch-to-sepa-overlay-btn"
          data-test="push-sepa-invoice-switch-btn"
          [labelKey]="staticContent?.campaign.btnSuccess"
          [showHoverEffect]="true"
          (clicked)="confirmPushSepa()"
        >
        </mwe-button>
      </div>
      <div class="text-center col-lg-auto mb-3">
        <div class="w-100 text-center mt-lg-0">
          <mwe-button-link
            [label]="staticContent?.campaign.btnCancel"
            btnId="mwe-switch-to-sepa-overlay-reminder"
            color="blue"
            (clicked)="closePopup(false)"
          ></mwe-button-link>
        </div>
      </div>
    </div>
  </div>
</mwe-custom-popup>
