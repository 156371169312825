<div class="switch-3-way-container mt-3">
  <div class="switch-3-way">
    <input
      type="radio"
      id="{{ 'switch-' + sliderId }}-off"
      class="switch-off"
      [value]="'false'"
      [(ngModel)]="value"
      [name]="'switch-' + sliderId"
      [attr.name]="'switch-' + sliderId"
      (ngModelChange)="returnValues()"
      [disabled]="disabled"
    />
    <label for="{{ 'switch-' + sliderId }}-off"></label>
    <input
      type="radio"
      id="{{ 'switch-' + sliderId }}-neutral"
      class="switch-neutral"
      [value]="'undefined'"
      [(ngModel)]="value"
      [name]="'switch-' + sliderId"
      [attr.name]="'switch-' + sliderId"
      (ngModelChange)="returnValues()"
      [disabled]="disabled"
    />
    <label for="{{ 'switch-' + sliderId }}-neutral"></label>
    <input
      type="radio"
      id="{{ 'switch-' + sliderId }}-on"
      class="switch-on"
      [value]="'true'"
      [(ngModel)]="value"
      [name]="'switch-' + sliderId"
      [attr.name]="'switch-' + sliderId"
      (ngModelChange)="returnValues()"
      [disabled]="disabled"
    />
    <label for="{{ 'switch-' + sliderId }}-on"></label>

    <div class="switch-3-way__inner"></div>
  </div>
  <span class="label">
    <p class="bigger text-start ml-3 mb-0">{{ text }}</p>
  </span>
</div>
