<ul class="nav nav-pills tab-btns mb-3" role="tablist" data-bs-toggle="buttons">
  <li class="nav-item" role="presentation">
    <a
      id="{{ leftButtonId }}"
      class="nav-link pointer"
      data-bs-toggle="pill"
      role="tab"
      aria-controls="pills-home"
      [ngClass]="{ active: isActive }"
      (click)="onClickLeft(leftButtonAction)"
    >
      {{ leftButtonLabel | translate }}
    </a>
  </li>

  <li class="nav-item ml-0" role="presentation">
    <a
      id="{{ rightButtonId }}"
      class="nav-link pointer"
      data-bs-toggle="pill"
      role="tab"
      aria-controls="pills-profile"
      [ngClass]="{ active: !isActive }"
      (click)="onClickRight(rightButtonAction)"
    >
      {{ rightButtonLabel | translate }}
    </a>
  </li>
</ul>
