import { Component, OnInit } from '@angular/core';
import { PUSH_SEPA_POPUP_CLOSED, PUSH_SEPA_POPUP_PERIOD_AFTER, ServiceStateEnum } from '@mwe/constants';
import { ISepaBillActivationConfiguration } from '@mwe/models';
import { StaticContentService, UserJournalService, InvoiceDataChangeLogic, InvoiceDataChangeStateService } from '@mwe/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceStateEnumExporter } from '@mwe/ui/shared';

@Component({
  selector: 'mwe-push-sepa',
  templateUrl: './push-sepa.component.html',
})
export class PushSepaComponent extends ServiceStateEnumExporter implements OnInit {
  state: ServiceStateEnum = ServiceStateEnum.INIT;
  staticContent: ISepaBillActivationConfiguration;

  constructor(
    private invoiceDataChangeStateService: InvoiceDataChangeStateService,
    private invoiceDataChangeLogic: InvoiceDataChangeLogic,
    private activeModal: NgbActiveModal,
    private userJournalService: UserJournalService,
    private staticContentService: StaticContentService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.staticContent = await this.staticContentService.loadSepaBillActivationConfiguration();
  }

  closePopup(pause: boolean): void {
    if (pause && this.state !== ServiceStateEnum.SUCCESS) {
      this.userJournalService.updateEvent(PUSH_SEPA_POPUP_CLOSED, PUSH_SEPA_POPUP_PERIOD_AFTER);
    }

    this.invoiceDataChangeStateService.clear();
    this.activeModal.close('OK');
  }

  confirmPushSepa(): void {
    this.invoiceDataChangeLogic.startBankAccountChangeWithoutAddressSelection(
      this.invoiceDataChangeStateService.products,
      this.invoiceDataChangeStateService.address
    );
    this.activeModal.close('OK');
  }

  onStatusSucess(success: boolean) {
    if (success) {
      this.state = ServiceStateEnum.SUCCESS;
    }
  }

  isStateLoading(): boolean {
    return this.state === ServiceStateEnum.LOADING;
  }

  isStateInit(): boolean {
    return this.state === ServiceStateEnum.INIT;
  }

  isStateSuccess(): boolean {
    return this.state === ServiceStateEnum.SUCCESS;
  }
}
