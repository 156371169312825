import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FrontpageAlertData } from '@mwe/models';
import { IkpEnvironmentService } from '../environment/ikp-environment.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private envService = inject(IkpEnvironmentService);
  protected httpClient = inject(HttpClient);
  protected apiUrl = this.envService.getApiUrl();
  protected staticContentUrl = this.envService.getStaticContentUrl();

  getTemporaryAlertData(): Observable<FrontpageAlertData> {
    return this.httpClient.get<FrontpageAlertData>(`${this.staticContentUrl}/frontpage-alert.json`);
  }

  downloadPdf(invoiceNumber: string): Observable<Blob> {
    const endpointUrl = `${this.apiUrl}/v1/ikp/auth/invoice/${invoiceNumber}/pdf`;
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.httpClient.get(endpointUrl, { headers: headers, responseType: 'blob', params: { systemId: 'SGX200' } });
  }
}
