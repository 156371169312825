import dayjs, { Dayjs } from 'dayjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { getDayJsFromNgbDateStruct, NgbDateDayjsAdapter } from './datepicker-adapter';
import { everyTrue, isArrayWithMinOneItem } from '../common/mwe-util';

export function isValidBirthdate(date: Date | string | Dayjs): boolean {
  const isProbablyDead = dayjs(date).isBefore(dayjs('1900-01-01'));

  return !isProbablyDead && isValidDate(date);
}

export function isValidDate(date: Date | string | Dayjs): boolean {
  return dayjs(date, 'YYYY-MM-DD', true).isValid();
}

export const formatDateAsDDMMYYY = (date: Date, separator?: string): string => {
  const _separator = separator ? separator : '.';
  return formatTwoDigitNumber(date.getDate()) + _separator + formatTwoDigitNumber(date.getMonth() + 1) + _separator + date.getFullYear();
};

function formatTwoDigitNumber(n: number): string {
  return n < 10 ? '0' + n : '' + n;
}

export const getLastMonthEndDate = (d = new Date()): Date => {
  d.setDate(1); // going to 1st of the month
  d.setHours(-1); // going to last hour before this date even started.
  return d;
};

export const getFirstOfNextMonthOneYearBack = (date: Date): Date => {
  const d = new Date(date.getTime());
  d.setDate(1);
  d.setMonth(d.getMonth() + 1);
  d.setFullYear(d.getFullYear() - 1);
  return d;
};

export const formatOrderDate = (isoDate: string): string => {
  return dayjs(isoDate, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm');
};

export const formatDate = (date: Date | string | Dayjs, format?: string): string => {
  return dayjs(date).format(format ? format : 'DD.MM.YYYY');
};

export const formatISODate = (yyyy: string, mm: string, dd: string): string => {
  if (!yyyy || !mm || !dd) {
    return '';
  }

  return yyyy + '-' + mm + '-' + dd;
};

export const getYear = (date: Dayjs | string): string => {
  return dayjs(date).format('YYYY');
};

export const getMonthLabel = (date: Dayjs | string): string => {
  const d = dayjs(date);

  if (!d.isValid()) {
    return '';
  }

  return `shared.month.${d.month() + 1}`;
};

export const convertUserJournalDateToDayJsObj = (userJournalDate: any) => {
  return dayjs(userJournalDate);
};

export const splitISODate = (yyyymmdd: string): string[] => {
  if (!yyyymmdd) {
    return [];
  }

  return yyyymmdd.split('-');
};

export const formatDateDP = (date: string | dayjs.Dayjs, stringFormat?: string): string => {
  let _date = '';

  if (typeof date === 'string') {
    // !dayjs(date).isValid() test if it is a valid iso8601 date
    _date = everyTrue(!!stringFormat, date !== '', !date.match(/(\d{4})-(\d{2})-(\d{2})/))
      ? dayjs(date, stringFormat).format('YYYY-MM-DD')
      : date;
  } else if (date) {
    // we assume that wee got a dayjs object
    _date = date.format('YYYY-MM-DD');
  }

  return _date;
};

export const newOrderTimeRange = (baseDate?: dayjs.Dayjs): NgbDateStruct[] => {
  if (!baseDate) {
    baseDate = dayjs();
  }

  const ngbDateDayjsAdapter = new NgbDateDayjsAdapter();
  const rangeStart = ngbDateDayjsAdapter.fromModel(baseDate.subtract(85, 'day'));
  const rangeEnd = ngbDateDayjsAdapter.fromModel(baseDate.add(30, 'day'));

  return [rangeStart, rangeEnd];
};

export const newOrderTimeRangeInFuture = (baseDate?: dayjs.Dayjs): NgbDateStruct[] => {
  if (!baseDate) {
    baseDate = dayjs();
  }

  const ngbDateDayjsAdapter = new NgbDateDayjsAdapter();
  const rangeStart = ngbDateDayjsAdapter.fromModel(baseDate.add(15, 'day'));
  const rangeEnd = ngbDateDayjsAdapter.fromModel(baseDate.add(3, 'month'));

  return [rangeStart, rangeEnd];
};

export const createDatePickerTimeRange = (monthsInPast: number, monthsInFuture: number, baseDate?: dayjs.Dayjs): NgbDateStruct[] => {
  if (!baseDate) {
    baseDate = dayjs();
  }

  const ngbDateDayjsAdapter = new NgbDateDayjsAdapter();
  const rangeStart = ngbDateDayjsAdapter.fromModel(baseDate.subtract(monthsInPast, 'month'));
  const rangeEnd = ngbDateDayjsAdapter.fromModel(baseDate.add(monthsInFuture, 'month'));

  return [rangeStart, rangeEnd];
};

export const createBirthDatePickerTimeRange = (baseDate?: dayjs.Dayjs): NgbDateStruct[] => {
  if (!baseDate) {
    baseDate = dayjs();
  }

  const ngbDateDayjsAdapter = new NgbDateDayjsAdapter();
  const rangeStart = ngbDateDayjsAdapter.fromModel(baseDate.subtract(100, 'year'));
  const rangeEnd = ngbDateDayjsAdapter.fromModel(baseDate.subtract(18, 'year'));

  return [rangeStart, rangeEnd];
};

export const isDateInTimeRange = (value: Dayjs | string, minValue: NgbDateStruct, maxValue: NgbDateStruct): boolean => {
  const date = dayjs(value);
  const minDate = getDayJsFromNgbDateStruct(minValue);
  const maxDate = getDayJsFromNgbDateStruct(maxValue);

  if (!date?.isValid() || !minDate?.isValid() || !maxDate?.isValid()) {
    return false;
  }

  return minDate.isBefore(date) && maxDate.isAfter(date);
};

export const getMaxDate = (dates: string[]): string => {
  if (!isArrayWithMinOneItem(dates)) {
    return '';
  }

  const sortedDates = dates.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? 1 : -1));
  return sortedDates[0];
};

export const getMinDate = (dates: string[]): string => {
  if (!isArrayWithMinOneItem(dates)) {
    return '';
  }

  const sortedDates = dates.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? -1 : 1));
  return sortedDates[0];
};

export const isDateInFuture = (date: string | Dayjs): boolean => {
  return dayjs(date).isAfter(dayjs());
};

export const isAfterToday = (date: string | Dayjs): boolean => {
  return dayjs(date)?.isAfter(new Date());
};
