<div data-test="wizard-product-card-container-toggle-text" class="product-card-dropdown mb-0">
  <div class="row">
    <div class="col-10">
      <p *ngIf="toggleLabel" class="text--big mb-0 pt-1 text-start" translate="{{ toggleLabel }}"></p>
    </div>
    <div class="col-2">
      <div class="switch r">
        <input type="checkbox" class="checkbox" (change)="onProductSelect($event.target.checked)" [checked]="isOrdered" />
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
    </div>
  </div>
</div>
