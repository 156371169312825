import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureToggleEnum } from '@mwe/constants';
import { ProfileService } from '@mwe/services';

@Directive({
  selector: '[mweIsAnyFeatureEnabled]',
})
export class IsAnyFeatureEnabledDirective {
  private hasView = false;

  @Input() set mweIsAnyFeatureEnabled(toggles: FeatureToggleEnum[]) {
    const isEnabled = this.profileService.isAnyFeatureToggleEnabled(toggles);

    if (isEnabled && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isEnabled && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private profileService: ProfileService) {}
}
