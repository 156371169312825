import { Component, Input, OnInit } from '@angular/core';
import { TextNodeType } from '@mwe/models';
import { HeadlineService } from './headline.service';

@Component({
  selector: 'mwe-headline',
  templateUrl: './headline.component.html',
})
export class HeadlineComponent implements OnInit {
  @Input() cssClass: string;
  @Input() iconClass: string;
  @Input() text: string;
  @Input() textParams;
  @Input() headlineLevelIncrement: number | undefined;
  @Input() headlineNodeType: TextNodeType;

  constructor(private headlineService: HeadlineService) {}

  ngOnInit() {
    if (this.headlineLevelIncrement !== undefined) {
      this.headlineNodeType = this.headlineService.getHeadline(this.headlineLevelIncrement);
    }
  }
}
