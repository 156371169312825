<button
  class="badge badge-pill badge-{{ type }} text-wrap mb-4"
  [title]="labelText | translate : { value: text }"
  [attr.aria-label]="labelText | translate : { value: text }"
  (click)="remove($event)"
  #button
>
  {{ text | translate }}
  <span href="#" *ngIf="closable">
    <i class="fas fa-times" aria-hidden="true"></i>
    <span class="sr-only" translate="address-input.deleteSelection"></span>
  </span>
</button>
