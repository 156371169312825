import { Component } from '@angular/core';
import { InfoText } from '@mwe/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mwe-info-text-popup',
  templateUrl: './info-text-popup.component.html',
})
export class InfoTextPopupComponent {
  // is being passed by popupService
  loadedData: InfoText;

  constructor(private activeModal: NgbActiveModal) {}

  closePopup(): void {
    this.activeModal.close('OK');
  }
}
