<header class="bg-primary" style="height: 60px">
  <div class="row h-100 justify-content-between align-items-center flex-no-wrap">
    <div class="col-auto flex-shrink-1 minw-0">
      <div class="d-flex align-items-center">
        <mwe-header-logo-image aria-hidden="true" class="d-block ml-3"></mwe-header-logo-image>
        <div class="h6 mb-0 text-white text-truncate">{{ titleKeyTranslated }}</div>
      </div>
    </div>
    <div class="col-auto">
      <button
        class="btn btn-clean btn-bubble text-white p-1 mr-3"
        *ngIf="isClosingAllowed"
        id="mwe-wizard-header-close"
        data-test="mwe-wizard-header-close"
        (click)="cancelOnHeader()"
        [title]="'shared.wizard.header.cancelAria' | translate: { name: titleKeyTranslated }"
      >
        <i class="far fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</header>
