import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IBankDetails, IIBANFormConfig } from '@mwe/models';
import { anyTrue, removeAllSpaces } from '@mwe/utils';
import { InvoiceDataService, LoggingService } from '@mwe/services';

@Component({
  selector: 'mwe-iban-form',
  templateUrl: './iban-form.component.html',
})
export class IBANFormComponent implements OnInit {
  @Input() config: IIBANFormConfig;
  @Output() formValidating = new EventEmitter<boolean>();
  @Output() formSubmitted = new EventEmitter<IBankDetails>();
  @Output() validationErrors = new EventEmitter<boolean>();
  form: UntypedFormGroup;
  ibanControl: UntypedFormControl;
  ibanControlName = 'mwe-iban-form-iban';
  iban: string;
  isValid = false;
  inputAriaValid: boolean | undefined = undefined;
  isValidating = false;
  ibanError = false;
  ibanError70XX = false;
  error = false;
  httpPromise: Promise<IBankDetails>;
  errorTitle: Map<string, string> = new Map();
  errorDescription: Map<string, string> = new Map();
  alertDefaultTitleKey = 'error.service.default.title';
  alertDefaultMsgKey = 'error.service.default.message';
  alertTitleKey = this.alertDefaultTitleKey;
  alertMsgKey = this.alertDefaultMsgKey;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private bankAccountService: InvoiceDataService,
    private loggingService: LoggingService,
  ) {}

  ngOnInit(): void {
    let errorCodes = ['1010', '1011', '1012'];
    errorCodes.forEach(code => {
      this.errorTitle.set(code, '');
      this.errorDescription.set(code, 'ibanForm.errors.1010.message');
    });
    errorCodes = [];
    for (let i = 1; i < 20; i++) {
      errorCodes.push('' + (7000 + i));
    }
    errorCodes.forEach(code => {
      this.errorTitle.set(code, '');
      this.errorDescription.set(code, 'ibanForm.errors.7001_7019.message');
    });

    this.errorTitle.set('1013', '');
    this.errorDescription.set('1013', 'ibanForm.errors.1013.message');
    this.setDefaultLabels();
    this.initFormModel();
  }

  async validateForm(): Promise<void> {
    if (!this.isValidating) {
      Object.keys(this.form.controls).forEach(controlName => this.form.controls[controlName].markAsDirty());
      return anyTrue(this.ibanError, this.ibanError70XX, !!this.ibanControl.errors) ? Promise.reject() : Promise.resolve();
    }
    await Promise.all([this.httpPromise])
      .then(() => {
        // sometime on click weiter we need some timeout
        // else it's not moving to next page
        setTimeout(() => {}, 10);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async validate(): Promise<void> {
    const value = this.iban;
    if (anyTrue(this.isValid, this.isValidating)) {
      return;
    }

    if (value?.length > 12) {
      this.emitValidating(true);

      const ibanStr = removeAllSpaces(this.iban);
      this.httpPromise = this.bankAccountService.checkBankDetails(ibanStr, this.config.processId);
      await this.httpPromise
        .then(payload => {
          this.inputAriaValid = true;
          this.resetErrors();
          if (payload) {
            this.validateBankDetails(payload);
          }
        })
        .catch(ex => {
          this.inputAriaValid = false;
          this.resetErrors();
          this.loggingService.logError(ex, `validation failed`);
          if (ex.system === 'IL') {
            if (ex.error?.code) {
              if (this.errorDescription.has(ex.error.code)) {
                this.error = true;
                this.alertTitleKey = this.errorTitle.get(ex.error.code);
                this.alertMsgKey = this.errorDescription.get(ex.error.code);
                this.emitErrorValues();
                const codeValue = Number(ex.error.code);
                if (codeValue > 7000 && codeValue < 7020) {
                  this.ibanError = false;
                  this.ibanError70XX = true;
                }
              } else {
                this.emitErrorValues();
              }
            } else {
              this.emitErrorValues();
            }
          } else {
            this.error = true;
            this.setDefaultAlertKeys();
            this.emitValidating(false);
          }
        });
    } else if (value?.length > 1) {
      this.emitErrorValues();
    }
  }

  onInputIBAN(newValue: string): void {
    this.isValid = false;
    this.iban = newValue;
  }

  validateBankDetails(bankDetails: IBankDetails): void {
    const bankdaten = bankDetails.bankdaten;
    if (bankdaten) {
      bankDetails.iban = removeAllSpaces(this.iban);
      if (bankdaten.bic === '-') {
        bankdaten.bic = '';
      }
      this.isValid = true;
      this.formSubmitted.emit(bankDetails);
      this.emitValidating(false);
    } else {
      this.emitErrorValues();
    }
  }

  private initFormModel(): void {
    this.iban = this.config.iban;
    const group = {};
    this.ibanControl = new UntypedFormControl(this.iban || '', {
      validators: [Validators.required, Validators.minLength(2)],
      updateOn: 'blur',
    });
    group[this.ibanControlName] = this.ibanControl;
    this.form = this.formBuilder.group(group);
  }

  private setDefaultLabels(): void {
    if (!this.config) {
      this.config = {};
    }
    if (!this.config.iban) {
      this.config.iban = '';
    }
    if (!this.config.bic) {
      this.config.bic = '';
    }
    if (!this.config.ibanLabelKey) {
      this.config.ibanLabelKey = 'ibanForm.iban.label';
    }
    if (!this.config.ibanPlaceholderKey) {
      this.config.ibanPlaceholderKey = 'ibanForm.iban.placeholder';
    }
    if (!this.config.ibanErrorKey) {
      this.config.ibanErrorKey = 'ibanForm.iban.error';
    }
  }

  private setDefaultAlertKeys() {
    this.alertTitleKey = this.alertDefaultTitleKey;
    this.alertMsgKey = this.alertDefaultMsgKey;
  }

  private emitErrorValues(): void {
    this.ibanError = true;
    this.validationErrors.emit(true);
    this.emitValidating(false);
  }

  private emitValidating(validating: boolean): void {
    this.isValidating = validating;
    this.formValidating.emit(this.isValidating);
  }

  private resetErrors(): void {
    this.error = false;
    this.ibanError = false;
    this.isValid = false;
  }
}
