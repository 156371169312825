import { TarifOption } from '@mwe/models';

window['dataLayer'] = window['dataLayer'] || [];

export const track = (event: string, contents: object) => {
  const entry = { ...contents, event };
  window['dataLayer']?.push(entry);
};

export const getTrackingDataTarifOptionsStrom = (tarifOptions: TarifOption[]): string[] => {
  if (tarifOptions.length === 0) {
    return ['12', 'okopure', 'false'];
  }
  const trackingTarifOptions = [];
  checkIfTarifOptionIsSelected(tarifOptions, 'SFAC_VB') ? trackingTarifOptions.push('0') : trackingTarifOptions.push('12');
  checkIfTarifOptionIsSelected(tarifOptions, 'SFAC_SON')
    ? trackingTarifOptions.push('sonnenmix')
    : checkIfTarifOptionIsSelected(tarifOptions, 'SFAC_BAS')
      ? trackingTarifOptions.push('basismix')
      : trackingTarifOptions.push('okopure');
  checkIfTarifOptionIsSelected(tarifOptions, 'SFAC_ASP') ? trackingTarifOptions.push('true') : trackingTarifOptions.push('false');
  return trackingTarifOptions;
};

export const getTrackingDataTarifOptionsGas = (tarifOptions: TarifOption[]): string[] => {
  if (tarifOptions.length === 0) {
    return ['12', 'gaspure'];
  }
  const trackingTarifOptions = [];
  checkIfTarifOptionIsSelected(tarifOptions, 'GFAC_VB') ? trackingTarifOptions.push('0') : trackingTarifOptions.push('12');
  checkIfTarifOptionIsSelected(tarifOptions, 'GFAC_BIO') ? trackingTarifOptions.push('gasbio') : trackingTarifOptions.push('gaspure');
  return trackingTarifOptions;
};

export const checkIfTarifOptionIsSelected = (tarifOptions: TarifOption[], option: string): boolean => {
  return !!tarifOptions?.find(tarifOption => tarifOption.option === option);
};
