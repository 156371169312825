import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, tap } from 'rxjs';
import { AuthService, LoggingService } from '@mwe/services';
import { Router } from '@angular/router';

@Injectable()
export class InvalidAccessTokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private loggingService: LoggingService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!matchUrlCondition(request.url)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      tap({
        next: value => value,
        error: (err: any) => {
          if (!(err instanceof HttpErrorResponse)) {
            return EMPTY;
          }
          if (!statusCodeCondition(err.status)) {
            return EMPTY;
          }
          //console.error('------- invalid access token');
          //this.loggingService.logError(err, 'Invalid access token');
          if (this.authService.isAuthenticated()) {
            const logoutRedirectUrl = `/#logout=automatic`;
            this.authService.logout(logoutRedirectUrl);
          } else {
            this.router.navigateByUrl('');
          }
        },
      }),
    );
  }
}

const statusCodeCondition = (responseCode: number): boolean => responseCode === 401;
const matchUrlCondition = (url: string): boolean => url.includes('api/') && !url.includes('bskwec/');
