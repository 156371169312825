import { Component, Input, OnInit } from '@angular/core';
import { ProductCategoryEnum } from '@mwe/constants';
import {
  getTarifBinding,
  getTariffOptionLabel,
  getTariffOptionTranslationParams,
  getTarifIndication,
  isProductCategoryFernwaerme,
  mergeSapT3TariffOptions,
} from '@mwe/utils';
import {
  IProduktSelektion,
  PreisIndikationen,
  Product,
  ProduktAttribute,
  TarifOption,
  translateTariffClasses,
  getPdfDocumentsSafeUrl,
  TextNodeType,
} from '@mwe/models';
import { EnvironmentService, RelocationService, TariffSelectionStateService } from '@mwe/services';
import {
  isArrayWithMinOneItem,
  isProductCategory,
  isProductCategoryEmobility,
  isProductCategoryInternet,
  isProductCategoryStromOrGas,
} from '@mwe/utils';
import { TranslateService } from '@ngx-translate/core';
import { HeadlineService } from '../../headline/headline.service';

@Component({
  selector: 'mwe-wizard-product-card',
  templateUrl: './wizard-product-card.component.html',
  providers: [HeadlineService],
  styles: [],
})
export class WizardProductCardComponent implements OnInit {
  @Input() product: Product;
  @Input() showTariff = true;
  @Input() showPrices = true;
  @Input() showTariffDetailsLink = false;
  @Input() showTariffDetails = false;
  @Input() isAvailable = true;
  @Input() tariffClasses: string[];
  @Input() tarifKey: string;
  @Input() productSelection: IProduktSelektion;
  @Input() grundpreisLabelOverwrite: string;
  @Input() voucherInfo: string[];
  @Input() accountNrLabel: string;
  @Input() translateParams: any;
  @Input() showWaermeTariffClassesAsideOfIcon = false;
  @Input() headingNodeType: TextNodeType = 'h2';

  grundpreisLabel: string;
  tariffClasses$: Promise<string[]>;

  tariffIndication: PreisIndikationen;
  selectedTariffOptions: TarifOption[] = [];

  indications: PreisIndikationen[];
  tariffBinding: ProduktAttribute;

  basePriceKey = 'tariff.details.basePrice';
  installationPriceKey = 'tariff.details.installationPrice';
  isCategoryInternet = false;
  isCategoryFernwaerme = false;
  discountInfoPopupText: string; // todo remove me once T2 is able to send text via api response...

  getPdfDocumentsSafeUrl = getPdfDocumentsSafeUrl;

  constructor(
    private relocationService: RelocationService,
    private translateService: TranslateService,
    private tariffSelectionStateService: TariffSelectionStateService,
    private environmentService: EnvironmentService,
    public headlineService: HeadlineService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.headlineService.setReferenceHeadline(this.headingNodeType);
    this.discountInfoPopupText = `tariff.isDiscounted.infoIcon.text.${this.environmentService.getPortalCustomerType()}`;
    this.isCategoryFernwaerme = isProductCategoryFernwaerme(this.product.category);
    this.grundpreisLabel = this.grundpreisLabelOverwrite ?? 'tariff.details.grundpreis';
    this.tariffClasses$ = this.getTariffClasses();
    this.showTariffDetails = this.showTariffDetails && !this.isCategoryFernwaerme && this.isAvailable;
    if (this.showTariffDetails) {
      this.indications = this.tariffSelectionStateService.indications;
    }
    this.tariffIndication = getTarifIndication(this.indications, this.tarifKey);
    if (isProductCategoryInternet(this.product.category)) {
      this.basePriceKey = 'tariff.details.basePriceInternet';
      this.installationPriceKey = 'tariff.details.installationPrice' + this.product.category;
      this.isCategoryInternet = isProductCategory(this.product.category, ProductCategoryEnum.INTERNET);
    }
    this.tariffBinding = getTarifBinding(this.tariffIndication);
    this.setTariffOptions();
  }

  getLabelFrom(option: TarifOption): string {
    const labelKey = getTariffOptionLabel(option, true);
    const params = getTariffOptionTranslationParams(option);
    return this.translateService.instant(labelKey, params);
  }

  getTariffClasses(): Promise<string[]> {
    if (this.tariffClasses) {
      return this.getTranslateTariffClasses();
    } else {
      return Promise.resolve([]);
    }
  }

  getTranslateTariffClasses(): Promise<string[]> {
    return translateTariffClasses(this.product.category, this.tariffClasses, this.translateService);
  }

  getTariffShortcut(): string {
    return isProductCategoryEmobility(this.product.category) ? 'tarifblatt-tanke/' : this.readTariffsFromConfig();
  }

  getTariffDetailsLink() {
    const category = this.product.category;
    let pdfUrl = this.getPdfDocumentsSafeUrl() + this.getTariffShortcut();
    if (isProductCategoryInternet(category)) {
      if (isProductCategory(category, ProductCategoryEnum.INTERNET)) {
        pdfUrl = pdfUrl.concat('/entgeltbestimmungen-und-leistungsbeschreibungen-von-superschnell-internet/');
      } else if (isProductCategory(category, ProductCategoryEnum.VOIP)) {
        pdfUrl = pdfUrl.concat('/entgeltbestimmungen-und-leistungsbeschreibungen-von-superschnell-festnetz-telefonie/');
      } else if (isProductCategory(category, ProductCategoryEnum.IPTV)) {
        pdfUrl = pdfUrl.concat('/entgeltbestimmungen-und-leistungsbeschreibungen-von-superschnell-tv/');
      }
    }
    return pdfUrl;
  }

  readTariffsFromConfig(): string {
    const customerType = this.environmentService.getPortalCustomerType();
    const categoryConfig = this.relocationService.getConfigTariffsForCategory(this.product.category.toLowerCase())?.[customerType];
    return this.tarifKey ? this.tarifKey : categoryConfig ? categoryConfig.shortcut : '';
  }

  private setTariffOptions(): void {
    this.selectedTariffOptions = mergeSapT3TariffOptions(this.getTariffOptions(), this.tariffIndication?.tarif?.tarifOptionen);
  }

  private getTariffOptions(): TarifOption[] {
    let tariffOptions: TarifOption[] = [];

    if (isArrayWithMinOneItem(this.productSelection?.anlagen)) {
      const correspondingAnlage = this.productSelection.anlagen.find(anlage => anlage.anlageId === this.product.anlageId);
      if (correspondingAnlage) {
        tariffOptions = correspondingAnlage.tarif.tarifOptionen;
      }
    } else if (
      this.productSelection &&
      (isProductCategoryStromOrGas(this.product.category) || isProductCategoryInternet(this.product.category))
    ) {
      tariffOptions = this.productSelection[this.product.category.toLowerCase()].tarif.tarifOptionen;
    }

    return tariffOptions;
  }
}
