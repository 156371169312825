<div class="vertical-center align-items-center">
  <div *ngIf="spinnerTextKey; else spinner_only" class="horizontal-center">
    <ng-container *ngTemplateOutlet="spinner_only"> </ng-container>
    <div style="height: 2rem" class="ml-2 vertical-center">
      <span [translate]="spinnerTextKey">...</span>
    </div>
  </div>
</div>

<ng-template #spinner_only>
  <div class="spinner-border" style="color: black" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</ng-template>
