import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { IMigrationResult, IPersonalInfoModel, UserStatus } from '@mwe/models';
import { AccountStateService } from './state/account-state.service';
import { IDENTITY_PROVIDER_LOGWIEN } from '@mwe/constants';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private readonly resourceUrl: string;
  private readonly logwienResourceUrl: string;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private accountStateService: AccountStateService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/account';
    this.logwienResourceUrl = this.environmentService.getApiUrl() + 'api/logwien';
  }

  getUserName(): Promise<string> {
    return lastValueFrom(this.getCredentialsUserName())
      .then(response => {
        return response.body.username;
      })
      .catch(() => {
        return null;
      });
  }

  getCredentialsUserName(): Observable<HttpResponse<any>> {
    return this.http.get<any>(this.resourceUrl + '/credentials/username', { observe: 'response' });
  }

  getPersonalInfo(): Promise<IPersonalInfoModel> {
    return lastValueFrom(this.http.get<IPersonalInfoModel>(this.resourceUrl + '/personal-info'));
  }

  hasAccount(email: string): Promise<UserStatus> {
    return lastValueFrom(this.http.post<UserStatus>(this.resourceUrl + '/hasAccount', email));
  }

  sendVerifyEmail(id: string): Promise<void> {
    return lastValueFrom(this.http.put<void>(this.resourceUrl + '/sendVerificationEmail', id));
  }

  async hasBSKWECAccess(): Promise<boolean> {
    if (this.accountStateService.isBskwUser == undefined) {
      try {
        const responseBSKWECAccess = await lastValueFrom(this.http.get<void>(this.resourceUrl + '/bskw/verify', { observe: 'response' }));
        if (responseBSKWECAccess.status === 200) {
          this.accountStateService.isBskwUser = true;
        }
      } catch (err) {
        if (err.status === 404) {
          this.accountStateService.isBskwUser = false;
        }
      }
    }
    return this.accountStateService.isBskwUser === true;
  }

  createLogwienIDPLink(keycloakId: string): Promise<void> {
    const federatedIdentity = {
      identityProvider: IDENTITY_PROVIDER_LOGWIEN,
      userId: keycloakId,
      userName: this.accountStateService.account.email,
    };
    return lastValueFrom(this.http.post<void>(this.resourceUrl + '/link/logwien', federatedIdentity));
  }

  createLogwienMigration(): Promise<IMigrationResult> {
    const migrationObject = {
      email: this.accountStateService.account.email,
      firstName: this.accountStateService.account.firstName,
      lastName: this.accountStateService.account.lastName,
      hasCompany: false,
      enforceConflictCheck: false,
      partners: [
        {
          name: this.environmentService.getLogwienPartnerName(),
        },
      ],
      clientId: 'we-meine-we',
      redirectUri: this.environmentService.getLogwienLoginFlowUri(),
    };

    return lastValueFrom(this.http.post<IMigrationResult>(this.logwienResourceUrl + '/migration/users', migrationObject));
  }
}
