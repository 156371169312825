import { Component, Input } from '@angular/core';
import { Datei, IPopupModel } from '@mwe/models';
import { openPdf } from '@mwe/utils';
import { PopupService } from '@mwe/services';

@Component({
  selector: 'mwe-download-button',
  templateUrl: './download-button.component.html',
})
export class DownloadButtonComponent {
  @Input() openPdfPromise: () => Promise<Datei>;
  @Input() isPdfLoading: boolean;
  @Input() label: string;
  @Input() fileTitle: string;
  @Input() errorTitleKey: string;
  @Input() errorMessageKey: string;
  @Input() errorSubmitButtonKey: string;

  constructor(private popupService: PopupService) {}

  openPdf(): void {
    if (this.isPdfLoading) {
      return;
    }
    this.isPdfLoading = true;
    this.openPdfPromise()
      .then(file => {
        if (file) {
          openPdf(file, this.fileTitle);
        } else {
          this.showDownloadErrorToast();
        }
      })
      .catch(() => {
        this.showDownloadErrorToast();
      })
      .finally(() => {
        this.isPdfLoading = false;
      });
  }

  showDownloadErrorToast(): void {
    const model: IPopupModel = {
      id: 'download-error',
      titleKey: this.errorTitleKey,
      messageKey: this.errorMessageKey,
      showSubmitButton: true,
      submitButtonKey: this.errorSubmitButtonKey,
      showCancelButton: false,
      iconColorClass: 'red',
      iconTypeClass: 'fa-exclamation',
    };
    this.popupService.open(model);
  }
}
