import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VOUCHER_REDEEM_ROUTE } from '@mwe/constants';

@Injectable({
  providedIn: 'root',
})
export class VoucherRedeemLogic {
  constructor(private router: Router) {}

  redeemVoucher(): void {
    this.router.navigateByUrl(`/${VOUCHER_REDEEM_ROUTE}`);
  }
}
