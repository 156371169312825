<div class="card-basic" [attr.data-test]="containerTestSelector">
  <mwe-wizard-product-card
    [ngClass]="'card-basic-segment card-basic-body'"
    [product]="product"
    [showTariff]="showTariff"
    [showTariffDetailsLink]="showTariffDetails"
    [showTariffDetails]="showTariffDetails"
    [isAvailable]="isActiveCard"
    [tariffClasses]="tariffClasses"
    [tarifKey]="showCurrentProductTariff ? product.tarifKey : null"
    [headingNodeType]="headingNodeType"
    aria-hidden="true"
  ></mwe-wizard-product-card>

  <div
    data-test="wizard-product-card-container-toggle-text"
    class="card-basic-body card-basic-body-slim card-basic-segment"
    *ngIf="isActiveCard || notActiveText"
  >
    <div class="row">
      <div class="col" aria-hidden="true">
        <div *ngIf="!isActiveCard; then notAvailableBlock; else availableBlock"></div>
        <ng-template #notAvailableBlock
          ><label class="text--big mb-0 pt-1" [for]="inputID" [translate]="notActiveText"></label
        ></ng-template>
        <ng-template #availableBlock><label class="text--big mb-0 pt-1" [for]="inputID" [translate]="toggleLabel"></label></ng-template>
      </div>
      <div class="col-auto" *ngIf="isActiveCard">
        <div class="switch r">
          <input
            [id]="inputID"
            type="checkbox"
            class="checkbox"
            data-test="wizard-product-card-container-toggle"
            [ngStyle]="{ 'pointer-events': isDisabled ? 'none' : 'all' }"
            (change)="onProductSelect($event.target.checked)"
            [checked]="isOrdered"
            [attr.aria-label]="'products.toggleLong' | translate: { category: product.category }"
          />
          <div class="knobs"></div>
          <div class="layer"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-basic-body card-basic-body-slim card-basic-segment" *ngIf="showInformationText && !isInitialChecked && isOrdered">
    <div class="row product-card-info">
      <div class="col-auto pr-0">
        <i class="fas fa-info" aria-hidden="true"></i>
      </div>
      <div class="col">
        <div class="d-flex expander-item align-items-start">
          <div class="flex-fill pb-0 mb-2">
            <p
              role="alert"
              [ngClass]="showMoreLegalText ? 'clamp' : ''"
              translate="relocation.information"
              [translateParams]="{ category: informationTextProductCategory }"
            ></p>
            <button type="button" class="btn btn-link" (click)="toggleReadMoreLegalText()" aria-hidden="true">
              {{ (showMoreLegalText ? 'global.expander.showMore' : 'global.expander.showLess') | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
