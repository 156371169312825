import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PRODUCTS_ROUTE, GOODIE_ROUTE, PROFILE_ROUTE } from '@mwe/constants';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private router: Router) {}

  isProductPath(): boolean {
    return this.router.url.indexOf(`/${PRODUCTS_ROUTE}`) !== -1;
  }

  isGoodiePath(): boolean {
    return this.router.url.indexOf(`/${GOODIE_ROUTE}`) !== -1;
  }

  isProfilePath(): boolean {
    return this.router.url.indexOf(`/${PROFILE_ROUTE}`) !== -1;
  }
}
