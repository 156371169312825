<mwe-basic-card
  *ngIf="config"
  iconClass="fas fa-gift-card"
  [headingNodeType]="headingNodeType"
  [cardTitle]="config.titleKey"
  [hasBackground]="true"
>
  <div class="row">
    <div class="col-12">
      <div class="has-text-button">
        <div class="pb-2 pt-2">
          <mwe-form
            #codeForm
            [formModel]="voucherFormModel"
            (formIsInvalid)="onFormChange($event)"
            (formIsValid)="onFormChange($event)"
            [suppressValidityCheckOnSubmit]="true"
          ></mwe-form>
          <mwe-wl-voucher-form
            *ngIf="showWLFields"
            [config]="config"
            [prozesstyp]="prozesstyp"
            [loading]="loading"
            [aktionsArt]="aktionsArt"
            (formValid)="wlFormValidityChanged($event)"
            (checkPressed)="wlFormCheckPressed($event)"
          ></mwe-wl-voucher-form>
        </div>
      </div>
    </div>
  </div>
  <div class="row pb-2" *ngIf="config.infoText">
    <mwe-info-text-card [text]="config.infoText" [hasBoxStyles]="false" [noBoxShadow]="true"></mwe-info-text-card>
  </div>
</mwe-basic-card>

<div class="mt-5" *ngIf="!!codeCheck">
  <mwe-alert
    *ngIf="codeCheck === 'invalid'"
    type="danger"
    [messageKey]="showWLFields ? config.invalidCodeWLMsgKey : config.invalidCodeMsgKey"
    titleKey="voucher.invalidTitle"
  ></mwe-alert>
  <mwe-alert
    *ngIf="codeCheck === 'error'"
    [type]="errorAlertStyle"
    [titleKey]="errorTitleKey || 'voucher.invalidTitle'"
    [messageKey]="errorMessageKey"
  ></mwe-alert>
  <mwe-alert
    *ngIf="codeCheck === 'warning'"
    (onLinkClick)="handleWarningLinkClicked()"
    type="warning"
    [messageKey]="errorMessageKey"
    [infoText]="isTariffNotValidError ? tariffNotValidInfoText : null"
    [link]="isTariffNotValidError ? 'voucher.validity.tariffNotValid.link' : ''"
  ></mwe-alert>
  <mwe-basic-card
    *ngIf="codeCheck === 'valid'"
    iconClass="fas fa-gift-card"
    cardTitle="voucher.validity.success.title"
    headingNodeType="h4"
  >
    <mwe-green-check-list [labels]="uiSuccessInfos"></mwe-green-check-list>
  </mwe-basic-card>
</div>

<div *ngIf="hasFaq" class="mt-5" data-test="voucher-form-faq">
  <h3 class="h5" translate="voucher.faq.title"></h3>
  <mwe-link-list-group [items]="config.faq"></mwe-link-list-group>
</div>
