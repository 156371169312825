import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import {
  IProduct,
  parseProduct,
  Product,
  Address,
  IAddress,
  IOlavEntry,
  parseAddress,
  IBankDetails,
  IDifferentInvoiceRecipient,
  IERechnungsInfo,
  MoveOutMapping,
  Verrechnungskonto,
} from '@mwe/models';
import { ProductsWhiteListLogic } from '../products/products-white-list.logic';
import { ConfirmationStatusStateService } from '../confirmation/confirmation-status-state.service';

const MOVE_OUT_PRODUCTS_KEY = 'moveOut_products';
const MOVE_OUT_ADDRESS_KEY = 'moveOut_address';
const MOVE_OUT_ADDRESS_DETAILS_LINK_TYPE_KEY = 'moveOut_addressDetailsLinkType';
const MOVE_OUT_IS_ADDRESS_SELECTION_VISIBLE_KEY = 'moveOut_isAddressSelectionVisible';
const MOVE_OUT_LAST_ORDER_ID_KEY = 'moveOut_lastOrderId';
const MOVE_OUT_RETRIED_ON_ERROR_KEY = 'moveOut_retriedOnError';
const MOVE_OUT_SUBMIT_ID_KEY = 'moveOut_submitId';
const MOVE_OUT_BANK_DETAILS_KEY = 'moveOut_bankDetails';
const MOVE_OUT_ERECHNUNGS_INFO_KEY = 'moveOut_eRechnungsInfo';
const MOVE_OUT_MOVE_OUT_DATE_KEY = 'moveOut_moveOutDate';
const MOVE_OUT_WANTS_TO_CHOOSE_DATE_KEY = 'moveOut_wantsToChooseDate';
const MOVE_OUT_NEW_INVOICE_ADDRESS_KEY = 'moveOut_newInvoiceAddress';
const MOVE_OUT_NEW_INVOICE_OLAV_ENTRY_KEY = 'moveOut_newInvoiceOlavEntry';
const MOVE_OUT_MAPPING_KEY = 'moveOut_mapping';
const MOVE_OUT_DETAILS_KEY = 'moveOut_details';
const MOVE_OUT_DIFFERENT_INVOICE_RECIPIENT_KEY = 'moveOut_differentInvoiceRecipient';

@Injectable({
  providedIn: 'root',
})
export class MoveOutStateService extends ConfirmationStatusStateService {
  @SessionStorage(MOVE_OUT_PRODUCTS_KEY)
  private _products: IProduct[];

  @SessionStorage(MOVE_OUT_ADDRESS_KEY)
  private _address: IAddress;

  @SessionStorage(MOVE_OUT_ADDRESS_DETAILS_LINK_TYPE_KEY)
  private _addressDetailsLinkType: string;

  @SessionStorage(MOVE_OUT_IS_ADDRESS_SELECTION_VISIBLE_KEY)
  private _isAddressSelectionVisible: boolean;

  @SessionStorage(MOVE_OUT_LAST_ORDER_ID_KEY)
  private _lastOrderId: string;

  @SessionStorage(MOVE_OUT_RETRIED_ON_ERROR_KEY)
  private _retriedOnError: boolean;

  @SessionStorage(MOVE_OUT_SUBMIT_ID_KEY)
  private _submitId: number;

  @SessionStorage(MOVE_OUT_BANK_DETAILS_KEY)
  private _bankDetails: IBankDetails;

  @SessionStorage(MOVE_OUT_ERECHNUNGS_INFO_KEY)
  private _eRechnungsInfo: IERechnungsInfo;

  @SessionStorage(MOVE_OUT_MOVE_OUT_DATE_KEY)
  private _moveOutDate: string;

  @SessionStorage(MOVE_OUT_WANTS_TO_CHOOSE_DATE_KEY)
  private _wantsToChooseDate: boolean;

  @SessionStorage(MOVE_OUT_NEW_INVOICE_ADDRESS_KEY)
  private _newInvoiceAddress: IAddress;

  @SessionStorage(MOVE_OUT_NEW_INVOICE_OLAV_ENTRY_KEY)
  private _newInvoiceOlavEntry: IOlavEntry;

  @SessionStorage(MOVE_OUT_MAPPING_KEY)
  private _mapping: MoveOutMapping[];

  @SessionStorage(MOVE_OUT_DETAILS_KEY)
  _details: Verrechnungskonto[];

  @SessionStorage(MOVE_OUT_DIFFERENT_INVOICE_RECIPIENT_KEY)
  _differentInvoiceRecipient: IDifferentInvoiceRecipient;

  constructor(
    protected override storage: SessionStorageService,
    private productsWhiteListLogic: ProductsWhiteListLogic,
  ) {
    super(storage, MOVE_OUT_LAST_ORDER_ID_KEY, MOVE_OUT_RETRIED_ON_ERROR_KEY, MOVE_OUT_SUBMIT_ID_KEY);
  }

  override clear(): void {
    this.storage.clear(MOVE_OUT_PRODUCTS_KEY);
    this.storage.clear(MOVE_OUT_ADDRESS_KEY);
    this.storage.clear(MOVE_OUT_ADDRESS_DETAILS_LINK_TYPE_KEY);
    this.storage.clear(MOVE_OUT_IS_ADDRESS_SELECTION_VISIBLE_KEY);
    this.storage.clear(MOVE_OUT_LAST_ORDER_ID_KEY);
    this.storage.clear(MOVE_OUT_RETRIED_ON_ERROR_KEY);
    this.storage.clear(MOVE_OUT_SUBMIT_ID_KEY);
    this.storage.clear(MOVE_OUT_BANK_DETAILS_KEY);
    this.storage.clear(MOVE_OUT_ERECHNUNGS_INFO_KEY);
    this.storage.clear(MOVE_OUT_MOVE_OUT_DATE_KEY);
    this.storage.clear(MOVE_OUT_WANTS_TO_CHOOSE_DATE_KEY);
    this.storage.clear(MOVE_OUT_NEW_INVOICE_ADDRESS_KEY);
    this.storage.clear(MOVE_OUT_NEW_INVOICE_OLAV_ENTRY_KEY);
    this.storage.clear(MOVE_OUT_MAPPING_KEY);
    this.storage.clear(MOVE_OUT_DETAILS_KEY);
    this.storage.clear(MOVE_OUT_DIFFERENT_INVOICE_RECIPIENT_KEY);
  }

  clearAll(): void {
    this.clear();
  }

  get productCategories(): string[] | undefined {
    return this.products?.map(product => product.category);
  }

  get products(): Product[] {
    if (!Array.isArray(this._products)) {
      return undefined;
    }

    return this._products.map(elem => parseProduct(elem));
  }

  set products(products: Product[]) {
    if (!Array.isArray(products)) {
      this.storage.clear(MOVE_OUT_PRODUCTS_KEY);
      return;
    }

    this._products = this.productsWhiteListLogic.removeNotWhiteListedProducts(products);
  }

  get address(): Address {
    if (!this._address) {
      return undefined;
    }

    return parseAddress(this._address);
  }

  set address(address: Address) {
    if (!address) {
      this.storage.clear(MOVE_OUT_ADDRESS_KEY);
    } else {
      this._address = address;
    }
  }

  get addressDetailsLinkType(): string {
    return this._addressDetailsLinkType;
  }

  set addressDetailsLinkType(addressDetailsLinkType: string) {
    if (!addressDetailsLinkType) {
      this.storage.clear(MOVE_OUT_ADDRESS_DETAILS_LINK_TYPE_KEY);
    } else {
      this._addressDetailsLinkType = addressDetailsLinkType;
    }
  }

  get isAddressSelectionVisible(): boolean {
    return !!this._isAddressSelectionVisible;
  }

  set isAddressSelectionVisible(value: boolean) {
    if (!value) {
      this.storage.clear(MOVE_OUT_IS_ADDRESS_SELECTION_VISIBLE_KEY);
      return;
    }

    this._isAddressSelectionVisible = value;
  }

  override get submitId(): number {
    return this._submitId || undefined;
  }

  override set submitId(submitId: number) {
    if (submitId) {
      this._submitId = submitId;
    } else {
      this.storage.clear(MOVE_OUT_SUBMIT_ID_KEY);
    }
  }

  override get retriedOnError(): boolean {
    return this._retriedOnError || undefined;
  }

  override set retriedOnError(retriedOnError: boolean) {
    if (retriedOnError) {
      this._retriedOnError = retriedOnError;
    } else {
      this.storage.clear(MOVE_OUT_RETRIED_ON_ERROR_KEY);
    }
  }

  override get lastOrderId(): string {
    return this._lastOrderId || undefined;
  }

  override set lastOrderId(lastOrderId: string) {
    if (lastOrderId) {
      this._lastOrderId = lastOrderId;
    } else {
      this.storage.clear(MOVE_OUT_LAST_ORDER_ID_KEY);
    }
  }

  get bankDetails(): IBankDetails {
    return this._bankDetails || undefined;
  }

  set bankDetails(bankDetails: IBankDetails) {
    if (bankDetails) {
      this._bankDetails = bankDetails;
    } else {
      this.storage.clear(MOVE_OUT_BANK_DETAILS_KEY);
    }
  }

  get eRechnungsInfo(): IERechnungsInfo {
    return this._eRechnungsInfo || undefined;
  }

  set eRechnungsInfo(eRechnungsInfo: IERechnungsInfo) {
    if (eRechnungsInfo) {
      this._eRechnungsInfo = eRechnungsInfo;
    } else {
      this.storage.clear(MOVE_OUT_ERECHNUNGS_INFO_KEY);
    }
  }

  get moveOutDate(): string {
    return this._moveOutDate || undefined;
  }

  set moveOutDate(moveOutDate: string) {
    if (moveOutDate) {
      this._moveOutDate = moveOutDate;
    } else {
      this.storage.clear(MOVE_OUT_MOVE_OUT_DATE_KEY);
    }
  }

  get wantsToChooseDate(): boolean {
    return this._wantsToChooseDate;
  }

  set wantsToChooseDate(wantsToChooseDate: boolean) {
    this._wantsToChooseDate = wantsToChooseDate;
  }

  get newInvoiceAddress(): Address {
    if (!this._newInvoiceAddress) {
      return undefined;
    }

    return parseAddress(this._newInvoiceAddress);
  }

  set newInvoiceAddress(invoiceAddress: Address) {
    if (invoiceAddress) {
      this._newInvoiceAddress = invoiceAddress;
    } else {
      this.storage.clear(MOVE_OUT_NEW_INVOICE_ADDRESS_KEY);
    }
  }

  get newInvoiceOlavEntry(): IOlavEntry {
    return this._newInvoiceOlavEntry || undefined;
  }

  set newInvoiceOlavEntry(newInvoiceOlavEntry: IOlavEntry) {
    if (newInvoiceOlavEntry) {
      this._newInvoiceOlavEntry = newInvoiceOlavEntry;
    } else {
      this.storage.clear(MOVE_OUT_NEW_INVOICE_OLAV_ENTRY_KEY);
    }
  }

  get mapping(): MoveOutMapping[] {
    return this._mapping || [];
  }

  set mapping(mapping: MoveOutMapping[]) {
    if (mapping) {
      this._mapping = mapping;
    } else {
      this.storage.clear(MOVE_OUT_MAPPING_KEY);
    }
  }

  get details(): Verrechnungskonto[] {
    return this._details || [];
  }

  set details(details: Verrechnungskonto[]) {
    if (details) {
      this._details = details;
    } else {
      this.storage.clear(MOVE_OUT_DETAILS_KEY);
    }
  }

  get differentInvoiceRecipient(): IDifferentInvoiceRecipient {
    return this._differentInvoiceRecipient || undefined;
  }

  set differentInvoiceRecipient(differentInvoiceRecipient: IDifferentInvoiceRecipient) {
    if (differentInvoiceRecipient) {
      this._differentInvoiceRecipient = differentInvoiceRecipient;
    } else {
      this.storage.clear(MOVE_OUT_DIFFERENT_INVOICE_RECIPIENT_KEY);
    }
  }
}
