export enum ServiceStateEnum {
  INIT = 'init',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
  FAILED = 'failed',
  SUCCESS = 'success',
  INFO = 'info',
}

export type ServiceStateType = 'init' | 'loading' | 'loaded' | 'error' | 'success' | 'info';
