import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICampaignData, ICampaignSliderPopup } from '@mwe/models';
import { WINDOW_TOKEN } from '@mwe/constants';

@Component({
  selector: 'mwe-campaign-popup',
  templateUrl: './campaign-popup.component.html',
})
export class CampaignPopupComponent {
  get popupData(): ICampaignSliderPopup {
    return this.loadedData?.slider?.popup;
  }

  // data set from PopupService
  @Input() loadedData: ICampaignData;

  constructor(private activeModal: NgbActiveModal, private router: Router, @Inject(WINDOW_TOKEN) private window: Window) {}

  closePopup(): void {
    this.activeModal.close('OK');
  }

  startNewClientProcess(): void {
    this.closePopup();
    this.navigate(this.loadedData.slider.popup.newClientUri);
  }

  startTariffChangeProcess(): void {
    this.closePopup();
    this.navigate(this.loadedData.slider.popup.tariffChangeUri);
  }

  private navigate(url: string): void {
    if (url.indexOf('http') === 0) {
      this.window.open(url, '_blank');
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
