import { Injectable } from '@angular/core';
import { ILSystem, TeilbetragsUebersicht } from '@mwe/models';
import { MemoryCacheService, MemoryCacheItem } from '../../cache/memory-cache.service';
import { InvoiceDataService } from '../invoice-data/invoice-data.service';

@Injectable({ providedIn: 'root' })
export class InteractiveInvoicePartsLogic {
  constructor(private memoryCacheService: MemoryCacheService, private invoiceDataService: InvoiceDataService) {}

  async getInvoiceParts(systemId: ILSystem, accountId: string): Promise<TeilbetragsUebersicht> {
    if (this.memoryCacheService.has(MemoryCacheItem.InvoiceParts, systemId, accountId)) {
      return this.memoryCacheService.get(MemoryCacheItem.InvoiceParts, systemId, accountId);
    }

    const response = await this.invoiceDataService.getInvoiceParts(systemId, accountId);
    if (!response?.teilbetraguebersicht) {
      return null;
    }

    const invoiceParts = response.teilbetraguebersicht[0];
    this.memoryCacheService.set(MemoryCacheItem.InvoiceParts, invoiceParts, systemId, accountId);
    return invoiceParts;
  }
}
