import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address, DataChangeType, IGeschaeftspartnerBeziehung, Product, PushPdfInfo, Verrechnungskonto } from '@mwe/models';
import { ClearingAccountLogic, InvoiceDataChangeStateService, StaticContentService } from '@mwe/services';
import { containsStromProducts, hasClearingAccountSepaMandant, getInvoiceEmail } from '@mwe/utils';

@Component({
  selector: 'mwe-invoice-data-change-confirmation-check-push-pdf-sepa',
  templateUrl: './check-push-pdf-sepa.component.html',
})
export class InvoiceDataChangeConfirmationCheckPushPdfSepaComponent implements OnInit {
  @Input() confirmationWasSuccessfully: boolean;
  @Output() pushPdfBtnClicked = new EventEmitter<void>();
  pushPdfInfo: PushPdfInfo;
  targetMode: DataChangeType;

  get products(): Product[] {
    return this.invoiceDataChangeStateService.products;
  }

  get address(): Address {
    return this.invoiceDataChangeStateService.address;
  }

  constructor(
    private invoiceDataChangeStateService: InvoiceDataChangeStateService,
    private staticContentService: StaticContentService,
    private clearingAccountLogic: ClearingAccountLogic,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.checkPdfInvoice();
  }

  onStartProcessBtnClicked() {
    this.invoiceDataChangeStateService.clear();
    // PushPdfInfoOnConfirmationComponent set storage data for process
    // navigating from pdf to sepa (or vice versa) will trigger clear again, so prevent this with keepStorageOnce
    this.invoiceDataChangeStateService.keepStorageOnce = true;
    this.pushPdfBtnClicked.emit();
  }

  private async checkPdfInvoice(): Promise<void> {
    const mode = this.invoiceDataChangeStateService.mode;
    if (mode === DataChangeType.InvoiceAddressChangeOnly) {
      return;
    }

    const pushPdfData = await this.staticContentService.getPushPdfData();
    const responses = await this.clearingAccountLogic.getAllAccountInfos(this.products);
    const hasNoPdfInvoice = responses?.every(r => !this.getInvoiceEmail(r?.verrechnungskonto));
    const hasNoSepa = responses?.every(r => !this.isSepaMandant(r?.verrechnungskonto));

    if (this.invoiceDataChangeStateService.mode === DataChangeType.BankAccountChangeOnly && hasNoPdfInvoice) {
      this.pushPdfInfo = pushPdfData.changeToPdfInvoiceBox;
      this.targetMode = DataChangeType.PdfInvoiceChangeOnly;
    } else if (this.invoiceDataChangeStateService.mode === DataChangeType.PdfInvoiceChangeOnly && hasNoSepa) {
      this.pushPdfInfo = containsStromProducts(this.products) ? pushPdfData.changeToSepaBox : pushPdfData.changeToSepaBoxNoStrom;
      this.targetMode = DataChangeType.BankAccountChangeOnly;
    }
  }

  private getInvoiceEmail(konto: Verrechnungskonto): string {
    return getInvoiceEmail(konto);
  }

  private isSepaMandant(vertragskonto: Verrechnungskonto): boolean {
    return hasClearingAccountSepaMandant(vertragskonto);
  }
}
