import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ChoiceOptions } from './choice-options.model';

@Component({
  selector: 'mwe-choices',
  templateUrl: './choices.component.html',
})
export class ChoicesComponent {
  @Input() options: ChoiceOptions[];
  @Input() choiceItemTemplateRef: TemplateRef<any>;

  @Input() selectedOption: unknown;
  @Output() selectedOptionChange = new EventEmitter<unknown>();

  constructor() {}

  updateSelectedOption(): void {
    this.selectedOptionChange.emit(this.selectedOption);
  }
}
