import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mwe-icon-info-box',
  templateUrl: './icon-info-box.component.html',
})
export class IconInfoBoxComponent implements OnInit {
  @Input() boxTitle: string;
  @Input() info: string;
  @Input() color: 'green' | 'yellow' = 'green';
  divColorCss: string;
  iconColorCss: string;

  ngOnInit(): void {
    switch (this.color) {
      case 'green':
        this.divColorCss = 'bg-green-100';
        this.iconColorCss = 'bg--green';
        break;
      case 'yellow':
        this.divColorCss = 'bg-warning-100';
        this.iconColorCss = 'bg-warning';
        break;
    }
  }
}
