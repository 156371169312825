import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'mwe-tooltip-card',
  templateUrl: './tooltip-card.component.html',
})
export class TooltipCardComponent {
  @Input() cardClass: { [value: string]: boolean };

  @Input() header: string;
  @Input() isHeaderTitle: boolean;
  @Input() content: string;
  @Input() iconClass: string;
  @Input() hasTooltipBody: boolean;

  @Input() isHeaderBold = false;
  @Input() isClickable = false;
  @Input() orangeInfoTooltipText: string | TemplateRef<string> = null;
  @Input() disabled = false;

  @Output() onClick = new EventEmitter<void>();
}
