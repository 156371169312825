<button
  [id]="buttonId"
  [attr.data-test]="buttonId"
  [attr.type]="buttonType"
  [ngClass]="btnCssClasses"
  (click)="onClick($event)"
  [disabled]="disabled"
  #btnElement
>
  <ng-container *ngIf="showHoverEffect && category === 'primary'">
    <div [ngClass]="['wave-1', 'wave--effect', waveCssClass]"></div>
    <div [ngClass]="['wave-2', 'wave--effect', waveCssClass]"></div>
  </ng-container>
  <span [style.pointer-events]="disabled ? 'none' : ''" [translate]="labelKey" *ngIf="!showSpinner"></span>
  <span class="loading-spinner" *ngIf="showSpinner"></span>
</button>
