import { Component, Input, OnInit } from '@angular/core';
import { IBankDetails, prettyPrintIBAN } from '@mwe/models';

@Component({
  selector: 'mwe-bank-details',
  templateUrl: './bank-details.component.html',
  styles: [],
})
export class BankDetailsComponent implements OnInit {
  @Input() bankDetails: IBankDetails;

  bankImgAlt: string;
  bankDataText: string;

  constructor() {}

  ngOnInit(): void {
    const iban = prettyPrintIBAN(this.bankDetails.iban);
    const bankname = this.bankDetails.bankdaten.bankname ? this.bankDetails.bankdaten.bankname + '<br>' : '';
    this.bankImgAlt = `${this.bankDetails.bankdaten.bankname} ${iban}`;
    this.bankDataText = `${bankname}${iban}`;
  }
}
