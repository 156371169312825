import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, EMPTY, from } from 'rxjs';
import { IdleCheckService } from './idle-check.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class BlockHttpRequestInterceptor implements HttpInterceptor {
  constructor(private idleCheckService: IdleCheckService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('api/mgmt/info')) {
      return next.handle(req);
    }

    if (this.idleCheckService.getIsUserIdle() || this.idleCheckService.getWasWindowInactiveForLongerThanIdleTime()) {
      return this.handleToggleRefreshAndBlockToggleIfNecessary(req, next);
    }

    return next.handle(req);
  }

  private handleToggleRefreshAndBlockToggleIfNecessary(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.idleCheckService.shouldReloadToggles()).pipe(
      switchMap(_ => {
        if (this.idleCheckService.isMaintenanceEnabled()) {
          this.idleCheckService.moveToMaintenance();
          return EMPTY;
        } else {
          return next.handle(req);
        }
      })
    );
  }
}
