<div *ngIf="showNotificationBanner && isNotificationBannerVisibleForRoute" class="pb-5">
  <div class="notify shadow notification-animated" id="notification">
    <div class="row mt-2">
      <div class="col-lg-3 col-md-3 col-sm-3 col-3 text-center">
        <i class="fas fa-info info-icon mt-2"></i>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-9 col-9 pl-0">
        <h4 class="mb-2 h5-custom" translate="products.associationSuggestionNotification.title"></h4>
        <p class="text--big black line-h mb-0" translate="products.associationSuggestionNotification.text"></p>
        <mwe-button-link
          customCss="mb-2 mt-2"
          label="products.associationSuggestionNotification.button"
          (clicked)="startFromBanner()"
        ></mwe-button-link>
      </div>
    </div>
  </div>
</div>
