import { Component, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'mwe-clipboard-copy-button',
  templateUrl: './clipboard-copy-button.component.html',
})
export class ClipboardCopyButtonComponent {
  @Input() copyData: string;
  copySuccessFull = false;

  constructor(private clipboardApi: ClipboardService) {}

  copyText(): void {
    this.copySuccessFull = this.clipboardApi.copyFromContent(this.copyData);

    setTimeout(() => {
      this.copySuccessFull = false;
    }, 2000);
  }
}
