<div class="custom-swiper-button-wrapper mb-3">
  <div [ngClass]="swiperId" class="custom-swiper-button-prev">
    <li class="fa fa-chevron-left"></li>
  </div>
  <div [ngClass]="swiperId" class="custom-swiper-button-next">
    <li class="fa fa-chevron-right"></li>
  </div>
</div>
<div class="swiper-focus-outline-fix">
  <div #swiper [ngClass]="swiperId" class="swiper swiper-container s-wrapper">
    <div class="swiper-wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</div>
