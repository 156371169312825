import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CUSTOMER_DATA_CHANGE_FORM_ROUTE, CUSTOMER_DATA_CHANGE_ROUTE } from '@mwe/constants';
import { IProduct } from '@mwe/models';
import { CustomerDataChangeStateService } from './customer-data-change-state.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataChangeLogic {
  constructor(private router: Router, private customerDataChangeStateService: CustomerDataChangeStateService) {}

  startProcessWithAddressSelection(): void {
    this.customerDataChangeStateService.isSelectionVisible = true;
    this.router.navigateByUrl(`/${CUSTOMER_DATA_CHANGE_ROUTE}`);
  }

  startProcessWithoutAddressSelection(products: IProduct[]): void {
    this.customerDataChangeStateService.products = products;
    this.customerDataChangeStateService.isSelectionVisible = false;
    this.router.navigateByUrl(`/${CUSTOMER_DATA_CHANGE_ROUTE}/${CUSTOMER_DATA_CHANGE_FORM_ROUTE}`);
  }
}
