import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MNRContentResponse, MNRUserMessagesResponse } from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { lastValueFrom } from 'rxjs';
import { RetryOnFailure } from '@mwe/utils';

@Injectable({
  providedIn: 'root',
})
export class MnrMessageService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  @RetryOnFailure({
    maxRetryCount: 1,
    delay: 100,
    retryOnError: true,
    validator: (returnValue: MNRUserMessagesResponse[]) => Array.isArray(returnValue),
  })
  async getUserMessages(): Promise<MNRUserMessagesResponse[]> {
    return await lastValueFrom(this.http.get<MNRUserMessagesResponse[]>(this.getResourceUrl('userMessages')));
  }

  async getMessageContent(messageId: number): Promise<MNRContentResponse> {
    return await lastValueFrom(this.http.get<MNRContentResponse>(`${this.getResourceUrl('content')}?messageId=${messageId}`));
  }

  async markMessageAsRead(messageId: number): Promise<void> {
    // @ts-ignore
    await lastValueFrom(this.http.post<MNRContentResponse>(`${this.getResourceUrl('markAsRead')}?messageId=${messageId}`));
  }

  async markMessageAsDeleted(messageId: number): Promise<void> {
    // @ts-ignore
    await lastValueFrom(this.http.post<MNRContentResponse>(`${this.getResourceUrl('markAsDeleted')}?messageId=${messageId}`));
  }

  private getResourceUrl(path: string): string {
    return `${this.environmentService.getApiUrl()}api/messages/${path}`;
  }
}
