import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccountLogic } from '@mwe/services';

@Directive({
  selector: '[mweShowOnlyForImpersonate]',
})
export class ShowOnlyForImpersonateDirective implements OnInit {
  constructor(private template: TemplateRef<any>, private container: ViewContainerRef, private accountLogic: AccountLogic) {}

  ngOnInit(): void {
    if (this.accountLogic.isLoggedIn() && this.accountLogic.isImpersonate()) {
      this.container.createEmbeddedView(this.template);
    }
  }
}
