import { inject, Injectable, Injector } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IPopupModel } from '@mwe/models';
import { DEFAULT_POPUP_COMPONENT_TOKEN } from '@mwe/constants';
import { IkpAuthService } from '@ikp/service/auth/ikp-auth.service';

@Injectable({
  providedIn: 'root',
})
export class IkpPopupService {
  private _events: Subject<unknown>;
  private _additionalButtonClickEvent: Subject<unknown>;
  private cleanUpSubscriptions = true;
  private authService = inject(IkpAuthService);

  constructor(
    private modalService: NgbModal,
    private injector: Injector,
  ) {
    this._events = new Subject();
    this._additionalButtonClickEvent = new Subject();
  }

  handleLogoutPopup(): Observable<unknown> {
    const model: IPopupModel = {
      id: 'profile-logout',
      titleKey: 'global.profile.logout.popup.title',
      messageKey: 'global.profile.logout.popup.message',
      showSubmitButton: true,
      submitButtonKey: 'global.profile.logout.popup.buttonOk',
      showCancelButton: true,
      cancelButtonKey: 'global.profile.logout.popup.buttonCancel',
    };
    this.handleGenericPopup(model);
    this.events().subscribe(result => {
      if (result) {
        this.authService.logout();
      }
    });
    return this.events();
  }

  handleGenericPopup(data: IPopupModel, closeOpenPopups = false) {
    let component = this.injector.get(DEFAULT_POPUP_COMPONENT_TOKEN);
    if (this.modalService.hasOpenModals()) {
      if (closeOpenPopups) {
        this.modalService.dismissAll();
        return;
      } else {
        throw new Error("modal already open - can't open more than one");
      }
    }

    const modalOptions = this.createModalOptions(data);

    if (data.component) {
      component = data.component;
    }
    this.openModal(component, data, modalOptions);
  }

  private createModalOptions(data: IPopupModel) {
    const modalOptions: NgbModalOptions = {
      backdrop: data.dismissable ? true : 'static',
      keyboard: data.dismissable,
    };
    modalOptions.centered = true;
    modalOptions.scrollable = true;

    if (data?.additionalWindowClass?.length) {
      modalOptions.windowClass += ` ${data.additionalWindowClass.join(' ')}`;
    }

    if (data?.modalSize) {
      modalOptions.size = data.modalSize;
    }

    if (data?.modalOpts) {
      Object.assign(modalOptions, data.modalOpts);
    }
    return modalOptions;
  }

  private async openModal(component: unknown, data: IPopupModel, modalOptions: NgbModalOptions) {
    const popupRef = this.modalService.open(component, modalOptions);
    popupRef.componentInstance.model = data;
    if (data.componentData) {
      popupRef.componentInstance.loadedData = data.componentData;
    }
    const additionalButtonClickSub = popupRef.componentInstance.onAdditionalButtonClick?.subscribe(buttonId => {
      this._additionalButtonClickEvent.next(buttonId);
    });

    try {
      const result = await popupRef.result;
      this._events.next(data.formModel || data.useCustomReturnValue ? result : result === 'OK');
    } catch (error) {
      this._events.next(false);
    } finally {
      if (this.cleanUpSubscriptions) {
        this.unsubscribeAll(additionalButtonClickSub);
      }
    }
  }

  unsubscribeAll(additionalButtonClickSub: Subscription) {
    this._events.complete();
    this._events = new Subject();
    additionalButtonClickSub?.unsubscribe();
    this._additionalButtonClickEvent.complete();
    this._additionalButtonClickEvent = new Subject();
    this.cleanUpSubscriptions = true;
  }
  events(): Observable<unknown> {
    return this._events.asObservable();
  }
}
