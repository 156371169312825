import { Injectable } from '@angular/core';
import { PreisIndikationen } from '@mwe/models';
import { TariffSelectionStateService } from './tariff-selection-state.service';
import { isArrayWithMinOneItem, isProductCategoryInternet } from '@mwe/utils';
import { ProductPriceIndicationCategory } from '@mwe/constants';
import { OrderService } from '../../order/order.service';

@Injectable({ providedIn: 'root' })
export class TariffSelectionLogic {
  mockCategories = ['Internet', 'Iptv', 'Voip'];

  constructor(
    private orderService: OrderService,
    private tariffSelectionStateService: TariffSelectionStateService,
  ) {}

  async getPriceIndications(plz: string, categories: string[]): Promise<PreisIndikationen[]> {
    let missingCategories = categories.filter(c => {
      return this.tariffSelectionStateService.indications.every(i => i.sparte.toLowerCase() !== c.toLowerCase());
    });
    const internetCategories = missingCategories.find(mc => isProductCategoryInternet(mc));

    if (internetCategories?.length > 0) {
      missingCategories = missingCategories.filter(mc => !isProductCategoryInternet(mc));
      missingCategories.push(ProductPriceIndicationCategory.SUPERSCHNELL);
    }

    if (isArrayWithMinOneItem(missingCategories)) {
      const requests = missingCategories.map(category => this.orderService.loadPriceIndications(plz, category));
      const newIndications = (await Promise.all(requests)).flat();
      this.tariffSelectionStateService.indications = [...this.tariffSelectionStateService.indications, ...newIndications];
    }

    return this.tariffSelectionStateService.indications;
  }

  async getUpdatedPriceIndications(
    plz: string,
    category: string,
    tariffKey?: string,
    options?: string,
    voucherCode?: string,
  ): Promise<PreisIndikationen> {
    const response = await this.orderService.loadPriceIndications(plz, category, tariffKey, options, voucherCode);
    return response?.[0];
  }

  // after tariff-options-change we need new priceIndications for this category to show updated prices
  async updatePriceIndications(plz: string, category: string, tariffKey?: string, options?: string): Promise<void> {
    if (this.mockCategories.includes(category)) {
      return Promise.resolve();
    }

    const updatedTariffIndications = await this.orderService.loadPriceIndications(plz, category, tariffKey, options);
    // remove old indications
    const indications = this.tariffSelectionStateService.indications.filter(i => {
      return updatedTariffIndications.every(ui => ui.tarif.ISUTarifKey !== i.tarif.ISUTarifKey);
    });
    // add new indications
    this.tariffSelectionStateService.indications = indications.concat(updatedTariffIndications);
  }

  wasTariffOptionsFormPopupOpenedFor(category: string): boolean {
    return this.tariffSelectionStateService.tariffOptionsFormPopupAlreadyOpenedFor.some(c => c === category);
  }

  markCategoryAsOpened(category: string): void {
    if (this.wasTariffOptionsFormPopupOpenedFor(category)) {
      return;
    }

    const categories = this.tariffSelectionStateService.tariffOptionsFormPopupAlreadyOpenedFor.concat(category);
    this.tariffSelectionStateService.tariffOptionsFormPopupAlreadyOpenedFor = categories;
  }
}
