import { Component, Input, OnInit } from '@angular/core';
import { PRODUCTS_ROUTE, LANDING_PAGE_ROUTE } from '@mwe/constants';
import { AccountLogic } from '@mwe/services';
import { EnvironmentCustomerType } from '@mwe/environment';

@Component({
  selector: 'mwe-header-logo',
  templateUrl: './header-logo.component.html',
})
export class HeaderLogoComponent implements OnInit {
  @Input() customerType: EnvironmentCustomerType;
  isLoggedIn: boolean;
  targetRoute = `/${LANDING_PAGE_ROUTE}`;

  constructor(private accountLogic: AccountLogic) {}

  ngOnInit(): void {
    if (this.accountLogic.isLoggedIn()) {
      this.targetRoute = `/${PRODUCTS_ROUTE}`;
    }
  }
}
