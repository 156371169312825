import { everyTrue, isEqualCategoryString } from '../model_utils';
import dayjs from 'dayjs';
import { PreisIndikationen } from '../availabilities/availabilities.model';
import {
  CommonMappingData,
  IAktionen,
  IBankDetails,
  IBulletConfirmationStatusChangeResponse,
  IDifferentInvoiceRecipient,
  IERechnungsInfo,
  Sparte,
} from '../integrationlayer/api.model';
import {
  Anlage,
  KundenTypEnum,
  Tarif,
  Tarifwechsel,
  TarifwechselBedingungen,
  TarifwechselProdukte,
} from '../integrationlayer/tarifwechsel.model';
import { MandatsReferenzMappingItem } from '../invoices/invoice-data-change.models';
import { IOrderDetailsInfo, ORDER_DETAILS_VERSION } from '../order/order.model';
import { Product } from '../products/product.model';

export const newTariffChangeDetailsInfo = (_category: string, _customerNumber: string, _accountNUmber: string): IOrderDetailsInfo => {
  return {
    apiVersion: ORDER_DETAILS_VERSION,
    produktDetails: null,
    invoiceAddress: null,
    eRechnungInfo: null,
    bezahlartIcon: null,
    bezahlartText: null,
    status: 'Tarifwechsel',
    category: _category,
    customerNumber: _customerNumber,
    accountNumber: _accountNUmber,
    physischePerson: null,
  };
};

export interface TariffChangeDTO {
  addressCode: string;
  differentInvoiceRecipient?: IDifferentInvoiceRecipient;
  sepaMandat: IBankDetails;
  eRechnungsInfo: IERechnungsInfo;
  voucher: IAktionen;
  tarifwechsel: Tarifwechsel[];
  orderedAt?: string;
  id?: number;
  processStatus?: string;
  mandatsReferenzMapping?: MandatsReferenzMappingItem[];
  customerDataChangeData?: { email: string; customerNumbers: string[] };
  mappingData: CommonMappingData[];
  customerNumbers?: string[];
  accountNumbers?: string[];
  priceIndications: PreisIndikationen[];
}

// tslint:disable-next-line:no-empty-interface
export type TariffChangeResponse = IBulletConfirmationStatusChangeResponse;

export interface TariffCondition {
  tariff: Tarif;
  condition: TarifwechselBedingungen;
}

export interface TariffChangeAvailability {
  anlage: Anlage;
  sparte: Sparte;
  vertagskontoId: string;
  kundenTyp: KundenTypEnum;
  tariffs: TariffCondition[];
}
export interface TariffChangeAvailabilities {
  availabilities: TariffChangeAvailability[];
}

export const getTariffChangeAvailability = (
  taAvailabilities: TariffChangeAvailabilities,
  accountNumber: string,
  product: TarifwechselProdukte
): TariffChangeAvailability => {
  return taAvailabilities?.availabilities.find(a =>
    everyTrue(a.vertagskontoId === accountNumber, isEqualCategoryString(a.sparte, product.sparte))
  );
};

export const getTariffChangeAvailabilityForProduct = (
  taAvailabilities: TariffChangeAvailabilities,
  product: Product
): TariffChangeAvailability => {
  return taAvailabilities?.availabilities.find(a =>
    everyTrue(a.vertagskontoId === product.accountNumber, isEqualCategoryString(a.sparte, product.category))
  );
};

export const parseTariffChangeDTO = (orderDetails: any): TariffChangeDTO[] => {
  const orderDTODetails: TariffChangeDTO[] = [];
  if (Array.isArray(orderDetails)) {
    orderDetails.forEach(item => {
      const orderData = JSON.parse(item.changeData);

      if (!orderData.mappingData) {
        // parse old stored data set
        // temporary code to map old data entries
        // for old data entries we have user either the accountNummber or the customerNumber to define bullet ids
        orderData.mappingData = [] as CommonMappingData[];
        let orderIdx = 0;
        orderData.tarifwechsel.forEach(tw => {
          let mappingData = orderData.mappingData.find(
            md => md.accountNumber === tw.vertragsKontoNummer && md.custommerNumber === tw.geschaeftsPartnerId
          );
          if (!mappingData) {
            mappingData = {} as CommonMappingData;
            mappingData.custommerNumber = tw.geschaeftsPartnerId;
            mappingData.businessPartnerNumber = tw.geschaeftsPartnerId;
            mappingData.accountNumber = tw.vertragsKontoNummer;
            mappingData.categories = [];
            mappingData.orderIdx = orderIdx;
            orderData.mappingData.push(mappingData);
            orderIdx++;
          }
          mappingData.categories.push(tw.sparte);
          if (orderData.accountNumbers?.includes(tw.vertragsKontoNummer)) {
            mappingData.orderIndexAlias = tw.vertragsKontoNummer;
            mappingData.changeERechnung = true;
          }
          if (orderData.customerNumbers?.includes(tw.geschaeftsPartnerId)) {
            mappingData.orderIndexAlias = tw.geschaeftsPartnerId;
            mappingData.changeSepaMandat = true;
          }
        });
      }

      const orderDataDTO = {
        addressCode: orderData.addressCode,
        invoiceAddress: orderData.invoiceAddress,
        sepaMandat: orderData.sepaMandat,
        eRechnungsInfo: orderData.eRechnungsInfo,
        voucher: orderData.voucher,
        tarifwechsel: orderData.tarifwechsel,
        orderedAt: item.createdAt,
        id: item.id,
        processStatus: item.processStatus,
        mandatsReferenzMapping: item.responseData ? JSON.parse(item.responseData).response : [],
        differentInvoiceRecipient: orderData.differentInvoiceRecipient,
        customerDataChangeData: orderData.customerDataChangeData,
        mappingData: orderData.mappingData,
        customerNumbers: orderData.customerNumbers,
        accountNumbers: orderData.accountNumbers,
        priceIndications: orderData.priceIndications,
      };

      orderDTODetails.push(orderDataDTO);
    });
  }
  return orderDTODetails.sort((a, b) => (dayjs(a.orderedAt).isBefore(dayjs(b.orderedAt)) ? 1 : -1));
};
