@if (state === ServiceStateEnum.LOADING) {
  @if (loadingSpinnerType === 'normal' || loadingSpinnerType === 'normal-without-box') {
    <div class="vertical-center align-items-center" [ngClass]="{ 'card teaser': loadingSpinnerType === 'normal' }">
      <mwe-loading-spinner />
    </div>
  }
  @if (loadingSpinnerType === 'extended') {
    <div>
      <mwe-loading-spinner-extended />
    </div>
  }

  @if (loadingSpinnerType === 'extended-white-background') {
    <div>
      <mwe-loading-spinner-extended-white-background [loadingSpinnerText]="loadingSpinnerText" />
    </div>
  }
}

@if (state === ServiceStateEnum.ERROR) {
  @if (errorTemplate) {
    <ng-container *ngTemplateOutlet="errorTemplate" />
  } @else {
    <mwe-alert
      *ngIf="!specialAlertError"
      [type]="alertType"
      [titleKey]="alertTitleKey"
      [messageKey]="alertMessageKey"
      [placeholderMap]="errorPlaceholderMap"
    />
    <mwe-special-alert
      *ngIf="specialAlertError"
      [titleKey]="alertTitleKey"
      [messageKey]="alertMessageKey"
      [reloadButtonKey]="reloadButtonKey"
      (reloadEventEmitter)="reloadButtonClicked.emit()"
    />
  }
}

@if (state === ServiceStateEnum.SUCCESS) {
  <ng-container *ngTemplateOutlet="successTemplate" />
}
