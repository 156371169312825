import { Component, computed, inject, input, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiComponentsModule } from '@mwe/ui/components';
import { CommonModule } from '@angular/common';
import {
  EMPTY_CONTRACT_PARTNER_KEY,
  EmptyContractPartner,
} from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/empty_contract_partner.model';
import { buildInvoiceAddressForm } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/current-contract-partner-form-builder';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { CompanyService } from '@ikp/service/company/company.service';
import { INVOICE_ADDRESS_KEY } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/occupied_contract_partner.model';
import { CustomValidators } from '@shared/utils/validators';
import { InvoiceAddressFormComponent } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/components/invoice-company-address/invoice-address-form.component';
import { preparePartnerAddressForFormatter } from '@ikp/pages/meter-point-change-request/meter-point-change-request.utils';

@Component({
  selector: 'app-empty-contract-partner',
  standalone: true,
  templateUrl: './empty_contract_partner.component.html',
  styleUrl: './empty_contract_partner.component.scss',
  imports: [UiComponentsModule, FormsModule, ReactiveFormsModule, CommonModule, InvoiceAddressFormComponent],
})
export class EmptyContractPartnerComponent implements OnInit {
  parentForm = input<FormGroup>();
  formGroup: FormGroup;
  private formBuilder = inject(FormBuilder);
  protected companyService = inject(CompanyService);
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  protected meterPoint = computed(() => this.wizardStore.state().meterPoint);
  emptyContractPartner = signal<EmptyContractPartner>(new EmptyContractPartner());

  protected readonly addressFormatted = computed(() => preparePartnerAddressForFormatter(this.formGroup.get('address').value));

  ngOnInit(): void {
    const currentEmptyContractPartner = this.wizardStore.state().currentContractPartner?.emptyContractPartner;
    if (currentEmptyContractPartner) {
      this.addControlsForEmptyContractPartner(currentEmptyContractPartner);
    } else {
      this.formGroup = this.buildEmptyContractPartnerForm(null);
      this.parentForm().addControl(EMPTY_CONTRACT_PARTNER_KEY, this.formGroup);
      this.loadCompanyData();
    }
  }

  isInputInvalid(key: string) {
    if (!this.parentForm()?.get(key).touched) {
      return false;
    }
    return this.parentForm()?.get(key).invalid;
  }

  private addControlsForEmptyContractPartner(currentEmptyContractPartner: EmptyContractPartner) {
    this.formGroup = this.buildEmptyContractPartnerForm(currentEmptyContractPartner);
    this.parentForm().addControl(EMPTY_CONTRACT_PARTNER_KEY, this.formGroup);
    if (currentEmptyContractPartner.invoiceAddress) {
      this.parentForm().addControl(
        INVOICE_ADDRESS_KEY,
        buildInvoiceAddressForm(this.formBuilder, currentEmptyContractPartner.invoiceAddress),
      );
    }
  }

  buildEmptyContractPartnerForm = (emptyContractPartner: EmptyContractPartner | null) => {
    return this.formBuilder.group({
      company: [emptyContractPartner?.company ?? '', Validators.required],
      customerNumber: [emptyContractPartner?.customerNumber ?? '', [Validators.required, CustomValidators.CUSTOMER_NUMBER]],
      address: [emptyContractPartner?.address ?? '', Validators.required],
      differentAddress: [emptyContractPartner?.invoiceAddress != null],
    });
  };

  protected readonly INVOICE_ADDRESS_KEY = INVOICE_ADDRESS_KEY;

  private loadCompanyData() {
    this.companyService.loadCompanyByGpNr(this.meterPoint().geschaeftspartnerNr).then(company => {
      const emptyContractPartner = new EmptyContractPartner();
      emptyContractPartner.company = company.name;
      emptyContractPartner.customerNumber = company.gpNr;
      emptyContractPartner.address = {
        plz: company.address.postcode,
        city: company.address.city,
        street: company.address.street,
        houseNumberStaircase: company.address.streetNumber,
        door: company.address.doorNumber,
      };
      this.emptyContractPartner.set(emptyContractPartner);
      this.parentForm().patchValue({ [EMPTY_CONTRACT_PARTNER_KEY]: emptyContractPartner });
    });
  }
}
