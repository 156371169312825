import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[mweLookAtMeIAmTheFocusNow]',
})
export class LookAtMeIAmTheFocusNowDirective implements OnInit {
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }
}
