<div [ngClass]="getAlertClasses()" [attr.aria-describedby]="uuid" [attr.role]="!!role ? role : null" *ngIf="isVisible">
  <button aria-label="Close" class="alert-close" type="button" *ngIf="showCloseButton" (click)="isVisible = false">
    <span aria-hidden="true"></span>
  </button>
  <div class="row g-2" [ngClass]="{ 'g-md-5': !isMinimalLayout }">
    <div class="col-auto text-center">
      <div class="alert-icon-wrapper">
        <i [ngClass]="getIconClasses()"></i>
      </div>
    </div>
    <div class="col align-self-center">
      <ng-container *ngIf="showTitle()">
        <ng-container [ngSwitch]="headingNodeType">
          <ng-container *ngSwitchCase="'h1'">
            <h1 [id]="uuid" class="h5" data-test="alert-headline" *ngIf="titleKey | translate: translateValues as titleLabel">
              {{ titleLabel }}
            </h1></ng-container
          >
          <ng-container *ngSwitchCase="'h2'">
            <h2 [id]="uuid" class="h5" data-test="alert-headline" *ngIf="titleKey | translate: translateValues as titleLabel">
              {{ titleLabel }}
            </h2></ng-container
          >
          <ng-container *ngSwitchCase="'h3'">
            <h3 [id]="uuid" class="h5" data-test="alert-headline" *ngIf="titleKey | translate: translateValues as titleLabel">
              {{ titleLabel }}
            </h3></ng-container
          >
          <ng-container *ngSwitchCase="'h4'">
            <h4 [id]="uuid" class="h5" data-test="alert-headline" *ngIf="titleKey | translate: translateValues as titleLabel">
              {{ titleLabel }}
            </h4></ng-container
          >
          <ng-container *ngSwitchCase="'h5'">
            <h5 [id]="uuid" class="h5" data-test="alert-headline" *ngIf="titleKey | translate: translateValues as titleLabel">
              {{ titleLabel }}
            </h5></ng-container
          >
          <ng-container *ngSwitchCase="'h6'">
            <h6 [id]="uuid" class="h5" data-test="alert-headline" *ngIf="titleKey | translate: translateValues as titleLabel">
              {{ titleLabel }}
            </h6></ng-container
          >
        </ng-container>
      </ng-container>
      <p class="text--big" *ngIf="infoText">
        <span
          data-test="alert-message"
          [ngStyle]="type === 'danger' && { color: 'white' }"
          [innerHTML]="messageKey | translate: translateValues | safeHtml"
        ></span>
        <mwe-info-text-icon
          [infoTextTitle]="infoText.title | translate"
          [infoTextDescription]="infoText.description | translate"
          colorClass="white"
        ></mwe-info-text-icon>
      </p>
      <p
        [id]="uuid"
        class="text--big"
        *ngIf="!infoText && messageKey"
        [ngStyle]="type === 'danger' && { color: 'white' }"
        [innerHTML]="messageKey | translate: translateValues | safeHtml"
      ></p>
      <ng-container *ngIf="messageKeys">
        <p class="text--big" *ngFor="let key of messageKeys" [innerHTML]="key | translate: translateValues | safeHtml"></p>
      </ng-container>
      <p *ngIf="link" class="text--big mt-1">
        <a id="alert-link" data-test="alert-link" href="#" mweClickPreventDefault (click)="fireLinkClickEvent()" [translate]="link"></a>
      </p>
      <ng-content></ng-content>
    </div>
  </div>
</div>
