import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ServiceStateEnum } from '@mwe/constants';
import { AlertType } from '@mwe/models';

@Component({
  selector: 'mwe-pre-load-data',
  templateUrl: './pre-load-data.component.html',
})
export class PreLoadDataComponent {
  @Input() state: ServiceStateEnum;
  @Input() alertTitleKey = 'newClient.status.loadError.key';
  @Input() alertMessageKey = 'newClient.status.loadError.text';
  @Input() alertType: AlertType = 'danger';
  @Input() errorPlaceholderMap: Map<string, string>;
  @Input() successTemplate: TemplateRef<any>;
  @Input() errorTemplate: TemplateRef<any>;
  @Input() specialAlertError = false;
  @Input() loadingSpinnerType: 'normal' | 'normal-without-box' | 'extended' | 'extended-white-background' = 'normal';
  @Input() loadingSpinnerText: string;
  @Input() reloadButtonKey: string;
  @Output() reloadButtonClicked = new EventEmitter();

  ServiceStateEnum = ServiceStateEnum;
}
