import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { IProduct, parseProduct, Product, ICustomerNumberInfo, IPersonalData, IBusinessData } from '@mwe/models';
import { ProductsWhiteListLogic } from '../../products/products-white-list.logic';

const PRODUCTS_KEY = 'customerDataChange_productsKey';
const IS_SELECTION_VISIBLE_KEY = 'customerDataChange_isSelectionVisible';
const SELECTED_CUSTOMER_DATA_KEY = 'customerDataChange_selectedCustomerData';
const LAST_ORDER_ID_KEY = 'customerDataChange_lastOrderId';
const RETRIED_ON_ERROR_KEY = 'customerDataChange_retriedOnError';
const SUBMIT_ID_KEY = 'customerDataChange_submitId';
const PERSONAL_DATA_KEY = 'customerDataChange_personalData';
const BUSINESS_DATA_KEY = 'customerDataChange_businessData';

@Injectable({
  providedIn: 'root',
})
export class CustomerDataChangeStateService {
  @SessionStorage(PRODUCTS_KEY)
  private _products: IProduct[];

  @SessionStorage(IS_SELECTION_VISIBLE_KEY)
  private _isSelectionVisible: boolean;

  @SessionStorage(SELECTED_CUSTOMER_DATA_KEY)
  private _selectedCustomerData: ICustomerNumberInfo;

  @SessionStorage(LAST_ORDER_ID_KEY)
  private _lastOrderId: string;

  @SessionStorage(RETRIED_ON_ERROR_KEY)
  private _retriedOnError: boolean;

  @SessionStorage(SUBMIT_ID_KEY)
  private _submitId: number;

  @SessionStorage(PERSONAL_DATA_KEY)
  private _personalData: IPersonalData;

  @SessionStorage(BUSINESS_DATA_KEY)
  private _businessData: IBusinessData;

  constructor(
    private storage: SessionStorageService,
    private productsWhiteListLogic: ProductsWhiteListLogic,
  ) {}

  clear(): void {
    this.storage.clear(PRODUCTS_KEY);
    this.storage.clear(IS_SELECTION_VISIBLE_KEY);
    this.storage.clear(SELECTED_CUSTOMER_DATA_KEY);
    this.storage.clear(RETRIED_ON_ERROR_KEY);
    this.storage.clear(SUBMIT_ID_KEY);
    this.storage.clear(PERSONAL_DATA_KEY);
    this.storage.clear(BUSINESS_DATA_KEY);
  }

  get products(): Product[] {
    if (!Array.isArray(this._products)) {
      return undefined;
    }

    return this._products.map(elem => parseProduct(elem));
  }

  set products(products: Product[]) {
    if (!Array.isArray(products)) {
      this.storage.clear(PRODUCTS_KEY);
      return;
    }

    this._products = this.productsWhiteListLogic.removeNotWhiteListedProducts(products);
  }

  get selectedCustomerData(): ICustomerNumberInfo {
    return this._selectedCustomerData || undefined;
  }

  set selectedCustomerData(value: ICustomerNumberInfo) {
    if (!value) {
      this.storage.clear(SELECTED_CUSTOMER_DATA_KEY);
      return;
    }
    this._selectedCustomerData = value;
  }

  get isSelectionVisible(): boolean {
    return !!this._isSelectionVisible;
  }

  set isSelectionVisible(value: boolean) {
    if (!value) {
      this.storage.clear(IS_SELECTION_VISIBLE_KEY);
      return;
    }

    this._isSelectionVisible = value;
  }

  get submitId(): number {
    return this._submitId || undefined;
  }

  set submitId(submitId: number) {
    if (submitId) {
      this._submitId = submitId;
    } else {
      this.storage.clear(SUBMIT_ID_KEY);
    }
  }

  get lastOrderId(): string {
    return this._lastOrderId || undefined;
  }

  set lastOrderId(lastOrderId: string) {
    if (lastOrderId) {
      this._lastOrderId = lastOrderId;
    } else {
      this.storage.clear(LAST_ORDER_ID_KEY);
    }
  }

  get retriedOnError(): boolean {
    return this._retriedOnError || undefined;
  }

  set retriedOnError(retriedOnError: boolean) {
    if (retriedOnError) {
      this._retriedOnError = retriedOnError;
    } else {
      this.storage.clear(RETRIED_ON_ERROR_KEY);
    }
  }

  get personalData(): IPersonalData {
    return this._personalData || undefined;
  }

  set personalData(personalData: IPersonalData) {
    if (personalData) {
      this._personalData = personalData;
    } else {
      this.storage.clear(PERSONAL_DATA_KEY);
    }
  }

  get businessData(): IBusinessData {
    return this._businessData || undefined;
  }

  set businessData(businessData: IBusinessData) {
    if (businessData) {
      this._businessData = businessData;
    } else {
      this.storage.clear(BUSINESS_DATA_KEY);
    }
  }
}
