<section class="maintenance-hero">
  <div class="maintenance-bg">
    <div class="maintenance-guy-bg">
      <header id="header">
        <div class="container-md">
          <div *ngIf="isHeaderLogoVisible" class="row pt-3">
            <div class="col-12">
              <mwe-header-logo></mwe-header-logo>
            </div>
          </div>
          <div class="row maintenance mt-7 h-auto">
            <div class="col-md-6 d-block d-md-none">
              <img class="maintenance-img" src="assets/img/maintenance_illustration.svg" alt="{{ headline | translate }}" />
            </div>
            <div class="col-md-6 p-4 maintenance-text">
              <h1 [innerHTML]="headline | translate"></h1>
              <h2 *ngIf="subheadline" [innerHTML]="subheadline | translate"></h2>
              <p [innerHTML]="text | translate"></p>
              <mwe-button
                class="d-block mt-7"
                *ngIf="isBtnVisible"
                [showHoverEffect]="true"
                [labelKey]="buttonLabelkey | translate"
                (clicked)="buttonClick.emit()"
              ></mwe-button>
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
</section>
