import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { ChatbotComponent } from '@mwe/ui/components';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { AccountLogic, ChatBotService, EnvironmentService, ProfileService } from '@mwe/services';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { IdleCheckService } from '@mwe/interceptors';
import { FEATURE_TOGGLED_OUT_ROUT, FeatureToggleEnum, LANDING_PAGE_B2C_REPLACEMENT_ROUTE } from '@mwe/constants';
import { getRouteData, track } from '@mwe/utils';

@Component({
  selector: 'mwe-app-base',
  templateUrl: './app-base.component.html',
})
export class AppBaseComponent {
  @ViewChild(ChatbotComponent) chatbotRef: ChatbotComponent;
  @Input() defaultPageTitle: string;

  showNavBar = false;
  hideFooter = false;
  noBottomPadding = false;
  isCookieConsentEnabled: boolean;
  isMaintenanceEnabled: boolean;
  routeLoaded = false;
  showChatbot = false;

  time: any;
  lastTimeWhenWindowWasFocused: dayjs.Dayjs;

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService,
    private translateService: TranslateService,
    private titleService: Title,
    private accountLogic: AccountLogic,
    private chatbotService: ChatBotService,
    private idleCheckService: IdleCheckService,
    private environmentService: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.isCookieConsentEnabled =
      this.profileService.isFeatureToggleEnabled(FeatureToggleEnum.COOKIE_POPUP_ENABLED) && !this.accountLogic.isImpersonate();

    const chatBotSub = this.chatbotService.events()?.subscribe(value => {
      if (this.showChatbot && value) {
        this.chatbotRef.showChatbot();
      }
      this.showChatbot = value;
    });

    const routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const routeData = getRouteData(this.activatedRoute) || {};
        const titles = routeData.pageTitles || [this.defaultPageTitle];
        this.showNavBar = routeData.showNavBar;
        this.hideFooter = routeData.hideFooter;
        this.noBottomPadding = routeData.noBottomPadding;
        this.updateTitleFromKeys(titles);

        track('virtualPageView', {
          title: document.title,
          url: location.pathname,
        });

        window.scrollTo(0, 0);
        this.isMaintenanceEnabled =
          this.profileService.isFeatureToggleEnabled(FeatureToggleEnum.MAINTENANCE_ENABLED) ||
          event.url === `/${LANDING_PAGE_B2C_REPLACEMENT_ROUTE}` ||
          event.url === `${this.environmentService.getPortalUrlPrefix()}/${FEATURE_TOGGLED_OUT_ROUT}`;
        this.routeLoaded = true;
      }
      if (event instanceof NavigationError && event.error.status === 404) {
        this.router.navigate(['/404']);
      }
    });
    this.subscriptions.add(routerSub);
    this.subscriptions.add(chatBotSub);
    this.resetIdleTimer();
  }

  @HostListener('document:mousemove') @HostListener('document:keypress') resetIdleTimer() {
    clearTimeout(this.time);
    this.idleCheckService.setIsUserIdle(false);
    this.time = setTimeout(() => {
      this.idleCheckService.setIsUserIdle(true);
    }, this.idleCheckService.getTimeWhenUserIsConsideredAsIdle());
  }

  @HostListener('window:focus') onFocusWindow(): void {
    if (!this.lastTimeWhenWindowWasFocused) {
      return;
    }

    const currentTime = dayjs();
    const lastWindowFocus = this.lastTimeWhenWindowWasFocused;
    const exactTimeWhenUserIsConsideredIdle = lastWindowFocus.add(this.idleCheckService.getTimeWhenUserIsConsideredAsIdle(), 'millisecond');

    if (currentTime.isAfter(exactTimeWhenUserIsConsideredIdle)) {
      this.idleCheckService.setWasWindowInactiveForLongerThanIdleTime(true);
    }
  }

  @HostListener('window:blur') onBlurWindow(event: any): void {
    this.lastTimeWhenWindowWasFocused = dayjs();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  updateTitleFromKeys(titleKeys?: string[], customTitle = ''): void {
    if (!titleKeys) {
      return;
    }

    this.translateService.get(titleKeys).subscribe(translationObject => {
      const titles = Object.values(translationObject);

      if (customTitle) {
        titles.push(customTitle);
      }

      const newTitle = titles.join(': ');
      this.titleService.setTitle(newTitle);
    });
  }
}
