import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieConsent } from '@wienenergiegithub/modules-next';
import { IkpEnvironmentService } from '@ikp/service/environment/ikp-environment.service';

const containerElementName = 'cookieConsent';
declare global {
  interface Window {
    initializePiwik: () => void;
    WE_COOKIE_DOMAIN: string;
    WE_COOKIECONSENT_DATAURL: string;
  }
}

@Component({
  selector: 'app-ikp-cookie',
  standalone: true,
  imports: [CommonModule],
  template: `<span #${containerElementName}></span>`,
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class CookieComponent implements OnDestroy, AfterViewInit, OnInit {
  @ViewChild(containerElementName, { static: false }) containerRef: ElementRef;

  envService = inject(IkpEnvironmentService);

  ngOnInit(): void {
    const piwikTagPropertyId = this.envService.getPiwikTagPropertyId();

    if (piwikTagPropertyId) {
      localStorage.setItem('COOKIECONSENT_PIWIKTAGPROPERTYID', piwikTagPropertyId);
      window.initializePiwik();
    }
  }

  ngOnDestroy(): void {
    CookieConsent.unMount(this.containerRef.nativeElement);
  }

  ngAfterViewInit(): void {
    this.render();
  }

  showConsent() {
    const piwikTagPropertyId = this.envService.getPiwikTagPropertyId();

    CookieConsent.mount({
      rootElement: this.containerRef.nativeElement,
      props: {
        hideOverlay: false,
        piwikTagPropertyId,
      },
    });
  }

  private render(): void {
    const temp = window.location.host.split('.').reverse();
    const rootDomain = '.' + temp[1] + '.' + temp[0];
    const cookieDomain = window.location.host.includes('localhost') ? 'localhost' : rootDomain;
    window.WE_COOKIE_DOMAIN = cookieDomain;
    window.WE_COOKIECONSENT_DATAURL = this.envService.getCookieConsentDataUrl();
    this.showConsent();
  }
}
