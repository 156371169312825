import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Address, parseAddress, parseProducts, Product } from '@mwe/models';
import { ProductConsumptionLogic } from './product-consumption.logic';
import { ProductsWhiteListLogic } from '../products-white-list.logic';
import { filterStromGasWaermeProducts } from '@mwe/utils';

const productsKey = 'consumption_products';
const addressKey = 'consumption_address';
const preselectedProductKey = 'consumption_preselected_product';

@Injectable({
  providedIn: 'root',
})
export class ConsumptionStateService {
  @SessionStorage(productsKey)
  private _products: Product[];

  @SessionStorage(addressKey)
  private _address: Address;

  @SessionStorage(preselectedProductKey)
  private _preselectedProduct: Product;

  constructor(
    private storage: SessionStorageService,
    private productsWhiteListLogic: ProductsWhiteListLogic,
    private productConsumptionLogic: ProductConsumptionLogic
  ) {}

  clearData(): void {
    this.storage.clear(productsKey);
    this.storage.clear(addressKey);
    this.storage.clear(preselectedProductKey);
  }

  get products(): Product[] {
    return this._products && this.productConsumptionLogic.sortProducts(parseProducts(this._products));
  }

  set products(products: Product[]) {
    const productsWhiteListed = this.productsWhiteListLogic.removeNotWhiteListedProducts(products);
    this._products = filterStromGasWaermeProducts(productsWhiteListed);
  }

  get preselectedProduct(): Product {
    return this._preselectedProduct;
  }

  set preselectedProduct(preselectedProduct: Product) {
    this._preselectedProduct = preselectedProduct;
  }

  get address(): Address {
    return this._address && parseAddress(this._address);
  }

  set address(address: Address) {
    this._address = address;
  }
}
