import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureToggleEnum } from '@mwe/constants';
import { ProfileService } from '@mwe/services';

@Directive({
  selector: '[mweIsFeatureEnabled]',
})
export class IsFeatureEnabledDirective {
  private hasView = false;

  @Input() set mweIsFeatureEnabled(toggle: FeatureToggleEnum) {
    const isEnabled = this.profileService.isFeatureToggleEnabled(toggle);

    if (isEnabled && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!isEnabled && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private profileService: ProfileService) {}
}
