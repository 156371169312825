export enum ActionType {
  ADDRESS_GROUP = 'ADDRESS_GROUP',
  ASSOCIATIONS = 'ASSOCIATIONS',
}

interface IAction {
  type: ActionType;
  message: string;
}

export class Action implements IAction {
  constructor(public type: ActionType, public message: string) {
    this.type = type;
    this.message = message;
  }
}
