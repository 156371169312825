import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DetailsLinkTypeEnum } from '@mwe/constants';
import {
  AddressGroup,
  IAddress,
  IAddressGroup,
  parseAddressGroup,
  parseIAddressGroup,
  parseISODate,
  checkAddressesForGroupUpdates,
} from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { ProductStateService } from '../products/product-state.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AddressGroupService {
  public resourceUrl: string;

  constructor(
    private http: HttpClient,
    private productStateService: ProductStateService,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/address-group';
  }

  loadAddressGroups(): Promise<AddressGroup[]> {
    return lastValueFrom(this.http.get<string>(this.resourceUrl + '/allGroups')).then(
      res => {
        // Success
        const addressGroupList: AddressGroup[] = [];
        if (res.length) {
          for (let i = 0; i < res.length; i++) {
            const iag: IAddressGroup = parseIAddressGroup(res[i]);
            addressGroupList.push(
              new AddressGroup(
                iag.id,
                iag.userId,
                iag.addressCode,
                iag.groupName,
                iag.detailsLinkType === DetailsLinkTypeEnum.OUTDATED ? undefined : iag.detailsLinkType,
                iag.customerType,
                this.toDate(iag.createdAt),
                this.toDate(iag.modifiedAt),
              ),
            );
          }
        }
        return addressGroupList;
      },
      err => {
        throw err;
      },
    );
  }

  setAddressGroupName(addressGroup: AddressGroup): Promise<AddressGroup> {
    return lastValueFrom(this.http.post<string>(this.resourceUrl, addressGroup, { observe: 'response' })).then(
      res => {
        return this.parseAddressGroup(res.body);
      },
      err => {
        throw err;
      },
    );
  }

  deleteAddressGroupName(addressGroup: AddressGroup): Promise<boolean> {
    return lastValueFrom(this.http.delete(this.resourceUrl + '/' + addressGroup.id, { observe: 'response' })).then(
      () => {
        // Success
        return true;
      },
      err => {
        throw err;
      },
    );
  }

  toDate(dateTime: any): Date {
    return parseISODate(dateTime);
  }

  parseAddressGroup(json?: any): AddressGroup {
    const a = json as AddressGroup;
    a.createdAt = this.toDate(a.createdAt);
    a.modifiedAt = this.toDate(a.modifiedAt);
    return a;
  }

  async loadAndMapGroupNames(addresses: IAddress[]): Promise<void> {
    const addressGroups = await this.getAndUpdateGroupNames();

    checkAddressesForGroupUpdates(addresses, addressGroups);
  }

  async getAndUpdateGroupNames(): Promise<AddressGroup[]> {
    let groupNames = this.productStateService.groupNames;

    if (!groupNames) {
      try {
        groupNames = await this.loadAddressGroups();
        this.productStateService.groupNames = groupNames;
      } catch (e) {
        groupNames = [];
      }
    }

    return parseAddressGroup(groupNames);
  }
}
