import { Component, OnInit } from '@angular/core';
import { DsgvoService, EnvironmentService } from '@mwe/services';
import { PROFILE_DSGVO_SETTINGS_ROUTE, PROFILE_ROUTE } from '@mwe/constants';

@Component({
  selector: 'mwe-dsgvo-disclaimer',
  templateUrl: './dsgvo-disclaimer.component.html',
})
export class DsgvoDisclaimerComponent implements OnInit {
  isCardVisible = false;
  textParams;

  constructor(
    private environmentService: EnvironmentService,
    private dsgvoService: DsgvoService,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const prefix = this.environmentService.getCurrentPortalUri();
      const url = `${prefix}/${PROFILE_ROUTE}/${PROFILE_DSGVO_SETTINGS_ROUTE}`;
      this.textParams = { url };

      this.isCardVisible = await this.dsgvoService.isDsgvoSuccessfullySet();
    } catch (_) {
      // ignore error
      this.isCardVisible = false;
    }
  }
}
