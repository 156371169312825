import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormInputLoadingSpinnerOptions, IFormInput } from '@mwe/models';

@Component({
  selector: 'mwe-form-input-loading-spinner',
  templateUrl: './form-input-loading-spinner.component.html',
})
export class FormInputLoadingSpinnerComponent implements OnInit {
  @Input() inputElem: IFormInput;
  @Input() currentValue;
  @Input() isValid: boolean;
  @Input() suppressValidityCheckOnSubmit = false;
  @Output() isLoading = new EventEmitter<boolean>();
  loadingSpinnerOptions: FormInputLoadingSpinnerOptions;

  state: 'loading' | 'submit' = 'submit';

  ngOnInit(): void {
    this.loadingSpinnerOptions = this.inputElem.loadingSpinnerOptions;
  }

  get submitLabel(): string {
    if (typeof this.inputElem.loadingSpinnerOptions.submitLabelKey === 'function') {
      return this.inputElem.loadingSpinnerOptions.submitLabelKey(this.currentValue, this.isValid);
    }
    return this.inputElem.loadingSpinnerOptions.submitLabelKey;
  }

  get disabled(): boolean {
    if (this.inputElem.loadingSpinnerOptions.disabled) {
      if (typeof this.inputElem.loadingSpinnerOptions.disabled === 'boolean') {
        return this.inputElem.loadingSpinnerOptions.disabled;
      }
      return this.inputElem.loadingSpinnerOptions.disabled(this.currentValue, this.isValid);
    }
    if (this.inputElem.loadingSpinnerOptions.disabledWhileEmpty) {
      return !this.currentValue;
    }
    return this.inputElem.loadingSpinnerOptions.disabledWhileInvalid ? !this.isValid : false;
  }

  async submit(): Promise<void> {
    if (!this.isValid && !this.suppressValidityCheckOnSubmit) {
      return Promise.resolve();
    }

    this.state = 'loading';
    this.isLoading.emit(true);
    await this.inputElem.loadingSpinnerOptions.asyncLoadingFunc(this.currentValue);
    this.state = 'submit';
    this.isLoading.emit(false);
  }
}
