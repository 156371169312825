import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { AddFormInput, IFormInput, IFormModel } from '@mwe/models';
import { isArrayWithMinOneItem } from './mwe-util';

export const getNumberOnlyValidator = (): ValidatorFn => {
  return Validators.pattern('^[0-9]*$');
};

export const addAdditionalFormInputs = (additionalFormInputs: AddFormInput[], formModel: IFormModel): void => {
  if (!isArrayWithMinOneItem(additionalFormInputs)) {
    return;
  }

  additionalFormInputs.forEach(addInfo => {
    if (addInfo.addBegin) {
      formModel.inputs = [addInfo.formInput, ...formModel.inputs];
      return;
    }

    if (addInfo.addEnd) {
      formModel.inputs = [...formModel.inputs, addInfo.formInput];
      return;
    }

    const fieldName = addInfo.addBeforeFieldName || addInfo.addAfterFieldName;
    const fieldIndex = formModel.inputs.findIndex(i => i.name === fieldName);

    if (fieldIndex === -1) {
      return;
    }

    const indexToAdd = fieldIndex + (addInfo.addBeforeFieldName ? 0 : 1);
    formModel.inputs.splice(indexToAdd, 0, addInfo.formInput);
  });
};

export const getB2BNameValidators = (): ValidatorFn => {
  return Validators.pattern(/^[\w\sÀ-ÿ0-9‘'"‚.:,\\\/„“\-!?+&@а-яА-ЯЁё]*$/);
};

export const getB2BCorporateRegisterNumberValidators = (): ValidatorFn => {
  return Validators.pattern(/^FN\d{6}[a-zA-Z]$/);
};

export const getB2BUidNumberValidators = (): ValidatorFn => {
  return Validators.pattern(/^ATU\d{8}$/);
};

export function isFormErrorVisible(inputElem: IFormInput, control: AbstractControl): boolean {
  const errorText = getFormErrorText(inputElem, control);

  return errorText && inputElem.validate === true && control.errors && !control.pristine;
}

export function getFormErrorText(inputElem: IFormInput, control: AbstractControl): string {
  if (inputElem.validationErrorLabelKey) {
    return inputElem.validationErrorLabelKey;
  }

  if (!isArrayWithMinOneItem(inputElem.validationErrors)) {
    return '';
  }

  const item = inputElem.validationErrors.find(item => !!control.errors?.[item.key]);
  return item?.label;
}
