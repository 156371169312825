<mwe-button-link
  mweClickPreventDefault
  customCss="img-icon d-inline-flex align-items-center height-36"
  (clicked)="openPdf()"
>
  <img class="mr-1 icon-small" src="/assets/icons/cloud-download.svg" alt="download" />
  <h5 *ngIf="!isPdfLoading" class="mb-0 orange text-uppercase">{{ label | translate }}</h5>
  <div
    *ngIf="isPdfLoading"
    class="spinner-border"
    style="color: black; width: 14px; height: 14px; border: 0.1em solid currentColor; border-right-color: transparent"
    role="status"
  >
    <span class="sr-only">Loading...</span>
  </div>
</mwe-button-link>
