import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Account,
  ActionType,
  IAddressProducts,
  IVerknuepfungen,
  VERKNUEPFUNGSSTATUS_VORSCHLAG_ABGELEHNT,
  VERKNUEPFUNGSSTATUS_VORSCHLAG_BESTAETIGT,
} from '@mwe/models';
import { cloneDeepJSON, parseVerknuepfung } from '@mwe/utils';
import {
  AccountLogic,
  LoggingService,
  ProductAddStateService,
  ProductLogic,
  ProductService,
  ProductStateService,
  PopupService,
  DsgvoLogic,
} from '@mwe/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PRODUCTS_ROUTE } from '@mwe/constants';

@Component({
  selector: 'mwe-association-suggestions',
  templateUrl: './association-suggestions.component.html',
})
export class AssociationSuggestionsComponent implements OnInit {
  associationSuggestions: IVerknuepfungen[];
  totalSuggestions = 0;
  currentSuggestionIdx = 1;
  progressBarWidth = 100;
  currentSuggestions: IAddressProducts[];
  isSubmittingAdd = false;
  isSubmittingIgnore = false;
  isError = false;
  account: Account;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private productStateService: ProductStateService,
    private productLogic: ProductLogic,
    private popupService: PopupService,
    private productService: ProductService,
    private loggingService: LoggingService,
    private accountLogic: AccountLogic,
    private productAddStateService: ProductAddStateService,
    private dsgvoLogic: DsgvoLogic,
  ) {}

  ngOnInit(): void {
    this.associationSuggestions = cloneDeepJSON(this.productStateService.associationSuggestions) as IVerknuepfungen[];
    this.account = this.accountLogic.getAccount();
    this.totalSuggestions = this.associationSuggestions.length;
    this.setCurrentProduct();
  }

  closePopup(): void {
    this.activeModal.close('suggestions-popup-closed');
  }

  async addSuggestion(): Promise<void> {
    this.isError = false;
    this.isSubmittingAdd = true;

    const currentAssociationSuggestion = this.associationSuggestions[this.currentSuggestionIdx - 1];
    const products = parseVerknuepfung(currentAssociationSuggestion, true);
    this.productAddStateService.addressGroups = this.productLogic.orderAndGroupByAddresses(products);

    try {
      await this.productService.addProducts(
        currentAssociationSuggestion.geschaeftsPartnerId,
        currentAssociationSuggestion.systemId,
        VERKNUEPFUNGSSTATUS_VORSCHLAG_BESTAETIGT,
      );

      this.popupService.showAddAssociationConfirmationPopup(false, 'products.confirm.popup.buttonOk').subscribe(async result => {
        this.loggingService.logAction({
          type: ActionType.ASSOCIATIONS,
          message: `[association-suggestion] added (${this.account.id} / ${this.account.email} / ${currentAssociationSuggestion.geschaeftsPartnerId})`,
        });

        this.isSubmittingAdd = false;

        if (result === 'product-choose-confirmation') {
          await this.dsgvoLogic.triggerDsgvoForAssociations(
            currentAssociationSuggestion.geschaeftsPartnerId,
            currentAssociationSuggestion.systemId,
          );
        }
        if (result === 'product-choose-confirmation' || result === 'dsgvo-policy-confirmation' || result === 'suggestions-popup-closed') {
          this.reloadProductsOverview();
        } else {
          this.productStateService.clearProducts();
          this.removeCurrentSuggestionFromCache();
        }
      });
    } catch (e) {
      this.isError = true;
      this.isSubmittingAdd = false;
    }
  }

  private reloadProductsOverview() {
    this.productLogic.clearAssociationsUpdate();
    this.productStateService.clearProducts();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([PRODUCTS_ROUTE]));
  }

  async ignoreSuggestion(): Promise<void> {
    this.isError = false;
    this.isSubmittingIgnore = true;

    const currentAssociationSuggestion = this.associationSuggestions[this.currentSuggestionIdx - 1];
    const products = parseVerknuepfung(currentAssociationSuggestion, true);
    this.productAddStateService.addressGroups = this.productLogic.orderAndGroupByAddresses(products);

    try {
      await this.productService.addProducts(
        currentAssociationSuggestion.geschaeftsPartnerId,
        currentAssociationSuggestion.systemId,
        VERKNUEPFUNGSSTATUS_VORSCHLAG_ABGELEHNT,
        false,
      );

      this.loggingService.logAction({
        type: ActionType.ASSOCIATIONS,
        message: `[association-suggestion] declined (${this.account.id} / ${this.account.email} / ${currentAssociationSuggestion.geschaeftsPartnerId})`,
      });

      this.removeCurrentSuggestionFromCache();
      this.isSubmittingIgnore = false;
      this.activeModal.close('suggestion-declined');
      this.reloadProductsOverview();
    } catch (e) {
      this.isError = true;
      this.isSubmittingIgnore = false;
    }
  }

  removeCurrentSuggestionFromCache(): void {
    // need to create tmp variable so that the setter in the state-service is called
    const tmp = this.productStateService.associationSuggestions;
    tmp.splice(0, 1);
    this.productStateService.associationSuggestions = tmp;
  }

  setCurrentProduct(): void {
    this.progressBarWidth = Math.round(((this.currentSuggestionIdx * 100) / this.totalSuggestions) * 100) / 100;
    const products = parseVerknuepfung(this.associationSuggestions[this.currentSuggestionIdx - 1], true);
    this.currentSuggestions = Array.from(this.productLogic.orderAndGroupByAddresses(products).values());
  }
}
