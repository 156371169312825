<mwe-alert type="info">
  <p
    class="text--big text-white"
    [ngClass]="{ 'mb-2': isFernwaermeInfoVisible || isStromGasInfoVisible }"
    [innerHTML]="'invoiceData.change.newBankData.info' | translate"
  ></p>
  <p
    class="text--big text-white"
    [ngClass]="{ 'mb-2': isFernwaermeInfoVisible }"
    *ngIf="isStromGasInfoVisible"
    [innerHTML]="'invoiceData.change.newBankData.infoStromGas' | translate"
  ></p>
  <p class="text--big text-white" *ngIf="isFernwaermeInfoVisible" [innerHTML]="'invoiceData.change.newBankData.infoFernwaerme' | translate"></p>
</mwe-alert>
