import { FeatureToggleEnum } from '@mwe/constants';

export class ProfileInfo {
  featureToggles?: FeatureToggleType;
  version?: string;
  serverTimestamp?: string;
  backendVersion?: string;
  toggleSetDesignator?: string;
}

export type FeatureToggleType = { [key in FeatureToggleEnum]: boolean };

export interface FeatureToggledOutInfo {
  processTitle?: string;
  titleOverwrite?: string;
  textOverwrite?: string;
  isCloseBtnVisible: boolean;
}
