import { Pipe } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({ name: 'formatNumber' })
export class FormatNumberPipe {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: string | number, digitsInfo = ''): string | null {
    return this.decimalPipe.transform(value, digitsInfo, 'de-AT');
  }
}
