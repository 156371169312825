import { Injectable } from '@angular/core';
import { IAddress, IAddressProducts, parseAddress } from '@mwe/models';
import { checkAddressProductsForAddressGroupUpdates } from '@mwe/utils';
import { ProductLogic } from '../products/product.logic';
import { AddressGroupService } from './address-group.service';

@Injectable({ providedIn: 'root' })
export class AddressLogic {
  constructor(
    private productLogic: ProductLogic,
    private addressGroupService: AddressGroupService,
  ) {}

  async loadAddressesWithGroupNames(): Promise<IAddressProducts[]> {
    const addressProducts = await this.getPossibleAddressesToSelect();
    const addressGroups = await this.addressGroupService.getAndUpdateGroupNames();

    checkAddressProductsForAddressGroupUpdates(addressProducts, addressGroups);
    return addressProducts;
  }

  loadAndMapGroupNames(addresses: IAddress[]): Promise<void> {
    return this.addressGroupService.loadAndMapGroupNames(addresses);
  }

  async getPossibleAddressesToSelect(): Promise<IAddressProducts[]> {
    const addressGroups = await this.getAddressGroups();
    const possibleAddressesToSelect: IAddressProducts[] = [];
    addressGroups?.forEach(entry => {
      const address = { address: parseAddress(entry.address), products: entry.products };
      possibleAddressesToSelect.push(address);
    });
    return possibleAddressesToSelect;
  }

  async getAddressGroups(): Promise<Map<string, IAddressProducts>> {
    const products = await this.productLogic.getProducts();
    return this.productLogic.orderAndGroupByAddresses(products);
  }
}
