import { Component, Input } from '@angular/core';
import { TextNodeType } from '@mwe/models';

@Component({
  selector: 'mwe-basic-card',
  templateUrl: './basic-card.component.html',
})
export class BasicCardComponent {
  @Input() iconClass: string;
  @Input() cardTitle: string;
  @Input() cardTitleParams;
  @Input() hasBackground = false;
  @Input() height100 = false;
  @Input() optionalLastChildClass: string;
  @Input() noBoxShadow = false;
  @Input() headingNodeType: TextNodeType = 'h5';
}
