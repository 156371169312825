import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TarifOption } from '@mwe/models';

@Component({
  selector: 'mwe-tariff-option-dropdown',
  templateUrl: './tariff-option-dropdown.component.html',
})
export class TariffOptionDropdownComponent implements OnInit {
  @Input() options: TarifOption[];
  @Input() selectedTariffOptionId: string;
  @Input() selectedTariffGroupId: string;
  @Input() isReadonly: boolean;
  @Output() readonly optionSelected = new EventEmitter<string>();

  get selectedTariffOption(): TarifOption {
    return this.options.find(o => this.selectedTariffOptionId === o.option);
  }

  get colorCss(): string {
    switch (this.selectedTariffOption?.farbe) {
      case 'green':
        return 'success';
      case 'blue':
        return 'secondary-light';
      default:
        return 'success';
    }
  }

  ngOnInit(): void {}

  onOptionSelected(option: TarifOption) {
    this.selectedTariffOptionId = option.option;
    this.optionSelected.emit(this.selectedTariffOptionId);
  }

  isOptionActive(option: TarifOption): boolean {
    return this.selectedTariffOptionId === option.option;
  }

  getTestId(type: string): string {
    return `tariff-option-dropdown-input-${type}`;
  }
}
