export enum ProductCategoryEnum {
  STROM, // Strom: 'Strom' as Sparte,
  STROMRUECK, // Stromrueck: 'Stromrueck' as Sparte,
  NACHTSTROM, // Nachtstrom: 'Nachtstrom' as Sparte,
  GAS, //  Gas: 'Gas' as Sparte,
  FERNWAERME, // Fernwaerme: 'Fernwaerme' as Sparte
  EMOBILITY, // Emobility: 'Emobility' as Sparte,
  INTERNET, // Internet: 'Internet' as Sparte,
  IPTV, // Iptv: 'Iptv' as Sparte,
  VOIP, // Voip: 'Voip' as Sparte,
  MOBILTELEFONIE, // Mobiltelefonie: 'Mobiltelefonie' as Sparte
  NETZ, // aus il swagger rechnungsuebersicht
}

export type ProductPriceIndicationCategory = 'tanke' | 'Superschnell';

export const ProductPriceIndicationCategory = {
  TANKE: 'tanke' as ProductPriceIndicationCategory,
  SUPERSCHNELL: 'Superschnell' as ProductPriceIndicationCategory,
};

export const productCategoryEnumToName = (enumMember: ProductCategoryEnum): string => {
  const isValueProperty = parseInt(enumMember.toString(), 10) >= 0;
  if (isValueProperty) {
    return ProductCategoryEnum[enumMember];
  } else {
    return enumMember.toString();
  }
};
