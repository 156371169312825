import { Component, Input } from '@angular/core';

@Component({
  selector: 'mwe-info-text',
  templateUrl: './info-text.component.html',
})
export class InfoTextComponent {
  @Input() infoTextCode: string;
  @Input() infoTextTitle: string;
  @Input() infoTextDescription: string;
  @Input() infoTextLink: { name: string; target: string };
  @Input() colorClass = 'text-primary';
}
