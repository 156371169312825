<div *ngIf="category" data-test="product-icon">
  <img
    class="w-auto h-auto mb-2"
    aria-hidden="true"
    [style.max-width]="maxWidth"
    [src]="category | categoryIconSrc: isDisabled"
    alt="{{ category | translateCategory | async }}"
    data-test="product-category-img"
  />
  <div [outerHTML]="heading"></div>
  <ng-container [ngSwitch]="textNodeType">
    <h1
      *ngSwitchCase="'h1'"
      class="mb-0 h6"
      data-test="product-category-text"
      [style.font-size]="fontSize"
      [ngClass]="{ 'd-lg-none': hideCategoryTextOnDesktop }"
    >
      {{ category | translateCategory | async }}
    </h1>
    <h2
      *ngSwitchCase="'h2'"
      class="mb-0 h6"
      data-test="product-category-text"
      [style.font-size]="fontSize"
      [ngClass]="{ 'd-lg-none': hideCategoryTextOnDesktop }"
    >
      {{ category | translateCategory | async }}
    </h2>
    <h3
      *ngSwitchCase="'h3'"
      class="mb-0 h6"
      data-test="product-category-text"
      [style.font-size]="fontSize"
      [ngClass]="{ 'd-lg-none': hideCategoryTextOnDesktop }"
    >
      {{ category | translateCategory | async }}
    </h3>
    <h4
      *ngSwitchCase="'h4'"
      class="mb-0 h6"
      data-test="product-category-text"
      [style.font-size]="fontSize"
      [ngClass]="{ 'd-lg-none': hideCategoryTextOnDesktop }"
    >
      {{ category | translateCategory | async }}
    </h4>
    <h5
      *ngSwitchCase="'h5'"
      class="mb-0 h6"
      data-test="product-category-text"
      [style.font-size]="fontSize"
      [ngClass]="{ 'd-lg-none': hideCategoryTextOnDesktop }"
    >
      {{ category | translateCategory | async }}
    </h5>
    <h6
      *ngSwitchCase="'h6'"
      class="mb-0 h6"
      data-test="product-category-text"
      [style.font-size]="fontSize"
      [ngClass]="{ 'd-lg-none': hideCategoryTextOnDesktop }"
    >
      {{ category | translateCategory | async }}
    </h6>
    <p
      *ngSwitchCase="'p'"
      class="mb-0 h6"
      data-test="product-category-text"
      [style.font-size]="fontSize"
      [ngClass]="{ 'd-lg-none': hideCategoryTextOnDesktop }"
    >
      {{ category | translateCategory | async }}
    </p>
  </ng-container>
</div>
