import { Component, computed, createNgModule, inject, Injector, signal, ViewChild, ViewContainerRef } from '@angular/core';
import { NavbarComponent } from './navbar.component';
import {
  AccountLogic,
  EnvironmentService,
  HelperService,
  MessagesStore,
  NavigationLogic,
  NavigationStateService,
  PopupService,
  ProductStateService,
  ProfileService,
} from '@mwe/services';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FeatureToggleEnum } from '@mwe/constants';

@Component({
  selector: 'mwe-navbar-header',
  templateUrl: './navbar-header.component.html',
})
export class NavbarHeaderComponent extends NavbarComponent {
  userName: string;
  emailAddress: string;

  @ViewChild('messagesComponent', { read: ViewContainerRef }) messageComponent!: ViewContainerRef;
  messagesStore = inject(MessagesStore);
  messagesPristine = signal<boolean>(true);

  messagesEnabled = computed<boolean>(() => this.profileService.isFeatureToggleEnabled(FeatureToggleEnum.MESSAGES_ENABLED));

  constructor(
    protected override helperService: HelperService,
    protected override profileService: ProfileService,
    protected override environmentService: EnvironmentService,
    protected override accountLogic: AccountLogic,
    protected override navigationStateService: NavigationStateService,
    protected override navigationLogic: NavigationLogic,
    private productStateService: ProductStateService,
    private popupService: PopupService,
    private injector: Injector,
    private offcanvasService: NgbOffcanvas,
  ) {
    super(helperService, profileService, environmentService, accountLogic, navigationStateService, navigationLogic);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.userName = this.accountLogic.getAccountFullName();
    this.emailAddress = this.accountLogic.getAccountEmail();
    if (this.messagesEnabled() && this.isLoggedIn) {
      this.messagesStore.loadMessages();
    }
  }

  resetProductState(): void {
    this.productStateService.clearRecentOrders();
  }

  logout(): void {
    this.popupService.openLogoutPopup();
  }

  async loadMessages() {
    this.messagesPristine.set(false);

    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { MessagesModule } = await import('@mwe/features/messages');
    const module = createNgModule(MessagesModule, this.injector);
    const ref = this.offcanvasService.open(module.instance.getComponent(), { position: 'end' });
    ref.shown.subscribe(_ => {
      (document.getElementById('mm-list').querySelector("[role='tab']") as HTMLElement)?.focus();
    });
    ref.hidden.subscribe(_ => {
      this.messagesStore.setShowDetails(false);
      this.messagesStore.updateSelectedMessage(undefined);
    });
  }
}
