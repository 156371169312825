import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CustomerSupportCallback,
  IClearingAccountChangeDataDTO,
  IClearingAccountStatusResponse,
  parseClearingAccountChangeDataDTOFromOrderStatusDetail,
  HTTPError,
  Sparte,
  parseAddress,
} from '@mwe/models';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { FeatureToggleEnum } from '@mwe/constants';
import { FindPaymentSolutionStateService } from './find-payment-solution-state.service';
import { filterDuePayments, getCustomerSupportCallbackGroupId, getSystemIDForCategory } from '@mwe/utils';
import { EnvironmentService } from '../../environment/environment.service';
import { ProfileService } from '../../profile/profile.service';
import { lastValueFrom } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class FindPaymentSolutionService {
  private resourceUrl: string;

  constructor(
    private httpClient: HttpClient,
    private findPaymentSolutionStateService: FindPaymentSolutionStateService,
    private environmentService: EnvironmentService,
    private profileService: ProfileService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/verrechnungskonten/';
  }

  async confirm(dataDTO: IClearingAccountChangeDataDTO): Promise<IClearingAccountStatusResponse> {
    try {
      return await lastValueFrom(
        this.httpClient.post<IClearingAccountStatusResponse>(this.resourceUrl + '/payment-solution/confirm', dataDTO),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async retry(submitId: number, dataDTO: IClearingAccountChangeDataDTO): Promise<IClearingAccountStatusResponse> {
    try {
      return await lastValueFrom(
        this.httpClient.put<IClearingAccountStatusResponse>(this.resourceUrl + '/payment-solution/retry?submitId=' + submitId, dataDTO),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  confirmStatus(submitId: number): Promise<IClearingAccountStatusResponse> {
    const url = this.resourceUrl + '/payment-solution/confirmStatus?submitId=' + submitId;
    return lastValueFrom(this.httpClient.get<IClearingAccountStatusResponse>(url));
  }

  async getOrderStatusDetails(): Promise<IClearingAccountChangeDataDTO> {
    const response = await lastValueFrom(
      this.httpClient.get(this.resourceUrl + 'payment-solution/orderStatusDetails/' + this.findPaymentSolutionStateService.lastOrderId),
    );
    const responseData = JSON.parse((response as any).responseData);
    const result = parseClearingAccountChangeDataDTOFromOrderStatusDetail(response);
    this.findPaymentSolutionStateService.clearingAccount = {
      accountId: result.mappingData[0].accountNumber,
      systemId: getSystemIDForCategory(result.mappingData[0].categories[0]), // this value is always set
      businessPartnerId: result.mappingData[0].businessPartnerNumber,
      unpaidInvoices: {
        offeneZahlungen: result.ratenplan ? filterDuePayments(responseData.offeneZahlungen) : result.unpaidInvoices,
        summen: result.ratenplan ? responseData.summen : result.unpaidInvoicesSum,
      },
    };
    let categories = [];
    result.mappingData.forEach(md => {
      categories = categories.concat(md.categories);
    });
    this.findPaymentSolutionStateService.categories = categories;
    this.findPaymentSolutionStateService.newDueDate = result.newExtensionDate;
    this.findPaymentSolutionStateService.paymentPlan = result.ratenplan;
    this.findPaymentSolutionStateService.customerSupportCallbackSuggestion = result.customerSupportCallback?.termin;
    this.findPaymentSolutionStateService.address = parseAddress(result.address);
    this.findPaymentSolutionStateService.invoiceParts = result.invoiceParts;
    result.createdAt = (response as any).createdAt;
    result.paymentInfo = (response as any).paymentInfo;
    return result;
  }

  getCustomerSupportCallBackSuggestions(categories: Sparte[]): Promise<CustomerSupportCallback> {
    if (this.profileService.isFeatureToggleDisabled(FeatureToggleEnum.FIND_PAYMENT_SOLUTION_CALLBACK_ENABLED)) {
      return Promise.resolve(null);
    }

    const url = this.environmentService.getApiUrl() + 'api/activity/appointmentSuggestions';
    const params = new HttpParams().set('gruppenId', getCustomerSupportCallbackGroupId(categories));

    return lastValueFrom(
      this.httpClient.get<CustomerSupportCallback>(url, { params }).pipe(
        map(response => {
          return {
            terminVorschlaege: response?.terminVorschlaege?.filter(t => !t.vergeben),
          };
        }),
        map(response => {
          return {
            terminVorschlaege: response?.terminVorschlaege?.sort((t1, t2) => {
              return dayjs(t1.zeitpunkt).isBefore(dayjs(t2.zeitpunkt)) ? -1 : 1;
            }),
          };
        }),
      ),
    );
  }
}
