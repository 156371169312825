<ng-container
  *ngIf="
    !isProductCategory(product.category, productCategoryEnum.FERNWAERME) && !product.isAddressDetailsLinkOnlineNotPossible;
    then showTariffClasses;
    else showFernwaermeTariffClasses
  "
></ng-container>
<ng-template #showTariffClasses>
  <h4 data-test="product-card-tariff-list-item" [innerHtml]="tariffClasses | tariffClassLineBreak"></h4>
</ng-template>
<ng-template #showFernwaermeTariffClasses>
  <p
    *ngIf="!showWaermeTariffClassesAsideOfIcon"
    class="mb-0 text--big"
    translate="newClient.productsSelection.noTariffSelection"
    [translateParams]="{ category: product.category | translateCategory | async }"
  ></p>
  <mwe-product-card-tariff-list-fernwaerme
    *ngIf="showWaermeTariffClassesAsideOfIcon"
    [tariffClasses]="tariffClasses"
  ></mwe-product-card-tariff-list-fernwaerme>
</ng-template>
