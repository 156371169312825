import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { RecentOrderLogic } from '@mwe/services';
import { DataChangeType } from '@mwe/models';
import { NavigateToType, RecentOrder, RecentOrderType } from '@mwe/models';
import { AppStorageService } from '@mwe/services';

@Component({
  selector: 'mwe-navigateto',
  template: ``,
})
export class NavigateToComponent implements OnInit {
  constructor(private appStorage: AppStorageService, private route: ActivatedRoute, private recentOrderLogic: RecentOrderLogic) {}

  ngOnInit(): void {
    this.appStorage.clear();
    this.navigateTo(this.route.snapshot.queryParamMap);
  }

  navigateTo(paramMap: ParamMap): void {
    const navigateToType = this.route.snapshot.queryParamMap.get('navigateTo') as NavigateToType;
    const recentOrderType: string = paramMap.get('recentOrderType');
    const recentOrderId: string = paramMap.get('recentOrderId');
    const recentOrderIDCDataType: string = paramMap.get('recentOrderIDCDataType');
    switch (navigateToType) {
      case NavigateToType.recentOrderStatus:
        this.recentOrderLogic.navigateToOrderDetails({
          id: Number(recentOrderId),
          type: recentOrderType as RecentOrderType,
          dataType: recentOrderIDCDataType as DataChangeType,
        } as RecentOrder);
        break;
    }
  }
}
