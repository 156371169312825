import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { AlertType, TextNodeType, InfoText } from '@mwe/models';
import { v4 } from 'uuid';

@Component({
  selector: 'mwe-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit {
  @Input() type: AlertType = 'info';
  @Input() titleKey: string;
  @Input() messageKey: string;
  @Input() messageKeys: string[];
  @Input() showCloseButton = false;
  @Input() placeholderMap: Map<string, string>;
  @Input() link: string;
  @Input() iconClassOverwrite = '';
  @Input() infoText: InfoText;
  @Input() isMinimalLayout = false;
  @Input() headingNodeType: TextNodeType = 'h5';
  @Input() role: string | null = 'alert';
  @Output() onLinkClick: EventEmitter<void> = new EventEmitter();

  isVisible = true;
  translateValues: { [key: string]: string | number } = {};
  uuid: string;

  constructor() {}

  ngOnInit(): void {
    if (this.placeholderMap) {
      this.placeholderMap.forEach((value: string, key: string) => {
        this.translateValues[key] = value;
      });
    }
    this.uuid = v4();
  }

  fireLinkClickEvent(): void {
    this.onLinkClick.emit();
  }

  getAlertClasses(): string[] {
    const classes = ['w-100', 'alert', `alert-${this.type}`];

    if (this.showCloseButton) {
      classes.push('alert-dismissible');
    }
    if (this.isMinimalLayout) {
      classes.push('alert-minimal');
    }

    return classes;
  }

  getIconClasses(): string {
    if (this.iconClassOverwrite) {
      return this.iconClassOverwrite;
    }

    switch (this.type) {
      case 'info':
        return 'fas fa-bell';
      case 'neutral':
        return 'fas fa-info';
      case 'danger':
        return 'fas fa-exclamation';
      case 'warning':
        return 'fas fa-siren-on';
      case 'success':
        return 'fas fa-check';
    }
  }

  showTitle(): boolean {
    return this.titleKey && this.titleKey.length > 0;
  }
}
