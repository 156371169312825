import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PRODUCTS_ROUTE } from '@mwe/constants';
import { AccountLogic } from '../account/account.logic';

export const anonymousAccessService: CanActivateFn = () => {
  const router = inject(Router);
  const accountLogic = inject(AccountLogic);

  return accountLogic.isLoggedOut() || router.parseUrl(`/${PRODUCTS_ROUTE}`);
};
