import { Component, Input } from '@angular/core';

@Component({
  selector: 'mwe-info-text-card',
  templateUrl: './info-text-card.component.html',
})
export class InfoTextCardComponent {
  @Input() hasBoxStyles = false;
  @Input() noBoxShadow = false;
  @Input() text: string;
  @Input() textParams;
  @Input() dataTest: string;
}
