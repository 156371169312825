import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdvancedPaymentDetails,
  HTTPError,
  IBulletConfirmationStatusChangeResponse,
  ILSystem,
  IPartialAmountChangeDataDTO,
  IPartialAmountChangeStatusData,
  TeilbetragsAenderung,
  TeilbetragsAenderungRequestData,
  TeilbetragsAenderungResponse,
} from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { map } from 'rxjs/operators';
import { PartialPaymentStateService } from '../partial-payment/partial-payment-state.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdvancedPaymentPlanService {
  private resourceUrl;

  constructor(
    private http: HttpClient,
    private stateService: PartialPaymentStateService,
    protected environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/advancedPaymentPlan/';
  }

  getAdvancedPaymentPlanDetails(systemId: ILSystem, accountId: string): Promise<AdvancedPaymentDetails> {
    const _params = new HttpParams().set('systemId', systemId).set('accountId', accountId);
    const url = this.resourceUrl + 'planDetails';
    return lastValueFrom(this.http.get<AdvancedPaymentDetails>(url, { params: _params }));
  }

  inquirePartialPaymentChange(data: TeilbetragsAenderungRequestData): Promise<TeilbetragsAenderung> {
    // only strom/gas wants to validate the change-data
    if (data.systemId === 'ISX100') {
      return Promise.resolve({
        neuerTeilbetrag: data.newAmount,
        naechstesFaelligkeitsdatum: data.startDate,
      });
    }

    const params = new HttpParams()
      .set('accountId', data.accountId)
      .set('advancedPaymentPlanNumber', data.abschlagsplannummer)
      .set('startDate', data.startDate)
      .set('newAmount', data.newAmount)
      .set('systemId', data.systemId);
    const url = this.resourceUrl + 'inquirePartialpaymentChange';
    return lastValueFrom(this.http.get<TeilbetragsAenderungResponse>(url, { params }).pipe(map(resp => resp.teilbetragsAenderung)));
  }

  confirm(data: IPartialAmountChangeDataDTO): Promise<IBulletConfirmationStatusChangeResponse> {
    const url = this.resourceUrl + 'confirm';
    return lastValueFrom(this.http.post<IBulletConfirmationStatusChangeResponse>(url, data));
  }

  async retry(submitId: number, data: IPartialAmountChangeDataDTO): Promise<IBulletConfirmationStatusChangeResponse> {
    try {
      return await lastValueFrom(
        this.http.put<IBulletConfirmationStatusChangeResponse>(this.resourceUrl + '/retry?submitId=' + submitId, data),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  confirmStatus(submitId: number): Promise<IBulletConfirmationStatusChangeResponse> {
    const url = this.resourceUrl + '/confirmStatus?submitId=' + submitId;
    return lastValueFrom(this.http.get<IBulletConfirmationStatusChangeResponse>(url));
  }

  async getOrderStatusDetails(): Promise<IPartialAmountChangeStatusData> {
    return lastValueFrom(
      this.http.get<IPartialAmountChangeStatusData>(this.resourceUrl + 'orderStatusDetails/' + this.stateService.lastOrderId),
    );
  }
}
