<mwe-impersonate-warning *mweShowOnlyForImpersonate></mwe-impersonate-warning>
<mwe-page-ribbon></mwe-page-ribbon>

<div
  class="page-content overflow-x-hidden"
  [ngClass]="{
    'maintenance-mode': isMaintenanceEnabled,
    'p-0': noBottomPadding
  }"
>
  <mwe-header *ngIf="routeLoaded" [withBottomMargin]="!isMaintenanceEnabled">
    <mwe-navbar-header></mwe-navbar-header>
  </mwe-header>
  <router-outlet></router-outlet>
</div>
<mwe-chatbot-popup *ngIf="showChatbot"></mwe-chatbot-popup>
<ng-content *ngIf="!hideFooter" select="[slot='footer']"></ng-content>
<mwe-cookie-consent *ngIf="isCookieConsentEnabled"></mwe-cookie-consent>
<mwe-navbar-footer *ngIf="showNavBar"></mwe-navbar-footer>
