import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AddFormInput,
  IFormInput,
  IFormInputGroup,
  IFormModel,
  IFormSelect,
  IPersonalData,
  IWizardStepAlert,
  KundenTypEnum,
} from '@mwe/models';
import dayjs from 'dayjs';
import {
  addAdditionalFormInputs,
  CountryLogic,
  createBirthDatePickerTimeRange,
  isArrayWithMinOneItem,
  isDateInFuture,
  isValidBirthdate,
} from '@mwe/utils';
import { Validators } from '@angular/forms';
import { OrderService } from '@mwe/services';
import { FormComponent } from '../form/form.component';
import { trimmedMinLength } from '../form/validators/trimmedMinLength.validator';

@Component({
  selector: 'mwe-personal-details-form',
  templateUrl: './personal-details-form.component.html',
})
export class PersonalDetailsFormComponent implements OnInit {
  @ViewChild(FormComponent, { static: false }) formRef: FormComponent;

  @Input() personalData: IPersonalData;
  @Input() fixedEmail = '';
  @Input() emailInputs: IFormInput[];
  @Input() isSalutationDisabled = false;
  @Input() isTitleDisabled = false;
  @Input() isPrefixDisabled = false;
  @Input() isFirstnameDisabled = false;
  @Input() isLastnameDisabled = false;
  @Input() isBirthdateDisabled = false;
  @Input() isPhoneDisabled = false;
  @Input() isPhoneRequired = true;
  @Input() additionalFormInputs: AddFormInput[];
  @Input() isSalutationGroupVisible = true;
  @Input() ignoreBeforeEighteenError = false;

  @Output() showAlert = new EventEmitter<IWizardStepAlert>();
  @Output() updatePersonalData = new EventEmitter<IPersonalData>();
  @Output() formDataUpdated = new EventEmitter();

  formModel: IFormModel;
  isValid = false;
  titleOptions: IFormSelect[] = [];
  invalidBirthday = false;
  fieldNames = {
    salutationGroup: 'mwe-order-personal-data-prefix',
    birthdayGroup: 'mwe-order-personal-data-birthday',
  };

  get areEmailFieldsVisible(): boolean {
    return isArrayWithMinOneItem(this.emailInputs);
  }

  constructor(
    private orderService: OrderService,
    private countryLogic: CountryLogic,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getTitlesList();
    this.initializeFormModel();
    this.addAdditionalFormInputs();
  }

  handleFormValidation(formData?: any): void {
    if (!this.formRef?.form || !formData) {
      return;
    }

    const telefonCountryCode = (formData['mwe-order-personal-data-telefon-countrycode'] as IFormSelect)?.value;

    this.personalData = {
      salutation: this.validValue(formData['mwe-order-personal-data-salutation']),
      title: formData['mwe-order-personal-data-title'],
      firstName: formData['mwe-order-personal-data-firstname'],
      lastName: formData['mwe-order-personal-data-lastname'],
      birthday: formData[this.fieldNames.birthdayGroup],
      telefonNumber: formData['mwe-order-personal-data-telefonnumber'],
      telefonCountryCode,
      email: this.areEmailFieldsVisible ? formData[this.emailInputs[0].name] : this.fixedEmail,
      emailRepeat: this.areEmailFieldsVisible ? formData[this.emailInputs[1].name] : this.fixedEmail,
    };

    if (!this.personalData.title) {
      this.personalData.title = { value: '', text: '' };
    }

    this.updatePersonalData.emit(this.personalData);
    this.formDataUpdated.emit(formData);
    this.validateSpecialFields();
  }

  validate(): boolean {
    this.isValid = this.formRef.form.valid;
    this.validateSpecialFields();
    this.formRef.fireValidation();
    return this.isValid;
  }

  private async getTitlesList(): Promise<void> {
    if (!this.isSalutationGroupVisible) {
      return;
    }

    try {
      this.titleOptions = [];
      await this.orderService.getTitles().then(titlesList => {
        titlesList.payload.titelListe.forEach(title => {
          this.titleOptions.push({ value: title?.titleText, text: title?.titleText });
        });
        this.titleOptions.sort((a, b) => (a.text < b.text ? -1 : 1));
      });
    } catch (error) {
      // nothing to do
    }
  }

  private initializeFormModel(): void {
    if (!this.personalData) {
      this.personalData = {
        type: '' as KundenTypEnum,
        salutation: '',
        title: { value: '', text: '' },
        firstName: '',
        lastName: '',
        birthday: '',
        email: this.fixedEmail,
        emailRepeat: this.fixedEmail,
        telefonCountryCode: this.isPhoneRequired ? '43' : '',
        telefonNumber: '',
      };
    }

    if (this.isPhoneRequired) {
      this.personalData.telefonCountryCode = this.countryLogic.checkCountryCode(this.personalData.telefonCountryCode);
    }

    // when only text is known, try to find value from title-options
    if (!!this.personalData.title?.text && !this.personalData.title?.value) {
      const title = this.titleOptions.find(option => option.text === this.personalData.title?.text);
      this.personalData.title = title || { value: '', text: '' };
    }

    this.formModel = {
      inputs: [],
      updateOn: 'blur',
    };

    if (this.isSalutationGroupVisible) {
      this.formModel.inputs.push({
        name: this.fieldNames.salutationGroup,
        inputs: [
          {
            name: 'mwe-order-personal-data-salutation',
            initialValue: this.personalData.salutation,
            validators: [Validators.required],
            labelKey: 'newClient.personalDetails.salutation.label',
            placeholder: 'newClient.personalDetails.salutation.placeholder',
            validationErrorLabelKey: 'newClient.personalDetails.salutation.error',
            inputClasses: 'col-4',
            validate: true,
            componentType: 'select',
            options: [
              { value: 'Herr', text: 'newClient.personalDetails.salutation.options.H' },
              { value: 'Frau', text: 'newClient.personalDetails.salutation.options.F' },
            ],
            isDisabled: this.isSalutationDisabled,
          },
        ],
      });
    }

    this.formModel.inputs.push({
      name: 'mwe-order-personal-data-firstname',
      initialValue: this.personalData.firstName,
      validators: [Validators.required, trimmedMinLength(2)],
      labelKey: 'newClient.personalDetails.firstName.label',
      validationErrorLabelKey: 'newClient.personalDetails.firstName.error',
      validate: true,
      directiveType: 'name',
      isDisabled: this.isFirstnameDisabled,
    });

    this.formModel.inputs.push({
      name: 'mwe-order-personal-data-lastname',
      initialValue: this.personalData.lastName,
      validators: [Validators.required, trimmedMinLength(2)],
      labelKey: 'newClient.personalDetails.lastName.label',
      validationErrorLabelKey: 'newClient.personalDetails.lastName.error',
      validate: true,
      directiveType: 'name',
      isDisabled: this.isLastnameDisabled,
    });

    if (this.isBirthdateDisabled) {
      this.formModel.inputs.push({
        name: this.fieldNames.birthdayGroup,
        labelKey: 'newClient.personalDetails.birthday.label',
        initialValue: dayjs(this.personalData.birthday).format('DD.MM.YYYY'),
        isDisabled: true,
      });
    } else {
      const birthdayTimeRange = createBirthDatePickerTimeRange();

      this.formModel.inputs.push({
        name: this.fieldNames.birthdayGroup,
        labelKey: 'newClient.personalDetails.birthday.label',
        initialValue: this.personalData.birthday,
        validators: [Validators.required],
        componentType: 'birthdate',
        validationErrorLabelKey: 'newClient.personalDetails.birthday.errors.birthday',
        validationErrorLabelParams: { ...birthdayTimeRange },
        validate: true,
        datepickerConfig: {
          minDate: birthdayTimeRange[0],
          maxDate: birthdayTimeRange[1],
          readonly: true,
          displayFormat: 'DD.MM.YYYY',
        },
      });
    }

    this.formModel.inputs.push(
      this.countryLogic.getPhoneFormInputGroup({
        countryCode: this.personalData.telefonCountryCode,
        phoneNumber: this.personalData.telefonNumber,
        isDisabled: this.isPhoneDisabled,
        namePrefix: 'mwe-order-personal-data',
        labelPrefix: 'newClient.personalDetails',
        isRequired: this.isPhoneRequired,
      }),
    );

    if (this.areEmailFieldsVisible) {
      this.formModel.inputs.push(...this.emailInputs);
    }

    if (this.titleOptions.length > 1 && this.isSalutationGroupVisible) {
      const prefixGroup = this.formModel.inputs.find(input => input.name === this.fieldNames.salutationGroup);
      (prefixGroup as IFormInputGroup).inputs.push({
        name: 'mwe-order-personal-data-title',
        initialValue: this.personalData.title?.value,
        validators: [],
        labelKey: 'newClient.personalDetails.titleInput.label',
        placeholder: 'newClient.personalDetails.titleInput.placeholder',
        inputClasses: 'col-8',
        componentType: 'typeahead',
        typeaheadOptions: {
          regex: '^{0}',
        },
        options: this.titleOptions,
        isDisabled: this.isTitleDisabled,
      });
    }
  }

  private validateSpecialFields(): void {
    this.invalidBirthday = false;
    const beforeEighteen = new Date();
    beforeEighteen.setFullYear(beforeEighteen.getFullYear() - 18);
    const isBeforeEighteen = beforeEighteen.getTime() < new Date(this.personalData.birthday).getTime();
    const hasBeforeEighteenError = !this.ignoreBeforeEighteenError && isBeforeEighteen;
    const isValid = isValidBirthdate(this.personalData.birthday);

    if (this.personalData.birthday && (hasBeforeEighteenError || !isValid || isDateInFuture(this.personalData.birthday))) {
      this.isValid = false;
      this.invalidBirthday = true;
      this.showAlert.emit({
        messageKey: 'newClient.personalDetails.birthday.errors.birthday',
      });
    } else {
      this.showAlert.emit(null);
    }
  }

  private validValue(value: string): string {
    return !value ? '' : value;
  }

  private addAdditionalFormInputs(): void {
    addAdditionalFormInputs(this.additionalFormInputs, this.formModel);
  }
}
