import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mwe-wizard-header',
  templateUrl: './wizard-header.component.html',
})
export class WizardHeaderComponent implements OnInit {
  @Input() titleKey: string;
  @Input() isClosingAllowed = true;
  @Output() cancelEvent = new EventEmitter<any>();

  titleKeyTranslated: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.titleKeyTranslated = this.translateService.instant(this.titleKey);
  }

  cancelOnHeader(): void {
    if (this.isClosingAllowed) {
      this.cancelEvent.emit();
    }
  }
}
