import { Buffer } from 'buffer';

export class CryptographyUtils {
  KEY_LENGTH = 32;
  SALT_LENGTH = 16;
  IV_LENGTH = 12;
  TAG_LENGTH = 16;
  ITERATION = 15000;

  async decrypt(cipherText: string, secret: string): Promise<string> {
    const parts = cipherText.split(':');
    const salt = Buffer.from(String(parts[0]), 'base64');
    const iv = Buffer.from(String(parts[1]), 'base64');
    const encryptedWithTag = Buffer.from(String(parts[2]), 'base64');
    const key = await this.getKey(salt, secret);

    const decrypted = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv,
        tagLength: this.TAG_LENGTH * 8,
      },
      key,
      encryptedWithTag,
    );
    return Buffer.from(decrypted).toString('utf-8');
  }
  async encrypt(plainText: string, secret: string): Promise<string> {
    const salt = crypto.getRandomValues(new Uint8Array(this.SALT_LENGTH)).buffer;
    const iv = crypto.getRandomValues(new Uint8Array(this.IV_LENGTH)).buffer;
    const key = await this.getKey(salt, secret);
    const encodedText = new TextEncoder().encode(plainText);
    const encrypted = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: Buffer.from(iv),
        tagLength: this.TAG_LENGTH * 8,
      },
      key,
      encodedText,
    );
    return this.toBase64(salt) + ':' + this.toBase64(iv) + ':' + this.toBase64(encrypted);
  }

  toBase64(bufferLike: ArrayBufferLike): string {
    return Buffer.from(bufferLike).toString('base64');
  }

  getKey(salt: ArrayBufferLike, secret: string) {
    return window.crypto.subtle.importKey('raw', new TextEncoder().encode(secret), 'PBKDF2', false, ['deriveKey']).then(passwordKey => {
      return crypto.subtle.deriveKey(
        {
          name: 'PBKDF2',
          hash: 'SHA-512',
          salt: Buffer.from(salt),
          iterations: this.ITERATION,
        },
        passwordKey,
        { name: 'AES-GCM', length: this.KEY_LENGTH * 8 },
        true,
        ['encrypt', 'decrypt'],
      );
    });
  }
}
