import { Verrechnungskonto } from './invoice-overview.model';
import { Ratenplan } from './find-payment-solution.model';
import { IAddressProducts } from '../address/address.model';

export enum UnpaidInvoiceState {
  Unpaid = 'offen',
}

export interface UnpaidInvoices {
  offeneZahlungen: UnpaidInvoice[];
  summen: UnpaidInvoicesSum[];
}

export interface UnpaidInvoicesSum {
  betrag: number;
  betragsArt: UnpaidInvoicesSumType;
  sollHabenKennzeichen: SollHabenKennzeichen;
  waehrung: string;
}

export interface UnpaidInvoice {
  verrechnungskontoId: string;
  systemId: string;
  faelligkeitsdatum: string;
  buchungstext: string;
  belegArt: BelegArt;
  betrag: number;
  status: UnpaidInvoiceState;
  waehrung: string;
  laufendeNummer: string;
  sollHabenKennzeichen: SollHabenKennzeichen;
  abrechnungsBelegNummer: string;
  druckbelegNummer: string;
  stundungsDatum: string;
  bereitsFaellig: boolean;
}

export enum UnpaidInvoicesSumType {
  All = 'Saldo + 45',
  DuePayment = 'Saldo aktuell',
  TotalPayment = 'Saldo gesamt',
}

type SollHabenKennzeichen = 'DEBIT' | 'CREDIT';
type BelegArt = 'RV' | 'VS' | 'AS' | 'JA';

export interface GetAllUnpaidInvoicesResponse {
  clearingAccounts: ClearingAccount[];
}

export interface ClearingAccount {
  businessPartnerId: string;
  accountId: string;
  systemId: string;
  unpaidInvoices: UnpaidInvoices;
  accountInfo?: Verrechnungskonto;
}

export interface ActivePaymentPlanRouteData {
  paymentPlan: Ratenplan;
  addressProducts: IAddressProducts;
}
