import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PRODUCTS_ROUTE } from '@mwe/constants';
import { ActionType, IPopupModel, IVerknuepfungen } from '@mwe/models';
import { Subscription } from 'rxjs';
import { AccountLogic, LoggingService, ProductStateService, PopupService } from '@mwe/services';
import { AssociationSuggestionsComponent } from '../association-suggestions/association-suggestions.component';

@Component({
  selector: 'mwe-association-suggestion-notification',
  templateUrl: './association-suggestion-notification.component.html',
})
export class AssociationSuggestionNotificationComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  showNotificationBanner = false;
  isNotificationBannerVisibleForRoute = false;

  constructor(
    private productStateService: ProductStateService,
    private popupService: PopupService,
    private router: Router,
    private loggingService: LoggingService,
    private accountLogic: AccountLogic,
  ) {}

  ngOnInit(): void {
    this.showNotificationBanner = this.productStateService.associationPopupClosed;
    if (this.productStateService.associationSuggestions?.length > 0) {
      this.showNotification(this.productStateService.associationSuggestions);
    }

    const storageSub = this.productStateService
      .observeAssociationSuggestions()
      .subscribe(suggestions => this.showNotification(suggestions));
    this.subscriptions.add(storageSub);

    this.setNotificationVisibility(this.router.url);
    const routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setNotificationVisibility(event.urlAfterRedirects);
      }
    });
    this.subscriptions.add(routerSub);
  }

  showNotification(suggestions: IVerknuepfungen[]): void {
    if (!this.showNotificationBanner && suggestions?.length > 0 && !this.popupService.isOpen()) {
      this.showAssociationPopup();
    }
  }

  showAssociationPopup(): void {
    const model: IPopupModel = {
      id: 'add-association-suggestions-popup',
      titleKey: '',
      messageKey: '',
      showSubmitButton: false,
      showCancelButton: false,
      useCustomReturnValue: true,
      additionalWindowClass: ['modal-lg'],
      component: AssociationSuggestionsComponent,
    };

    this.popupService.open(model);

    this.popupService.keepSubscriptions();
    this.logSuggestionAction('show popup');
    this.popupService.events().subscribe(result => {
      if (this.productStateService.associationSuggestions.length === 0) {
        this.productStateService.associationPopupClosed = this.showNotificationBanner = false;
      } else if (result === 'suggestions-popup-closed') {
        this.productStateService.associationPopupClosed = this.showNotificationBanner = true;
      }
    });
  }

  startFromBanner() {
    this.logSuggestionAction('start process from notification');
    this.productStateService.associationPopupClosed = false;
    this.showAssociationPopup();
  }

  logSuggestionAction(message: string) {
    const account = this.accountLogic.getAccount();
    const suggestions = this.productStateService.associationSuggestions;

    this.loggingService.logAction({
      type: ActionType.ASSOCIATIONS,
      message: `[association-suggestion] ${message}(${account.id} / ${account.email} / ${suggestions
        ?.map(elem => elem.geschaeftsPartnerId)
        .join(',')})`,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setNotificationVisibility(route: string): void {
    this.isNotificationBannerVisibleForRoute = route?.endsWith(`/${PRODUCTS_ROUTE}`);
  }
}
