import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICheckBoxesModel, IPopupModel } from '@mwe/models';

@Component({
  selector: 'mwe-dsgvo-popup',
  templateUrl: './dsgvo-popup.component.html',
  styles: [],
})
export class DsgvoPopupComponent {
  // is being passed by popupService
  loadedData: ICheckBoxesModel;
  model: IPopupModel;

  constructor(private activeModal: NgbActiveModal) {}

  closePopup(): void {
    this.activeModal.close('dsgvo-policy-confirmation');
  }
}
