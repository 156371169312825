import { Component, Input, OnInit } from '@angular/core';
import { Address } from '@mwe/models';

@Component({
  selector: 'mwe-vertrags-konto-product-list',
  templateUrl: './vertrags-konto-product-list.component.html',
})
export class VertragsKontoProductListComponent implements OnInit {
  @Input() categories: string[];
  @Input() address: Address;
  @Input() accountNumber: string;
  @Input() noBox: boolean;
  @Input() oneRow = true;

  constructor() {}

  ngOnInit(): void {}
}
