<div class="rounded border-2 border-gray-500 p-4 p-lg-5" style="border-style: dashed">
  <div class="row gy-3">
    <div class="mr-md-3 col-sm-auto text-center"><img [src]="'products-in-a-box.svg' | addStaticImagePath" width="146px" /></div>
    <div class="col">
      <h5 [translate]="noAddressGroupsTitle" [translateParams]="translateValues" data-test="no-associations-found-warning-title"></h5>
      <p
        [innerHTML]="noAddressGroupsText | translate: translateValues | safeHtml"
        data-test="no-associations-found-warning-text"
        class="mb-0"
      ></p>
    </div>
  </div>
</div>
<div id="form-buttons" class="mt-7 submit text-end form-buttons">
  <mwe-button
    buttonId="mwe-button-product-add"
    labelKey="shared.noAddressGroupsError.addProductBtn"
    (clicked)="navigateToProductSearch()"
  ></mwe-button>
</div>
