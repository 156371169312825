<mwe-basic-card
  cardTitle="tariff.additionalOptions.title"
  [headingNodeType]="headlineService.getHeadline(0)"
  iconClass="fa-box-circle-check"
>
  <label
    class="wrap-label"
    [ngClass]="[!isReadOnly ? 'p-4 pointer' : '']"
    [attr.title]="title() + ' wählen'"
    data-test="additional-options-checkbox"
  >
    <div class="row justify-content-between mb-3">
      <div class="col">
        <i class="fa fa-helmet-safety text-secondary" style="font-size: 2.5rem"></i>
      </div>
      @if (!isReadOnly) {
        <div class="col-auto">
          <div class="form-check">
            <input
              (change)="onCheckboxChange()"
              [attr.aria-label]="title()"
              [checked]="isSelected()"
              [disabled]="isCheckBoxDisabled()"
              class="form-check-input"
              type="checkbox"
            />
          </div>
        </div>
      }
    </div>
    <mwe-headline
      [headlineLevelIncrement]="1"
      cssClass="h6"
      [text]="title()"
      data-test="tariff-card-additional-options-title"
    ></mwe-headline>
    <p class="text--legal product-info mb-0">{{ tariff.category | correlatedCompanyReference }}</p>
  </label>
  @if (!isReadOnly) {
    <mwe-expander [textKey]="infoText()" />
  }
</mwe-basic-card>
