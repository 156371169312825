<button
  (click)="onClick()"
  [attr.data-test]="dataTest"
  [attr.disabled]="isDisabled ? true : null"
  [attr.id]="btnId"
  [attr.tabindex]="tabindex || null"
  [type]="btnType"
  [attr.aria-label]="(ariaLabel | translate) || null"
  [attr.aria-hidden]="ariaHidden || null"
  class="btn btn-link btn-link--{{ color }} text-start text-uppercase {{ customCss }}"
  type="button"
>
  <ng-content></ng-content>
  <span>
    <i *ngIf="iconCss" [ngClass]="iconCss" class="pr-1"></i>
    <span [innerHTML]="label | translate"></span>
  </span>
</button>
