import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getRouteData, reverseString } from '@mwe/utils';
import { environment } from '@mwe/environment';
import { KundenTypEnum } from '@mwe/models';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export abstract class CorrelationIdInterceptor implements HttpInterceptor {
  protected abstract getCustomerType(): KundenTypEnum;

  constructor(private activatedRoute: ActivatedRoute) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const routeData = getRouteData(this.activatedRoute);
    if (req.url.indexOf('auth/realms/logwien') === -1) {
      const modifiedReq = req.clone({
        headers: req.headers
          .set('x-correlationId', routeData?.isLandingPage ? this.getCorrelationId('LP-') : this.getCorrelationId())
          .set('x-app-version', this.getAppVersion())
          .set('x-customerType', this.getCustomerType()),
      });
      return next.handle(modifiedReq);
    }
    return next.handle(req);
  }

  private getCorrelationId(prefixOverwrite?: string): string {
    const prefix = prefixOverwrite || this.getApplicationPrefix();
    return prefix + reverseString('' + new Date().getTime());
  }

  private getAppVersion(): string {
    return environment.appVersion;
  }
  protected getApplicationPrefix(): string {
    return '';
  }
}
