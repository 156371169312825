<ng-container *ngIf="isErrorVisible()">
  <mwe-info-text colorClass="text-danger" [infoTextCode]="inputElem.infoTextCode" *ngIf="inputElem.infoTextCode">
    <ng-container *ngTemplateOutlet="errorMsg"></ng-container>
  </mwe-info-text>

  <ng-container *ngIf="!inputElem.infoTextCode">
    <ng-container *ngTemplateOutlet="errorMsg"></ng-container>
  </ng-container>

  <ng-template #errorMsg>
    <div
      [attr.id]="'form-input-desc--' + inputElem.name"
      [attr.data-test]="'form-error-' + inputElem.name"
      class="font-size-11 text-danger mt-1"
    >
      <i class="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
      <span [translate]="getErrorText()" [translateParams]="inputElem.validationErrorLabelParams"></span>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="!isErrorVisible() && inputElem.helpLabelKey">
  <div [attr.id]="'form-input-desc--' + inputElem.name" class="font-size-11 text-muted mt-1">
    <i class="fa fa-info-circle mr-1" aria-hidden="true"></i>
    <span [translate]="inputElem.helpLabelKey"></span>
  </div>
</ng-container>
