<mwe-collapse titleMessageKey="global.terms.listOfContents.title">
  <ng-container>
    @for (i of items; track i) {
      <h6><a [mweScrollToTargetOnClick]="getScrollTarget(i)" class="blue pointer" [translate]="getItemTitleKey(i)"></a></h6>
    }
  </ng-container>
</mwe-collapse>
<mwe-collapse titleMessageKey="global.terms.conditionsOfUse.title" initialState="expanded">
  <div class="overflow-auto scrollbar-thin p-1" tabindex="0">
    <ng-container>
      @for (i of items; track i) {
        <h6 [attr.id]="getItemId(i)" translate="global.terms.item{{ i }}.title"></h6>
        <p [innerHTML]="getItemTextKey(i) | translate | safeHtml"></p>
      }
    </ng-container>
  </div>
</mwe-collapse>
