import { KeycloakService } from 'keycloak-angular';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_HOME, ROUTE_LOGOUT_SUCCESS } from '../../app.constants';

@Injectable({
  providedIn: 'root',
})
export class IkpAuthService {
  keycloak = inject(KeycloakService);
  router = inject(Router);

  // Utility function for full URL paths
  fullRoutePrefix = (route: string) => {
    // check if route already starts with /
    if (route.startsWith('/')) {
      return window.location.origin + route;
    }

    return window.location.origin + '/' + route;
  };

  async login() {
    await this.keycloak.login({
      redirectUri: this.fullRoutePrefix(ROUTE_HOME),
    });
  }

  async register() {
    await this.keycloak.register({
      redirectUri: this.fullRoutePrefix(ROUTE_HOME),
    });
  }

  async logout() {
    await this.keycloak.logout(this.fullRoutePrefix(ROUTE_LOGOUT_SUCCESS));
  }
}
