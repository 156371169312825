@if (items().length > 1) {
  <mwe-button
    category="secondary"
    labelKey="tariff.tariffsSelectionModal.button"
    buttonId="tariff-selections-open-modal-button"
    (clicked)="openTariffsSelectionModal(tariffsSelection)"
    [hasFullWidth]="true"
  ></mwe-button>
} @else {
  <mwe-info-text-card text="tariff.tariffsSelectionModal.onlyOneTariffAvailable"></mwe-info-text-card>
}

<ng-template #tariffsSelection>
  <div class="px-4 pt-4 px-lg-5 pt-lg-5" data-test="tariff-selection-modal">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button (click)="closeModal()" type="button" class="btn btn-clean fs-5 text-secondary" aria-label="Close">
          <i class="fas fa-times" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="modal-body px-0 pt-0 pt-lg-3">
      <div class="container-xl container-wide px-0">
        <h4 [translateParams]="{ count: items().length }" translate="tariff.tariffsSelectionModal.title.{{ category.toLowerCase() }}"></h4>
        <mwe-horizontal-swiper [additionalConfig]="swiperConfig">
          @for (item of items(); track item; let first = $first) {
            <mwe-horizontal-swiper-item class="swiper-slide mt-5 h-auto">
              <mwe-tariff-selection-card-ui
                [hasActions]="true"
                [additionalOptionGroupName]="additionalOptionGroupName"
                [tariff]="item"
                (tariffUpdated)="onTariffUpdate($event, item, cardRef)"
                (reloadAfterError)="onReloadAfterError(item, cardRef)"
                [attr.data-test]="'tariff-selection-card-' + item.category.toLowerCase() + '-' + item.tariffKey"
                #cardRef
              >
                <div class="text-center">
                  <mwe-button-link
                    dataTest="tariff-selection-overlay-select-{{ item.tariffKey }}"
                    label="tariff.tariffsSelectionModal.selectTariffBtn"
                    (clicked)="selectTariff(cardRef)"
                  ></mwe-button-link>
                </div>
              </mwe-tariff-selection-card-ui>
              @if (first) {
                <mwe-info-text-card class="d-block mt-3" text="tariff.tariffsSelectionModal.currentTariff"></mwe-info-text-card>
              }
            </mwe-horizontal-swiper-item>
          }
        </mwe-horizontal-swiper>
      </div>
    </div>
  </div>
</ng-template>
