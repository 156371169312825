import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LandingPageService {
  private resourceUrl: string;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {
    this.resourceUrl = this.environmentService.getApiUrl() + 'api/email';
  }

  checkEmailAdressVerification(guid: string, systemId?: string): Promise<HttpResponse<null | string>> {
    return lastValueFrom(
      this.http.post<null | string>(this.resourceUrl + '/verification?guid=' + guid + '&systemId=' + systemId, {}, { observe: 'response' }),
    );
  }
}
