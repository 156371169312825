<div class="page-content py-0 my-0 overflow-x-hidden">
  <div class="header">
    <header class="bg-secondary header-bg-ikp mb-5 mb-lg-6">
      <nav class="navbar navbar-expand-lg header-container">
        <div class="header-row">
          <div class="col-2 logo-col">
            <a class="logo-link" data-test="header-logo-link" [routerLink]="'/'">
              <lib-ikp-header-logo />
            </a>
          </div>
        </div>
      </nav>
    </header>
  </div>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row mx-auto">
        <div class="col-lg">
          <div class="row">
            <div class="centered-top mb-0">
              <app-ikp-terms-of-use />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-ikp-footer></app-ikp-footer>
