import { Component, Input } from '@angular/core';
import { IERechnungsInfo } from '@mwe/models';
import { AdditionalProductsSummaryPoint } from '@mwe/models';

@Component({
  selector: 'mwe-products-summary-invoice-old',
  templateUrl: './products-summary-invoice-old.component.html',
})
export class ProductsSummaryInvoiceOldComponent {
  @Input() isPaymentInfoVisible;
  @Input() personalBillingTitle: string;
  @Input() eRechnungInfo: IERechnungsInfo;
  @Input() paymentIcon: string;
  @Input() paymentText: string;
  @Input() formattedAddress: string;
  @Input() contactDataEmailAddress: string;
  @Input() additionalBulletPoints: AdditionalProductsSummaryPoint[];
  @Input() areBankAccountInfoTextsVisible: boolean;
  @Input() productCategories: string[];
  @Input() invoiceInfoAlertMessage: string;
  @Input() paymentDataLabelClass?: string;
  @Input() eBillingLabelClass?: string;
  @Input() contactDataEmailAddressLabelClass?: string;
  @Input() summaryBillingTitleText?: string = 'summary.eBilling';
  @Input() billingAddressTitleText?: string = null;
  @Input() shippingEmailTitleText?: string = null;
  @Input() infoTextCardLabel: string;
}
