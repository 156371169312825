import { Injectable } from '@angular/core';
import { EnergyVoucherMapping, IAktionen, IProduktSelektion, parseAddress } from '@mwe/models';
import { isArrayWithMinOneItem, isProductCategoryStromOrGas } from '@mwe/utils';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { VoucherStateService } from '../voucher-state.service';

const voucherKey = 'energyVoucher_voucher';
const lastOrderIdKey = 'energyVoucher_lastOrderId';
const retriedOnErrorKey = 'energyVoucher_retriedOnError';
const submitIdKey = 'energyVoucher_submitId';
const allMappingDataKey = 'energyVoucher_allMappingData';
const selectableMappingDataKey = 'energyVoucher_selectableMappingData';
const selectedMappingDataKey = 'energyVoucher_selectedMappingData';
const isVoucherCodeInvalidKey = 'energyVoucher_isVoucherCodeValid';
const predefinedVoucherCodeKey = 'energyVoucher_predefinedVoucherCode';

@Injectable({ providedIn: 'root' })
export class VoucherRedeemStateService extends VoucherStateService {
  keepStorageOnce = false;

  @SessionStorage(allMappingDataKey)
  _allMappingData: EnergyVoucherMapping[];

  @SessionStorage(selectableMappingDataKey)
  _selectableMappingData: EnergyVoucherMapping[];

  @SessionStorage(selectedMappingDataKey)
  _selectedMappingData: EnergyVoucherMapping;

  @SessionStorage(voucherKey)
  private _voucher: IAktionen;

  @SessionStorage(isVoucherCodeInvalidKey)
  private _isVoucherCodeInvalid: boolean;

  @SessionStorage(predefinedVoucherCodeKey)
  private _predefinedVoucherCode: string;

  constructor(protected override storage: SessionStorageService) {
    super(storage, lastOrderIdKey, retriedOnErrorKey, submitIdKey);
  }

  clearAll() {
    this.clear();
  }

  override clear(): void {
    super.clear();
    this.storage.clear(allMappingDataKey);
    this.storage.clear(selectableMappingDataKey);
    this.storage.clear(selectedMappingDataKey);

    if (this.keepStorageOnce) {
      this.keepStorageOnce = false;
      return;
    }

    this.storage.clear(lastOrderIdKey);
    this.storage.clear(retriedOnErrorKey);
    this.storage.clear(submitIdKey);
    this.storage.clear(voucherKey);
    this.storage.clear(isVoucherCodeInvalidKey);
    this.storage.clear(predefinedVoucherCodeKey);
  }

  get voucher(): IAktionen {
    if (!this._voucher) {
      return undefined;
    }
    return this._voucher;
  }

  set voucher(voucher: IAktionen) {
    if (!voucher) {
      this.storage.clear(voucherKey);
    }
    this._voucher = voucher;
  }

  get allMappingData(): EnergyVoucherMapping[] {
    if (!this._allMappingData) {
      return undefined;
    }

    this._allMappingData.forEach(data => (data.address = parseAddress(data.address)));
    return this._allMappingData;
  }

  set allMappingData(allMappingData: EnergyVoucherMapping[]) {
    if (!allMappingData) {
      this.storage.clear(allMappingDataKey);
    }
    this._allMappingData = allMappingData;
  }

  get selectableMappingData(): EnergyVoucherMapping[] {
    if (!this._selectableMappingData) {
      return undefined;
    }

    this._selectableMappingData.forEach(data => (data.address = parseAddress(data.address)));
    return this._selectableMappingData;
  }

  set selectableMappingData(selectableMappingData: EnergyVoucherMapping[]) {
    if (!selectableMappingData) {
      this.storage.clear(selectableMappingDataKey);
    }
    this._selectableMappingData = selectableMappingData;
  }

  get selectedMappingData(): EnergyVoucherMapping {
    if (!this._selectedMappingData) {
      return undefined;
    }

    this._selectedMappingData.address = parseAddress(this._selectedMappingData.address);
    return this._selectedMappingData;
  }

  set selectedMappingData(selectedMappingData: EnergyVoucherMapping) {
    if (!selectedMappingData) {
      this.storage.clear(selectableMappingDataKey);
    }
    this._selectedMappingData = selectedMappingData;
  }

  get isVoucherCodeInvalid(): boolean {
    return this._isVoucherCodeInvalid;
  }

  set isVoucherCodeInvalid(value: boolean) {
    this._isVoucherCodeInvalid = value;
  }

  get predefinedVoucherCode(): string {
    return this._predefinedVoucherCode;
  }

  set predefinedVoucherCode(value: string) {
    this._predefinedVoucherCode = value;
  }

  observeRedeemButtonVisibility(): Observable<boolean> {
    const allMapping$ = this.storage.observe(allMappingDataKey).pipe(startWith(this.allMappingData));
    const isInvalid$ = this.storage.observe(isVoucherCodeInvalidKey).pipe(startWith(false));

    return combineLatest([allMapping$, isInvalid$]).pipe(map(([allMapping, isInvalid]) => isArrayWithMinOneItem(allMapping) && !isInvalid));
  }

  override isFilterMappingData(): boolean {
    // overwrites default behaviour !!!
    return true;
  }

  getProductSelection(): IProduktSelektion {
    return { commonMapping: this.allMappingData.filter(md => isProductCategoryStromOrGas(md.categories[0])) };
  }

  getProductSelectionIds(): string[] {
    let anlageIds = [];
    if (this.isFilterMappingData()) {
      this.getProductSelection().commonMapping.forEach(cm => {
        anlageIds = anlageIds.concat(cm.anlageIds);
      });
    } else {
      anlageIds = anlageIds.concat(this.selectedMappingData.anlageIds);
    }
    return anlageIds.filter(aid => !!aid);
  }
}
