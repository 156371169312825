import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClearingAccountLogic, ProfileService } from '@mwe/services';
import { ActivePaymentPlanRouteData, IAddress, Product, Ratenplan } from '@mwe/models';
import { Router } from '@angular/router';
import { ACTIVE_PAYMENT_PLAN_ROUTE, FeatureToggleEnum, PRIVATE_ROUTE } from '@mwe/constants';

@Component({
  selector: 'mwe-active-payment-plan-alert',
  templateUrl: './active-payment-plan-alert.component.html',
})
export class ActivePaymentPlanAlertComponent implements OnInit {
  @Input() products: Product[];
  @Input() address: IAddress;
  @Output() linkClicked = new EventEmitter<void>();

  hasActivePaymentPlan: boolean;
  paymentPlan: Ratenplan;

  constructor(private logic: ClearingAccountLogic, private router: Router, private profileService: ProfileService) {}

  async ngOnInit(): Promise<void> {
    if (this.profileService.isFeatureToggleDisabled(FeatureToggleEnum.ACTIVE_PAYMENT_PLAN_DATA_ENABLED)) {
      return;
    }

    this.paymentPlan = await this.logic.getActivePaymentPlan(this.products);
    this.hasActivePaymentPlan = !!this.paymentPlan;
  }

  onActivePaymentLinkClicked(): void {
    this.linkClicked.emit();

    const state: ActivePaymentPlanRouteData = {
      paymentPlan: this.paymentPlan,
      addressProducts: { address: this.address, products: this.products },
    };
    this.router.navigateByUrl(`${PRIVATE_ROUTE}/${ACTIVE_PAYMENT_PLAN_ROUTE}`, { state });
  }
}
