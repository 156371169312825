<mwe-custom-popup (dismissClicked)="closePopup()">
  <div class="text-center">
    <h4 class="mb-5" *ngIf="popupData.title">{{ popupData.title }}</h4>

    <div class="mb-5">
      <h6>{{ popupData.newClientText }}</h6>
      <p *ngIf="popupData.newClientTextParagraph" class="text--big">{{ popupData.newClientTextParagraph }}</p>
      <mwe-button
        buttonId="mwe-campaign-popup-newclient-button"
        class="campaign-popup-button"
        [labelKey]="popupData.newClientButton"
        (clicked)="startNewClientProcess()"
      ></mwe-button>
    </div>

    <div>
      <h6>{{ popupData.tariffChangeText }}</h6>
      <p *ngIf="popupData.tariffChangeTextParagraph" class="text--big">{{ popupData.tariffChangeTextParagraph }}</p>
      <mwe-button
        buttonId="mwe-campaign-popup-tariffchange-button"
        class="campaign-popup-button"
        color="blue"
        [labelKey]="popupData.tariffChangeButton"
        (clicked)="startTariffChangeProcess()"
      ></mwe-button>
    </div>
  </div>
</mwe-custom-popup>
