import { Component, OnInit } from '@angular/core';
import { AccountLogic } from '@mwe/services';

@Component({
  selector: 'mwe-impersonate-warning',
  templateUrl: './impersonate-warning.component.html',
  styles: [
    `
      .blinking-background {
        background-color: red;
        animation: blinkingText 1.5s 5;
      }

      @keyframes blinkingText {
        0% {
          background-color: red;
        }
        50% {
          background-color: #d9a300;
        }
      }
    `,
  ],
})
export class ImpersonateWarningComponent implements OnInit {
  email: string;

  constructor(private accountLogic: AccountLogic) {}

  ngOnInit(): void {
    this.email = this.accountLogic.getAccountEmail();
  }
}
