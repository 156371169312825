import { Datei } from '@mwe/models';

export const openPdf = (datei: Datei, title: string, openNewTab?: boolean): void => {
  const b64Data = datei.dateiInhalt;
  const byteCharacters = atob(b64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: 'application/pdf;base64' }) as File;
  const fileURL = URL.createObjectURL(file);
  downloadPdfDataContent(title, fileURL, openNewTab);
};

export const downloadPdfDataContent = (fileTitle: string, fileURL: string, openNewTab?: boolean): void => {
  const downloadLink = document.createElement('a');
  downloadLink.target = fileTitle;
  downloadLink.href = fileURL;
  if ( !openNewTab ) {
    downloadLink.download = fileTitle;
  }
  document.body.appendChild(downloadLink);
  downloadLink.click();
  // cleanup: remove element and revoke object URL
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(fileURL);
};
