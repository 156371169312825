import { ApplicationRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  AppStorageService,
  ConfirmationStatusStateService,
  EnvironmentService,
  NavigationLogic,
  NavigationStateService,
  PopupService,
  ProfileService,
} from '@mwe/services';
import { IMG_PATH, IPopupModel, ITrackingData, IWizardModel, IWizardStep, IWizardStepAlert, IWizardStepComponent } from '@mwe/models';
import { track } from '@mwe/utils';
import { fromEvent, isObservable, Observable, of, Subscription, take } from 'rxjs';

@Component({
  selector: 'mwe-wizard',
  templateUrl: './wizard.component.html',
})
export class WizardComponent implements OnInit, OnDestroy {
  @ViewChild('wizardAlert', { static: false }) wizardAlert: ElementRef;
  @Input() wizardModel: IWizardModel;
  @Input() confirmationStatusStateService: ConfirmationStatusStateService;
  @Input() wizardModelUrlPrefix: string;
  @Input() customCssClass: string;
  imageUrl: string;
  currentWizardStepIdx: number;
  currentWizardStepReference: IWizardStepComponent;
  wizardStepWidth: number;
  isSubmitting = false;
  buttonsDisabled = false;
  showAlert = false;
  alertTitleKey: string;
  alertMessageKey: string;
  alertType = 'danger';
  showAlertIcon = false;
  buttonsHidden = false;
  prevButtonVisibleOverwrite = true;
  nextButtonVisibleOverwrite = true;
  forwardDirectionChangeIx = 1;
  currentWizardStep: IWizardStep;
  currentWizardStepPrevLabel: string;
  currentWizardStepNextLabel$: Observable<string>;
  currentWizardStepNextLabelOverwrite: string;
  currentWizardStepNextDisabled$: Observable<boolean>;
  currentWizardStepHideNextButton: boolean;
  currentWizardStepHidePrevButton: boolean;
  currentWizardStepTitleVisibilityOverwrite: boolean;
  currentWizardStepSubTitleLabelOverwrite: string;
  progressBarVisibilityOverwrite: boolean;
  imageVisibilityOverwrite: boolean;
  currentWizardStepIsPrimaryHoverButton: boolean;
  progressBarLabels?: string[];
  isNavigationDirectionForward = true;
  private subscriptions = new Subscription();
  private stepSubscriptions = new Subscription();

  constructor(
    private app: ApplicationRef,
    private router: Router,
    private elementRef: ElementRef,
    private popupService: PopupService,
    private appStorage: AppStorageService,
    private navigationLogic: NavigationLogic,
    private navigationStateService: NavigationStateService,
    private environmentService: EnvironmentService,
    private profileService: ProfileService,
  ) {}

  get isWizardTitleVisible(): boolean {
    return this.currentWizardStep?.titleKey && this.currentWizardStepTitleVisibilityOverwrite;
  }

  get isWizardSubTitleVisible(): boolean {
    const hasSubTitleLabel = !!this.currentWizardStepSubTitleLabelOverwrite || this.currentWizardStep?.subTitleKey;
    return hasSubTitleLabel && this.currentWizardStepTitleVisibilityOverwrite;
  }

  get isPrevButtonVisible(): boolean {
    const hasSpecialLogic = !!this.currentWizardStepReference?.additionalPrevStepLogic;
    const hasMeetNormalConditions = !this.currentWizardStepHidePrevButton && this.currentWizardStepIdx > 1;

    return hasSpecialLogic || hasMeetNormalConditions;
  }

  get subTitle(): string {
    return this.currentWizardStepSubTitleLabelOverwrite || this.currentWizardStep.subTitleKey;
  }

  ngOnInit(): void {
    if (this.wizardModelUrlPrefix === undefined) {
      this.wizardModelUrlPrefix = this.environmentService.getPortalUrlPrefix();
    }

    if (this.wizardModel.imageName) {
      this.imageUrl = `${this.environmentService.staticContent()}/${IMG_PATH}${this.wizardModel.imageName}`;
    }

    if (this.wizardModel.forwardDirectionChangeIx >= 0) {
      this.forwardDirectionChangeIx = this.wizardModel.forwardDirectionChangeIx;
    }
    this.updateCurrentWizardStep(this.router.url);

    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateCurrentWizardStep(event.url);
      }
    });
    this.subscriptions.add(routerSubscription);

    this.checkProgressBar();
  }

  ngOnDestroy(): void {
    this.clearRecentOrdersCache();
    this.subscriptions.unsubscribe();
    this.stepSubscriptions.unsubscribe();
    this.currentWizardStep = undefined;
  }

  getCurrentWizardStepIdxFromUrl(url: string): number {
    if (this.wizardModel && this.wizardModel.wizardSteps) {
      const stepUrl = url.split('?')[0].split('#')[0];
      const idx = this.wizardModel.wizardSteps.findIndex(elem => {
        return this.getPrefixedPath(elem) === stepUrl;
      });
      return idx !== -1 ? idx + 1 : -1;
    }
    return -1;
  }

  calculateProgressWidth(overwriteStepIndex?: number): void {
    if (this.wizardModel && this.wizardModel.wizardSteps && this.wizardModel.wizardSteps.length > 0) {
      // round 2 decimal places
      const currentStepWidthIndex = overwriteStepIndex ? overwriteStepIndex : this.currentWizardStepIdx;
      this.wizardStepWidth = Math.round(((currentStepWidthIndex * 100) / this.wizardModel.wizardSteps.length) * 100) / 100;
    }
  }

  cancelOnHeader(): void {
    if (this.wizardModel.customCancelLogic) {
      this.wizardModel.customCancelLogic();
      return;
    }

    if (this.currentWizardStep.doSubmitInsteadOfCancel) {
      this.submit();
      return;
    }

    if (this.currentWizardStepIdx > 1 && this.currentWizardStep.showCancelWarning !== false) {
      const model: IPopupModel = {
        id: 'wizard-cancel-on-header',
        titleKey: 'global.wizard.close.popup.title',
        messageKey: 'global.wizard.close.popup.message',
        showSubmitButton: true,
        submitButtonKey: 'global.wizard.close.popup.buttonStay',
        showCancelButton: true,
        cancelButtonKey: 'global.wizard.close.popup.buttonExit',
        modalOpts: {
          ariaLabelledBy: 'mwe_popup-title',
        },
      };

      if (this.wizardModel && this.wizardModel.closeWarning) {
        model.showAdditionalButtons = true;
        model.additionalButtons = this.wizardModel.closeWarning.additionalButtons;
      }

      this.popupService.open(model);
      this.subscribeToPopUpAdditionalButtonClick();
      this.subscribeToPopupEvents();
    } else {
      if (this.profileService.getProfileInfo()?.toggleSetDesignator == 'LP') {
        this.router.navigate(this.getBackUrl()).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate(this.getBackUrl());
      }
    }
  }

  nextWithSkip(): void {
    const skipCount = this.currentWizardStep.skipNextStepButton?.skipCount ?? 1;
    this.nextSteps(skipCount);
  }

  cancelWithSkip(): void {
    const skipCount = this.currentWizardStep.skipPrevStepButton?.skipCount ?? 1;
    this.prevSteps(skipCount);
  }

  cancel(): void {
    if (this.currentWizardStepReference.cancel) {
      try {
        const noWizardLogic = this.currentWizardStepReference.cancel();
        if (noWizardLogic) {
          return;
        }
      } catch (_) {
        // ignore
      }
    }

    this.prevStep();
  }

  async submit(): Promise<void> {
    this.showAlert = false;
    if (this.currentWizardStepReference.validate()) {
      try {
        this.setSubmitting(true);
        await this.currentWizardStepReference.submit();
        this.nextStep();
      } catch (e) {
        // ignore, is handled in wizard step
      }
      this.setSubmitting(false);
    } else if (this.wizardModel.scrollToTopmostInvalidFormControl) {
      this.scrollToTopmostInvalidElement();
    }
  }

  /** We need to use is-invalid as a indicator for invalid inputs as not all inputs are inside a FormComponent like AddressInputComponent */
  scrollToTopmostInvalidElement() {
    this.app.tick(); // force change detection to apply is-invalid classes
    const invalidControls = this.elementRef.nativeElement.querySelectorAll('.is-invalid');
    console.warn('Invalid controls:', invalidControls);
    // find topmost invalid control via getClientBoundingRect().top
    const topmost = Array.from<HTMLElement>(invalidControls).reduce((a, b) =>
      a.getBoundingClientRect().top < b.getBoundingClientRect().top ? a : b,
    );
    if (topmost && 'focus' in topmost) {
      if ('onscrollend' in window) {
        fromEvent(window, 'scrollend')
          .pipe(take(1))
          .subscribe(() => {
            topmost.focus();
          });
      } else {
        topmost.focus();
      }
      topmost.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  setSubmitting(isSubmitting: boolean): void {
    this.isSubmitting = isSubmitting;
    Array.from(this.elementRef.nativeElement.querySelectorAll('input, select')).forEach((elem: any) => (elem.disabled = isSubmitting));
  }

  prevSteps(stepCount: number): void {
    let i = 0;

    while (i < stepCount) {
      this.prevStep();
      i++;
    }
  }

  nextSteps(stepCount: number): void {
    let i = 0;

    while (i < stepCount) {
      this.nextStep();
      i++;
    }
  }

  prevStep(): void {
    if (this.currentWizardStepReference?.additionalPrevStepLogic) {
      try {
        this.currentWizardStepReference.additionalPrevStepLogic();
      } catch (e) {
        // no error handling, error is just thrown when we want to skip the prev-logic
        return;
      }
    }

    if (this.currentWizardStep?.prevButtonRouteOverwrite) {
      this.router.navigateByUrl(this.currentWizardStep.prevButtonRouteOverwrite);
      return;
    }

    if (this.currentWizardStepIdx > 1) {
      this.currentWizardStepIdx--;
      if (
        this.wizardModel.wizardSteps[this.currentWizardStepIdx - 1].skipStep &&
        this.wizardModel.wizardSteps[this.currentWizardStepIdx - 1].skipStep()
      ) {
        this.prevStep();
      } else {
        this.showAlert = false;
        this.isNavigationDirectionForward = this.currentWizardStepIdx - 1 === this.forwardDirectionChangeIx;
        this.router.navigate([this.getPrefixedPath(this.wizardModel.wizardSteps[this.currentWizardStepIdx - 1])]);
      }
    } else {
      this.router.navigate([`/`]);
    }
  }

  nextStep(): void {
    if (this.wizardModel.wizardSteps[this.currentWizardStepIdx]) {
      this.currentWizardStepIdx++;
      if (
        this.wizardModel.wizardSteps[this.currentWizardStepIdx - 1].skipStep &&
        this.wizardModel.wizardSteps[this.currentWizardStepIdx - 1].skipStep()
      ) {
        this.nextStep();
      } else {
        this.isNavigationDirectionForward = this.currentWizardStepIdx < this.wizardModel.wizardSteps.length;
        this.router.navigate([this.getPrefixedPath(this.wizardModel.wizardSteps[this.currentWizardStepIdx - 1])]);
      }
    } else {
      this.router.navigate(this.getSuccessUrl());
    }
    this.setTrackingDataIfAvailable();
  }

  setTrackingDataIfAvailable(): void {
    const trackingData = this.getCurrentTrackingData();
    const trackingName = this.wizardModel.processId || 'wizard';
    if (trackingData) {
      track(trackingName, trackingData);
    }
  }

  onActivate(componentReference: IWizardStepComponent, wizardModel?: IWizardModel): void {
    Array.from(this.elementRef.nativeElement.querySelectorAll('button')).forEach((elem: any) => elem.blur());
    this.currentWizardStepReference = componentReference;
    this.currentWizardStepNextLabelOverwrite = '';
    this.prevButtonVisibleOverwrite = true;
    this.nextButtonVisibleOverwrite = true;
    this.currentWizardStepTitleVisibilityOverwrite = true;
    this.currentWizardStepSubTitleLabelOverwrite = '';
    this.progressBarVisibilityOverwrite = true;
    this.buttonsHidden = false;

    this.stepSubscriptions.unsubscribe();
    this.stepSubscriptions = new Subscription();

    if (this.currentWizardStepReference.onDisableButtons) {
      const sub = this.currentWizardStepReference.onDisableButtons.subscribe((value: boolean) => {
        this.buttonsDisabled = value;
      });
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onHideButtons) {
      const sub = this.currentWizardStepReference.onHideButtons.subscribe((value: boolean) => {
        this.buttonsHidden = value;
      });
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onHidePrevButton) {
      const sub = this.currentWizardStepReference.onHidePrevButton.subscribe((value: boolean) => {
        this.prevButtonVisibleOverwrite = !value;
      });
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onHideNextButton) {
      const sub = this.currentWizardStepReference.onHideNextButton.subscribe((value: boolean) => {
        this.nextButtonVisibleOverwrite = !value;
      });
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onDisableNextButton) {
      const sub = this.currentWizardStepReference.onDisableNextButton.subscribe((value: boolean) => {
        this.currentWizardStepNextDisabled$ = of(value);
      });
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onOverwriteNextButtonLabel) {
      const sub = this.currentWizardStepReference.onOverwriteNextButtonLabel.subscribe((value: string) => {
        this.currentWizardStepNextLabelOverwrite = value;
      });
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onHideTitle) {
      const sub = this.currentWizardStepReference.onHideTitle.subscribe((value: boolean) => {
        this.currentWizardStepTitleVisibilityOverwrite = !value;
      });
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onOverwriteSubTitle) {
      const sub = this.currentWizardStepReference.onOverwriteSubTitle.subscribe((value: string) => {
        this.currentWizardStepSubTitleLabelOverwrite = value;
      });
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onHideProgressBar) {
      const sub = this.currentWizardStepReference.onHideProgressBar.subscribe((value: boolean) => {
        this.progressBarVisibilityOverwrite = !value;
      });
      this.stepSubscriptions.add(sub);
    }
    if (typeof this.currentWizardStepReference.setNavigationDirection !== 'undefined') {
      this.currentWizardStepReference.setNavigationDirection(this.isNavigationDirectionForward);
    }
    if (this.currentWizardStepReference.onShowAlert) {
      const sub = this.currentWizardStepReference.onShowAlert.subscribe((alert: IWizardStepAlert) => {
        this.onStepShowAlert(alert);
      });
      this.stepSubscriptions.add(sub);
    }
    if (!this.currentWizardStepReference.hasAllNeededData()) {
      if (!this.wizardModel && wizardModel) {
        this.wizardModel = wizardModel;
      }
      this.router.navigate([this.wizardModel.isDeepLinkEnabled ? this.getPrefixedPath(this.wizardModel.wizardSteps[0]) : `/`]);
    }
    if (this.currentWizardStepReference.onAutoTriggerNextBtn) {
      const sub = this.currentWizardStepReference.onAutoTriggerNextBtn.subscribe((value: boolean) => (value ? this.submit() : null));
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onSkipStep) {
      const sub = this.currentWizardStepReference.onSkipStep.subscribe(() => {
        this.onSkipStep();
      });
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onNextStep) {
      const sub = this.currentWizardStepReference.onNextStep.subscribe(() => {
        this.nextWithSkip();
      });
      this.stepSubscriptions.add(sub);
    }
    if (this.currentWizardStepReference.onOverwriteImageVisibility) {
      const sub = this.currentWizardStepReference.onOverwriteImageVisibility.subscribe((value: boolean) => {
        this.imageVisibilityOverwrite = value;
      });
      this.stepSubscriptions.add(sub);
    }
  }

  onSkipStep(): void {
    if (this.isNavigationDirectionForward === true) {
      const skipCount = this.currentWizardStep.skipNextStepButton?.skipCount ?? 1;
      this.nextSteps(skipCount);
    } else {
      const skipCount = this.currentWizardStep.skipPrevStepButton?.skipCount ?? 1;
      this.prevSteps(skipCount);
    }
  }

  onStepShowAlert(alert: IWizardStepAlert): void {
    if (alert === null) {
      this.showAlert = false;
      return;
    }

    if (alert.titleKey) {
      this.alertTitleKey = alert.titleKey;
    }
    if (alert.messageKey) {
      this.alertMessageKey = alert.messageKey;
    }

    if (alert.type) {
      this.alertType = alert.type;
    }

    if (alert.showIcon) {
      this.showAlertIcon = alert.showIcon;
    }
    this.showAlert = true;
    if (this.elementRef.nativeElement.querySelector('.alert-danger')) {
      this.elementRef.nativeElement.querySelector('.alert-danger').scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    }
  }

  updateCurrentWizardStep(url: string): void {
    this.currentWizardStepIdx = this.getCurrentWizardStepIdxFromUrl(url);
    if (this.currentWizardStepIdx !== -1) {
      this.currentWizardStep = this.wizardModel.wizardSteps[this.currentWizardStepIdx - 1];

      if (this.confirmationStatusStateService?.submitId && !this.currentWizardStep.validConfirmationStatusStateStep) {
        this.confirmationStatusStateService.clearAll();
        const wizardStep = this.wizardModel.confirmationStatusStateStartIndex || 0;
        if (wizardStep > -1) {
          this.router.navigate([this.getPrefixedPath(this.wizardModel.wizardSteps[wizardStep])]);
        } else {
          this.router.navigate([`/`]);
        }
      }

      this.updateButtonStatusAndLabel();
    }

    this.calculateProgressWidth(this.currentWizardStep?.overwriteStepIndex);
  }

  getCurrentProgressBarIndex(): number {
    const currentIndex = this.currentWizardStepIdx - 1;
    const hiddenStepsBeforeCurrentIndex = this.wizardModel.wizardSteps.filter((step, i) => {
      return step.hideFromProgressBar && currentIndex >= i;
    });

    return currentIndex - hiddenStepsBeforeCurrentIndex.length;
  }

  openLink(url): void {
    window.open(url, '_blank');
  }

  private getPrefixedPath(elem: IWizardStep) {
    return this.wizardModelUrlPrefix + elem.path;
  }

  private getCurrentTrackingData(): ITrackingData {
    return typeof this.currentWizardStepReference?.getTrackingData === 'function'
      ? this.currentWizardStepReference.getTrackingData()
      : undefined;
  }

  private subscribeToPopUpAdditionalButtonClick(): void {
    this.popupService.additionalButtonClickEvent()?.subscribe(buttonId => {
      if ('mwe-feedback-button' === buttonId) {
        const trackingData = this.getCurrentTrackingData();
        const trackingName = this.wizardModel.processId || 'wizard';
        if (trackingData) {
          trackingData.cancellationAction = 'Feedback geben';
          track(trackingName, trackingData);
        }
      }
    });
  }

  private subscribeToPopupEvents(): void {
    this.popupService.events().subscribe(result => {
      if (!result) {
        this.router.navigate(this.getBackUrl());
      }

      const trackingData = this.getCurrentTrackingData();
      const trackingName = this.wizardModel.processId || 'wizard';
      if (trackingData) {
        trackingData.cancellationAction = result ? 'Nein, ich mache weiter' : 'Ja, abbrechen';
        track(trackingName, trackingData);
      }
    });
  }

  private useNavigatingBackTo(): boolean {
    return this.wizardModel.useNavigatingBackTo || this.currentWizardStepReference.useNavigatingBackTo?.();
  }

  private getBackUrl(): any[] {
    if (this.useNavigatingBackTo()) {
      return this.getNavigateBackRoute();
    }

    const backUrl = this.navigationStateService.wizardCancelUrl;
    this.clearWizardUrls();

    return [backUrl];
  }

  private getSuccessUrl(): string[] {
    if (this.useNavigatingBackTo()) {
      return this.getNavigateBackRoute();
    }

    const successUrl = this.navigationStateService.wizardSuccessUrl;
    this.clearWizardUrls();

    return successUrl ? [successUrl] : ['/'];
  }

  private clearWizardUrls(): void {
    // reset back url to enforce that the next time we go back to default url '/'
    this.navigationStateService.clearWizardUrls();
  }

  private async updateButtonStatusAndLabel(): Promise<void> {
    this.setNextStepLabel();
    this.setNextDisabled();
    this.currentWizardStepPrevLabel =
      typeof this.currentWizardStep.prevButtonLabel === 'function'
        ? this.currentWizardStep.prevButtonLabel()
        : this.currentWizardStep.prevButtonLabel;
    this.currentWizardStepHideNextButton =
      typeof this.currentWizardStep.hideNextButton === 'function'
        ? await this.currentWizardStep.hideNextButton()
        : this.currentWizardStep.hideNextButton;
    this.currentWizardStepHidePrevButton =
      typeof this.currentWizardStep.hidePrevButton === 'function'
        ? await this.currentWizardStep.hidePrevButton()
        : this.currentWizardStep.hidePrevButton;
    this.currentWizardStepIsPrimaryHoverButton =
      typeof this.currentWizardStep.isPrimaryHoverButton === 'function'
        ? await this.currentWizardStep.isPrimaryHoverButton()
        : this.currentWizardStep.isPrimaryHoverButton;
  }

  private setNextStepLabel(): void {
    if (isObservable(this.currentWizardStep.nextButtonLabel)) {
      this.currentWizardStepNextLabel$ = this.currentWizardStep.nextButtonLabel;
      return;
    }

    const label = this.currentWizardStep.nextButtonLabel;
    const value = typeof label === 'function' ? label() : label;
    this.currentWizardStepNextLabel$ = of(value);
  }

  private setNextDisabled(): void {
    if (!isObservable(this.currentWizardStep.nextButtonDisabled)) {
      this.currentWizardStepNextDisabled$ = of(false);
      return;
    }

    this.currentWizardStepNextDisabled$ = this.currentWizardStep.nextButtonDisabled;
  }

  private clearRecentOrdersCache(): void {
    // only one step means that this is the details page -> no changes
    // so no need to clear cache
    const isProcessWizard = this.wizardModel.wizardSteps.length > 1;
    // last step means that user has finished process and we have to clear cache
    // otherwise recent orders will not be updated
    const lastStep = this.wizardModel.wizardSteps.length === this.currentWizardStepIdx;

    if (isProcessWizard && lastStep) {
      this.appStorage.clearDataAfterProcess();

      if (this.wizardModel.cleanUpAfterLastStep) {
        this.wizardModel.cleanUpAfterLastStep();
      }
    }
  }

  private getNavigateBackRoute(): string[] {
    return [this.navigationLogic.getNavigateBackRouteAndRemoveFromState()];
  }

  private checkProgressBar() {
    const progressBarLabels = this.wizardModel.wizardSteps.map(step => step.progressBarLabel).filter(i => i !== undefined);
    const stepsWithNoLabelHide = this.wizardModel.wizardSteps.filter(step => !step.hideFromProgressBar);

    if (progressBarLabels.length !== stepsWithNoLabelHide.length) {
      return;
    }

    this.wizardModel.hideProgressBar = true;
    this.progressBarLabels = progressBarLabels;
  }
}
