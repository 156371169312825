<div class="d-flex">
  <ng-content></ng-content>
  <mwe-info-text-icon
    [infoTextCode]="infoTextCode"
    [infoTextTitle]="infoTextTitle"
    [infoTextDescription]="infoTextDescription"
    [infoTextLink]="infoTextLink"
    [colorClass]="colorClass"
  ></mwe-info-text-icon>
</div>
