import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { isArrayWithMinOneItem } from '@mwe/utils';

@Component({
  selector: 'mwe-progress-bar',
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent {
  @Input() labels: string[];
  @Input() selectedIndex: number;
  _offset = 0;
  constructor(private elementRef: ElementRef) {}

  hasLabels(): boolean {
    return isArrayWithMinOneItem(this.labels);
  }
  ngAfterViewInit() {
    this._offset = this.measureOverhang();
    this.onResize();
  }
  measureOverhang() {
    const elementRect = this.elementRef.nativeElement.getBoundingClientRect();
    const testArr = [
      this.elementRef.nativeElement.querySelector('.step-navigation-step:first-child .step-navigation-step-label').getBoundingClientRect(),
      this.elementRef.nativeElement.querySelector('.step-navigation-step:last-child .step-navigation-step-label').getBoundingClientRect(),
    ];
    return Math.max(
      ...testArr.map(e => {
        return Math.max(...[elementRect.left - e.left, e.right - elementRect.right]);
      })
    );
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this._offset <= 0) {
      return;
    }
    const elementRect = this.elementRef.nativeElement.getBoundingClientRect();

    if (elementRect.left < this._offset) {
      this.elementRef.nativeElement.classList.add('step-navigation-flush-labels');
    } else {
      this.elementRef.nativeElement.classList.remove('step-navigation-flush-labels');
    }
    if (this.checkStepOverlap()) {
      this.elementRef.nativeElement.classList.add('step-navigation-one-label');
    } else {
      this.elementRef.nativeElement.classList.remove('step-navigation-one-label');
    }
  }
  checkStepOverlap() {
    const testArr = [...this.elementRef.nativeElement.querySelectorAll('.step-navigation-step-label')].map(e => e.getBoundingClientRect());
    // checks overlap between neighboring elements
    for (let i = 0; i < testArr.length - 1; i++) {
      if (testArr[i].right > testArr[i + 1].left) {
        return true;
      }
    }
    return false;
  }
}
