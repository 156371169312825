// tslint:disable-next-line: no-empty-interface

import { IAddress, IOlavKey } from '../address/address.model';
import {
  CommonMappingData,
  IBankDetails,
  IBulletConfirmationStatusChangeResponse,
  IDifferentInvoiceRecipient,
  IERechnungsInfo,
} from '../integrationlayer/api.model';
import { MandatsReferenzMappingItem } from '../invoices/invoice-data-change.models';

export type MoveOutMapping = CommonMappingData;

// tslint:disable-next-line:no-empty-interface
export type IMoveOutConfirmationStatusResponse = IBulletConfirmationStatusChangeResponse;
export interface MoveOutDataDTO {
  mappingData: MoveOutMapping[];
  productsWhichCanNotBeMovedOut?: { category: string; tariffClasses: string[]; accountNumber: string }[];
  moveOutDate: string;
  addressCode: string;
  addressGroupId: any;
  sepaMandat?: IBankDetails;
  eRechnungsInfo?: IERechnungsInfo;
  newInvoiceAddress?: IAddress;
  newInvoiceAddressOlavKey?: IOlavKey;
  orderedAt?: string;
  id?: number;
  processStatus?: string;
  mandatsReferenzMapping?: MandatsReferenzMappingItem[];
  differentInvoiceRecipient?: IDifferentInvoiceRecipient;
}

export const parseMoveOutDataItemDTO = (orderDetail): MoveOutDataDTO => {
  if (!orderDetail) {
    return null;
  }

  const orderData = JSON.parse(orderDetail.changeData);
  return {
    mappingData: orderData.mappingData,
    productsWhichCanNotBeMovedOut: orderData.productsWhichCanNotBeMovedOut,
    moveOutDate: orderData.moveOutDate,
    addressCode: orderData.addressCode,
    addressGroupId: orderData.addressGroupId,
    sepaMandat: orderData.sepaMandat,
    eRechnungsInfo: orderData.eRechnungsInfo,
    differentInvoiceRecipient: orderData.differentInvoiceRecipient,
    orderedAt: orderDetail.createdAt,
    id: orderDetail.id,
    processStatus: orderDetail.processStatus,
    mandatsReferenzMapping: orderDetail.responseData ? JSON.parse(orderDetail.responseData).response : [],
    newInvoiceAddress: orderData.newInvoiceAddress,
    newInvoiceAddressOlavKey: orderData.newInvoiceAddressOlavKey,
  };
};
