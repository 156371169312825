<div class="row align-items-center g-3" role="list" data-test="product-card-tariff-list">
  <ng-container *ngFor="let tariff of tariffClasses">
    <div class="col-auto" aria-hidden="true">
      <div class="rounded-pill bg-success d-flex align-items-center justify-content-center text-white" style="width: 2rem; height: 2rem">
        <i class="fa fa-check"></i>
      </div>
    </div>
    <div class="col text-capitalize" role="listitem" data-test="product-card-tariff-list-item">{{ tariff | lowercase }}</div>
    <div class="w-100 m-0"></div>
  </ng-container>
</div>
