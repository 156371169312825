import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnergyVoucherDTO, HTTPError, IEnergyVoucherStatusChangeResponse, parseEnergyVoucherDTO } from '@mwe/models';
import { AccountLogic } from '../../account/account.logic';
import { EnvironmentService } from '../../environment/environment.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VoucherRedeemService {
  private voucherResourceUrl: string;

  constructor(
    protected http: HttpClient,
    protected accountLogic: AccountLogic,
    protected environmentService: EnvironmentService,
  ) {
    this.voucherResourceUrl = this.environmentService.getApiUrl() + 'api/voucher/redeem/';
  }

  async confirm(addressCode: string, dataDTO: EnergyVoucherDTO): Promise<IEnergyVoucherStatusChangeResponse> {
    try {
      return await lastValueFrom(
        this.http.post<IEnergyVoucherStatusChangeResponse>(
          this.getVoucherResourceUrl() + 'confirm' + '?addressCode=' + addressCode,
          dataDTO,
        ),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  async retry(submitId: number, dataDTO: EnergyVoucherDTO): Promise<IEnergyVoucherStatusChangeResponse> {
    try {
      return await lastValueFrom(
        this.http.put<IEnergyVoucherStatusChangeResponse>(this.getVoucherResourceUrl() + 'retry' + '?submitId=' + submitId, dataDTO),
      );
    } catch (err) {
      throw new HTTPError(err.status, err.error);
    }
  }

  confirmStatus(submitId: number): Promise<IEnergyVoucherStatusChangeResponse> {
    return lastValueFrom(
      this.http.get<IEnergyVoucherStatusChangeResponse>(this.getVoucherResourceUrl() + 'confirmStatus' + '?submitId=' + submitId),
    );
  }

  getOrderStatusDetails(orderId: string): Promise<EnergyVoucherDTO> {
    return lastValueFrom(this.http.get(this.getVoucherResourceUrl() + 'orderStatusDetails/' + orderId)).then(res => {
      return parseEnergyVoucherDTO(res);
    });
  }

  private getVoucherResourceUrl(): string {
    return this.voucherResourceUrl;
  }
}
