import { Directive, Input, OnInit } from '@angular/core';
import { isProductCategoryGas, isProductCategoryNachtstrom, isProductCategoryStrom, isProductCategoryStromRueck } from '@mwe/utils';
import { DefaultImageDirective } from './default-image.directive';

@Directive({
  selector: '[mweDefaultTariffImage]',
})
export class DefaultTariffImageDirective extends DefaultImageDirective implements OnInit {
  @Input() mweDefaultTariffImage;

  ngOnInit(): void {
    let defaultImage = '';
    const sparte = this.mweDefaultTariffImage;

    if (isProductCategoryStrom(sparte)) {
      defaultImage = 'SOPTP_0002';
    }

    if (isProductCategoryGas(sparte)) {
      defaultImage = 'GEOPTP__01';
    }

    if (isProductCategoryStromRueck(sparte)) {
      defaultImage = 'SEINS_SON1';
    }

    if (isProductCategoryNachtstrom(sparte)) {
      defaultImage = 'SOPTPG_001';
    }

    this.mweDefaultImage = `/assets/icons/tariffs/${defaultImage}.svg`;
  }
}
