import { Injectable, inject } from '@angular/core';
import { KeycloakService, KeycloakEventType } from 'keycloak-angular';
/* eslint-disable */
@Injectable({
  providedIn: 'root',
})
export class KeycloakEventLoggerService {
  private keycloakService = inject(KeycloakService);

  constructor() {
    console.log('KeycloakEventLoggerService initialized');
    this.subscribeToKeycloakEvents();
  }

  private subscribeToKeycloakEvents() {
    this.keycloakService.keycloakEvents$.subscribe({
      next: event => {
        switch (event.type) {
          case KeycloakEventType.OnAuthSuccess:
            console.log('Authentifizierung erfolgreich');
            break;
          case KeycloakEventType.OnAuthError:
            console.log('Authentifizierungsfehler');
            this.keycloakService.login();
            break;
          case KeycloakEventType.OnAuthRefreshSuccess:
            console.log('Token-Aktualisierung erfolgreich');
            break;
          case KeycloakEventType.OnAuthRefreshError:
            console.log('Token-Aktualisierungsfehler');
            this.keycloakService.login();
            break;
          case KeycloakEventType.OnAuthLogout:
            console.log('Benutzer ausgeloggt');
            break;
          case KeycloakEventType.OnTokenExpired:
            console.log('Token abgelaufen');
            break;
          case KeycloakEventType.OnReady:
            console.log('Keycloak bereit');
            break;
        }
        console.log('Keycloak-Ereignis:', event);
      },
    });
  }
}
