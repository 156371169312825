import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UiComponentsModule } from '@mwe/ui/components';
import { UiPipesModule } from '@mwe/ui/pipes';
import { NgModule } from '@angular/core';
import { BaseFrontpageComponent } from './base-frontpage.component';

const modules = [CommonModule, RouterModule, TranslateModule, UiComponentsModule, UiPipesModule];

@NgModule({
  imports: modules,
  declarations: [BaseFrontpageComponent],
  exports: [BaseFrontpageComponent, ...modules],
})
export class FeatureFrontpageModule {}
