import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[mweClickPreventDefault]',
})
export class ClickPreventDefaultDirective {
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
