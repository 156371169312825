import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountLogic } from '@mwe/services';
import { Observable } from 'rxjs';

@Injectable()
export class SessionIdInterceptor implements HttpInterceptor {
  constructor(private accountLogic: AccountLogic) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url;
    const sessionId = this.accountLogic.getUUID();
    if (matchUrlCondition(sessionId, url)) {
      const modifiedReq = req.clone({
        headers: req.headers.set('sessionId', sessionId),
      });
      return next.handle(modifiedReq);
    }
    return next.handle(req);
  }
}

const matchUrlCondition = (sessionId: string, url: string): boolean => !!sessionId && !url.includes('auth/realms/logwien');
