export interface IBillinfoDetail {
  detailType: string; // BLART
  amount: string; // AMOUNT Betrag
  description: string; // TEXT Belegtext
  bookingDate: Date; // BUDAT Buchungsdatum
  dueDate: Date; // FAEDN Fälligkeitsdatum
  cdFlag: string; // creditDebitFlag
}

export class BillinfoDetail implements IBillinfoDetail {
  constructor(
    public detailType: string,
    public amount: string,
    public description: string,
    public bookingDate: Date,
    public dueDate: Date,
    public cdFlag: string
  ) {
    this.detailType = detailType;
    this.amount = amount;
    this.description = description;
    this.bookingDate = bookingDate;
    this.dueDate = dueDate;
    this.cdFlag = cdFlag;
  }
}

export interface IProductBillinfo {
  accountNumber: string; // vertragsKontoNummer
  category: string; // sparte
  amount: string;
  amountKind: string;
  groupLevel: string;
  currency: string;
  cdFlag: string;
  details: BillinfoDetail[];
  getCategoryKey: () => string;
}

export class ProductBillinfo implements IProductBillinfo {
  constructor(
    public accountNumber: string,
    public category: string,
    public amount: string,
    public amountKind: string,
    public groupLevel: string,
    public currency: string,
    public cdFlag: string,
    public details: BillinfoDetail[]
  ) {
    this.accountNumber = accountNumber;
    this.category = category;
    this.amount = amount;
    this.amountKind = amountKind;
    this.groupLevel = groupLevel;
    this.currency = currency;
    this.cdFlag = cdFlag;
    this.details = details;
  }

  // TODO re-factoring candidate
  getCategoryKey(): string {
    return 'products.category.' + this.category.toLowerCase();
  }
}

export interface IBillinfoSum {
  accountNumber: string; // vertragsKontoNummer
  category: string; // sparte
  amount: string; // AMOUNT Betrag
  amountKind: string;
  cdFlag: string; // creditDebitFlag
}

export class BillinfoSum implements IBillinfoSum {
  constructor(
    public accountNumber: string,
    public category: string,
    public amount: string,
    public amountKind: string,
    public cdFlag: string
  ) {
    this.accountNumber = accountNumber;
    this.category = category;
    this.amount = amount;
    this.amountKind = amountKind;
    this.cdFlag = cdFlag;
  }

  // TODO re-factoring candidate
  getCategoryKey(): string {
    return 'products.category.' + this.category.toLowerCase();
  }
}

export interface IProductBillinfoOverview {
  details: Map<string, BillinfoSum>;
}

export class ProductBillinfoOverview implements IProductBillinfoOverview {
  constructor(public details: Map<string, BillinfoSum>) {
    this.details = details;
  }

  getAmountFor(amountKind: string, defaultValue: string): string {
    return this.details.has(amountKind) ? this.details.get(amountKind).amount : defaultValue;
  }

  getCreditDebitFor(amountKind: string): string {
    return this.details.has(amountKind) ? this.details.get(amountKind).cdFlag : '';
  }
}

//TODO move functional processing to utils
export const parseProductBillinfoOverview = (json?: any): ProductBillinfoOverview => {
  const details: Map<string, BillinfoSum> = new Map();
  if (json.kostenUebersicht && json.kostenUebersicht.length > 0) {
    for (let i = 0; i < json.kostenUebersicht.length; i++) {
      const sum: BillinfoSum = new BillinfoSum(
        json.kostenUebersicht[i].vertragskontoId,
        json.kostenUebersicht[i].sparte,
        json.kostenUebersicht[i].amount,
        json.kostenUebersicht[i].amountKind,
        json.kostenUebersicht[i].creditDebit
      );

      details.set(sum.amountKind, sum);
    }
  }
  return new ProductBillinfoOverview(details);
};

//TODO move functional processing to utils
export const parseProductBillinfo = (json?: any): ProductBillinfo => {
  let pbi: ProductBillinfo = null;
  if (json.kostenDetails && json.kostenDetails.length > 0) {
    const biDetails: BillinfoDetail[] = [];
    for (let i = 0; i < json.kostenDetails.length; i++) {
      if (json.kostenDetails[i].amountKind === '04') {
        if (json.kostenDetails[i].groupLevel === '01' || json.kostenDetails[i].groupLevel === '02') {
          pbi = new ProductBillinfo(
            json.kostenDetails[i].vertragskontoId,
            json.kostenDetails[i].sparte,
            json.kostenDetails[i].amount,
            json.kostenDetails[i].amountKind,
            json.kostenDetails[i].groupLevel,
            json.kostenDetails[i].currency,
            json.kostenDetails[i].creditDebit,
            biDetails
          );
        }
        for (let j = 0; j < json.kostenDetails[i].itemDetails.length; j++) {
          biDetails.push(
            new BillinfoDetail(
              json.kostenDetails[i].itemDetails[j].blart,
              json.kostenDetails[i].itemDetails[j].amount,
              json.kostenDetails[i].itemDetails[j].text,
              new Date(json.kostenDetails[i].itemDetails[j].budat),
              new Date(json.kostenDetails[i].itemDetails[j].faedn),
              json.kostenDetails[i].itemDetails[j].creditDebitFlag
            )
          );
        }
      }
    }
  }
  return pbi;
};
