<div class="card-basic tarif-selection" data-test="product-card">
  <div class="card-basic-body">
    <div class="text-center" data-test="product-category">
      <mwe-product-icon [category]="category" size="lg" textNodeType="h2"></mwe-product-icon>
    </div>
  </div>
  <div class="card-basic-body pt-0" *ngIf="!isTariffSelectionWaermeVisible" [ngClass]="{ 'pb-3': isTariffSelectionStromGasVisible }">
    <div class="mb-3">
      <h3 class="fw-bold mb-1 text--big text-secondary" translate="tariff.details.tariff"></h3>
      <mwe-form
        id="mwe-tariff-selection-form"
        [formModel]="formModel"
        (formIsValid)="handleFormValidation(true, $event)"
        (formIsInvalid)="handleFormValidation(false)"
      >
      </mwe-form>
      <div class="mb-n3"></div>
    </div>
  </div>
  <div class="card-basic-body pt-0" *ngIf="areTariffIndicationsUpdating">
    <mwe-loading-spinner></mwe-loading-spinner>
  </div>
  <div class="card-basic-body text--big pt-0" *ngIf="isTariffSelectionStromGasVisible" [ngClass]="{ 'mb-0': tarifIndication?.rechtsText }">
    <div class="mb-3" *ngIf="tarifIndication">
      <h3 class="fw-bold mb-1 text--big text-secondary" translate="tariff.details.prices"></h3>

      <div role="list">
        <div role="listitem" class="row mt-1" *ngIf="tarifIndication?.grundpreis">
          <div class="col-6 hyphenate" [translate]="basePriceKey"></div>
          <div
            class="col col-sm"
            [attr.data-test]="getDataTestValue('basePrice')"
            [translate]="grundpreisLabel"
            [translateParams]="{ value: tarifIndication?.grundpreis, pricePeriod: tarifIndication?.grundpreisUnit }"
          ></div>
        </div>

        <div role="listitem" class="row mt-1" *ngIf="tarifIndication?.arbeitspreis">
          <div class="col-6 hyphenate" translate="tariff.details.consumptionPrice"></div>
          <div
            class="col col-sm"
            [attr.data-test]="getDataTestValue('consumptionPrice')"
            translate="tariff.details.arbeitspreis"
            [translateParams]="{ value: tarifIndication?.arbeitspreis, pricePeriod: tarifIndication?.arbeitspreisUnit }"
          ></div>
        </div>

        <div role="listitem" class="row mt-1" *ngIf="tarifIndication?.anschlusspreis">
          <div class="col-6 hyphenate" [translate]="installationPriceKey"></div>
          <div
            class="col col-sm"
            [attr.data-test]="getDataTestValue('installionPricePrice')"
            translate="tariff.details.anschlusspreis"
            [translateParams]="{ value: tarifIndication?.anschlusspreis, pricePeriod: tarifIndication?.anschlusspreisUnit }"
          ></div>
        </div>

        <div role="listitem" class="row mt-1" *ngFor="let info of tarifIndication?.preisInfos; index as i">
          <div class="col-6 hyphenate" [attr.data-test]="getDataTestValue('preis-info-label-' + i)">{{ info.text }}</div>
          <div class="col col-sm" [attr.data-test]="getDataTestValue('preis-info-amount-' + i)">{{ info.preis }}</div>
        </div>

        <div class="row mt-3 align-items-center" *ngIf="isCategoryInternet && tarifIndication.vorteile[0]">
          <div class="col-2 hyphenate">
            <i class="fas fa-check-circle font-size-18 green"></i>
          </div>
          <div class="col col-sm pl-0">
            <span>{{ tarifIndication.vorteile[0] }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3" role="table" *ngIf="selectedTariffOptions?.length === 0 && tarifBinding?.kurzWert">
      <h3 class="fw-bold mb-1 text--big text-secondary mt-4" translate="tariff.details.options"></h3>
      <div role="listitem" class="row">
        <div class="col-6 hyphenate" translate="tariff.details.binding"></div>
        <div class="col col-sm" [attr.data-test]="getDataTestValue('bindung-from-preis-bestandteil')">
          {{ tarifBinding?.kurzWert }}
        </div>
      </div>
    </div>

    <div class="mb-3" *ngIf="selectedTariffOptions?.length > 0">
      <div class="row mb-1 flex-nowrap justify-content-between">
        <h3 class="fw-bold mb-0 text--big text-secondary col" translate="tariff.details.options"></h3>
        <p class="mb-0 col">
          <mwe-button-link
            [dataTest]="tariffOptionsOpenPopupSelector"
            label="tariff.optionsSelectionForm.edit"
            customCss="font-size-0_8rem p-0 d-flex"
            (clicked)="openTariffOptionsForm()"
          ></mwe-button-link>
        </p>
      </div>
      <div role="list">
        <div role="listitem" class="row mt-1" *ngFor="let option of selectedTariffOptions">
          <div class="col-6 hyphenate">
            <mwe-info-text [infoTextCode]="option | tariffOptionInfoTextCode: category">{{ option.art }}</mwe-info-text>
          </div>
          <div class="col col-sm" [innerHTML]="getLabelFrom(option)" [attr.data-test]="getDataTestValue(option.option)"></div>
        </div>
      </div>
    </div>

    <mwe-alert
      class="d-block my-4"
      type="success"
      messageKey="tariff.isDiscounted.text"
      *ngIf="tarifIndication?.istRabattiert"
      [isMinimalLayout]="true"
      [infoText]="{ description: discountInfoPopupText, title: 'tariff.isDiscounted.infoIcon.title' }"
    ></mwe-alert>

    <mwe-alert
      *ngIf="tarifIndication?.rabattiert"
      class="d-block my-4"
      type="success"
      [messageKey]="tarifIndication.rabattiert.info"
      [isMinimalLayout]="true"
      [infoText]="{ description: tarifIndication.rabattiert.text, title: tarifIndication.rabattiert.title }"
    ></mwe-alert>

    <div class="mb-3" *ngIf="boni">
      <h3 translate="newClient.productsSelection.advantages" class="text-secondary fw-bold mb-1"></h3>
      <div class="row">
        <div class="col-12">
          <img src="/assets/icons/green-bubble-checked.svg" alt="Green checked icon" />
          <p class="mb-0 pt-1">
            <mwe-info-text [infoTextTitle]="boni.heading" [infoTextDescription]="boni.text">
              <span translate="{{ boni.heading }}"></span>
            </mwe-info-text>
          </p>
        </div>
      </div>
    </div>

    <h3 class="text-secondary text--big fw-bold mb-0">{{ labelMoreInformation | translate }}</h3>
    <div class="row mb-3" *ngIf="productSelection">
      <div class="col-12">
        <mwe-button-link
          customCss="font-size-0_8rem p-0"
          dataTest="tariff-selection-card-tariff-link"
          [url]="tariffLinkOverwrite || getPdfDocumentsSafeUrl() + productSelection.tarif.ISUTarifKey"
          [label]="getTariffLinkLabel()"
        ></mwe-button-link>
      </div>
    </div>
    <div class="row mb-3" *ngIf="allesSicherOption">
      <div class="col-12">
        <mwe-button-link
          customCss="font-size-0_8rem p-0"
          [label]="tariffOptionAllesSicherTariffLinkLabel"
          [url]="tariffOptionAllesSicherTariffLink"
        ></mwe-button-link>
      </div>
    </div>
    <div class="mb-n3"></div>
  </div>
  <div class="card-basic-body text--big pt-0" *ngIf="isTariffSelectionWaermeVisible">
    <div class="product-card-info product-card-fernwaerme" *ngIf="tariffClasses">
      <h3 class="fw-bold mb-1 text--big text-secondary">Tarif</h3>
      <p
        class="f-16 text-body fw-normal"
        translate="newClient.productsSelection.noTariffSelection"
        data-test="new-client-tariff-selection-fernwaerme"
        [translateParams]="{ category: category | translateCategory | async }"
      ></p>
      <h3 class="fw-bold mb-2 text--big text-secondary">An dieser Adresse verfügbar</h3>
      <div class="row align-items-center g-3" role="list" data-test="tariff-card-class-list">
        <ng-container *ngFor="let tariff of tariffClasses">
          <div class="col-auto" aria-hidden="true">
            <div
              class="rounded-pill bg-success d-flex align-items-center justify-content-center text-white"
              style="width: 2rem; height: 2rem"
              aria-hidden="true"
            >
              <i class="fa fa-check"></i>
            </div>
          </div>
          <div class="col text-capitalize" role="listitem">{{ tariff.lieferkomponente | lowercase }}</div>
          <div class="w-100 m-0"></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<mwe-expander
  *ngIf="tarifIndication?.rechtsText"
  [textKey]="tarifIndication.rechtsText"
  class="d-block mt-3 mb-5"
  textCss="text--legal"
></mwe-expander>
