import { Component, Input, OnInit } from '@angular/core';
import { Sparte, TextNodeType } from '@mwe/models';

@Component({
  selector: 'mwe-product-icon',
  templateUrl: './product-icon.component.html',
})
export class ProductIconComponent implements OnInit {
  @Input() category: Sparte | string;
  @Input() size: 'sm' | 'md' | 'lg';
  @Input() textNodeType: TextNodeType = 'h6';
  @Input() isDisabled: boolean = false;
  @Input() hideCategoryTextOnDesktop: boolean = false;
  maxWidth: string;
  fontSize: string;
  heading: string;
  headingText: string;

  constructor() {}

  ngOnInit(): void {
    if (this.size === 'sm') {
      this.maxWidth = '32px';
      this.fontSize = '12px';
    } else if (this.size === 'md') {
      this.maxWidth = '50px';
      this.fontSize = '18px';
    } else if (this.size === 'lg') {
      this.maxWidth = '76px';
    }
  }
}
