import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ChatBotService {
  private _events: Subject<any>;
  private _hasScriptAlreadyBeenLoaded = false;

  get hasScriptAlreadyBeenLoaded(): boolean {
    return this._hasScriptAlreadyBeenLoaded;
  }

  set hasScriptAlreadyBeenLoaded(value: boolean) {
    this._hasScriptAlreadyBeenLoaded = value;
  }

  constructor() {
    this._events = new Subject();
  }

  eventAsSubject() {
    return this._events;
  }

  events(): Observable<any> {
    return this._events.asObservable();
  }
}
