import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mwe-dsgvo-slider',
  templateUrl: './dsgvo-slider.component.html',
  styles: [],
})
export class DsgvoSliderComponent {
  @Input() text: string;
  @Input() value: string;
  @Input() reihe: number;
  @Input() sliderId: number;
  @Input() disabled = false;

  @Output() returnValueEmitter = new EventEmitter<any>();

  returnValues(): void {
    this.returnValueEmitter.emit({
      reihe: this.reihe,
      value: this.value,
    });
  }
}
