import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductsSummaryComponent } from './products-summary.component';
import { NewClientLogic } from '@mwe/services';
import { HeadlineService } from '../../headline/headline.service';
import { TextNodeType } from '@mwe/models';

@Component({
  selector: 'mwe-products-summary-redesign',
  templateUrl: './products-summary-redesign.component.html',
  providers: [HeadlineService],
})
export class ProductsSummaryRedesignComponent extends ProductsSummaryComponent implements OnChanges {
  @Input() isEligableForSepaBenefits?: boolean = false;
  @Input() sepaBenefitsTitleKey?: string;
  @Input() sepaBenefitsTextKey?: string;
  @Input() meterReadingTitleKey?: string = 'summary.meterReading.title';
  @Input() meterReadingTextKey?: string = 'summary.meterReading.description';
  @Input() showFormCardInfo?: boolean = true;
  @Input() additionalOptionGroupName: string;
  @Input() headlineReference: TextNodeType = 'h2';

  showBenefitsCard: boolean = false;

  constructor(
    private newClientLogic: NewClientLogic,
    public headlineService: HeadlineService,
  ) {
    super();
    this.headlineService.setReferenceHeadline(this.headlineReference);
  }

  get showAdditionalInfo(): boolean {
    if (this.newClientLogic.isSuperschnellProcess() || (!this.stromOrGasProducts?.length && this.fernwaermeProducts?.length)) {
      return false;
    }
    return this.showMeterReading || !!this.mgmCode || this.showFormCardInfo;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.headlineService.setReferenceHeadline(this.headlineReference);
    this.showBenefitsCard =
      (!!this.voucherInfo || this.isEligableForSepaBenefits) &&
      this.stromOrGasProducts?.length > 0 &&
      !this.newClientLogic.isSuperschnellProcess();
  }

  ngOnChanges(changes: SimpleChanges) {
    const isEligableForSepaBenefits = changes?.['isEligableForSepaBenefits']?.currentValue;
    if (isEligableForSepaBenefits) {
      this.showBenefitsCard =
        (!!this.voucherInfo || this.isEligableForSepaBenefits) &&
        this.stromOrGasProducts?.length > 0 &&
        !this.newClientLogic.isSuperschnellProcess();
    }
  }
}
