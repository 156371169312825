import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

async function checkLogin() {
  const keycloak = inject(KeycloakService);
  const loggedIn = keycloak.isLoggedIn();
  if (!loggedIn) {
    await keycloak.login();
    return false;
  }
  return true;
}

export const authGuard: CanActivateFn = checkLogin;
