import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Account, LoadUserProfileResponse } from '@mwe/models';
import { AccountStateService } from './state/account-state.service';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AccountLogic {
  constructor(
    private authService: AuthService,
    private accountStateService: AccountStateService,
  ) {}

  getAccount(): Account {
    return this.accountStateService.account;
  }

  getAccountEmail(): string {
    return this.accountStateService.account?.email;
  }

  getAccountFullName(): string {
    return this.accountStateService.account?.firstName + ' ' + this.accountStateService.account?.lastName;
  }

  getUUID(): string {
    if (!this.accountStateService.uuid) {
      this.accountStateService.uuid = this.createUuidv4();
    }
    if (this.isImpersonate() && !this.accountStateService.uuid.includes('-collaborate')) {
      this.accountStateService.uuid = this.accountStateService.uuid + '-collaborate';
    }
    return this.accountStateService.uuid;
  }

  createUuidv4(): string {
    return uuidv4();
  }

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  checkAuthentication(): void {
    this.setupAccount();
  }

  setupAccount(): void {
    // retrieve the userIdentity data from the JWT.
    const identity = this.authService.getIdentity();
    this.accountStateService.account = identity
      ? {
          id: identity.sub,
          email: identity.preferred_username,
          firstName: identity.given_name,
          lastName: identity.family_name,
          impersonator: identity.impersonator?.username,
          salutation: identity.salutation,
        }
      : null;
  }

  reloadIdentity(): void {
    this.authService.reloadUserProfile().then(userInfo => {
      const identity = (userInfo as LoadUserProfileResponse).info;

      this.accountStateService.account = {
        id: identity.sub,
        email: identity.preferred_username,
        firstName: identity.given_name,
        lastName: identity.family_name,
        salutation: identity.salutation,
      };
    });
  }

  isImpersonate(): boolean {
    return this.accountStateService.isImpersonate();
  }
}
