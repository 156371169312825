<div class="form-floating-icon">
  <ng-container *ngIf="state === 'submit'">
    <mwe-button-link
      [isDisabled]="disabled"
      color="blue"
      [dataTest]="'form-input-' + inputElem.name + '-submit'"
      [label]="submitLabel"
      (clicked)="submit()"
    ></mwe-button-link>
  </ng-container>
  <div *ngIf="state === 'loading'" class="loading-spinner"></div>
</div>
