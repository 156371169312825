<div id="form-buttons">
  <mwe-button
    class="chatbot-hide-btn"
    *ngIf="!isChatbotVisible"
    buttonId="mwe-hide-livechat-button"
    [showHoverEffect]="true"
    labelKey="find-payment-solution.customer-support.liveChatCard.showLiveChatAgain"
    color="blue"
    (click)="showChatbot()"
  ></mwe-button>
</div>
<div id="mwe-chatbot">
  <div class="p-0 text-start">
    <div id="onlim-chat-widget-lite-anchor" class="chatbot mb-3 text-center">
      <div class="chatbot-header d-flex justify-content-between">
        <label class="ml-3">Live-Chat</label>
        <div>
          <i class="far fa-window-minimize pr-4" (click)="hideChatbot()"></i>
          <i class="far fa-times" (click)="shutdownChatbot()"></i>
        </div>
      </div>
    </div>
  </div>
</div>
