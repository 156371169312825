import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[mweScrollToTargetOnClick]',
})
export class ScrollToTargetOnClickDirective {
  @Input() mweScrollToTargetOnClick: string;

  @HostListener('click')
  @HostListener('touchstart')
  onClick(): void {
    if (!this.mweScrollToTargetOnClick) {
      return;
    }

    document.querySelector(this.mweScrollToTargetOnClick)?.scrollIntoView();
  }
}
