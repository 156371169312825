<ng-container *ngIf="displayMode === 'list'">
  <div *ngFor="let label of labels" class="d-flex align-items-center mb-2">
    <div class="green-check vertical-center mr-2">
      <i class="fas fa-check text-center fw-normal" aria-hidden="true"></i>
    </div>
    <span [innerHTML]="label | translate: translateParams | safeHtml" data-test="green-check-list-item"></span>
  </div>
</ng-container>
<ng-container *ngIf="displayMode === 'grid'">
  <div class="row row-cols-md-2">
    <div *ngFor="let label of labels" class="mb-2">
      <div class="green-check vertical-center mr-2">
        <i class="fas fa-check text-center fw-normal" aria-hidden="true"></i>
      </div>
      <span [translate]="label" [translateParams]="translateParams" data-test="green-check-list-item"></span>
    </div>
  </div>
</ng-container>
