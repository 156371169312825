import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CheckboxExpanderForm, CheckboxExpanderFormItem } from '@mwe/models';
import { everyTrue } from '@mwe/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mwe-checkbox-expander-form',
  templateUrl: './checkbox-expander-form.component.html',
})
export class CheckboxExpanderFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('collapsible') collapsibles: QueryList<ElementRef>;
  @Input() model: CheckboxExpanderForm;
  @Input() removePadding: boolean = false;
  @Output() isValid = new EventEmitter<boolean>();

  form: UntypedFormGroup;
  initialShowMore = new Map<string, boolean>();

  private subscriptions = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    this.ref.detectChanges();
    // this is to undo any change in scroll position by tab-focusing inside while collapsed, see MWE-12458
    [...this.collapsibles].forEach(el => {
      el.nativeElement.addEventListener('focusout', this.onFocusout.bind(this));
    });
  }

  ngOnInit(): void {
    this.initForm();
  }
  onFocusout(e): void {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      [...this.collapsibles].forEach(el => (el.nativeElement.scrollTop = 0));
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    [...this.collapsibles].forEach(el => el.nativeElement.removeEventListener('focusout', this.onFocusout));
  }

  initForm(): void {
    const formGroup = {};
    this.model.items.forEach(elem => {
      this.initialShowMore.set(elem.name, elem.showMore);
      const formGroupArr = [elem.initialValue] as any;
      if (elem.required === true) {
        formGroupArr.push(Validators.requiredTrue);
      }
      formGroup[elem.name] = formGroupArr;
    });
    this.form = this.formBuilder.group(formGroup);
    this.isValid.emit(this.model.items.every(item => item.initialValue === true));

    const formSubscription = this.form.valueChanges.subscribe(() => {
      this.isValid.emit(this.form.valid);
    });
    this.subscriptions.add(formSubscription);
  }

  toggleReadMore(itemName: string): void {
    const item = this.model.items.find(elem => elem.name === itemName);
    item.open = !item.open;
    [...this.collapsibles].forEach(el => (el.nativeElement.scrollTop = 0));
  }

  fireValidation(): void {
    Object.keys(this.form.controls).forEach(controlName => this.form.controls[controlName].markAsDirty());
    this.ref.detectChanges();
    if (this.elementRef.nativeElement.querySelector('form.form-error span')) {
      this.elementRef.nativeElement.querySelector('form.form-error span').scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
    this.isValid.emit(this.form.valid);
  }

  @HostListener('window:resize', ['$event'])
  checkAllClamps(event: unknown) {
    if (event) {
      this.model.items.forEach(item => {
        if (this.initialShowMore.get(item.name)) {
          const classList = document.getElementById('description-' + item.name).classList;
          let clampAdded = false;
          if (!classList.contains('clamp')) {
            // add clamp class to correctly correctly if text is too long
            classList.add('clamp');
            clampAdded = true;
          }
          const isTooLong = this.isLineClampNeeded(item);
          if (item.open && clampAdded) {
            // remove clamp because text is fully shown
            classList.remove('clamp');
          }
          item.showMore = isTooLong;
        }
      });
    }
  }

  isLineClampNeeded(item: CheckboxExpanderFormItem): boolean {
    if (this.initialShowMore.get(item.name)) {
      const p = document.getElementById('description-' + item.name);
      const isTooLong = p.scrollHeight > p.clientHeight;
      if (everyTrue(!isTooLong, item.showMore, p.classList.contains('clamp'))) {
        // handle situation when page is first time rendered
        item.showMore = false;
      }
      return isTooLong;
    }
    return false;
  }

  isErrorVisible(inputName: string): boolean {
    return this.form?.controls?.[inputName]?.errors && this.form?.controls?.[inputName].dirty;
  }
}
