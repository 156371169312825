import { Component, Host, Input, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControlDirective, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFormInput } from '@mwe/models';
import { FormComponent } from '../../form.component';

@Component({
  selector: 'mwe-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxInputComponent,
      multi: true,
    },
  ],
})
export class CheckboxInputComponent implements ControlValueAccessor {
  @Input() parentForm: UntypedFormGroup;
  @Input() inputElem: IFormInput;
  @Input() isLoading = false;

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  constructor(@Optional() @Host() private formComponent?: FormComponent) {}

  get isValid(): any {
    return this.formControlDirective?.control?.valid;
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  get ariaDescribedBy(): string | undefined {
    if (this.formComponent) {
      // Error State hat Vorrang für Screenreader
      if (this.formComponent.isInputErrorVisible(this.inputElem.componentType)) {
        return `form-input-error--${this.inputElem.name}`;
      } else if (this.formComponent.isInputHelpInfoVisible(this.inputElem)) {
        return `form-input-info--${this.inputElem.name}`;
      }
    }
    return undefined;
  }
}
