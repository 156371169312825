import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IMG_PATH, BannerData, BannerPopupData } from '@mwe/models';
import { EnvironmentService } from '@mwe/services';

@Component({
  selector: 'mwe-banner-popup',
  templateUrl: './banner-popup.component.html',
})
export class BannerPopupComponent implements OnInit {
  loadedData: BannerData;
  popupData: BannerPopupData;
  imageUrl: string;

  constructor(
    private activeModal: NgbActiveModal,
    private environmentService: EnvironmentService,
  ) {}

  ngOnInit(): void {
    this.popupData = this.loadedData?.popup;
    if (this.popupData?.image) {
      this.imageUrl = this.environmentService.staticContent() + IMG_PATH + this.popupData?.image;
    }
  }

  submit(): void {
    this.activeModal.close('OK');
  }

  cancel(): void {
    this.activeModal.close('CANCEL');
  }
}
