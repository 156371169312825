import { Injectable } from '@angular/core';
import countryList from './country.list.json';
import { Country, IFormInputGroup, IFormSelect, PhoneNumberFormInputGroupConfig } from '@mwe/models';
import { Validators } from '@angular/forms';
import { getNumberOnlyValidator } from '../common/form.utils';
import { bothOrNothingRequired } from '../personal-details/personal-details-form.utils';
import { removeAllSpaces } from '../common/string.utils';

@Injectable({ providedIn: 'root' })
export class CountryLogic {
  getDialingCodeValues(): IFormSelect[] {
    return this.getAllCountries().map(country => this.mapDialingCode(country));
  }

  getDialingCodeInitialSuggestions(): IFormSelect[] {
    return this.getCountriesForInitialSuggestions().map(country => this.mapDialingCode(country));
  }

  getCountryCodeValues(): IFormSelect[] {
    return countryList.map(country => this.mapCountryCode(country));
  }

  getCountryCodeInitialSuggestions(): IFormSelect[] {
    return this.getCountriesForInitialSuggestions().map(country => this.mapCountryCode(country));
  }

  getPhoneFormInputGroup(config: PhoneNumberFormInputGroupConfig): IFormInputGroup {
    const countryCodeName = `${config.namePrefix}-telefon-countrycode`;
    const phoneNumberName = `${config.namePrefix}-telefonnumber`;
    const labelPostfix = config.isRequired ? 'label' : 'labelNotRequired';
    const placeholderPostfix = config.isRequired ? 'placeholder' : 'placeholderNotRequired';

    const group: IFormInputGroup = {
      name: `${config.namePrefix}-telefonnumber-container`,
      inputs: [
        {
          name: countryCodeName,
          initialValue: config.countryCode,
          validators: [],
          inputClasses: 'col-12 col-sm-4 pb-3 pb-sm-0',
          labelKey: `${config.labelPrefix}.telefonCountryCode.${labelPostfix}`,
          placeholder: `${config.labelPrefix}.telefonCountryCode.${placeholderPostfix}`,
          validationErrorLabelKey: `${config.labelPrefix}.telefonCountryCode.error`,
          validate: true,
          componentType: 'typeahead',
          options: this.getDialingCodeValues(),
          typeaheadOptions: {
            initialSuggestions: this.getDialingCodeInitialSuggestions(),
          },
          isDisabled: config.isDisabled,
        },
        {
          name: phoneNumberName,
          initialValue: removeAllSpaces(config.phoneNumber),
          validators: [getNumberOnlyValidator(), Validators.minLength(2)],
          inputClasses: 'col-12 col-sm-8',
          labelKey: `${config.labelPrefix}.telefonNumber.${labelPostfix}`,
          helpLabelKey: `${config.labelPrefix}.telefonNumber.helperText`,
          validationErrorLabelKey: `${config.labelPrefix}.telefonNumber.error`,
          validate: true,
          directiveType: 'integer; removeLeadingZero',
          dataType: 'text',
          isDisabled: config.isDisabled,
          updateOn: 'change',
        },
      ],
    };

    if (config.isRequired) {
      group.inputs[0].initialValue = config.countryCode || '43';
      group.inputs[0].validators.push(Validators.required);
      group.inputs[1].validators.push(Validators.required);
    } else {
      group.inputs[0].validators.push(bothOrNothingRequired(phoneNumberName));
      group.inputs[1].validators.push(bothOrNothingRequired(countryCodeName));
    }

    return group;
  }

  checkCountryCode(countryCode: string): string {
    if (!countryCode) {
      return '+43';
    }

    if (!countryCode.includes('+')) {
      return `+${countryCode}`;
    }

    return countryCode;
  }

  private getAllCountries(): Country[] {
    return countryList;
  }

  private getCountriesForInitialSuggestions(): Country[] {
    const initialSuggestions = [
      'Deutschland',
      'Italien',
      'Liechtenstein',
      'Österreich',
      'Schweiz',
      'Slowakei',
      'Slowenien',
      'Tschechische Republik',
      'Ungarn',
    ];

    return this.getAllCountries().filter(c => initialSuggestions.find(s => c.name === s));
  }

  private mapDialingCode(country: Country): IFormSelect {
    return {
      value: country.dialingNumber,
      text: `${country.name} ${country.dialingNumber}`,
    };
  }

  private mapCountryCode(country: Country): IFormSelect {
    return {
      value: country.countryCode,
      text: country.name,
    };
  }
}
