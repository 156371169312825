import { Component, Input, OnInit } from '@angular/core';
import { Address, TariffSelectionCardData } from '@mwe/models';
import { IProduct } from '@mwe/models';
import { IdCard } from '@mwe/models';
import { IERechnungsInfo, IAktionen, IProduktSelektion } from '@mwe/models';
import { AdditionalProductsSummaryPoint } from '@mwe/models';
import { IBusinessData } from '@mwe/models';
import { sortProductsByCategory } from '@mwe/utils';

@Component({
  selector: 'mwe-products-summary',
  templateUrl: './products-summary.component.html',
})
export class ProductsSummaryComponent implements OnInit {
  @Input() collapseId: string;
  @Input() address: Address;
  @Input() invoiceAddress: Address;
  @Input() initialState: 'expanded' | 'collapsed';
  @Input() stromOrGasProducts: IProduct[];
  @Input() fernwaermeProducts: IProduct[];
  @Input() registrationAt: string;
  @Input() registerStromGasTitle = 'summary.productsFromWEV';
  @Input() registerStromGasText: string;
  @Input() registerStromGasInfoMessage: string;
  @Input() registerFernwaermeTitle = 'summary.checkRegistrationForProducts';
  @Input() registerFernwaermeSubTitle = 'summary.checkRegistrationForProducts';
  @Input() registerFernwaermeText: string;
  @Input() registerFernwaermeTextPlaceholder = new Map<string, string>();
  @Input() birthDate: string;
  @Input() telefonNumber: string;
  @Input() idCard: IdCard;
  @Input() salutation: string;
  @Input() title: string;
  @Input() firstname: string;
  @Input() lastname: string;
  @Input() email: string;
  @Input() eRechnungInfo: IERechnungsInfo;
  @Input() paymentIcon: string;
  @Input() paymentText: string;
  @Input() voucherInfo: IAktionen;
  @Input() showMeterReading = true;
  @Input() invoiceInfoAlertMessage = '';
  @Input() isCollapsable = true;
  @Input() personalBillingTitle = 'summary.personalBilling';
  @Input() areBankAccountInfoTextsVisible = false;
  @Input() contactDataEmailAddress: string;
  @Input() showStromGasTarifDetails = true;
  @Input() additionalBulletPointsStromGas: AdditionalProductsSummaryPoint[];
  @Input() additionalBulletPointsFernwaerme: AdditionalProductsSummaryPoint[];
  @Input() showFernwaermeInvoiceData = false;
  @Input() isPaymentInfoVisible = true;
  @Input() showTariffDetails = true;
  @Input() showTariffDetailsLink = false;
  @Input() productSelection: IProduktSelektion;
  @Input() customerDataType: string;
  @Input() businessData: IBusinessData;
  @Input() grundpreisLabelOverwrite: string;
  @Input() mgmCode: string;
  @Input() isCollapseHeaderVisible = true;
  @Input() isKundeWerden = false;
  @Input() showWaermeTariffClassesAsideOfIcon = false;
  @Input() useNewWaermeCards = false;
  @Input() selectedTariffs: TariffSelectionCardData[] = [];
  @Input() infoTextCardLabel: string;

  voucherStrom: string;
  voucherGas: string;
  productCategories: string[];
  formattedAddress: string;

  ngOnInit(): void {
    this.setupVoucherMessages();
    this.setCategories();
    this.formattedAddress = this.invoiceAddress ? this.invoiceAddress.getGroupFormatted(true) : this.address.getGroupFormatted(true);
    if (this.stromOrGasProducts) {
      sortProductsByCategory(this.stromOrGasProducts);
    }
  }

  setupVoucherMessages(): void {
    if (this.voucherInfo?.infoTextKey?.length > 0) {
      if (this.voucherInfo.infoTextKey[0].toLowerCase().startsWith('strom')) {
        this.voucherStrom = this.voucherInfo.infoText[0];
        this.voucherGas = this.voucherInfo.infoText[1];
      } else {
        this.voucherStrom = this.voucherInfo.infoText[1];
        this.voucherGas = this.voucherInfo.infoText[0];
      }
    }
  }

  private setCategories(): void {
    let allProducts = [];

    if (Array.isArray(this.stromOrGasProducts)) {
      allProducts = allProducts.concat(this.stromOrGasProducts);
    }

    if (Array.isArray(this.fernwaermeProducts)) {
      allProducts = allProducts.concat(this.fernwaermeProducts);
    }

    this.productCategories = allProducts.map(product => product.category);
  }
}
