import { Component, Input, OnInit } from '@angular/core';
import { Product, TextNodeType, translateTariffClasses } from '@mwe/models';
import { TranslateService } from '@ngx-translate/core';
import { HeadlineService } from '../../headline/headline.service';

@Component({
  selector: 'mwe-tariff-card-waerme',
  templateUrl: './tariff-card-waerme.component.html',
  providers: [HeadlineService],
})
export class TariffCardWaermeComponent implements OnInit {
  @Input() product: Product;
  @Input() headlineReference: TextNodeType = 'h3';
  tariffClasses$: Promise<string[]>;

  constructor(
    private translateService: TranslateService,
    public headlineService: HeadlineService,
  ) {}

  ngOnInit() {
    this.headlineService.setReferenceHeadline(this.headlineReference);
    this.tariffClasses$ = translateTariffClasses(this.product.category, this.product.tariffClasses, this.translateService);
  }
}
