import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IFormModel } from '@mwe/models';
import { TranslateService } from '@ngx-translate/core';
import { FormComponent } from '../../form/form.component';

@Component({
  selector: 'mwe-checkbox-card',
  templateUrl: './checkbox-card.component.html',
})
export class CheckboxCardComponent implements OnInit {
  @ViewChild(FormComponent) formRef: FormComponent;
  @Input() title: string;
  @Input() text: string;
  @Input() initialValue: boolean;
  @Input() inputName = 'checkbox-card-cb';
  formModel: IFormModel;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.createFormModel();
  }

  isChecked(): boolean {
    return this.formRef?.form.get(this.inputName)?.value;
  }

  private createFormModel(): void {
    const translatedTitle = this.translateService.instant(this.title);
    const translatedText = this.translateService.instant(this.text);

    this.formModel = {
      inputs: [
        {
          name: this.inputName,
          initialValue: this.initialValue ? 'true' : '',
          placeholder: `<strong class="text-secondary">${translatedTitle}</strong><br>${translatedText}`,
          validators: [],
          componentType: 'checkbox',
        },
      ],
    };
  }
}
