import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CheckboxExpanderForm, FormInputErrorItem, IFormModel, ZusatzInformationen } from '@mwe/models';
import { FormComponent } from '../../form/form.component';
import { CheckboxExpanderFormComponent } from '../../checkbox/checkbox-expander-form.component';
import { createBirthDatePickerTimeRange } from '@mwe/utils';
import { IVoucherFormConfig } from '@mwe/services';

const DEFAULT_LABELS = {
  wlFieldsTitleKey: 'voucher.wlinien.title',
  wlFieldsDescriptionKey: 'voucher.wlinien.description',
  wlFieldsDescriptionDigitalKey: 'voucher.wlinien.descriptionDigital',
  wlFieldsErrorKey: 'voucher.wlinien.error',
  wlFieldsDataUseCommit: {
    titleKey: 'voucher.wlinien.dataUseCommit.title',
    labelKey: 'voucher.wlinien.dataUseCommit.label',
    errorKey: 'voucher.wlinien.dataUseCommit.error',
  },
  wlFieldsLastName: { labelKey: 'voucher.wlinien.lastName.label', errorKey: 'voucher.wlinien.lastName.error' },
  wlFieldsBirthday: { labelKey: 'voucher.wlinien.birthday.label', errorKey: 'voucher.wlinien.birthday.error' },
  wlFieldsCardNumber: {
    labelKey: 'voucher.wlinien.cardNumber.label',
    errorKey: 'voucher.wlinien.cardNumber.error',
    lengthErrorKey: 'voucher.wlinien.cardNumber.lengthError',
  },
};

@Component({
  selector: 'mwe-wl-voucher-form',
  templateUrl: './wl-voucher-form.component.html',
})
export class WlVoucherFormComponent implements OnInit {
  @ViewChild(FormComponent) formRef: FormComponent;
  @ViewChild(CheckboxExpanderFormComponent) approvalFormRef: CheckboxExpanderFormComponent;
  @Input() config: IVoucherFormConfig;
  @Input() prozesstyp = 'Kunde werden';
  @Input() loading = false;
  @Input() aktionsArt: '1' | '2';
  @Output() readonly formValid = new EventEmitter<boolean>();
  @Output() readonly checkPressed = new EventEmitter<boolean>();
  // @Output() readonly onSubmit = new EventEmitter<any>();

  birthdateControlName = 'mwe-voucher-birthdate';
  cardNumberControlName = 'mwe-voucher-cardNumber';
  birthdayElems: {
    name: string;
    labelKey?: string;
    controls: { control: UntypedFormControl; name: string; placeholderKey: string; errorKey: string }[];
  };
  formModel: IFormModel;
  approvalFormModel: CheckboxExpanderForm;
  isFormValid = false;
  isApprovalFormValid = false;
  formData: any;

  ngOnInit(): void {
    this.setWLDefaultLabels();
    this.initFormModel();
    this.initApprovalFormModel();
  }

  private initFormModel() {
    const birthdayTimeRange = createBirthDatePickerTimeRange();
    this.formModel = {
      inputs: [
        {
          name: this.birthdateControlName,
          labelKey: 'products-add.product-search.labels.birthday',
          initialValue: '',
          componentType: 'birthdate',
          validators: [Validators.required],
          validationErrorLabelKey: 'voucher.wlinien.birthday.error',
          validationErrorLabelParams: { ...birthdayTimeRange },
          validate: true,
          datepickerConfig: {
            minDate: birthdayTimeRange[0],
            maxDate: birthdayTimeRange[1],
            readonly: true,
            displayFormat: 'DD.MM.YYYY',
          },
        },
        {
          labelKey: this.config.wlFieldsCardNumber.labelKey,
          name: this.cardNumberControlName,
          initialValue: '',
          validators: [Validators.required, Validators.maxLength(10), Validators.pattern(/^[0-9]{10}$/)],
          // validationErrorLabelKey: this.config.wlFieldsCardNumber.errorKey,
          validationErrors: [
            {
              key: 'required',
              label: this.config.wlFieldsCardNumber.errorKey,
            },
            {
              key: 'pattern',
              label: this.config.wlFieldsCardNumber.lengthErrorKey,
            },
            {
              key: 'maxlength',
              label: this.config.wlFieldsCardNumber.lengthErrorKey,
            },
          ] as FormInputErrorItem[],
          inputClasses: 'col-12',
          validate: true,
          dataType: 'text',
          maxLength: 10,
          directiveType: 'integer',
          updateOn: 'blur',
        },
      ],
    };
  }

  private initApprovalFormModel() {
    this.approvalFormModel = {
      showLessKey: 'global.expander.showLess',
      showMoreKey: 'global.expander.showMore',
      items: [
        {
          name: 'mwe-voucher-useDataCommit',
          titleKey: this.config.wlFieldsDataUseCommit.titleKey,
          descriptionKey: this.config.wlFieldsDataUseCommit.labelKey,
          errorKey: this.config.wlFieldsDataUseCommit.errorKey,
          showMore: true,
          required: true,
        },
      ],
    };
  }

  handleFormValid(formData: any): void {
    this.formData = formData;
    this.formValid.emit((this.isFormValid = true) && this.isApprovalFormValid);
  }

  handleFormInvalid(formData: any): void {
    this.formData = formData;
    this.formValid.emit((this.isFormValid = false) && this.isApprovalFormValid);
  }

  approvalFormChange($event): void {
    this.formValid.emit(this.isFormValid && (this.isApprovalFormValid = $event));
  }

  private setWLDefaultLabels(): void {
    this.config = { ...DEFAULT_LABELS, ...this.config };
    if (this.config.processCode) {
      this.prozesstyp = this.config.processCode;
    }
  }

  fireValidation(): void {
    this.formRef.fireValidation();
    this.approvalFormRef.fireValidation();
  }

  async checkForm(): Promise<void> {
    this.fireValidation();
    if (!this.isFormValid || !this.isApprovalFormValid) {
      return;
    }
    this.checkPressed.emit(this.isFormValid);
  }

  createWLAdditionalValues(noAdditionalFields: boolean): ZusatzInformationen[] {
    const additionalFields: ZusatzInformationen[] = [];
    if (!noAdditionalFields) {
      additionalFields.push({
        feld: 'geburtsdatum',
        wert: this.formData[this.birthdateControlName],
      });
      additionalFields.push({ feld: 'jahreskartennummer', wert: this.formData[this.cardNumberControlName] });
    }
    return additionalFields;
  }
}
