<h2 data-test="process-summary-title" *ngIf="header">{{ header }}</h2>
<ng-content select=".error-container"></ng-content>
<p class="mb-5 mb-md-6" data-test="process-summary-description" [translate]="description"></p>
<div class="mb-4 mb-md-6">
  <mwe-address [address]="address"></mwe-address>
</div>

<div class="expander-item mt-4 mb-4" *ngIf="processTitle">
  <h6 class="ml-3" data-test="process-summary-title" [translate]="processTitle"></h6>
</div>

<mwe-multi-product-card-container
  *ngIf="multiProductCards"
  class="d-block mb-4"
  [multiProductCards]="multiProductCards"
  [isSliderVisible]="false"
></mwe-multi-product-card-container>

<ng-content select=".additional-stuff"></ng-content>
