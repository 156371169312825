<div class="row align-items-center h-100">
  <div class="col-auto">
    <mwe-header-logo></mwe-header-logo>
  </div>
  <div *ngIf="isLoggedIn" class="col">
    <ul class="nav justify-content-end">
      <li class="nav-item d-none d-md-block">
        <a [ngClass]="{ active: isProductPath }" [routerLink]="productsRoute" class="nav-link" data-test="mwe-products-header-link">
          <i class="far fa-box"></i>
          <span class="fw-semibold" translate="global.navbar.products"></span>
        </a>
      </li>

      <li *ngIf="config.goodies.isRouteEnabled" class="nav-item d-none d-md-block">
        <a
          (click)="resetProductState()"
          [ngClass]="{ active: isGoodiePath }"
          [routerLink]="goodieRoute"
          class="nav-link"
          data-test="navbar-header-goodies-link"
        >
          <i class="far fa-gift"></i>
          <span class="fw-semibold" translate="global.navbar.goodies"></span>
        </a>
      </li>

      <li #profileDropdown="ngbDropdown" class="nav-item nav-item-profile d-none d-md-block" ngbDropdown placement="bottom-right top-right">
        <button
          [ngClass]="{ active: isProfilePath }"
          class="nav-link pointer px-2 rounded-2"
          data-test="navbar-header-profile-link"
          ngbDropdownToggle
          tabindex="0"
        >
          <i class="far {{ config.profile.menuIconClass }}"></i>
          <span class="fw-semibold" translate="global.navbar.profile"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <div class="card-basic text-start" data-test="profile-dropdown-menu-container">
            <div class="card-basic-body card-basic-segment p-3">
              <div class="row g-2 align-items-center flex-nowrap">
                <div class="col-auto">
                  <div class="bg-primary btn btn-blank btn-bubble text-white" style="width: 2rem; height: 2rem">
                    <i [ngClass]="config.profile.menuIconClass" class="far"></i>
                  </div>
                </div>
                <div class="col">
                  <p class="text--big text-body mb-0">
                    <b class="text-secondary text-break">{{ userName }}</b>
                    <br />
                    {{ emailAddress }}
                  </p>
                </div>
                <div class="col-auto">
                  <div
                    class="rounded-pill bg-gray--300 text-secondary-light font-size-14 fw-semibold d-inline-flex align-items-center justify-content-center px-3"
                    style="height: 2rem"
                  >
                    {{ config.profile.menuTagNameLabel | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-basic-body card-basic-segment p-3">
              <div>
                <a
                  (click)="profileDropdown.close()"
                  [routerLink]="profileRoute"
                  class="text-secondary link-active-opacity text-focus-ring"
                  data-test="profile-dropdown-menu-profile-link"
                  routerLinkActive="active"
                  translate="global.navbar.profileSubMenu.profileLinkText"
                ></a>
              </div>
            </div>
            <div class="card-basic-body card-basic-segment p-3">
              <div>
                <a
                  (click)="profileDropdown.close(); switchPortal()"
                  (keydown.enter)="profileDropdown.close(); switchPortal()"
                  [translate]="config.profile.portalSwitchRouteLabel"
                  class="text-secondary link-active-opacity text-focus-ring pointer d-block"
                  data-test="profile-dropdown-menu-business-link"
                  routerLinkActive="active"
                  tabindex="0"
                ></a>
              </div>
            </div>
            <div class="card-basic-body card-basic-segment p-3">
              <div>
                <a
                  (click)="profileDropdown.close(); logout()"
                  (keyup.enter)="profileDropdown.close(); logout()"
                  class="text-secondary pointer link-active-opacity text-focus-ring"
                  data-test="profile-dropdown-menu-logout-link"
                  tabindex="0"
                >
                  <i class="fas fa-sign-out"></i>
                  {{ 'global.navbar.profileSubMenu.logoutLinkText' | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </li>
      @if (messagesEnabled()) {
        <li class="nav-item nav-item-popover">
          <button
            (click)="loadMessages()"
            [ngClass]="{ heartbeat: messagesStore.unreadMessageCount() && messagesPristine() }"
            class="btn btn-sm btn-ghost-outline px-12px font-size-16"
            data-test="navbar-header-messages-toggle"
            style="min-width: 2.875rem"
            title="{{ 'mnr.title' | translate }}"
          >
            <i class="far fa-envelope"></i>
            @if (messagesStore.unreadMessageCount()) {
              <span class="ml-1">{{ messagesStore.unreadMessageCount() }}</span>
            }
          </button>
          <ng-template #messagesComponent />
        </li>
      }
    </ul>
  </div>
</div>
