import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AccountLogic } from '../account/account.logic';
import { EnvironmentService } from '../environment/environment.service';
import { Account, IGoodieResponse, IGoodiesDictModel, IGoodiesModel } from '@mwe/models';
import { lastValueFrom } from 'rxjs';
import { GoodieStateService } from './goodie-state.service';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class GoodieNewService {
  constructor(
    private http: HttpClient,
    private accountLogic: AccountLogic,
    private environmentService: EnvironmentService,
    private goodieStateService: GoodieStateService,
  ) {}

  private get goodieResourceUrl(): string {
    return `${this.environmentService.getApiUrl()}api/goodies`;
  }

  private get account(): Account {
    return this.accountLogic.getAccount();
  }

  async getGoodies(): Promise<IGoodiesModel[]> {
    const { data: allGoodies } = await lastValueFrom(this.http.get<IGoodieResponse<IGoodiesModel[]>>(`${this.goodieResourceUrl}/all`));
    const sortedGoodies = allGoodies.toSorted((a, b) => a.voucher.position - b.voucher.position);
    this.goodieStateService.setGoodies(sortedGoodies);
    return sortedGoodies;
  }

  async getSingleGoodieBySlug(slug: string): Promise<IGoodiesModel> {
    const params = new HttpParams().set('goodieId', slug);
    const { data } = await lastValueFrom(this.http.get<IGoodieResponse<IGoodiesModel[]>>(`${this.goodieResourceUrl}/single`, { params }));

    return data?.[0];
  }

  async getSingleGoodieById(id: string): Promise<IGoodiesModel> {
    const params = new HttpParams().set('goodieId', id);
    return lastValueFrom(this.http.get<IGoodiesModel>(`${this.goodieResourceUrl}/get`, { params }));
  }

  async activate(id: string): Promise<IGoodieResponse<{ code: string | null }>> {
    const goodie = await this.getSingleGoodieById(id);
    if (!goodie.userId) {
      await this.insert(id);
    }

    return lastValueFrom(
      this.http.post<IGoodieResponse<{ code: string | null }>>(`${this.goodieResourceUrl}/activate`, {
        goodieId: id,
        userId: this.account.id,
        email: this.account.email,
        firstName: this.account.firstName,
        lastName: this.account.lastName,
      }),
    );
  }

  async insert(id: string): Promise<IGoodieResponse<{ code: string | null }>> {
    return lastValueFrom(
      this.http.post<IGoodieResponse<{ code: string | null }>>(`${this.goodieResourceUrl}/insert`, {
        userId: this.account.id,
        goodieId: id,
      }),
    );
  }

  async checkVoucher(goodieId: string, code: string) {
    const params = new HttpParams().set('goodieId', goodieId).set('code', code);
    return await lastValueFrom(this.http.get(`${this.goodieResourceUrl}/codes/checkcode`, { params }));
  }

  async favorite(id: string, isFavorite: boolean) {
    const goodie = await this.getSingleGoodieById(id);
    if (!goodie.userId) {
      await this.insert(id);
    }

    if (isFavorite) {
      return await lastValueFrom(
        this.http.post(`${this.goodieResourceUrl}/unfavorite`, {
          goodieId: id,
          userId: this.account.id,
        }),
      );
    }

    return await lastValueFrom(
      this.http.post(
        `${this.goodieResourceUrl}/favorite`,
        {
          goodieId: id,
          userId: this.account.id,
        },
        { observe: 'response' },
      ),
    );
  }

  async getGoodieCode(goodieId: string): Promise<IGoodieResponse<IGoodiesModel>> {
    const params = new HttpParams().set('goodieId', goodieId);
    return await lastValueFrom(this.http.get<IGoodieResponse<IGoodiesModel>>(`${this.goodieResourceUrl}/code`, { params }));
  }

  async getAllGoodies(): Promise<IGoodieResponse<IGoodiesDictModel>> {
    const sortedGoodies = await this.getGoodies();

    const now = dayjs();
    const mappedData: IGoodiesDictModel = sortedGoodies.reduce(
      (result, goodie) => {
        // do not display goodies without image
        if (!goodie.voucher.image) return result;

        const { slug } = goodie.voucher.category;

        if (now.isAfter(goodie.validTill) && goodie.activatedOn) {
          result.expired.goodies.push(goodie);
          return result;
        }

        if (goodie.activatedOn) {
          result.activated.goodies.push(goodie);
          return result;
        }

        if (now.isBefore(goodie.validTill)) {
          const categoryPosition = goodie.voucher.category?.position_category ?? 0;

          // highlight and favorite do not remove goodie out of other categories
          if (goodie.isHighlight) {
            result.highlight.goodies.push(goodie);
          }

          if (goodie.isFavorite) {
            result.favorite.goodies.push(goodie);
          }

          if (!result[slug]) {
            result[slug] = {
              category: { ...goodie.voucher.category, categoryPosition },
              goodies: [goodie],
            };
          } else {
            result[slug].goodies.push(goodie);
          }

          return result;
        }

        return result;
      },
      {
        highlight: {
          category: {
            // title and description get translated in goodies-overview.component.html
            name: '',
            slug: 'highlight',
            description: '',
            position_category: 0,
          },
          goodies: [],
        },
        favorite: {
          category: {
            name: '',
            slug: 'favorite',
            description: '',
            position_category: 0,
          },
          goodies: [],
        },
        activated: {
          category: {
            name: '',
            slug: 'activated',
            description: '',
            position_category: 0,
          },
          goodies: [],
        },
        expired: {
          category: {
            name: '',
            slug: 'expired',
            description: '',
            position_category: 0,
          },
          goodies: [],
        },
      },
    );

    return {
      data: mappedData,
      meta: {},
      errors: [],
    };
  }
}
