import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from '../logging/logging.service';
import { hash } from 'object-code';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private lastErrorHashValues: number[] = [];
  private maxErrorItems = 10;

  constructor(private loggingService: LoggingService) {}

  handleError(error: Error): void {
    if (error && (error as any).rejection && (error as any).rejection.status === 401) {
      // ignore HTTP-401 errors
      return;
    }

    if (this.checkIfSkipErrorLog(error)) {
      return;
    }

    console.error(error);
    this.loggingService.logError(error);
  }

  private checkIfSkipErrorLog(error: Error) {
    try {
      if (this.lastErrorHashValues.length >= this.maxErrorItems) {
        this.lastErrorHashValues.shift(); // first in first out
      }

      // error in template renderings causes logError spam
      const hashValue = hash(error);
      const hasErrorAlreadyBeenLogged = this.lastErrorHashValues.some(i => i === hashValue);
      this.lastErrorHashValues.push(hashValue);

      return hasErrorAlreadyBeenLogged;
    } catch (e) {
      // ignore error of skip-logic and do not prevent log
      return false;
    }
  }
}
