export interface IAddressGroup {
  id: any;
  userId: string;
  addressCode: string;
  groupName: string;
  detailsLinkType: string;
  customerType: string;
  createdAt: Date;
  modifiedAt: Date;

  markAsMatched(): void;

  isMatchedGroup(): boolean;
}

export class AddressGroup implements IAddressGroup {
  private matchedGroup: boolean;

  constructor(
    public id: any,
    public userId: string,
    public addressCode: string,
    public groupName: string,
    public detailsLinkType: string,
    public customerType: string,
    public createdAt: Date,
    public modifiedAt: Date,
  ) {
    this.id = id;
    this.userId = userId;
    this.addressCode = addressCode;
    this.groupName = groupName;
    this.detailsLinkType = detailsLinkType;
    this.customerType = customerType;
    this.matchedGroup = false;
  }

  markAsMatched(): void {
    this.matchedGroup = true;
  }

  isMatchedGroup(): boolean {
    return this.matchedGroup;
  }
}

export const parseIAddressGroup = (json?: any): IAddressGroup => {
  return json as AddressGroup;
};

export const parseAddressGroup = (groups: AddressGroup[]): AddressGroup[] => {
  const parsedAddressGroups: AddressGroup[] = [];
  if (groups && groups.length) {
    groups.forEach(elem => {
      parsedAddressGroups.push(
        new AddressGroup(
          elem.id,
          elem.userId,
          elem.addressCode,
          elem.groupName,
          elem.detailsLinkType,
          elem.customerType,
          elem.createdAt,
          elem.modifiedAt,
        ),
      );
    });
  }
  return parsedAddressGroups;
};
