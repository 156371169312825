import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { BadgeType } from '../badge.model';

@Component({
  selector: 'mwe-link-badge',
  templateUrl: './link-badge.component.html',
})
export class LinkBadgeComponent implements OnInit {
  @Input() text: string;
  @Input() type: BadgeType;
  @Input() link: string;

  constructor() {}

  ngOnInit(): void {}
}
