import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ISaveConsentModel, ICheckBoxesModel, IDsgvoStatus } from '@mwe/models';
import { EnvironmentService } from '../environment/environment.service';
import { LoggingService } from '../logging/logging.service';
import { UserJournalService } from '../user/user.journal.service';
import { RetryOnFailure } from '@mwe/utils';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DsgvoService {
  public resourceUrl: string;

  constructor(
    private http: HttpClient,
    private userJournalService: UserJournalService,
    private environmentService: EnvironmentService,
    private loggingService: LoggingService,
  ) {
    // TODO put into environment files
    this.resourceUrl = this.environmentService.getApiUrl() + '/v1/ikp/auth/dsgvo/';
  }

  saveConsent(dsvgoData: ISaveConsentModel): Promise<HttpResponse<void>> {
    return lastValueFrom(this.http.post<void>(this.resourceUrl + 'consents', dsvgoData, { observe: 'response' }));
  }

  copyConsent(dsvgoData: ISaveConsentModel): Promise<HttpResponse<void>> {
    return lastValueFrom(this.http.post<void>(this.resourceUrl + 'copy/consents', dsvgoData, { observe: 'response' }));
  }

  async loadDsvgoData(): Promise<HttpResponse<ICheckBoxesModel>> {
    const res = await lastValueFrom(this.http.get<ICheckBoxesModel>(this.resourceUrl + 'checkboxes', { observe: 'response' }));
    res.body?.gruppen?.forEach(gruppe => {
      if (gruppe.darstellungsTyp.includes('Schieberegler')) {
        gruppe.defaultZustand = 'undefined';
      }
    });
    if (!res || res.status !== 200 || !res.body || !res.body.gruppen || !res.body.zustimmungscode) {
      throw new Error('response is faulty');
    }
    return res;
  }

  @RetryOnFailure({
    maxRetryCount: 1,
    delay: 100,
    retryOnError: true,
    validator: (returnValue: IDsgvoStatus[]) => Array.isArray(returnValue),
  })
  async loadDsgvoStatus(): Promise<IDsgvoStatus[]> {
    try {
      return await lastValueFrom(this.http.get<IDsgvoStatus[]>(this.resourceUrl + 'status'));
    } catch (e) {
      this.loggingService.logError(e, 'error on loading dsgvo-status');
      return null;
    }
  }

  async isDsgvoSuccessfullySet(): Promise<boolean> {
    try {
      const result = await this.userJournalService.getEvent('dsgvoSuccess');
      return UserJournalService.EVENT_NOT_FOUND !== result;
    } catch (e) {
      return false;
    }
  }

  getTriggeringSystem() {
    return 'meine.wienenergie.at';
  }
}
