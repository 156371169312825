<div class="card-basic mb-5" [ngClass]="{ 'shadow-none': noShadow }" role="alert">
  <div class="row mt-4 gx-3 mx-n2">
    <div class="col-6">
      <img src="/assets/img/power-left.svg" alt="" class="img-fluid w-100 h-auto" />
    </div>
    <div class="col-6">
      <img src="/assets/img/power-right.svg" alt="" class="img-fluid w-100 h-auto" />
    </div>
  </div>
  <div class="card-basic-body">
    <div class="row">
      <div class="col-12 col-md-6 text-start mx-auto">
        <h1 data-test="special-alert-title" [translate]="titleKey" class="h5 text-start" *ngIf="showTitle()"></h1>
        <p class="text--big" [innerHTML]="messageKey | translate"></p>
        <ng-content select="[id=additional-content]"></ng-content>
        <mwe-button
          *ngIf="reloadButtonKey"
          class="d-block mt-4 mb-4"
          [showSpinner]="showReloadLoadingSpinner"
          [disabled]="showReloadLoadingSpinner"
          [labelKey]="reloadButtonKey"
          (clicked)="reloadEventEmitter.emit()"
        ></mwe-button>
      </div>
    </div>
  </div>
</div>
