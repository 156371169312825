import { Route, RouterModule } from '@angular/router';
import { FrontpageComponent } from './pages/frontpage/frontpage.component';
import { NgModule } from '@angular/core';
import { ROUTE_CONDITIONS_OF_USE_WIZARD, ROUTE_HOME, ROUTE_LOGOUT_SUCCESS, ROUTE_REGISTRATION, ROUTE_TERMS_OF_USE } from './app.constants';
import { authGuard } from '@shared/auth/guard/auth.guard';
import { TermsOfUsePageComponent } from '@ikp/pages/terms-of-use/terms-of-use-page.component';
import { TrackingGuard } from '@ikp/guards/tracking.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    component: FrontpageComponent,
    canActivate: [TrackingGuard],
    children: [],
  },
  {
    path: ROUTE_LOGOUT_SUCCESS,
    component: FrontpageComponent,
    canActivate: [TrackingGuard],
  },
  {
    path: ROUTE_HOME,
    loadChildren: () => import('./pages/main/main.routes').then(mod => mod.MAIN_ROUTES),
    canActivate: [authGuard],
  },
  {
    path: ROUTE_REGISTRATION,
    data: {
      hideHeader: true,
      hideFooter: true,
      toggles: [],
      pageTitle: 'Registration',
    },
    loadChildren: () => import('./pages/registration/registration.module').then(mod => mod.RegistrationModule),
    canActivate: [TrackingGuard, authGuard],
  },
  {
    path: ROUTE_CONDITIONS_OF_USE_WIZARD,
    data: {
      hideHeader: true,
      hideFooter: true,
      toggles: [],
      pageTitle: 'ConditionsOfUse',
    },
    canActivate: [TrackingGuard, authGuard],
    loadChildren: () => import('./pages/conditions-of-use/conditions-of-use.module').then(mod => mod.ConditionsOfUseModule),
  },
  {
    path: ROUTE_TERMS_OF_USE,
    canActivate: [TrackingGuard],
    component: TermsOfUsePageComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: () => import('./pages/main/main.routes').then(mod => mod.MAIN_ROUTES.find(route => route.path === '').component),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
