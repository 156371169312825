<mwe-product-tariff-card-template [product]="product" [headlineReference]="headlineService.getHeadline(0)" data-test="tariff-card-waerme">
  @if (product.tariffClasses?.length > 0) {
    <mwe-headline [headlineLevelIncrement]="1" cssClass="h5 mb-3" text="products.details.tariffList"></mwe-headline>
    <ul class="list-ul" data-test="waerme-tariff-card-class-list">
      @for (item of tariffClasses$ | async; track item; let last = $last) {
        <li class="fw-semibold text-secondary" [ngClass]="{ 'mb-2': !last }">
          {{ item }}
        </li>
      }
    </ul>
  } @else {
    <mwe-info-text-card text="products.details.noTariffList"></mwe-info-text-card>
  }
</mwe-product-tariff-card-template>
