import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class OAuthInterceptor implements HttpInterceptor {
  constructor(private authStorage: OAuthStorage) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();

    if (matchUrlCondition(url)) {
      const token = this.authStorage.getItem('access_token');
      if (token) {
        const header = 'Bearer ' + token;
        const headers = req.headers.set('X-Authorization', header);
        req = req.clone({ headers });
      }
    }

    return next.handle(req);
  }
}

const excludedPaths = ['api/log/error', 'api/mgmt/info', 'api/public'];

const matchUrlCondition = (url: string): boolean =>
  url.includes('api/') && !url.includes('bskwec/') && excludedPaths.every(path => !url.includes(path));
