import { Component, Input } from '@angular/core';

@Component({
  selector: 'mwe-link',
  templateUrl: './link.component.html',
})
export class LinkComponent {
  @Input() href: string;
  @Input() target: string;
  @Input() class: string;
  @Input() labelKey: string;
  @Input() iconClass: string;

  constructor() {}
}
