import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiPipesModule } from '@mwe/ui/pipes';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../directives/directives.module';
import { LoadingSpinnerExtendedWhiteBackgroundComponent } from './loading/loading-spinner-extended-white-background.component';
import { LoadingSpinnerExtendedComponent } from './loading/loading-spinner-extended.component';
import { LoadingSpinnerComponent } from './loading/loading-spinner.component';

const components = [LoadingSpinnerComponent, LoadingSpinnerExtendedComponent, LoadingSpinnerExtendedWhiteBackgroundComponent];

@NgModule({
  imports: [CommonModule, TranslateModule, FormsModule, NgbModule, ReactiveFormsModule, UiPipesModule, RouterModule, DirectivesModule],
  declarations: components,
  providers: [NgbActiveModal],
  exports: [...components],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoadingComponentsModule {}
