<div class="item" role="listitem" [ngClass]="getLineClass(bulletId)">
  <div class="image">
    <div class="bubble" [ngClass]="getBulletClass(bulletId)" [id]="'bubble_' + bulletId">
      <i class="fas" [ngClass]="getIconClass(bulletId)" aria-hidden="true"></i>
    </div>
  </div>
  <div class="details">
    <div>
      <h2
        class="h3"
        [id]="'title_' + bulletId"
        data-test="mwe-confirmation-view-detail-title"
        [translate]="getBulletTitle(bulletId)"
        [translateParams]="getTranslateValues(bulletId)"
      ></h2>
      <p
        *ngFor="let label of getBulletLabel(bulletId); trackBy: trackByIndex"
        class="text-break"
        [id]="'label_' + bulletId"
        data-test="mwe-confirmation-view-detail-label"
        [innerHTML]="label | translate: getTranslateValues(bulletId) | safeHtml"
      ></p>
    </div>
  </div>
</div>
