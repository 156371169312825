<footer class="bg-gray--400">
  <div class="footer-area">
    <div class="py-5 py-lg-9 container-fluid">
      <div class="row mx-auto">
        <div class="col-lg">
          <div class="row">
            <div class="col-6 col-lg-auto mb-lg-0 mb-3">
              <p class="d-block font-size-16 mb-2 text-secondary fw-semibold">
                <a [routerLink]="ROUTE_STARTPAGE" class="pointer text-decoration-none link">
                  <span translate="global.footer.common"></span>
                </a>
              </p>
              <ul class="list-unstyled font-size-14">
                <!-- not within the scope of mvp -->
                <li class="mb-2" style="display: none">
                  <a [routerLink]="links.defaultRoute" class="pointer fw-normal link-gray-light text-decoration-none text-none">
                    <span translate="global.footer.products"></span>
                  </a>
                </li>
                <li class="mb-2">
                  <a [routerLink]="homeRoutePrefix(ROUTE_METER_POINTS)" class="pointer fw-normal link-gray-light text-none footer-link">
                    <span translate="global.footer.meter"></span>
                  </a>
                </li>
                <li class="mb-2">
                  <a [routerLink]="homeRoutePrefix(ROUTE_INVOICES)" class="pointer fw-normal link-gray-light text-none footer-link">
                    <span translate="global.footer.bills"></span>
                  </a>
                </li>
                <li class="mb-2">
                  <a [routerLink]="homeRoutePrefix(ROUTE_PROFILE_DATA)" class="pointer fw-normal link-gray-light text-none footer-link">
                    <span translate="global.footer.account"></span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-6 col-lg-auto">
              <p class="d-block font-size-16 mb-2 text-secondary fw-semibold">
                <a [routerLink]="homeRoutePrefix(ROUTE_FAQ)" class="pointer text-decoration-none link">
                  <span translate="global.footer.supportAndContact"></span>
                </a>
              </p>
              <ul class="list-unstyled font-size-14">
                <!-- not within the scope of mvp -->
                <li class="mb-2" style="display: none">
                  <a [routerLink]="links.defaultRoute" class="pointer fw-normal link-gray-light text-none footer-link">
                    <span translate="global.footer.tour"></span>
                  </a>
                </li>
                <li class="mb-2">
                  <a [routerLink]="homeRoutePrefix(ROUTE_FAQ)" class="pointer fw-normal link-gray-light text-none footer-link">
                    <span translate="global.footer.faq"></span>
                  </a>
                </li>
                <!-- not within the scope of mvp -->
                <li class="mb-2" style="display: none">
                  <a [routerLink]="links.defaultRoute" class="pointer fw-normal link-gray-light footer-link">
                    <span translate="global.footer.contact"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-auto">
          <div class="row gx-3 justify-content-center justify-content-lg-end align-items-center mb-5 mb-lg-4">
            <div class="col col-auto">
              <a
                class="d-block fs-5 lh-1 text-secondary footer-link-icon"
                href="https://www.facebook.com/WienEnergie"
                rel="noopener noreferrer"
                target="_blank"
                title="{{ 'global.footer.socialMedia.facebook' | translate }}"
              >
                <i class="fa-brands fa-facebook"></i>
              </a>
            </div>
            <div class="col col-auto">
              <a
                class="d-block fs-5 lh-1 text-secondary footer-link-icon"
                href="https://www.instagram.com/wienenergie"
                rel="noopener noreferrer"
                target="_blank"
                title="{{ 'global.footer.socialMedia.instagram' | translate }}"
              >
                <i class="fab fa-instagram"></i>
              </a>
            </div>
            <div class="col col-auto">
              <a
                class="d-block fs-5 lh-1 text-secondary footer-link-icon"
                href="https://www.tiktok.com/@wienenergie"
                rel="noopener noreferrer"
                target="_blank"
                title="{{ 'global.footer.socialMedia.tiktok' | translate }}"
              >
                <i class="fa-brands fa-tiktok"></i>
              </a>
            </div>
            <div class="col col-auto">
              <a
                class="d-block fs-5 lh-1 text-secondary footer-link-icon"
                href="https://twitter.com/wienenergie"
                rel="noopener noreferrer"
                target="_blank"
                title="{{ 'global.footer.socialMedia.x' | translate }}"
              >
                <svg fill="none" style="width: 1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.2437 2.25H21.5531L14.325 10.5094L22.8281 21.75H16.1719L10.9547 14.9344L4.99216 21.75H1.6781L9.40779 12.9141L1.25623 2.25H8.08122L12.7922 8.47969L18.2437 2.25ZM17.0812 19.7719H18.914L7.08279 4.125H5.11404L17.0812 19.7719Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
            <div class="col col-auto">
              <a
                class="d-block fs-5 lh-1 text-secondary footer-link-icon"
                href="https://www.youtube.com/WienEnergieGmbH"
                rel="noopener noreferrer"
                target="_blank"
                title="{{ 'global.footer.socialMedia.youtube' | translate }}"
              >
                <i class="fab fa-youtube"></i>
              </a>
            </div>
            <div class="col col-auto">
              <a
                class="d-block fs-5 lh-1 text-secondary footer-link-icon"
                href="http://blog.wienenergie.at"
                rel="noopener noreferrer"
                target="_blank"
                title="{{ 'global.footer.socialMedia.blog' | translate }}"
              >
                <i class="fas fa-newspaper"></i>
              </a>
            </div>
            <div class="col col-auto">
              <a
                class="d-block fs-5 lh-1 text-secondary footer-link-icon"
                href="https://www.linkedin.com/company/wien-energie-gmbh"
                rel="noopener noreferrer"
                target="_blank"
                title="{{ 'global.footer.socialMedia.linkedin' | translate }}"
              >
                <i class="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
          <ul class="row gx-3 gy-2 list-unstyled font-size-14 justify-content-center mb-0">
            <li class="col-auto">
              <a [routerLink]="'/' + ROUTE_TERMS_OF_USE" class="fw-normal link text-decoration-none text-none">
                <span translate="global.footer.termsOfUse"></span>
              </a>
            </li>
            <li class="col-auto">
              <mwe-link href="https://www.wienenergie.at/datenschutz" labelKey="global.footer.privacyPolicy" target="_blank"></mwe-link>
            </li>
            <li class="col-auto">
              <mwe-link href="https://www.wienenergie.at/impressum" labelKey="global.footer.legalInformation" target="_blank"></mwe-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="border-top border-white py-5">
    <div class="footer-area">
      <div class="container-fluid">
        <div class="row mx-auto">
          <div class="col-12 col-lg-auto d-flex align-items-center justify-content-center mb-3 mb-xl-0">
            <p class="mb-0 font-size-14 fw-semibold text-center text-lg-start">
              © {{ currentYear }} {{ 'global.footer.companyInformation' | translate }}
            </p>
          </div>
          <div class="col-12 col-lg-auto">
            <p class="mb-0 font-size-14 text-center text-lg-start text-secondary fw-semibold mb-3 mb-xl-0">
              {{ 'global.footer.energieAllianzInformationVertrieb' | translate }}
            </p>
            <p class="mb-0 font-size-14 text-center text-lg-start text-secondary fw-semibold">
              {{ 'global.footer.energieAllianzInformationWE' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
