import { Injectable } from '@angular/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { IGoodieAlert, IGoodiesModel } from '@mwe/models';

const goodiesKey = 'goodies_goodies';
const goodiesAlertsKey = 'goodies_alerts';

@Injectable({ providedIn: 'root' })
export class GoodieStateService {
  @SessionStorage(goodiesKey)
  private _goodies: Array<IGoodiesModel>;

  @SessionStorage(goodiesAlertsKey)
  private _alerts: Array<IGoodieAlert> | undefined;

  constructor(private storage: SessionStorageService) {}

  clearData(): void {
    this.storage.clear(goodiesKey);
    this.storage.clear(goodiesAlertsKey);
  }

  public getGoodies(): Array<IGoodiesModel> {
    return this._goodies || undefined;
  }

  public setGoodies(goodies: Array<IGoodiesModel>): void {
    if (goodies) {
      this._goodies = goodies;
    } else {
      this.storage.clear(goodiesKey);
    }
  }

  public getAlerts(purge: boolean = true): Array<IGoodieAlert> {
    const alerts = this._alerts || undefined;
    if (purge) {
      this.storage.clear(goodiesAlertsKey);
    }
    return alerts;
  }

  public addAlert(alert: IGoodieAlert): void {
    if (!this._alerts) {
      this._alerts = [];
    }
    this._alerts.push(alert);
  }
}
