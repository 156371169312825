import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAddress, IAddressProducts } from '@mwe/models';
import { AddressLogic } from '@mwe/services';
import { isArrayWithOneItem } from '@mwe/utils';

@Component({
  selector: 'mwe-address-with-selection-dropdown',
  templateUrl: './address-with-selection-dropdown.component.html',
})
export class AddressWithSelectionDropdownComponent implements OnInit {
  @Input() address: IAddress;
  @Input() loadAllAddresses = true;
  @Output() newAddressSelected = new EventEmitter<IAddressProducts>();

  isCollapsed = true;
  addressOptions: IAddressProducts[] = [];

  constructor(private addressLogic: AddressLogic) {}

  async ngOnInit(): Promise<void> {
    if (!this.loadAllAddresses) {
      return;
    }

    this.addressOptions = await this.addressLogic.loadAddressesWithGroupNames();
    this.addressLogic.loadAndMapGroupNames([this.address]);
  }

  toggleDropDown(): void {
    if (isArrayWithOneItem(this.addressOptions)) {
      return;
    }

    this.isCollapsed = !this.isCollapsed;
  }

  hideDropDown(): void {
    this.isCollapsed = true;
  }

  isAddressSelected(address: IAddress): boolean {
    return address.getHashCode() === this.address?.getHashCode();
  }

  onAddressSelected(addressProducts: IAddressProducts): void {
    this.newAddressSelected.emit(addressProducts);
    this.isCollapsed = false;
  }
}
