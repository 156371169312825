<mwe-tariff-selection-card-ui
  [hasActions]="hasActions"
  [additionalOptionGroupName]="additionalOptionGroupName"
  [isReadonly]="isReadonly"
  [tariff]="tariff"
  [lockedGroupIds]="lockedGroupIds"
  (tariffUpdated)="onTariffUpdated($event)"
  (reloadAfterError)="onReloadAfterError()"
>
  <ng-content></ng-content>
</mwe-tariff-selection-card-ui>
