import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { BadgeType } from '../badge.model';

@Component({
  selector: 'mwe-basic-badge',
  templateUrl: './basic-badge.component.html',
})
export class BasicBadgeComponent implements OnInit {
  @Input() text: string;
  @Input() type: BadgeType;
  @Input() heading: string;
  @Input() headingBadgeText: string;

  constructor() {}

  ngOnInit(): void {}
}
