import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DropdownInputSelectChanged, IFormInput, IFormSelect } from '@mwe/models';
import { isFormErrorVisible } from '@mwe/utils';

@Component({
  selector: 'mwe-form-select',
  templateUrl: './form-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormSelectComponent,
      multi: true,
    },
  ],
})
export class FormSelectComponent implements OnInit, ControlValueAccessor {
  @Input() inputElem: IFormInput;
  @Input() parentForm: UntypedFormGroup;
  @Input() isLoading = false;
  @Input() selectedValue;
  @Output() selectChanged = new EventEmitter<DropdownInputSelectChanged>();
  isRequired: boolean;

  ngOnInit(): void {
    this.isRequired = this.inputElem.validators?.indexOf(Validators.required) > -1;
  }

  getOptionByValue(value: string): IFormSelect {
    return this.inputElem.options.find(element => element.value === value);
  }

  isSelected(option: IFormSelect) {
    const value = this.selectedValue[this.inputElem.name];
    return value === option.text;
  }

  // handled in FormComponent via selectChanged and FormModel/IFormInput
  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {}

  writeValue(obj: any): void {}

  get isValid(): any {
    return this.isRequired ? this.selectedValue : true;
  }

  get ariaDescribedBy(): string | undefined {
    const control = this.parentForm.get(this.inputElem.name);
    const hasError = isFormErrorVisible(this.inputElem, control);

    if (!!this.inputElem.helpLabelKey || hasError) {
      return `form-input-desc--${this.inputElem.name}`;
    }
    return undefined;
  }
}
