import { Component, OnInit } from '@angular/core';
import { StaticContentService } from '@mwe/services';
import { Router } from '@angular/router';
import { FeatureToggleEnum } from '@mwe/constants';
import { InteractiveInvoiceStaticData } from '@mwe/models';
import { ProfileService } from '@mwe/services';

@Component({
  selector: 'mwe-interactive-invoice-alert',
  templateUrl: './interactive-invoice-alert.component.html',
})
export class InteractiveInvoiceAlertComponent implements OnInit {
  isAlertVisible = false;
  data: InteractiveInvoiceStaticData;
  featureToggleName = FeatureToggleEnum.INVOICE_ALERT_ENABLED;

  constructor(private staticContentService: StaticContentService, private router: Router, private profileService: ProfileService) {}

  async ngOnInit(): Promise<void> {
    if (this.profileService.isFeatureToggleDisabled(this.featureToggleName)) {
      return;
    }

    this.data = await this.staticContentService.getInteractiveInvoiceStaticData();
    this.isAlertVisible = this.data?.alert?.urls?.some(url => url === this.router.url);
  }
}
