import { Component, Input, OnInit } from '@angular/core';
import { InfoText } from '@mwe/models';
import { StaticContentService, PopupService } from '@mwe/services';
import { track } from '@mwe/utils';
import { InfoTextPopupComponent } from './info-text-popup.component';

@Component({
  selector: 'mwe-info-text-icon',
  templateUrl: './info-text-icon.component.html',
})
export class InfoTextIconComponent implements OnInit {
  @Input() infoTextCode: string;
  @Input() infoTextTitle: string;
  @Input() infoTextDescription: string;
  @Input() infoTextLink: { name: string; target: string };
  @Input() dataTest: string;
  @Input() colorClass = 'text-primary';
  @Input() translations;
  infoText: InfoText;

  constructor(
    private staticContentService: StaticContentService,
    private popupService: PopupService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.infoTextCode) {
      await this.getInfoText();
    } else {
      this.infoText = {
        code: null,
        title: this.infoTextTitle,
        description: this.infoTextDescription,
        link: this.infoTextLink,
      };
    }

    if (!!this.translations && this.infoText) {
      // create copy to avoid manipulating texts in cache
      this.infoText = { ...this.infoText };
      Object.keys(this.translations).forEach(key => {
        this.infoText.description = this.infoText.description.replace(`{{ ${key} }}`, this.translations[key]);
      });
    }
  }

  async iconClicked(): Promise<void> {
    track('infoCodeClicked', { code: this.infoText.code });

    this.popupService.open({
      id: 'info-text-icon-clicked',
      titleKey: '',
      messageKey: '',
      showSubmitButton: false,
      showCancelButton: false,
      component: InfoTextPopupComponent,
      componentData: this.infoText,
      dismissable: true,
      modalOpts: {
        ariaLabelledBy: 'mwe_info-text-popup-title',
      },
    });
  }

  private async getInfoText(): Promise<void> {
    const infoTextList = await this.staticContentService.getInfoTextList();

    if (!infoTextList) {
      return;
    }

    // example: rechnungen.interaktiveRechnung.details.nope.label_rechnungsnr
    const parts = this.infoTextCode.split('.');
    // last part is infoTextCode
    const infoTextCode = parts.splice(-1)[0];
    const firstPart = parts.splice(0, 1)[0];

    let obj = infoTextList[firstPart];
    parts.forEach(part => {
      if (!obj) {
        this.infoText = null;
        return;
      }

      if (Array.isArray(obj[part])) {
        this.infoText = (obj[part] as [])
          .filter((it: InfoText) => !!it.title && !!it.description)
          .find((it: InfoText) => it.code === infoTextCode);
        return;
      }

      obj = obj[part];
    });
  }
}
