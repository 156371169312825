import { Component, Input, OnInit } from '@angular/core';
import { IMultiProductCard } from '@mwe/models';
import { isProductCategoryFernwaerme, isProductCategoryStromOrGas, sortProductsEnumCategory } from '@mwe/utils';

@Component({
  selector: 'mwe-multi-product-card',
  templateUrl: './multi-product-card.component.html',
  styles: [],
})
export class MultiProductCardComponent implements OnInit {

  @Input() card: IMultiProductCard;
  @Input() isDisabled = false;
  @Input() showCompanyReference = false;
  @Input() showSummarizedCompanyReference = false;
  @Input() showAccountNumber = false;
  @Input() showCustomerNumber = false;
  @Input() height100 = false;
  @Input() noBoxShadow = false;

  ngOnInit(): void {
    if ( this.card?.categories ) {
      sortProductsEnumCategory(this.card.categories)
    }
  }

  isAccountNumberVisible() {
    return this.showAccountNumber && this.card.accountNumber && isProductCategoryStromOrGas(this.card.categories[0]);
  }

  isCustomerNumberVisible() {
    return this.showCustomerNumber && this.card.customerNumber && isProductCategoryFernwaerme(this.card.categories[0]);
  }
}
