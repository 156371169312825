<div *ngIf="isLoggedIn" class="tabs fixed-bottom d-sm-block d-md-none px-4" id="tabbar">
  <div class="row gx-3">
    <div class="col">
      <a (click)="closeProfile()" [ngClass]="{ active: isProductPath }" [routerLink]="productsRoute" class="tabs-item">
        <div class="tabs-item-icon">
          <i class="far fa-box"></i>
        </div>
        <p translate="global.navbar.products"></p>
      </a>
    </div>
    <div *ngIf="config.goodies.isRouteEnabled" class="col">
      <a (click)="closeProfile()" [ngClass]="{ active: isGoodiePath }" [routerLink]="goodieRoute" class="tabs-item">
        <div class="tabs-item-icon">
          <i class="far fa-gift"></i>
        </div>
        <p translate="global.navbar.goodies"></p>
      </a>
    </div>
    <div class="col">
      <a (click)="toggleProfile()" [ngClass]="{ 'profile-active': isProfileOpen, active: isProfilePath }" class="tabs-item position-static">
        <div class="tabs-item-icon">
          <i class="far {{ config.profile.menuIconClass }}"></i>
        </div>
        <p translate="global.navbar.profile"></p>
      </a>
      <div class="tabs-submenu">
        <div class="card-basic text-start">
          <div class="card-basic-body card-basic-segment p-3">
            <div class="mb-3">
              <div class="bg-primary btn btn-blank btn-bubble text-white">
                <i class="far {{ config.profile.menuIconClass }} fs-2rem"></i>
              </div>
            </div>
            <p class="text--big text-body">
              <b class="text-secondary">{{ userName }}</b>
              <br />
              <span class="text-break">{{ emailAddress }}</span>
            </p>
            <div
              class="rounded-pill bg-gray--300 text-secondary-light font-size-14 fw-semibold d-inline-flex align-items-center justify-content-center px-3"
              style="height: 2rem"
            >
              {{ config.profile.menuTagNameLabel | translate }}
            </div>
          </div>
          <div class="card-basic-body card-basic-segment p-3">
            <div>
              <a
                (click)="closeProfile()"
                [routerLink]="profileRoute"
                class="text-secondary link-active-opacity text-focus-ring"
                routerLinkActive="active"
                translate="global.navbar.profileSubMenu.profileLinkText"
              ></a>
            </div>
          </div>
          <div class="card-basic-body card-basic-segment p-3">
            <div>
              <a
                (click)="closeProfile(); switchPortal()"
                [translate]="config.profile.portalSwitchRouteLabel"
                class="text-secondary link-active-opacity text-focus-ring pointer"
                routerLinkActive="active"
              ></a>
            </div>
          </div>
          <div class="card-basic-body card-basic-segment mb-9 p-3">
            <div>
              <a
                (click)="closeProfile(); logout()"
                (keyup.enter)="closeProfile(); logout()"
                class="text-secondary link-active-opacity text-focus-ring"
                tabindex="0"
              >
                <i class="fas fa-sign-out"></i>
                {{ 'global.navbar.profileSubMenu.logoutLinkText' | translate }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
