import { Address, IOlavEntry, parseAddress } from '../address/address.model';
import { IBulletConfirmationStatusChangeResponse, ITarif } from '../integrationlayer/api.model';
import { IdCard, IOrderDetailsInfo, ORDER_DETAILS_VERSION, toReferrencedFiles } from '../order/order.model';
import { IFileReferenceModel, IUploadResult } from '../upload/file.model';

export type RelocationConfirmationStatusResponse = IBulletConfirmationStatusChangeResponse;

export interface RelocationIPDFLinks {
  nutzungsbedingungen: string;
  allgemeineBedingungenStrom: string;
  allgemeineBedingungenGas: string;
  allgemeineBedingungenFernwaerme: string;
  allgemeineBedingungenZusatzProdukte: string;
  rueckstrittsfristFAGG: string;
}

export interface IRelocationData {
  relocationFromAddress: Address;
  relocationToOlav: IOlavEntry;
  relocationToAddress: Address;
  registrationDate: string;
  deregistrationDate: string;
  relocationDetails: IOrderDetailsInfo[];
  idCard: IdCard;
  uploadedFiles: IUploadResult;
  useSubstituteDetails: boolean;
  pdfLinks: RelocationIPDFLinks;
  noOnlineCategories?: string[];
}

export interface IRelocationDataDTO {
  selectedOlav: IOlavEntry;
  oldAddress: Address;
  newAddress: Address;
  deregistrationDate: string;
  registrationDate: string;
  relocationSubmittedAt?: string;
  orderDetailsInfo: IOrderDetailsInfo[];
  idcard: IdCard;
  referencedFiles: IFileReferenceModel[];
  useSubstituteDetails: boolean;
  pdfLinks: RelocationIPDFLinks;
  noOnlineCategories?: string[];
}

//TODO move functional processing to utils
export const newRelocationDataDTO = (relocationData: IRelocationData): IRelocationDataDTO => {
  const dto = {
    selectedOlav: relocationData?.relocationToOlav,
    oldAddress: relocationData?.relocationFromAddress,
    newAddress: relocationData?.relocationToAddress,
    deregistrationDate: relocationData?.deregistrationDate,
    registrationDate: relocationData?.registrationDate,
    orderDetailsInfo: relocationData?.relocationDetails,
    idcard: relocationData?.idCard,
    referencedFiles: relocationData?.uploadedFiles ? toReferrencedFiles(relocationData?.uploadedFiles.uploadedFiles) : null,
    useSubstituteDetails: relocationData?.useSubstituteDetails,
    pdfLinks: relocationData?.pdfLinks,
    noOnlineCategories: relocationData?.noOnlineCategories,
  };
  // firstfix: fixe date time format for birthday
  // secondfix: now remove T00:00Z
  relocationData?.relocationDetails?.forEach(d => {
    if (d.partnerDetails?.physischePerson?.geburtsdatum && d.partnerDetails.physischePerson.geburtsdatum.indexOf('T00:00Z') !== -1) {
      d.partnerDetails.physischePerson.geburtsdatum = d.partnerDetails.physischePerson.geburtsdatum.replace('T00:00Z-', '');
    }
  });
  return dto;
};

//TODO move functional processing to utils
export const parseRelocationDataDTO = (relocationDetails: any): IRelocationDataDTO => {
  const relocationData = JSON.parse(relocationDetails?.relocationData);
  const dto: IRelocationDataDTO = {
    selectedOlav: relocationData?.selectedOlav,
    oldAddress: parseAddress(relocationData?.moveFromAddress ? relocationData?.moveFromAddress : relocationData?.oldAddress),
    newAddress: parseAddress(relocationData?.moveToAddress ? relocationData?.moveToAddress : relocationData?.newAddress),
    deregistrationDate: relocationData?.deregistrationAt ? relocationData?.deregistrationAt : relocationData?.deregistrationDate,
    registrationDate: relocationData?.registrationAt ? relocationData?.registrationAt : relocationData?.registrationDate,
    relocationSubmittedAt: relocationDetails?.createdAt,
    orderDetailsInfo: relocationData?.relocationDetailsInfo ? relocationData?.relocationDetailsInfo : relocationData?.orderDetailsInfo,
    idcard: relocationData?.idcard,
    referencedFiles: relocationData?.referencedFiles,
    useSubstituteDetails: relocationData?.useSubstituteDetails,
    pdfLinks: relocationData?.pdfLinks,
    noOnlineCategories: relocationData?.noOnlineCategories,
  };
  dto.oldAddress.addressGroup.id = relocationDetails.addressGroupId;
  return dto;
};

export const newRelocationDetailsInfo = (
  relocationStatus: 'Abmeldung' | 'Anmeldung',
  _zustelladresse: 'from' | 'to' | '',
  _category: string,
  _customerNumber: string,
  _accountNUmber: string,
  _tariff: ITarif,
  _availabilityDetailsFernwaerme: string[],
): IOrderDetailsInfo => {
  return {
    apiVersion: ORDER_DETAILS_VERSION,
    produktDetails: null,
    invoiceAddress: null,
    eRechnungInfo: null,
    bezahlartIcon: null,
    bezahlartText: null,
    status: relocationStatus,
    zustelladresse: _zustelladresse,
    category: _category,
    customerNumber: _customerNumber,
    accountNumber: _accountNUmber,
    physischePerson: null,
    tariff: _tariff,
    availabilityDetailsFernwaerme: _availabilityDetailsFernwaerme,
  };
};
export interface OSSRelocationData {
  customer: Customer;
  address: OSSAddress;
}

export interface Customer {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
}

export interface OSSAddress {
  streetName: string;
  houseNumber: string;
  houseNumberExtension?: string;
  postalCode: string;
  cityName: string;
  isoCountryCode: string;
  additionalAddressLines?: string[];
}

export interface RelocationOssResponse {
  id: string;
  iamUserId: string;
  customer: Customer;
  address: OSSAddress;
  createdAt: string;
}
