import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { anyTrue } from '@mwe/utils'; /*
VALIDATOR_NAME_PATTERN
^[^0-9_!¡?÷?¿/\\+=@#$§€%ˆ&*(){}|~<>;:[\]]{1, }$
supports following characters
abcdefghijklmnopqrstwxyz
ABCDEFGHIJKLMNOPQRSTUVWXYZ
áéíóúäëïöüÄ'
陳大文
łŁőŐűŰZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųū
ÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁ
ŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ.-
ñÑâê都道府県Федерации
আবাসযোগ্য জমির걸쳐 있는
*/

/*
VALIDATOR_NAME_PATTERN
^[^0-9_!¡?÷?¿/\\+=@#$§€%ˆ&*(){}|~<>;:[\]]{1, }$
supports following characters
abcdefghijklmnopqrstwxyz
ABCDEFGHIJKLMNOPQRSTUVWXYZ
áéíóúäëïöüÄ'
陳大文
łŁőŐűŰZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųū
ÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁ
ŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ.-
ñÑâê都道府県Федерации
আবাসযোগ্য জমির걸쳐 있는
*/
export const VALIDATOR_NAME_PATTERN = /^[^0-9_!¡?÷?¿/\\+=@#$§%°ˆ#"`´&*()|~<>,;:]{1,}$/;

@Directive({
  selector: 'input[mweVoucherInputDirective]',
})
export class VoucherInputDirective {
  intRegExp = new RegExp('^[0-9]+$');
  inputMethods = ['integer', 'name', 'autoFocusNext'];
  blurMethods = ['leadingZero'];

  constructor(private _el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event'])
  onClick(event: any): void {
    if (event.target.attributes['directiveType']) {
      const methods = event.target.attributes['directiveType'].nodeValue.split(';');
      this.handleMethods(event, methods, this.inputMethods);
    }
  }

  @HostListener('paste', ['$event'])
  blockPaste(event: ClipboardEvent): void {
    const methods = this._el.nativeElement.attributes['directiveType'].nodeValue.split(';');
    const integerCheckFailed = methods.includes('integer') && !this.intRegExp.test(event.clipboardData.getData('Text'));
    const nameCheckFailed = methods.includes('name') && !VALIDATOR_NAME_PATTERN.test(event.clipboardData.getData('Text'));

    if (anyTrue(integerCheckFailed, nameCheckFailed)) {
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event.target'])
  onBlur(input: any): void {
    const methods = input.attributes['directiveType'].nodeValue.split(';');
    this.handleMethods(input, methods, this.blurMethods);
  }

  integer = (event: any) => {
    const pattern = this.intRegExp;
    this.checkInput(event, pattern);
  };

  leadingZero = (input: any) => {
    if (input.value < 10 && input.value.length === 1) {
      const newValue = '0' + input.value;
      this.control.control.patchValue(newValue);
    }
  };

  name = (event: any) => {
    const pattern = VALIDATOR_NAME_PATTERN;
    this.checkInput(event, pattern);
  };

  autoFocusNext = (event: any) => {
    const input = event.target;
    if (input.maxLength && input.value.length === input.maxLength) {
      this.control.control.patchValue(input.value);
      // keep the order of replace
      const nextName = input.name.replace('-month', '-year').replace('-day', '-month');
      const focusInput = document.getElementById(nextName) as HTMLInputElement;
      focusInput.focus();
      if (focusInput.type === 'text') {
        focusInput.select();
      }
    }
  };

  private checkInput(event: any, pattern: RegExp): void {
    const input = event.target;
    if (input.value && input.value.length > 0 && !pattern.test(input.value)) {
      const ix = input.value.indexOf(event.data);
      input.value = input.value.replace(event.data, '');
      input.selectionStart = input.selectionEnd = ix;
    }
  }

  handleMethods(input: any, methods: any, allowedMethods: string[]): void {
    for (let methodName of methods) {
      methodName = methodName.trim();
      let params: string = null;
      if (methodName.indexOf('(') > 0) {
        params = methodName.substring(methodName.indexOf('(') + 1, methodName.indexOf(')'));
        methodName = methodName.substring(0, methodName.indexOf('(')).trim();
      }
      if (!allowedMethods.includes(methodName)) {
        continue;
      }
      if (this[methodName]) {
        if (params === null) {
          this[methodName](input);
        } else {
          this[methodName](input, params);
        }
      }
    }
  }
}
