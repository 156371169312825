import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ICheckBoxesModel,
  IDsgvoAdditionalData,
  IDsgvoTrackingData,
  IFelder,
  IGruppen as CheckboxGruppen,
  ISaveConsentModel,
} from '@mwe/models';
import { TriggeringProcessEnum } from '@mwe/constants';
import { track } from '@mwe/utils';
import { AccountLogic, DsgvoService, DsgvoStateService, LoggingService, ProductLogic, DsgvoLogic } from '@mwe/services';
import { ServiceStateEnumExporter } from '@mwe/ui/shared';

@Component({
  selector: 'mwe-dsgvo',
  templateUrl: './dsgvo.component.html',
  styles: [],
})
export class DsgvoComponent extends ServiceStateEnumExporter implements OnInit {
  // is being passed by popupService
  @Input() loadedData: ICheckBoxesModel;
  @Input() isPopUp = true;
  @Input() showTitle = true;

  @Output() isSubmittingEvent = new EventEmitter<boolean>();
  @Output() onSubmitEventResult = new EventEmitter<boolean>();
  @Output() closePopUpEvent = new EventEmitter<void>();
  @Output() onSliderChange = new EventEmitter<void>();

  showAlert = false;
  showOptionToSaveDecisionFor365Days = false;
  saveDecisionFor365Days = false;
  submitButtonDisabled = true;
  isSubmitting = false;
  translateParams;

  constructor(
    private dsgvoService: DsgvoService,
    private loggingService: LoggingService,
    private productLogic: ProductLogic,
    private dsgvoLogic: DsgvoLogic,
    private dsgvoStateService: DsgvoStateService,
    private accountLogic: AccountLogic,
  ) {
    super();
  }
  ngOnInit(): void {
    this.loadedData.gruppen.sort((a, b) => a.reihenfolge - b.reihenfolge);
    this.showOptionToSaveDecisionFor365Days = this.dsgvoStateService.showOptionToSaveDecisionFor365Days;
    this.translateParams = {
      email: this.accountLogic.getAccountEmail(),
    };
  }

  editDsgvoData(obj: any): void {
    this.loadedData.gruppen.forEach(group => {
      if (group.reihenfolge === obj.reihe) {
        group.defaultZustand = obj.value.toString();
        this.onSliderChange.emit();
      }
    });
    this.showAlert = this.showAlert ? !this.hasEveryObligatoryFieldBeenSet() : false;
    const isEverythingSet = this.hasEveryObligatoryFieldBeenSet();
    this.submitButtonDisabled = !isEverythingSet;
  }

  hasEveryObligatoryFieldBeenSet(): boolean {
    const obligatoryGroups = this.loadedData.gruppen.filter(group => {
      return group.darstellungsTyp.includes('Schieberegler');
    });
    return obligatoryGroups.every(group => group.defaultZustand !== 'undefined');
  }

  hasEveryFieldBeenSetToStatus(value: string): boolean {
    return this.loadedData.gruppen
      .filter(group => group.istVerpflichtend === false && group.darstellungsTyp.includes('Schieberegler'))
      .every(group => group.defaultZustand?.toString() === value);
  }

  getFieldStatus(): IDsgvoAdditionalData {
    const allFieldsAccepted = this.hasEveryFieldBeenSetToStatus('true');
    const noFieldsAccepted = this.hasEveryFieldBeenSetToStatus('false');

    const fieldstatus = allFieldsAccepted ? 'fully_accepted' : !noFieldsAccepted ? 'partly_accepted' : 'nothing_accepted';
    return { dsgvoAccepted: fieldstatus };
  }

  async createSaveConsentReq(): Promise<ISaveConsentModel> {
    // TODO: better mapping?
    const req: ISaveConsentModel = {
      gruppen: [],
      gpList: [],
      ausloesenderProzess: this.getTriggeringProccess(),
    };
    if (this.loadedData.gruppen) {
      const filteredData = this.loadedData.gruppen.filter(group => group.enthaeltCheckbox === true);
      filteredData.forEach(filteredGroup => {
        const fieldArray = this.parseFields(filteredGroup);
        req.gruppen.push({
          consent: filteredGroup.defaultZustand === 'true',
          groupId: filteredGroup.groupId,
          felder: fieldArray,
        });
      });
    }
    return req;
  }

  parseFields(groups: CheckboxGruppen): IFelder[] {
    return groups.felder.map(field => ({
      wert: '1',
      id: field.id,
    }));
  }

  updateJournal(isSuccessfull: boolean, currentFieldStatus: string): Promise<void> {
    return this.dsgvoLogic.updateDsgvoEvent(isSuccessfull, currentFieldStatus, this.saveDecisionFor365Days);
  }

  getTriggeringProccess(): TriggeringProcessEnum {
    if (this.isPopUp) {
      const firstTimePopupShowsUp = !this.showOptionToSaveDecisionFor365Days;
      return firstTimePopupShowsUp ? TriggeringProcessEnum.INITIAL : TriggeringProcessEnum.RENEWED_INPUT;
    } else {
      return TriggeringProcessEnum.EDITED;
    }
  }

  setEverythingToTrue() {
    this.loadedData.gruppen.forEach(group => {
      if (group.darstellungsTyp === 'Schieberegler') {
        group.defaultZustand = 'true';
      }
    });
    this.submitButtonDisabled = false;
  }

  setEverythingToFalse() {
    this.loadedData.gruppen.forEach(group => {
      if (group.darstellungsTyp === 'Schieberegler') {
        group.defaultZustand = 'false';
      }
    });
    this.submitButtonDisabled = false;
  }

  async submit(): Promise<void> {
    if (this.hasEveryObligatoryFieldBeenSet()) {
      this.isSubmittingEvent.emit(true);
      this.showAlert = false;
      this.isSubmitting = true;
      const req: ISaveConsentModel = await this.createSaveConsentReq();
      const currentFieldStatus = JSON.stringify(this.getFieldStatus());
      await this.updateJournal(true, currentFieldStatus);
      this.dsgvoService
        .saveConsent(req)
        .then(response => {
          if (response.status === 201) {
            this.updateJournal(true, currentFieldStatus);
            this.onSubmitEventResult.emit(true);
          } else {
            this.updateJournal(false, currentFieldStatus);
            this.onSubmitEventResult.emit(false);
          }
        })
        .catch(error => {
          this.updateJournal(false, currentFieldStatus);
          this.onSubmitEventResult.emit(false);
          this.loggingService.logError(error, `could not save dsgvo-data`);
        })
        .finally(() => {
          this.isSubmittingEvent.emit(false);
          this.trackData(req);
          this.isSubmitting = false;
        });
      this.closePopUpEvent.emit();
    } else {
      this.showAlert = true;
    }
  }

  trackData(request: ISaveConsentModel) {
    const gruppen = request.gruppen;
    const trackingDataType = this.isPopUp
      ? !this.showOptionToSaveDecisionFor365Days
        ? 'pop-up-initial'
        : 'pop-up-change'
      : 'profile-change';

    const trackingData: IDsgvoTrackingData = {
      type: trackingDataType,
      event: 'marketing-permissions',
    };

    for (let i = 0; i < gruppen.length; i++) {
      const boxNr = 'box' + (i + 1);
      trackingData[boxNr] = gruppen[i].consent;
    }

    track('marketing-permissions', trackingData);
  }
}
