import { Component, OnInit } from '@angular/core';
import { environment } from '@mwe/environment';
import packageInfo from 'package.json';

@Component({
  selector: 'mwe-page-ribbon',
  template: `
    <div class="ribbon" *ngIf="ribbonEnv !== 'prod'">
      <span translate="global.ribbon.{{ ribbonEnv }}">{{ ribbonEnv }}</span>
      <span class="version">{{ version }}</span>
    </div>
  `,
  styleUrls: ['page-ribbon.scss'],
})
export class PageRibbonComponent implements OnInit {
  ribbonEnv: string;
  version: string;

  ngOnInit(): void {
    this.ribbonEnv = environment.env;
    this.version = packageInfo.version;
  }
}
