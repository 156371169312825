import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMultiProductCard } from '@mwe/models';
import { isArrayWithOneItem } from '@mwe/utils';
import { ISliderResponse } from '../product-card-slider/product-card-slider.component';

@Component({
  selector: 'mwe-multi-product-card-container',
  templateUrl: './multi-product-card-container.component.html',
  styles: [],
})
export class MultiProductCardContainerComponent implements OnInit {
  @Input() isSliderVisible = true;
  @Input() multiProductCards: IMultiProductCard[] = [];
  @Input() isDisabled = false;
  @Input() showCompanyReference = false;
  @Input() showSummarizedCompanyReference = false;
  @Input() showAccountNumber = false;
  @Input() showCustomerNumber = false;
  @Input() hasMousePointerStyle = false;
  @Output() handleSelectedIds = new EventEmitter<string[]>();
  @Output() cardClicked = new EventEmitter<IMultiProductCard>();

  selectedIds: string[] = [];

  constructor() {}

  ngOnInit(): void {
    if (isArrayWithOneItem(this.multiProductCards)) {
      this.isSliderVisible = false;
    }

    this.selectedIds = this.multiProductCards.map(mpc => mpc.id);
  }

  handleSliderResponse(response: ISliderResponse): void {
    this.selectedIds = response.checked
      ? [...this.selectedIds, response.id]
      : this.selectedIds.filter(accountNr => accountNr !== response.id);
    this.handleSelectedIds.emit(this.selectedIds);
  }
}
