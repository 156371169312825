import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavbarComponent } from './navbar.component';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  AccountLogic,
  EnvironmentService,
  HelperService,
  NavigationLogic,
  NavigationStateService,
  ProfileService,
  PopupService,
} from '@mwe/services';

@Component({
  selector: 'mwe-navbar-footer',
  templateUrl: './navbar-footer.component.html',
})
export class NavbarFooterComponent extends NavbarComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  backdrop: HTMLElement;
  isProfileOpen = false;
  userName: string;
  emailAddress: string;
  constructor(
    protected override helperService: HelperService,
    protected override profileService: ProfileService,
    protected override environmentService: EnvironmentService,
    protected override accountLogic: AccountLogic,
    protected override navigationStateService: NavigationStateService,
    protected override navigationLogic: NavigationLogic,
    private popupService: PopupService,
    private router: Router,
  ) {
    super(helperService, profileService, environmentService, accountLogic, navigationStateService, navigationLogic);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && window['drawCutOut']) {
        setTimeout(window['drawCutOut'], 10);
      }
    });
    this.subscriptions.add(routerSub);
    this.userName = this.accountLogic.getAccountFullName();
    this.emailAddress = this.accountLogic.getAccountEmail();
  }

  toggleProfile(): void {
    if (this.isProfileOpen) {
      this.closeProfile();
      return;
    }
    this.isProfileOpen = true;
    this.backdrop = document.createElement('div');
    this.backdrop.classList.add('modal-backdrop', 'fade', 'show');
    this.backdrop.style.zIndex = '1';
    this.backdrop.addEventListener('click', () => {
      this.closeProfile();
    });
    document.body.append(this.backdrop);
  }

  closeProfile(): void {
    this.isProfileOpen = false;
    this.backdrop?.remove();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout(): void {
    this.popupService.openLogoutPopup();
  }
}
