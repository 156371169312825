import { Injectable } from '@angular/core';
import { IAddressProducts, ILSystem, parseAddress, parseProducts } from '@mwe/models';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';

const customerNumberKey = 'product-add_customerNumber';
const businessPartnerNumberKey = 'product-add_businessPartnerNumber';
const lastNameKey = 'product-add_lastName';
const birthdayDDKey = 'product-add_birthdayDD';
const birthdayDMKey = 'product-add_birthdayDM';
const birthdayDYKey = 'product-add_birthdayDY';
const addressGroupsKey = 'product-add_addressGroups';
const systemIdKey = 'product-add_systemId';
const associationCompletedKey = 'product-add_associationCompleted';
const associationStatusKey = 'product-add_associationStatus';
const selectedSystemIdKey = 'product-add_selectedSystemId';
const accountIdKey = 'product-add_accountId';
const organisationNameKey = 'product-add_organisationName';

@Injectable({ providedIn: 'root' })
export class ProductAddStateService {
  @SessionStorage(customerNumberKey)
  private _customerNumber: string;
  @SessionStorage(lastNameKey)
  private _lastName: string;
  @SessionStorage(birthdayDDKey)
  private _birthdayDD: string;
  @SessionStorage(birthdayDMKey)
  private _birthdayDM: string;
  @SessionStorage(birthdayDYKey)
  private _birthdayDY: string;
  @SessionStorage(addressGroupsKey)
  private _addressGroups: [string, IAddressProducts][];
  @SessionStorage(associationCompletedKey)
  private _associationCompleted: boolean;

  // set by search response
  @SessionStorage(businessPartnerNumberKey)
  private _businessPartnerNumber: string;
  @SessionStorage(systemIdKey)
  private _systemId: ILSystem;
  @SessionStorage(associationStatusKey)
  private _associationStatus: string;

  // b2b-context
  @SessionStorage(selectedSystemIdKey)
  private _selectedSystemId: ILSystem;
  @SessionStorage(accountIdKey)
  private _accountId: string;
  @SessionStorage(organisationNameKey)
  private _organisationName: string;

  constructor(private storage: SessionStorageService) {}

  clearData(): void {
    this.storage.clear(customerNumberKey);
    this.storage.clear(businessPartnerNumberKey);
    this.storage.clear(lastNameKey);
    this.storage.clear(birthdayDDKey);
    this.storage.clear(birthdayDMKey);
    this.storage.clear(birthdayDYKey);
    this.storage.clear(addressGroupsKey);
    this.storage.clear(systemIdKey);
    this.storage.clear(associationCompletedKey);
    this.storage.clear(associationStatusKey);
    this.storage.clear(selectedSystemIdKey);
    this.storage.clear(accountIdKey);
    this.storage.clear(organisationNameKey);
  }

  get birthdayISO(): string {
    if (!this.birthdayDY || !this.birthdayDM || !this.birthdayDD) {
      return '';
    }

    return this.birthdayDY + '-' + this.birthdayDM + '-' + this.birthdayDD;
  }

  get customerNumber(): string {
    return this._customerNumber || undefined;
  }

  set customerNumber(customerNumber: string) {
    if (customerNumber) {
      this._customerNumber = customerNumber;
    } else {
      this.storage.clear(customerNumberKey);
    }
  }

  get businessPartnerNumber(): string {
    return this._businessPartnerNumber || undefined;
  }

  // set by search-response
  set businessPartnerNumber(businessPartnerNumber: string) {
    if (businessPartnerNumber) {
      this._businessPartnerNumber = businessPartnerNumber;
    } else {
      this.storage.clear(businessPartnerNumberKey);
    }
  }

  get lastName(): string {
    return this._lastName || undefined;
  }

  set lastName(lastName: string) {
    if (lastName) {
      this._lastName = lastName;
    } else {
      this.storage.clear(lastNameKey);
    }
  }

  get birthdayDD(): string {
    return this._birthdayDD || undefined;
  }

  set birthdayDD(birthdayDD: string) {
    if (birthdayDD) {
      this._birthdayDD = birthdayDD;
    } else {
      this.storage.clear(birthdayDDKey);
    }
  }

  get birthdayDM(): string {
    return this._birthdayDM || undefined;
  }

  set birthdayDM(birthdayDM: string) {
    if (birthdayDM) {
      this._birthdayDM = birthdayDM;
    } else {
      this.storage.clear(birthdayDMKey);
    }
  }

  get birthdayDY(): string {
    return this._birthdayDY || undefined;
  }

  set birthdayDY(birthdayDY: string) {
    if (birthdayDY) {
      this._birthdayDY = birthdayDY;
    } else {
      this.storage.clear(birthdayDYKey);
    }
  }

  get addressGroups(): Map<string, IAddressProducts> {
    if (!this._addressGroups || !this._addressGroups.length) {
      return undefined;
    }

    return new Map(
      this._addressGroups.map(elem => [elem[0], { address: parseAddress(elem[1].address), products: parseProducts(elem[1].products) }]),
    );
  }

  set addressGroups(addressGroups: Map<string, IAddressProducts>) {
    if (addressGroups) {
      this._addressGroups = Array.from(addressGroups);
    } else {
      this.storage.clear(addressGroupsKey);
    }
  }

  get systemId(): ILSystem {
    return this._systemId || undefined;
  }

  // set by search-response
  set systemId(systemId: ILSystem) {
    if (systemId) {
      this._systemId = systemId;
    } else {
      this.storage.clear(systemIdKey);
    }
  }

  get associationCompleted(): boolean {
    return this._associationCompleted || false;
  }

  set associationCompleted(completed: boolean) {
    this._associationCompleted = completed;
  }

  get associationStatus(): string {
    return this._associationStatus || null;
  }

  // set by search response
  set associationStatus(status: string) {
    this._associationStatus = status || null;
  }

  // b2b-context
  get selectedSystemId(): ILSystem {
    return this._selectedSystemId;
  }

  set selectedSystemId(value: ILSystem) {
    this._selectedSystemId = value;
  }

  // b2b-context
  get accountId(): string {
    return this._accountId;
  }

  set accountId(value: string) {
    this._accountId = value;
  }

  // b2b-context
  get organisationName(): string {
    return this._organisationName;
  }

  set organisationName(value: string) {
    this._organisationName = value;
  }
}
