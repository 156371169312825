<div *ngIf="(unknownBadge || activeBadge || inactiveBadge)" class="availability-badge-wrapper d-inline-block {{ customCss }}">
  <div
    class="availability-badge rounded-pill btn-sm bg--yellow pl-2 pr-2 d-inline-flex align-items-center justify-content-start"
    [ngClass]="{'bg--yellow': unknownBadge, 'bg--green': activeBadge, 'bg--red': inactiveBadge}"
    placement="bottom"
    tooltipClass="text--medium mt-1 mb-1"
    ngbTooltip="{{ hoverMessage }}"
    triggers="hover focus click"
  >
    <img class="mr-2" alt="{{ getAvailabilityIcon() }}" [src]="'assets/icons/white-' + getAvailabilityIcon()">
    <p class="text--large mb-0 text-white" data-test="availability-badge-title">{{ getAvailabilityBadgeMessage() }}</p>
  </div>
</div>
