import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IPopupModel } from '@mwe/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormComponent } from '../form/form.component';

@Component({
  selector: 'mwe-popup',
  templateUrl: './popup.component.html',
})
export class PopupComponent {
  @ViewChild(FormComponent, { static: false }) formComponent: FormComponent;
  @Input() model: IPopupModel;
  @Input() size: 'small' | 'large' = 'large';
  @Output() onAdditionalButtonClick = new EventEmitter<string>();
  constructor(private activeModal: NgbActiveModal) {}

  clickAdditionalButton(buttonId: string): void {
    this.onAdditionalButtonClick.emit(buttonId);
  }

  getAdditionalButtonId(buttonId: string, idx: string): string {
    return buttonId ? buttonId : 'mwe-additional-button-' + idx;
  }

  cancel(): void {
    this.activeModal.close('CANCEL');
  }

  submit(): void {
    if (this.model.formModel) {
      this.activeModal.close(this.formComponent.form.value);
    } else {
      this.activeModal.close(this.model.subscriptionId ? this.model.subscriptionId : 'OK');
    }
  }
}
