import { Component, inject, OnInit } from '@angular/core';
import { TermsOfUseComponent } from '@ikp/components/terms-of-use/terms-of-use.component';
import { homeRoutePrefix, ROUTE_STARTPAGE, ROUTE_TERMS_OF_USE } from '@ikp/app.constants';
import { IkpPopupService } from '@ikp/service/popup/ikp-popup.service';
import { KeycloakService } from 'keycloak-angular';
import { HeaderLogoComponent } from '@shared/layout/header/header-logo/header-logo.component';
import { Router, RouterLink } from '@angular/router';
import { FooterComponent } from '@ikp/layout/footer/footer.component';

@Component({
  selector: 'app-ikp-terms-of-use-page',
  standalone: true,
  imports: [TermsOfUseComponent, HeaderLogoComponent, FooterComponent, RouterLink],
  templateUrl: './terms-of-use-page.component.html',
  styleUrl: './terms-of-use-page.component.scss',
})
export class TermsOfUsePageComponent implements OnInit {
  popupService = inject(IkpPopupService);
  protected keycloakService = inject(KeycloakService);
  protected router = inject(Router);
  isCollapsed = true;
  isLoggedIn = false;

  async ngOnInit() {
    if (this.keycloakService.isLoggedIn()) {
      this.router.navigate([homeRoutePrefix(ROUTE_TERMS_OF_USE)]);
    }
  }

  protected readonly ROUTE_STARTPAGE = ROUTE_STARTPAGE;
}
