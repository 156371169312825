import { Component, Input, OnInit } from '@angular/core';
import { Product, TextNodeType } from '@mwe/models';
import { ServiceStateEnum } from '@mwe/constants';
import { isProductFernwaerme } from '@mwe/utils';

@Component({
  selector: 'mwe-product-tariff-card-template',
  templateUrl: './product-tariff-card-template.component.html',
})
export class ProductTariffCardTemplateComponent implements OnInit {
  @Input() product: Product;
  @Input() hasDiscount: boolean;
  @Input() state: ServiceStateEnum;
  @Input() headlineReference: TextNodeType = 'p';
  isWaerme: boolean;

  ngOnInit(): void {
    this.isWaerme = isProductFernwaerme(this.product);
  }
}
