import { Injectable } from '@angular/core';
import type { Account } from '@mwe/models';
import { LocalStorageService, SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { CookieService } from 'ngx-cookie-service';

export const accountSessionIdKey = 'account_sessionId';
const accountKey = 'account_currentaccount';
const islogwiensyncedKey = 'account_islogwiensynced';
const isBskwUserKey = 'account_isBskw';

@Injectable()
export class AccountStateService {
  @SessionStorage(accountSessionIdKey)
  private _uuid: string;

  @SessionStorage(islogwiensyncedKey)
  private _isLogwienSynced: boolean;

  @SessionStorage(isBskwUserKey)
  private _isBskwUser: boolean;

  // this service should be private, unfortunately there is no official angular-way to hide it
  // use AccountLogic to access data

  constructor(
    private storage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private cookieService: CookieService,
  ) {}

  clearBeforeCollaboration(): void {
    this.cookieService.delete(accountKey);
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(newValue: string) {
    if (!newValue) {
      this.sessionStorage.clear(accountSessionIdKey);
      return;
    }
    this._uuid = newValue;
  }

  get account(): Account {
    if (this.cookieService.check(accountKey)) {
      return JSON.parse(this.cookieService.get(accountKey));
    }
    return null;
  }

  set account(newValue: Account) {
    if (!newValue) {
      this.cookieService.delete(accountKey, '/privat');
      this.cookieService.delete(accountKey, '/business');
      return;
    }

    this.cookieService.set(accountKey, JSON.stringify(newValue), { secure: false, path: '/privat' });
    this.cookieService.set(accountKey, JSON.stringify(newValue), { secure: false, path: '/business' });
  }

  get isBskwUser(): boolean {
    return this._isBskwUser;
  }

  set isBskwUser(newValue: boolean) {
    if (newValue == undefined) {
      this.storage.clear(isBskwUserKey);
      return;
    }
    this._isBskwUser = newValue;
  }

  isImpersonate(): boolean {
    return !!this.account?.impersonator;
  }
}
