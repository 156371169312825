import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mwe-tab-buttons',
  templateUrl: './tab-buttons.component.html',
})
export class TabButtonsComponent implements OnInit {
  @Input() leftButtonId: string;
  @Input() rightButtonId: string;
  @Input() leftButtonLabel: string;
  @Input() rightButtonLabel: string;
  @Input() isActive = true;
  @Output() leftButtonAction = new EventEmitter<void>();
  @Output() rightButtonAction = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onClickLeft(action) {
    this.leftButtonAction.emit(action);
  }

  onClickRight(action) {
    this.rightButtonAction.emit(action);
  }
}
