<ng-container [ngSwitch]="headlineNodeType">
  <h1 class="{{ cssClass }}" *ngSwitchCase="'h1'">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
  </h1>
  <h2 class="{{ cssClass }}" *ngSwitchCase="'h2'">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
  </h2>
  <h3 class="{{ cssClass }}" *ngSwitchCase="'h3'">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
  </h3>
  <h4 class="{{ cssClass }}" *ngSwitchCase="'h4'">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
  </h4>
  <h5 class="{{ cssClass }}" *ngSwitchCase="'h5'">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
  </h5>
  <h6 class="{{ cssClass }}" *ngSwitchCase="'h6'">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
  </h6>
  <p class="{{ cssClass }}" *ngSwitchCase="'p'">
    <i class="fas {{ iconClass }} mr-1" *ngIf="iconClass" aria-hidden="true"></i> {{ text | translate: textParams }}
  </p>
</ng-container>
