import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { IFormModel, IFormSelect } from '@mwe/models';
import { Validators } from '@angular/forms';
import { IdCard } from '@mwe/models';
import { FormComponent } from '../form/form.component';
@Component({
  selector: 'mwe-id-card',
  templateUrl: './id-card.component.html',
  styles: [],
})
export class IdCardComponent implements OnInit {
  @ViewChild(FormComponent, { static: false }) formRef: FormComponent;
  @Output() onHandleFormValidation = new EventEmitter<IdCard>();
  @Input() idCard: IdCard;

  isValid = false;
  formModel: IFormModel;
  continuousButtonEnabled: boolean;

  idcardTypeOptions: IFormSelect[] = [
    { value: 'Führerschein', text: 'idcard.type.options.FS' },
    { value: 'Personalausweis', text: 'idcard.type.options.PA' },
    { value: 'Reisepass', text: 'idcard.type.options.RP' },
  ];

  constructor() {}

  ngOnInit(): void {
    this.initializeFormModel();
  }

  private initializeFormModel(): void {
    this.continuousButtonEnabled = false;
    if (!this.idCard) {
      this.idCard = { art: 'Ausweis', type: '', number: '', issuer: '' };
    }

    this.formModel = {
      inputs: [
        {
          name: 'id-card-type',
          initialValue: this.idCard.type,
          validators: [Validators.required],
          labelKey: 'idcard.type.label',
          placeholder: 'idcard.type.placeholder',
          validationErrorLabelKey: 'idcard.type.error',
          validate: true,
          componentType: 'select',
          options: this.idcardTypeOptions,
        },
        {
          name: 'id-card-number',
          initialValue: this.idCard.number,
          validators: [Validators.required, Validators.minLength(2)],
          labelKey: 'idcard.number.label',
          validationErrorLabelKey: 'idcard.number.error',
          validate: true,
        },
        {
          name: 'id-card-issuer',
          initialValue: this.idCard.issuer,
          validators: [Validators.required, Validators.minLength(2)],
          labelKey: 'idcard.issuer.label',
          validationErrorLabelKey: 'idcard.issuer.error',
          validate: true,
        },
      ],
    };
  }

  handleFormValidation(value: boolean, formData?: any): void {
    if (formData) {
      this.idCard = {
        art: 'Ausweis',
        type: formData['id-card-type'],
        number: formData['id-card-number'],
        issuer: formData['id-card-issuer'],
      };
      this.onHandleFormValidation.emit(this.idCard);
    }
    this.continuousButtonEnabled = value;
    this.isValid = value;
  }

  validate(): boolean {
    this.formRef.fireValidation();
    return this.isValid;
  }
}
