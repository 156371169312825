import { Injectable } from '@angular/core';

export enum MemoryCacheItem {
  InvoiceDocument = 'INVOICE_DOCUMENT',
  InvoiceDetails = 'INVOICE_DETAILS',
  InvoiceParts = 'INVOICE_PARTS',
  InvoiceList = 'INVOICE_LIST',
  ProductDetails = 'PRODUCT_DETAILS',
  PartnerDetails = 'PARTNER_DETAILS',
  UnpaidInvoices = 'UNPAID_INVOICES',
  ClearingAccountDetails = 'CLEARING_ACCOUNT_DETAILS',
}

@Injectable({ providedIn: 'root' })
export class MemoryCacheService {
  private cache = new Map<MemoryCacheItem, Map<string, unknown>>();

  has(type: MemoryCacheItem, ...args: unknown[]): boolean {
    return this.cache.get(type)?.has(JSON.stringify(args));
  }

  set(type: MemoryCacheItem, value: unknown, ...args: unknown[]): void {
    if (!this.cache.get(type)) {
      this.cache.set(type, new Map());
    }

    this.cache.get(type).set(JSON.stringify(args), value);
  }

  get<T>(type: MemoryCacheItem, ...args: unknown[]): T {
    return this.cache.get(type)?.get(JSON.stringify(args)) as T;
  }

  delete<T>(type: MemoryCacheItem, ...args: unknown[]): void {
    if (!this.cache.get(type)) {
      return;
    }

    this.cache.get(type).delete(JSON.stringify(args));
  }

  clear(type: MemoryCacheItem): void {
    this.cache.get(type)?.clear();
  }
}
