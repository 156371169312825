export interface IHTTPResponse<T> {
  status: string;
  payload: T;
}

export class HTTPResponse<T> implements IHTTPResponse<T> {
  constructor(public status: string, public system: 'BE' | 'IL' | 'WP' | 'ES', public payload: T) {
    this.status = status;
    this.system = system;
    this.payload = payload;
  }
}
