import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RPAData } from '@mwe/models';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RPADataService {
  SERVICE_API_URL = '/api/public/rpadata';

  constructor(private http: HttpClient) {}

  referenceData(reference: string): Promise<RPAData> {
    return lastValueFrom(this.http.get<RPAData>(this.SERVICE_API_URL + '/reference?reference=' + reference));
  }

  eventData(reference: string, mergeProperty: string): Promise<RPAData[]> {
    return lastValueFrom(
      this.http.get<RPAData[]>(this.SERVICE_API_URL + '/events?reference=' + reference + '&mergeProperty=' + mergeProperty),
    );
  }
}
