<div [id]="model.id" class="popup w-100 {{ size }}">
  <!-- TODO add type for icon / color -->
  <div [ngClass]="model.iconColorClass ? model.iconColorClass : 'blue'" class="button small text-white" style="pointer-events: none">
    <i [className]="model.iconTypeClass ? 'fas ' + model.iconTypeClass : 'fas fa-bell'" aria-hidden="true" class="far"></i>
  </div>
  <div class="popup-inner">
    <h3 [translate]="model.titleKey" data-test="popup-title" id="mwe_popup-title"></h3>
    <p *ngIf="model.messageKey" [innerHTML]="model.messageKey | translate" data-test="popup-message"></p>
    <mwe-form (formSubmitted)="submit()" *ngIf="model.formModel" [formModel]="model.formModel"></mwe-form>
    <div class="mt-3">
      <mwe-button-link
        (clicked)="submit()"
        *ngIf="model.showSubmitButton"
        [label]="model.submitButtonKey"
        dataTest="popup-submit-link"
      ></mwe-button-link>
    </div>

    <div class="mt-3 mb-3">
      <mwe-button-link
        (clicked)="cancel()"
        *ngIf="model.showCancelButton"
        [label]="model.cancelButtonKey"
        [url]="model.cancelButtonHref"
        color="blue"
        dataTest="popup-cancel-link"
      ></mwe-button-link>
    </div>

    <div *ngIf="model.showAdditionalButtons" class="mb-3">
      <span *ngFor="let button of model.additionalButtons; let idx = index">
        <mwe-button-link
          (clicked)="clickAdditionalButton(getAdditionalButtonId(button.buttonId, idx))"
          [btnId]="getAdditionalButtonId(button.buttonId, idx)"
          [dataTest]="'popup-additional-link-' + idx"
          [label]="button.buttonKey"
          [url]="button.buttonHref"
          color="blue"
        ></mwe-button-link>
      </span>
    </div>
    <div #testAnchorElementFeedback></div>
  </div>
</div>
