<header class="align-items-center cover bg-primary minimized px-3 px-lg-4 rounded-top">
  <div class="row h-100 align-items-center justify-content-between">
    <div class="col">
      <div class="text--big text-white">{{ loadedData.name }}</div>
    </div>
    <div class="col col-auto">
      <a
        (click)="closePopup()"
        aria-label="Vorschau schließen"
        class="text-white"
        data-dismiss="modal"
        data-test="mwe-custom-popup-close"
        href="javascript:"
      >
        <i aria-hidden="true" class="far fa-times"></i>
      </a>
    </div>
  </div>
</header>
<div class="modal-body scrollbar-thin p-0 rounded-bottom">
  @switch (loadedData.extension) {
    @case ('PDF') {
      <div class="overflow-auto" style="min-height: 70dvh">
        <object class="w-100" height="600" [data]="safeUrl()" type="application/pdf"></object>
      </div>
    }
    @default {
      <img src="{{ loadedData.base64 }}" alt />
    }
  }
</div>
