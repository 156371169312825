import { IFileModel, IFileReferenceModel, IUploadResult } from '../upload/file.model';
import dayjs from 'dayjs';
import { PreisIndikationen, TarifOption } from '../availabilities/availabilities.model';
import { NewClientDetailsDTO, NewClientMappingData } from '../new-client/new-client.model';
import { KundenTypEnum } from '../integrationlayer/tarifwechsel.model';
import { Address, IOlavEntry, parseAddress } from '../address/address.model';
import { IAktionen, IERechnungsInfo, IProduktDetails, ITarif } from '../integrationlayer/api.model';
import { IGeschaeftsPartner, IOrganisation, IPhysischePerson } from '../integrationlayer/customer.model';
import { isProductCategoryFernwaerme } from '../model_utils';
import { Verrechnungskonto } from '../invoices/invoice-overview.model';

export const ORDER_DETAILS_VERSION_CV3 = 'customer_v_3';
export const ORDER_DETAILS_VERSION = ORDER_DETAILS_VERSION_CV3;

export interface IdCard {
  art: 'Ausweis' | 'Email V2';
  type?: string;
  number?: string;
  issuer?: string;
  ip?: string;
  email?: string;
}

export interface OrderIPDFLinks {
  nutzungsbedingungen: string;
  allgemeineBedingungenStrom: string;
  allgemeineBedingungenGas: string;
  allgemeineBedingungenInternet: string;
  allgemeineBedingungenFernwaerme: string;
  allgemeineBedingungenZusatzProdukte: string;
  rueckstrittsfristFAGG: string;
}

export interface IContractDetails {
  providerChange: boolean;
  earliestSupplyAsPossible: boolean;
}

export interface IPersonalData {
  type?: KundenTypEnum;
  salutation: string;
  title?: {
    value: string;
    text: string;
  };
  firstName: string;
  lastName: string;
  birthday: string;
  telefonNumber: string;
  telefonCountryCode?: string;
  email: string;
  emailRepeat?: string;
}

export interface IBusinessData {
  type?: KundenTypEnum;
  companyName?: string;
  corporateRegisterNumber?: string;
  uidNumber?: string;
  sector?: string;
  email?: string;
  emailRepeat?: string;
  telefonNumber?: string;
  telefonCountryCode?: string;
}

export interface IOrderData {
  selectedOlav: IOlavEntry;
  newAddress: Address;
  registrationDate: string;
  orderDetails: IOrderDetailsInfo[];
  idCard: IdCard;
  uploadedFiles: IUploadResult;
  pdfLinks: OrderIPDFLinks;
  contractDetails?: IContractDetails;
  priceIndications?: PreisIndikationen[];
}

export interface IOrderDetailsInfo {
  apiVersion: string;
  status: 'Abmeldung' | 'Anmeldung' | 'Tarifwechsel' | 'Bankverbindung';
  category: string;
  verrechnungskonto?: Verrechnungskonto;
  produktDetails?: IProduktDetails;
  invoiceAddress?: Address;
  eRechnungInfo?: IERechnungsInfo;
  bezahlartIcon?: string;
  bezahlartText?: string;
  zustelladresse?: 'from' | 'to' | '';
  customerNumber?: string;
  accountNumber?: string;
  partnerDetails?: IGeschaeftsPartner;
  physischePerson?: IPhysischePerson;
  tariff?: ITarif;
  tarifOptionen?: TarifOption[];
  aktionen?: IAktionen[];
  availabilityDetailsFernwaerme?: string[];
  organisation?: IOrganisation;
}

export interface IOrderDataDTO {
  selectedOlav: IOlavEntry;
  newAddress: Address;
  registrationDate: string;
  orderDetailsInfo: IOrderDetailsInfo[];
  idcard: IdCard;
  referencedFiles: IFileReferenceModel[];
  pdfLinks: OrderIPDFLinks;
  voucherInfo?: IAktionen;
  contractDetails?: IContractDetails;
  orderedAt?: string;
  id?: number;
  priceIndications?: PreisIndikationen[];
  processStatus?: string;
  mappingData?: NewClientMappingData[];
  organisation?: IOrganisation;
}

export interface IIBANFormConfig {
  iban?: string;
  bic?: string;
  ibanLabelKey?: string;
  ibanPlaceholderKey?: string;
  ibanErrorKey?: string;
  processId?: string;
}

export const newOrderDataDTO = (orderData: IOrderData): IOrderDataDTO => {
  return {
    selectedOlav: orderData.selectedOlav,
    newAddress: orderData.newAddress,
    registrationDate: orderData.registrationDate,
    orderDetailsInfo: orderData.orderDetails,
    idcard: orderData.idCard,
    referencedFiles: orderData.uploadedFiles ? toReferrencedFiles(orderData.uploadedFiles.uploadedFiles) : null,
    pdfLinks: orderData.pdfLinks,
    contractDetails: orderData.contractDetails,
    priceIndications: orderData.priceIndications,
  };
};

export const createGeschaeftsPartnerV3 = (newClientDTO: NewClientDetailsDTO) => {
  const personalData = newClientDTO.personalData;
  return {
    adressen: null,
    bankkonten: null,
    geschaeftsPartnerId: null,
    kundensegment: null,
    nickname: null,
    organisation: null,
    systemId: null,
    physischePerson: {
      anrede: personalData.salutation,
      titel: personalData.title?.text,
      vorname: personalData.firstName,
      nachname: personalData.lastName,
      geburtsdatum: personalData.birthday,
      familienstand: null,
      geschlecht: null,
      staatsbuergerschaft: null,
      titelNachgestellt: null,
      titelVorgestellt: null,
    },
    kontaktdetails: [
      {
        fax: null,
        telefon: {
          nummer: personalData.telefonNumber,
          land: personalData.telefonCountryCode,
        },
        email: {
          eMail: personalData.email,
        },
      },
    ],
  };
};

export const createProduktDetailsV3 = (newClientDTO: NewClientDetailsDTO): IProduktDetails => {
  //TODO TWN: Richtiges Vertragskonto mappen??
  const sepaMandat = newClientDTO.sepaMandat;
  return {
    vertragskonto: {
      blz: sepaMandat.bankdaten.bankleitzahl,
      kontonummer: 'null',
      zahlungsmethode: sepaMandat.iban ? 'Bankeinzug' : 'Zahlschein',
      iban: sepaMandat.iban,
      vertragskontoId: 'null',
    },
    partner: null,
    anschlussobjekt: null,
    rechnungsempfaenger: null,
    kontostand: null,
    ablesung: null,
    verbraeuche: null,
    teilbetragsplaene: null,
    rechnungen: null,
  };
};

export const createOrderDetailsInfoV3 = (item: any, newClientDTO: NewClientDetailsDTO): IOrderDetailsInfo[] => {
  const orderDetailsInfoArr: IOrderDetailsInfo[] = [];
  newClientDTO.mappingData?.forEach(mappedData => {
    const partnerDetails = createGeschaeftsPartnerV3(newClientDTO);
    const produktDetails = createProduktDetailsV3(newClientDTO);
    const orderDetailsInfo: IOrderDetailsInfo = {
      apiVersion: item.version,
      status: 'Anmeldung',
      category: mappedData.categories[0],
      partnerDetails,
      eRechnungInfo: newClientDTO.eRechnungsInfo,
      produktDetails,
      tarifOptionen: mappedData.tarifOptionen,
      availabilityDetailsFernwaerme: isProductCategoryFernwaerme(mappedData.categories[0]) ? mappedData.tariffClasses?.[0] : [],
    };
    orderDetailsInfoArr.push(orderDetailsInfo);
  });
  return orderDetailsInfoArr;
};

export const parseOrderDataDTO = (orderDetails: any): IOrderDataDTO[] => {
  const orderDTODetails: IOrderDataDTO[] = [];
  if (Array.isArray(orderDetails)) {
    orderDetails.forEach(item => {
      if (item?.version === 'GESCHAEFTSFALL_API/V3') {
        const orderDataV3: NewClientDetailsDTO = JSON.parse(item.changeData);
        const createdOrderDetailsInfo = createOrderDetailsInfoV3(item, orderDataV3);
        const orderDataDTO = {
          selectedOlav: orderDataV3.newAddressOlav,
          newAddress: parseAddress(orderDataV3.newAddress),
          registrationDate: orderDataV3.registrationDate,
          orderDetailsInfo: createdOrderDetailsInfo,
          idcard: orderDataV3.idCard,
          referencedFiles: orderDataV3.referencedFiles,
          pdfLinks: orderDataV3.pdfLinks,
          contractDetails: orderDataV3.contractDetails,
          voucherInfo: orderDataV3.voucherInfo,
          orderedAt: item.createdAt,
          id: item.id,
          priceIndications: orderDataV3.priceIndication,
          processStatus: item.processStatus,
          mappingData: orderDataV3.mappingData,
          organisation: orderDataV3.organisation,
        };
        orderDTODetails.push(orderDataDTO);
      } else {
        const orderData = JSON.parse(item.changeData);
        const orderDataDTO = {
          selectedOlav: orderData.selectedOlav,
          newAddress: parseAddress(orderData.newAddress),
          registrationDate: orderData.registrationDate,
          orderDetailsInfo: orderData.orderDetailsInfo,
          idcard: orderData.idcard,
          referencedFiles: orderData.referencedFiles,
          pdfLinks: orderData.pdfLinks,
          contractDetails: orderData.contractDetails,
          orderedAt: item.createdAt,
          id: item.id,
          priceIndications: orderData.priceIndications,
          processStatus: item.processStatus,
        };

        orderDTODetails.push(orderDataDTO);
      }
    });
  }
  return orderDTODetails.sort((a, b) => (dayjs(a.orderedAt).isBefore(dayjs(b.orderedAt)) ? 1 : -1));
};

export const newOrderDetailsInfo = (
  relocationStatus: 'Abmeldung' | 'Anmeldung',
  _zustelladresse: 'from' | 'to' | '',
  _category: string,
  _customerNumber: string,
  _accountNUmber: string,
  _tariff: ITarif,
  _availabilityDetailsFernwaerme: string[],
): IOrderDetailsInfo => {
  return {
    apiVersion: ORDER_DETAILS_VERSION,
    produktDetails: null,
    invoiceAddress: null,
    eRechnungInfo: null,
    bezahlartIcon: null,
    bezahlartText: null,
    status: relocationStatus,
    zustelladresse: _zustelladresse,
    category: _category,
    customerNumber: _customerNumber,
    accountNumber: _accountNUmber,
    physischePerson: null,
    tariff: _tariff,
    availabilityDetailsFernwaerme: _availabilityDetailsFernwaerme,
  };
};

export const toReferrencedFiles = (uploadedFiles: IFileModel[]): IFileReferenceModel[] => {
  const referrencedFiles: IFileReferenceModel[] = [];
  if (uploadedFiles) {
    uploadedFiles.forEach(fm => referrencedFiles.push({ dokumentRefId: fm.id }));
  }
  return referrencedFiles;
};

export const parsePersonalData = (orderDetails: IOrderDetailsInfo): IPersonalData => {
  if (orderDetails.apiVersion === ORDER_DETAILS_VERSION_CV3 || orderDetails.apiVersion === 'GESCHAEFTSFALL_API/V3') {
    return {
      salutation: orderDetails.partnerDetails.physischePerson.anrede,
      title: {
        value: 'orderDetails.produktDetails.partner.titelKurz',
        text: orderDetails.partnerDetails?.physischePerson?.titel,
      },
      firstName: orderDetails.partnerDetails?.physischePerson?.vorname,
      lastName: orderDetails.partnerDetails?.physischePerson?.nachname,
      birthday: orderDetails.partnerDetails?.physischePerson?.geburtsdatum,
      telefonNumber: orderDetails.partnerDetails?.kontaktdetails[0]?.telefon.nummer,
      telefonCountryCode: orderDetails.partnerDetails?.kontaktdetails[0]?.telefon.land,
      email: orderDetails.partnerDetails?.kontaktdetails[0]?.email.eMail,
    };
  } else {
    // before customer service v3
    return {
      salutation: orderDetails.produktDetails.partner.anrede,
      title: {
        value: orderDetails.produktDetails.partner.titelKurz,
        text: orderDetails.produktDetails.partner.titel,
      },
      firstName: orderDetails.produktDetails.partner.vorname,
      lastName: orderDetails.produktDetails.partner.nachname,
      birthday: orderDetails.partnerDetails.geburtsDatum,
      telefonNumber: orderDetails.produktDetails.partner.telefon,
      email: orderDetails.produktDetails.partner.email,
    };
  }
};

export const parseBusinessData = (orderDetails: any): IBusinessData => {
  return {
    type: 'BUSINESS',
    companyName: orderDetails?.organisation?.organisationsname,
    corporateRegisterNumber: orderDetails?.organisation?.firmenbuchnummer,
    uidNumber: orderDetails?.organisation?.umsatzsteuerId,
    email: orderDetails?.personalData?.email,
    telefonNumber: orderDetails?.personalData?.telefonNumber,
    telefonCountryCode: orderDetails?.personalData?.telefonCountryCode,
  };
};

export enum AvailabilityState {
  YES = 'JA',
  NO = 'NEIN',
  UNKNOWN = 'UNBEKANNT',
  BUILDING = 'IM_GEBAEUDE',
  TECHNICAL_ERROR = 'TECHNISCHER_FEHLER',
}
