import { CallBackGroupId, CustomerSupportCallbackSuggestion, Sparte } from '@mwe/models';
import { formatDate } from '../../dates/date.utils';
import { containsStromOrGasCategory } from '../../products/product-categoriy.utils';

export const getCustomerSupportCallbackGroupId = (categories: Sparte[]): CallBackGroupId => {
  return containsStromOrGasCategory(categories) ? CallBackGroupId.StromGas : CallBackGroupId.Waerme;
};

export const getCustomerSupportCallbackQueueId = (categories: Sparte[]): string => {
  return containsStromOrGasCategory(categories) ? '5433' : '5418';
};

export const getCallbackTranslationValues = (suggestion: CustomerSupportCallbackSuggestion): any => {
  if (!suggestion) {
    return null;
  }

  const isoDate = suggestion.zeitpunkt;
  const phoneNumber = suggestion.telefon.land + suggestion.telefon.nummer;

  return {
    payment_callback_date: formatDate(isoDate, 'dddd DD.MM.YYYY'),
    payment_callback_time: formatDate(isoDate, 'HH:mm'),
    payment_callback_telefonnumber: phoneNumber,
  };
};
