import { Component, Input } from '@angular/core';
import { ServiceStateEnum } from '@mwe/constants';
import { ServiceStateEnumExporter } from '@mwe/ui/shared';
import { StaticContentService } from '@mwe/services';

@Component({
  selector: 'mwe-bullet-confirmation-view',
  templateUrl: './bullet-confirmation-view.component.html',
  styles: [],
})
export class BulletConfirmationViewComponent extends ServiceStateEnumExporter {
  @Input() errorMsgKey: string;
  @Input() errorTitleKey = 'error.service.default.title';
  @Input() bulletStateMap: Map<string, ServiceStateEnum> = new Map();
  @Input() bulletLabelMap: Map<string, string> = new Map();
  @Input() bulletStateIconMap: Map<string, Map<ServiceStateEnum, string>> = new Map();
  @Input() bulletStateClassMap: Map<string, Map<ServiceStateEnum, string>> = new Map();
  @Input() bulletTitleMap: Map<string, string> = new Map();
  @Input() bulletIds: string[];
  @Input() bulletTranslateMap: Map<string, any> = new Map();
  @Input() generalTranslateValues: any;
  @Input() alertPlaceholders: Map<string, any> = new Map();
  @Input() state: ServiceStateEnum;
  @Input() successMsgKey: string;
  @Input() bottomMsgKey: string;
  @Input() workInProgressMsgKey: string;
  @Input() successImageSrc = '/assets/img/success-animation_no_text.gif';
  @Input() useStaticSuccessImage = false;
  @Input() isSuccessImageVisible = true;

  constructor(private staticContentService: StaticContentService) {
    super();
  }

  getImagePath() {
    return this.staticContentService.getImagePath();
  }
}
