<h6 class="mb-4 mt-4" [translate]="config.wlFieldsTitleKey"></h6>
<p *ngIf="aktionsArt !== '2'" [innerHTML]="config.wlFieldsDescriptionKey | translate" class="mb-3"></p>
<p *ngIf="aktionsArt === '2'" [innerHTML]="config.wlFieldsDescriptionDigitalKey | translate" class="mb-3"></p>
<mwe-form [formModel]="formModel" (formIsValid)="handleFormValid($event)" (formIsInvalid)="handleFormInvalid($event)"></mwe-form>
<mwe-checkbox-expander-form [model]="approvalFormModel" (isValid)="approvalFormChange($event)"></mwe-checkbox-expander-form>
<div id="form-buttons" class="mb-4 submit text-center form-buttons">
  <mwe-button
    buttonId="wl-voucher-form-submit"
    [labelKey]="config.checkCodeButtonKey"
    [showSpinner]="loading"
    [disabled]="loading"
    (click)="checkForm()"
  ></mwe-button>
</div>
