<mwe-tariff-card-template
  [category]="selectedTariff?.category"
  [hasActions]="hasActions"
  [hasDiscount]="selectedTariff?.priceIndication?.istRabattiert"
  [isDiscountKnoedlVisible]="!!selectedTariff?.priceIndication?.aktionsHinweis"
  [noBoxShadow]="true"
  [state]="state"
  [subTitle]="selectedTariff?.priceIndication?.tarif?.tarifBeschreibung"
  [title]="selectedTariff?.priceIndication?.tarif?.tarifName"
  [attr.test]="'tariff-card-' + selectedTariff?.category.toLowerCase()"
>
  <mwe-pre-load-data
    [state]="state"
    [successTemplate]="success"
    [errorTemplate]="error"
    loadingSpinnerType="normal-without-box"
  ></mwe-pre-load-data>
  <ng-container slot="actions">
    <ng-content></ng-content>
  </ng-container>
</mwe-tariff-card-template>

<ng-template #error>
  <div class="text-center">
    <img [src]="'error.svg' | addStaticImagePath" />
    <p class="mt-3" translate="tariff.error.reloadPriceIndications.card.info"></p>
    <mwe-button-link
      color="blue"
      label="tariff.error.reloadPriceIndications.card.reloadButton"
      (clicked)="reloadAfterError.emit()"
    ></mwe-button-link>
  </div>
</ng-template>

<ng-template #success>
  <div>
    <div class="text-center">
      <p [attr.data-test]="getTestId('arbeitspreis-label')" class="mb-0">
        {{ selectedTariff.priceIndication.arbeitspreisLabel }}
      </p>
      <p [attr.data-test]="getTestId('arbeitspreis-value')" class="fs-2 fw-bold text-secondary mb-0 lh-1">
        {{ selectedTariff.priceIndication.arbeitspreisZahlbar }}
      </p>
      @if (!selectedTariff.priceIndication.arbeitspreisSubtext) {
        <p [attr.data-test]="getTestId('arbeitspreis-unit')" class="fw-semibold text-secondary">
          {{ selectedTariff.priceIndication.arbeitspreisUnit }}
        </p>
      }
      @if (selectedTariff.priceIndication.arbeitspreisSubtext) {
        <p [attr.data-test]="getTestId('arbeitspreis-unit')">
          {{ selectedTariff.priceIndication.arbeitspreisSubtext }}
        </p>
      }
    </div>
    <hr />

    <div class="mb-3" role="list">
      <mwe-tariff-selection-card-ui-row [isReadOnly]="true" [attr.data-test]="getTestId('grundpreis')">
        <ng-container slot="left">
          <p [attr.data-test]="getTestId('grundpreis-label')" class="fw-semibold text-secondary mb-0">
            {{ selectedTariff.priceIndication.grundpreisLabel }}:
          </p>
        </ng-container>
        <ng-container slot="right">
          <p
            [attr.data-test]="getTestId('grundpreis-value')"
            class="{{ selectedTariff.priceIndication.grundpreisSubtext ? 'fw-semibold text-secondary' : '' }} mb-0"
          >
            {{ selectedTariff.priceIndication.grundpreisZahlbar }} {{ selectedTariff.priceIndication.grundpreisUnit }}
          </p>
          @if (selectedTariff.priceIndication.grundpreisSubtext) {
            <p class="mb-0">{{ selectedTariff.priceIndication.grundpreisSubtext }}</p>
          }
        </ng-container>
      </mwe-tariff-selection-card-ui-row>

      @for (item of groups; track item) {
        <mwe-tariff-selection-card-ui-row [isReadOnly]="item.isReadOnly" [attr.data-test]="getTestId(item.id)">
          <ng-container slot="left">
            <mwe-info-text [infoTextCode]="item.infoTextCode">
              <p class="fw-semibold text-secondary mb-0" [attr.data-test]="getTestId(item.id + '-label')">{{ item.label }}:</p>
            </mwe-info-text>
          </ng-container>
          <ng-container slot="right">
            <mwe-tariff-option-dropdown
              [options]="item.options"
              [selectedTariffOptionId]="item.selectedTariffOptionId"
              [selectedTariffGroupId]="item.id"
              [isReadonly]="item.isReadOnly"
              (optionSelected)="onOptionSelected(item, $event)"
            ></mwe-tariff-option-dropdown>
          </ng-container>
        </mwe-tariff-selection-card-ui-row>
      }
    </div>
    <hr />
    <div class="mb-3" role="list">
      @for (label of advantages; track label) {
        <div class="mb-2 row gx-2" role="listitem" [attr.data-test]="getTestId('vorteile')">
          <div class="col-auto">
            <div class="green-check vertical-center">
              <i class="fas fa-check text-center fw-normal" aria-hidden="true"></i>
            </div>
          </div>
          <div class="col align-self-center">
            <p class="fw-semibold text-secondary mb-0">{{ label }}</p>
          </div>
        </div>
      }
    </div>

    @if (selectedTariff.priceIndication.aktionsHinweis) {
      <div class="mb-2 row gx-2" [attr.data-test]="getTestId('available-campaign-hint')">
        <div class="col-auto">
          <div
            class="d-flex align-items-center justify-content-center rounded-pill text-white text-center bg-warning"
            style="width: 1.5rem; height: 1.5rem; font-size: 0.75rem"
          >
            <i class="fa fa-gift text-center fw-normal" aria-hidden="true"></i>
          </div>
        </div>
        <div class="fw-semibold text-secondary col">
          {{ selectedTariff.priceIndication.aktionsHinweis }}
        </div>
      </div>
    }

    @for (item of activeCampaignTexts; track item) {
      <mwe-icon-info-box
        [boxTitle]="item.aktionsLabel"
        [info]="item.aktionsDetails"
        color="green"
        [attr.data-test]="getTestId('active-campaign')"
      ></mwe-icon-info-box>
    }
  </div>
  <div [attr.data-test]="getTestId('legal-text')" class="text-center text--legal mt-3">
    {{ selectedTariff.priceIndication.rechtsTextGueltig }}
  </div>
</ng-template>
