import { Injectable } from '@angular/core';
import { IAddress, IOrderDetailsInfo, parseAddress, Product } from '@mwe/models';
import {
  DetailsLinkTypeEnum,
  PRIVATE_ROUTE,
  ProductCategoryEnum,
  RELOCATION_NEW_ADDRESS_ROUTE,
  RELOCATION_ERROR_ROUTE,
  RELOCATION_ROUTE,
  RELOCATION_STATUS_ROUTE,
} from '@mwe/constants';
import { Router } from '@angular/router';
import { isProductCategory } from '@mwe/utils';
import { RelocationStateService } from './relocation-state.service';
import { AddressGroupService } from '../address/address-group.service';

@Injectable({
  providedIn: 'root',
})
export class RelocationLogic {
  constructor(
    private relocationStateService: RelocationStateService,
    private router: Router,
    private addressGroupService: AddressGroupService
  ) {}

  initRelocationStatusProcess(address: IAddress): void {
    this.router.navigate([`/${RELOCATION_ROUTE}/${RELOCATION_STATUS_ROUTE}`]).then(() => {
      // to prevent state-clear from wizard/ngOnDestroy
      this.relocationStateService.relocationFromAddress = parseAddress(address);
    });
  }

  async initRelocationProcess(products: Product[]): Promise<void> {
    const address = parseAddress(products[0].address);
    this.relocationStateService.relocationFromAddress = address;
    this.relocationStateService.relocationFromProducts = products;
    await this.addressGroupService.loadAndMapGroupNames([address]);
    this.relocationStateService.addressDetailsLinkType = address.addressGroup?.detailsLinkType;

    if (this.relocationStateService.addressDetailsLinkType === DetailsLinkTypeEnum.MOVEOUT_IGNORE_ERROR) {
      this.relocationStateService.addressDetailsLinkType = null;
    }

    const url = this.checkIfRelocationIsAllowed(products) ? RELOCATION_NEW_ADDRESS_ROUTE : RELOCATION_ERROR_ROUTE;
    this.router.navigate([`/${PRIVATE_ROUTE}/${RELOCATION_ROUTE}/${url}`]);
  }

  checkIfRelocationIsAllowed(products: Product[]): boolean {
    if (!Array.isArray(products) || products.length <= 1) {
      return true;
    }

    const stromGasArray = products.filter(
      product =>
        isProductCategory(product.category, ProductCategoryEnum.STROM) || isProductCategory(product.category, ProductCategoryEnum.GAS)
    );

    const fernwaermeArray = products.filter(product => isProductCategory(product.category, ProductCategoryEnum.FERNWAERME));

    return this.isThereOnlyOneUniqueCustomer(stromGasArray) && this.isThereOnlyOneUniqueCustomer(fernwaermeArray);
  }

  isThereOnlyOneUniqueCustomer(products: Product[]): boolean {
    if (!Array.isArray(products) || products.length <= 1) {
      return true;
    }

    const customerNumbers = products.map(product => product.customerNumber);
    const uniqueCustomerNumbers = Array.from(new Set(customerNumbers));

    return uniqueCustomerNumbers.length === 1;
  }

  getProductsForNoOnlineCategories(orderDetails: IOrderDetailsInfo[], noOnlineCategories: string[]): Product[] {
    const noOnlineProducts = [];
    if (noOnlineCategories) {
      orderDetails.forEach(od => {
        if (noOnlineCategories.includes(od.category) && od.status === 'Abmeldung') {
          noOnlineProducts.push({ category: od.category, tariffClasses: [od.tariff.name] });
        }
      });
    }
    return noOnlineProducts;
  }
}
