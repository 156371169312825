import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ADD_PRODUCTS_ROUTE, ADD_PRODUCTS_SEARCH_ROUTE } from '@mwe/constants';
import { NavigatingBackTo } from '@mwe/models';
import { EnvironmentService, NavigationStateService } from '@mwe/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mwe-no-associations-found-warning',
  templateUrl: './no-associations-found-warning.component.html',
})
export class NoAssociationsFoundWarningComponent implements OnInit {
  @Input() noAddressGroupsTitle: string;
  @Input() noAddressGroupsText: string;
  @Input() navigatingBackTo: NavigatingBackTo;
  @Output() productSearchBtnClicked = new EventEmitter<void>();

  translateValues;

  constructor(
    private router: Router,
    private navigationStateService: NavigationStateService,
    private translateService: TranslateService,
    private environmentService: EnvironmentService,
  ) {}

  ngOnInit(): void {
    const prefixLabel = `shared.noAddressGroupsError.productPrefix_${this.environmentService.getPortalCustomerType()}`;
    const prefix = this.translateService.instant(prefixLabel);
    this.translateValues = { prefix };

    if (!this.noAddressGroupsTitle) {
      this.noAddressGroupsTitle = 'shared.noAddressGroupsError.title';
    }
  }

  navigateToProductSearch(): void {
    this.productSearchBtnClicked.emit();
    this.navigationStateService.addNavigatingBackTo(this.navigatingBackTo);
    this.router.navigateByUrl(`/${ADD_PRODUCTS_ROUTE}/${ADD_PRODUCTS_SEARCH_ROUTE}`);
  }
}
